import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { fetchConToken, fetchConTokenFile,handleResponse } from 'helpers/fetch';
import { getDataSession } from 'views/Utils/Common';
import Pagination from 'components/pagination/Pagination';
import { descargarFactura } from 'actions/archivos';
import TituloPagina from 'components/header/TituloPagina';

import Swal from 'sweetalert2'




const OpinionCumplimiento = ({ match, saludo, subtitulo }) => {
    let [descargaActiva, setDescargaActiva] = useState(false);
    const [PDF,setPDF] = useState(['']);

     function DescargarOpinion()
     {
        verFactura();
     }

     const verFactura =  () => {
        setDescargaActiva(true)
        var url = "HerramientasSAT/OpinionCumplimientoPDF"
        fetchConTokenFile(url)
        .then(response => {
            if(response.status == 200)
            {
                return response.blob()
            }
            else{
                handleResponse(response).then(data=>{
                    if (data.hasOwnProperty('status')) {
                        var erroresA = {};
                        Object.entries(data?.errors).map(([key, value]) => {
                            Swal.fire('Error', value[0], 'error');
                            return;
                        });
                        return false;
                    } else if (data.hasOwnProperty('codigo')) {
                        Swal.fire('Error', data.mensajes[0], 'error');
                        return;
                    }
                })
            }
        })
        .then(function(blob) {
            const fileURL = URL.createObjectURL(blob);
            setPDF(fileURL);
          })
          setDescargaActiva(false)
    };

    return (
        <>
            <div className='container-fluid'>
                <TituloPagina 
                    titulo='Opinión de cumplimiento' 
                    descripcion='Solicitar la Opinión de cumplimiento al SAT' 
                />
                <div className='col-md-12 row '>
                <button
                                className='btn btn-outline-primary m-1'
                                onClick={(e) => DescargarOpinion()}
                                style={{ width: 'auto' }}
                                disabled={descargaActiva}
                            >
                                <svg
                                    xmlns='http://www.w3.org/2000/svg'
                                    width='20'
                                    height='20'
                                    fill='currentColor'
                                    className='bi bi-cloud-arrow-down-fill'
                                    viewBox='0 0 16 16'
                                >
                                    <path d='M8 2a5.53 5.53 0 0 0-3.594 1.342c-.766.66-1.321 1.52-1.464 2.383C1.266 6.095 0 7.555 0 9.318 0 11.366 1.708 13 3.781 13h8.906C14.502 13 16 11.57 16 9.773c0-1.636-1.242-2.969-2.834-3.194C12.923 3.999 10.69 2 8 2zm2.354 6.854-2 2a.5.5 0 0 1-.708 0l-2-2a.5.5 0 1 1 .708-.708L7.5 9.293V5.5a.5.5 0 0 1 1 0v3.793l1.146-1.147a.5.5 0 0 1 .708.708z' />
                                </svg>
                                &nbsp; Solicitar Opinión
                            </button>
                            &nbsp;
                </div>
                <div className='col-md-12' >
                   <div className="row" style={{padding: '21px'}}>
                    <embed
                        src={PDF}
                        type="application/pdf"
                        height={800}
                        width={500}
                    />
                    </div>
                </div>
            </div>
         
        </>
    );
};


export { OpinionCumplimiento };
