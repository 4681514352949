import React, { useState,useEffect } from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { fetchConToken, fetchConTokenFile } from 'helpers/fetch';
import { getDataSession, setDataSession } from 'views/Utils/Common';
import Pagination from 'components/pagination/Pagination';
import TituloPagina from 'components/header/TituloPagina';
import { alertService } from '_services';
import * as FileSaver from 'file-saver';
import { currency } from 'resources/utilities';
import { initMercadoPago, Wallet } from '@mercadopago/sdk-react'
import Swal from 'sweetalert2';
import moment from 'moment';

const $ = require('jquery');
initMercadoPago(process.env.REACT_APP_MERCADO_PAGO_PUBLIC_KEY);

const Compra = ({ match, saludo, subtitulo }) => {
    
    const { path } = match;

    let [data, setData] = useState([]);

    let [errores, setErrores] = useState({});
    let [error, setError] = useState(['']);
    const [preferencia_id, setPreferencia_id] = useState(null);

    async function getData() {
        const response = await fetchConToken(`Carritos`);
        setData(response);
    }

    function actualizarCarrito(event) {
        setErrores({});
        setError('');
        fetchConToken(`Carritos?paquete_id=${event.target.name}&cantidad=${event.target.value}`, null, 'POST').then(
            (response) => {

                if (response.hasOwnProperty('status')) {
                    Object.entries(response.errors).map(([key, value]) => {
                        Swal.fire('Error', value[0], 'error');
                        return false;
                    });
                    return false;
                    } else if (response.hasOwnProperty('codigo')) {
                        Swal.fire('Error', response.mensajes[0], 'error');
                        return false;
                    }

                setData(response)
            }
        )
    }

    function agregarCupon(event) {
        fetchConToken(`Carritos?paquete_id=${event.target.name}&cantidad=${event.target.value}`, null, 'POST').then(
            (response) => {

                if (response.hasOwnProperty('status')) {
                    Object.entries(response.errors).map(([key, value]) => {
                        Swal.fire('Error', value[0], 'error');
                        return false;
                    });
                    return false;
                    } else if (response.hasOwnProperty('codigo')) {
                        Swal.fire('Error', response.mensajes[0], 'error');
                        return false;
                    }

                setData(response)
            }
        )
    }


    async function eliminarDetalle(id) {
        await fetchConToken(`Carritos/DeleteDetalle?paquete_id=${id}`,null,'DELETE').then(
            (response) => {

                if (response.hasOwnProperty('status')) {
                    Object.entries(response.errors).map(([key, value]) => {
                        Swal.fire('Error', value[0], 'error');
                        return false;
                    });
                    return false;
                    } else if (response.hasOwnProperty('codigo')) {
                        Swal.fire('Error', response.mensajes[0], 'error');
                        return false;
                    }

                    setDataSession('carrito', response.carrito_Detalle.length);
                    window.location.href = window.location.href;
            })
        
    }

    function desabilitarBtn(){
        $("#btnFinalizar").prop( "disabled", false );
        $("#btnFinalizar").css( "background", "rgb(63, 215, 123)" );
    }
    const delay = ms => new Promise(
        resolve => setTimeout(resolve, ms)
      );

    async function finalizar() {
 
        $("#btnFinalizar").prop( "disabled", true );
        $("#btnFinalizar").css( "background", "rgb(1, 41, 95)" );
       var url = `MercadoPago/PreferenciaPago?carrito_id=${data.id}`;
       var peticion = await fetchConToken(url,null,'POST').then(
        (response) => {
            if (response.hasOwnProperty('status') ) {
                Object.entries(response.errors).map(([key, value]) => {
                    Swal.fire('Error', value[0], 'error');
                    desabilitarBtn();
                    return;
                });
                desabilitarBtn();
                return false;
            } else if (response.hasOwnProperty('codigo') ) {
                Swal.fire('Error', response.mensajes[0], 'error');
                desabilitarBtn();
                return false;
            }

               setPreferencia_id(response.preferencia_id);  
               //await delay(1000);
               
            });    
            await delay(2000);
            $("#walletBrick_container button").click();
            desabilitarBtn();
        }

        /*useEffect(() => {
            console.log("actualizo")
            console.log(preferencia_id)
            if(preferencia_id){
                console.log("actualizo2")
                $("#walletBrick_container button").click();
            }
            
        }, [preferencia_id]);*/

        React.useEffect(() => {
            getData();
            $("#walletBrick_container").css("display", "none");
            
        }, []);

    return (
        <>
            <div className='container-fluid'>
                <TituloPagina
                    titulo={'Mi carrito (' + getDataSession('carrito') + ' paquetes )'}
                    descripcion=''
                />
                <div className='col-md-12 row'>
                    <div className='col-md-8'>
                        {data.carrito_Detalle?.map((item) => {
                            return (
                                <>
                                    <div
                                        className='row'
                                        style={{
                                            background: '#F4F7FF',
                                            border: '1px solid #F0F0F0',
                                            boxShadow: '8px 8px 36px rgba(30, 56, 136, 0.30)'
                                        }}
                                    >
                                        <div
                                            className='col-md-2 col-sm-2 col-2 text-center'
                                            style={{
                                                paddingTop: '35px',
                                                paddingBottom: '35px',
                                                paddingLeft: '5px',
                                                background:
                                                    'linear-gradient(157.04deg, #0F5EF6 38.3%, #232ED1 121.11%)',
                                                borderRadius: '8px',
                                                color: '#fff'
                                            }}
                                        >
                                            <h4>Paquete</h4>
                                            <h2>{item.paquete_Detalle.cantidad_Timbres}</h2>
                                            <h3>
                                                <b>Timbres</b>
                                            </h3>
                                        </div>
                                        <div
                                            className='col-md-5 col-sm-5 col-5'
                                            style={{ paddingTop: '20px' }}
                                        >
                                            <label style={{ color: '#000' }}>
                                                <b>
                                                    Total{' '}
                                                    {item.paquete_Detalle.cantidad_Timbres * item.cantidad}{' '}
                                                    Timbres
                                                </b>
                                            </label>
                                            <br></br>
                                            <label style={{ color: '#232ED1' }}>
                                                <b>Paquete de facturación electrónica </b>
                                            </label>
                                            <label style={{ color: '#C3C5D2' }}>
                                                <b>{item.paquete_Detalle.nombre}</b>
                                            </label>
                                            <br></br>
                                            <button
                                                className='btn btn-outline-secondary'
                                                type='button'
                                                style={{ color: '#C3C5D2' }}
                                                onClick={() => eliminarDetalle(item.paquete_Id)}
                                            >
                                                <svg
                                                    xmlns='http://www.w3.org/2000/svg'
                                                    width='16'
                                                    height='16'
                                                    fill='currentColor'
                                                    className='bi bi-trash'
                                                    viewBox='0 0 16 16'
                                                >
                                                    <path d='M5.5 5.5A.5.5 0 0 1 6 6v6a.5.5 0 0 1-1 0V6a.5.5 0 0 1 .5-.5zm2.5 0a.5.5 0 0 1 .5.5v6a.5.5 0 0 1-1 0V6a.5.5 0 0 1 .5-.5zm3 .5a.5.5 0 0 0-1 0v6a.5.5 0 0 0 1 0V6z' />
                                                    <path
                                                        fillRule='evenodd'
                                                        d='M14.5 3a1 1 0 0 1-1 1H13v9a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2V4h-.5a1 1 0 0 1-1-1V2a1 1 0 0 1 1-1H6a1 1 0 0 1 1-1h2a1 1 0 0 1 1 1h3.5a1 1 0 0 1 1 1v1zM4.118 4 4 4.059V13a1 1 0 0 0 1 1h6a1 1 0 0 0 1-1V4.059L11.882 4H4.118zM2.5 3V2h11v1h-11z'
                                                    />
                                                </svg>
                                                &nbsp;Eliminar
                                            </button>
                                        </div>
                                        <div
                                            className='col-md-5 col-sm-5 col-5 row'
                                            style={{ paddingTop: '20px' }}
                                        >
                                            <div className='row'>
                                                <div className='col-md-12'>
                                                    <label style={{ color: '#01295F' }}>
                                                        Fecha Caducidad
                                                    </label>
                                                </div>
                                            </div>
                                            <label style={{ color: '#01295F' }}>{moment().add(item.paquete_Detalle?.dias_Duracion, 'days').format('DD/MM/Y')}</label>
                                            <div className='col-md-6'>
                                                <label
                                                    style={{
                                                        color: '#01295F',
                                                        textDecorationLine: 'line-through'
                                                    }}
                                                >
                                                    {item.total !== item.subtotal
                                                        ? `${currency(item.subtotal || 0)} MXN`
                                                        : ''}
                                                </label>
                                            </div>
                                            <div className='col-md-6'>
                                                <label style={{ color: '#F13C3C' }}>
                                                    {currency(item.total || 0)} MXN
                                                </label>
                                            </div>
                                            <label>
                                                Tienes {item.descuento_Porcentaje || 0}% de descuento
                                            </label>
                                        </div>
                                    </div>
                                    <hr></hr>
                                </>
                            );
                        })}
                        <br></br>
                    </div>
                    <div className='col-md-4 col-sm-4 col-12'>
                    <p style={{ color: 'red' }}>{error}</p>
                        {Object.entries(errores).map(([key, value]) => {
                            return (
                                <>
                                    <p style={{ color: 'red' }}>{value}</p>
                                </>
                            );
                        })}
                        <div
                            style={{
                                border: '1px solid #F0F0F0',
                                boxShadow: '0px 0px 14px rgb(30 56 136 / 50%)',
                                padding: '22px 37px'
                            }}
                        >
                            <table style={{ width: '100%' }}>
                                <thead></thead>
                                <tbody>
                                    <tr>
                                        <th colSpan='2'>
                                            <h2>Total</h2>
                                        </th>
                                    </tr>
                                    <tr>
                                        <th colSpan='2'>
                                            <hr></hr>
                                        </th>
                                    </tr>
                                    <tr>
                                        <td className='text-left'>Subtotal</td>
                                        <td className='text-right'>{currency(data.subtotal || 0)} MXN</td>
                                    </tr>
                                    {/*<tr>
                                        <td className='text-left'>Descuento</td>
                                        <td className='text-right'>{currency(data.descuento_Total || 0)} MXN</td>
                        </tr>*/}
                                    <tr>
                                        <th colSpan='2'>
                                            <hr></hr>
                                        </th>
                                    </tr>
                                    <tr>
                                        <td className='text-left'>Total (más IVA incluido )</td>
                                        <td className='text-right'>{currency(data.total || 0)} MXN</td>
                                    </tr>
                                    <tr>
                                    </tr>
                                    <tr>
                                        <td colSpan='2'>
                                            <br></br>

                                      
                                        <button id="btnFinalizar" className='btn' style={{width: '100%', background: '#3FD77B', color: '#fff'}} onClick={() => finalizar()}>
                                               <svg
                                                    xmlns='http://www.w3.org/2000/svg'
                                                    width='16'
                                                    height='16'
                                                    fill='currentColor'
                                                    className='bi bi-bag-check-fill'
                                                    viewBox='0 0 16 16'
                                                >
                                                    <path
                                                        fillRule='evenodd'
                                                        d='M10.5 3.5a2.5 2.5 0 0 0-5 0V4h5v-.5zm1 0V4H15v10a2 2 0 0 1-2 2H3a2 2 0 0 1-2-2V4h3.5v-.5a3.5 3.5 0 1 1 7 0zm-.646 5.354a.5.5 0 0 0-.708-.708L7.5 10.793 6.354 9.646a.5.5 0 1 0-.708.708l1.5 1.5a.5.5 0 0 0 .708 0l3-3z'
                                                    />
                                                </svg>
                                                &nbsp; Finalizar la compra
                                        </button>
                                        
                                        <Wallet initialization={{ preferenceId: preferencia_id, redirectMode: 'modal' }}/> 
                                        
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                        <br></br>
                        {/*<div
                            className='col-md-12 row'
                            style={{
                                fontWeight: '500',
                                border: '1px solid #F0F0F0',
                                boxShadow: '0px 0px 14px rgb(30 56 136 / 50%)',
                                padding: '20px 20px 20px 0px',
                                marginLeft: '0px'
                            }}
                        >
                            <label>Código de promoción</label>
                            <div className='col-md-8'>
                                <input
                                    type='text'
                                    className='form-control'
                                    placeholder='Ingresa tu código'
                                />
                            </div>
                            <div className='col-md-4'>
                                <button
                                    className='btn'
                                    style={{ background: '#01295F', color: '#fff' }}
                                    disabled
                                >
                                    Aplicar descuento
                                </button>
                            </div>
                        </div>*/}
                    </div>
                </div>
            </div>
        </>
    );
};

export { Compra };
