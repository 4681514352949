import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { useForm } from 'react-hook-form';

import { fetchConToken } from 'helpers/fetch';
import { alertService } from '_services';

import Swal from 'sweetalert2';

const AddEdit = ({ history, match }) => {
    const path = match.url;
    const { id } = match.params;
    const isAddMode = !id;
    const { register, handleSubmit, reset } = useForm();

    let [errores, setErrores] = useState({});
    let [error, setError] = useState(['']);

    function onSubmit(data) {
        return isAddMode ? create(data) : update(id, data);
    }

    function create(data) {
        setErrores({});
        setError('');
        if(data.concepto == "0" || data.concepto.length == 0)
            {
                Swal.fire("info","Seleccione un concepto","info");
                return;
            }

        return fetchConToken('TiposImpuestos', data, 'POST')
            .then((response) => {

                if (response.hasOwnProperty('status')) {
                    var erroresA = {};
                    Object.entries(response.errors).map(([key, value]) => {
                        erroresA[key] = value[0];
                        setErrores(erroresA);
                    });
                    return false;
                    } else if (response.hasOwnProperty('codigo')) {
                                        setError(response.mensajes[0]);
                        return false;
                    }
                alertService.success('TIpo de impuesto agregado', { keepAfterRouteChange: true });
                history.push('.');
            })
            .catch(alertService.error);
    }

    function update(id, data) {
        setErrores({});
        setError('');
        console.log(data);
        if(data.concepto == "0" || data.concepto.length == 0)
            {
                Swal.fire("info","Seleccione un concepto","info");
                return;
            }

        return fetchConToken('TiposImpuestos', data, 'PUT')
        .then((response) => {
            console.log(response);
            if (response.hasOwnProperty('status')) {
                var erroresA = {};
                Object.entries(response.errors).map(([key, value]) => {
                    erroresA[key] = value[0];
                    setErrores(erroresA);
                });
                return false;
                } else if (response.hasOwnProperty('codigo')) {
                                    setError(response.mensajes[0]);
                    return false;
                }

                alertService.success('Tipo de impuesto modificado', { keepAfterRouteChange: true });
                history.push('..');
            
            })
            .catch(alertService.error);
    }

    const [impuesto, setImpuesto] = useState({
        descripcion: "IVA",
        clave: "002",
        porcentaje: 0,
        nombre: "",
        tipo_Factor: "Tasa",
        concepto: "traslado"
    });


    useEffect(() => {
        if (!isAddMode) {
            fetchConToken(`TiposImpuestos?id=${id}`).then(
                (imp) => {
                    setImpuesto(imp);
                }
            );
        }
    }, []);

    // effect runs when user state is updated
    useEffect(() => {
        // reset form with user data
        reset(impuesto);
        // eslint-disable-next-line
    }, [impuesto]);

    const handleInputChange = ({ target }) => {
        setImpuesto({
            ...impuesto,
            [target.name]: target.value
        });
        console.log(impuesto);
    }

    return (
        <>
            <div className='container-fluid'>
                <form onSubmit={handleSubmit(onSubmit)} /*onReset=""*/>
                    <div className='mb-3'>
                        <div className='row' style={{ textAlign: 'left' }}>
                            <div className='col-sm-6  col-md-6'>
                                <h3>{isAddMode ? 'Agregar artículo' : 'Editar artículo'}</h3>
                            </div>
                            <div className='col-sm-6  col-md-6' style={{ textAlign: 'right' }}>
                                <button className='btn btn-outline-success' type='submit'>
                                    <svg
                                        xmlns='http://www.w3.org/2000/svg'
                                        width='20'
                                        height='20'
                                        fill='currentColor'
                                        className='bi bi-check-lg'
                                        viewBox='0 0 16 16'
                                    >
                                        <path d='M12.736 3.97a.733.733 0 0 1 1.047 0c.286.289.29.756.01 1.05L7.88 12.01a.733.733 0 0 1-1.065.02L3.217 8.384a.757.757 0 0 1 0-1.06.733.733 0 0 1 1.047 0l3.052 3.093 5.4-6.425a.247.247 0 0 1 .02-.022Z' />
                                    </svg>
                                    &nbsp; Guardar
                                </button>
                                &nbsp;
                                <Link
                                    to={isAddMode ? '.' : '..'}
                                    className='btn btn-outline-warning'
                                >
                                    <svg
                                        xmlns='http://www.w3.org/2000/svg'
                                        width='20'
                                        height='20'
                                        fill='currentColor'
                                        className='bi bi-reply'
                                        viewBox='0 0 16 16'
                                    >
                                        <path d='M6.598 5.013a.144.144 0 0 1 .202.134V6.3a.5.5 0 0 0 .5.5c.667 0 2.013.005 3.3.822.984.624 1.99 1.76 2.595 3.876-1.02-.983-2.185-1.516-3.205-1.799a8.74 8.74 0 0 0-1.921-.306 7.404 7.404 0 0 0-.798.008h-.013l-.005.001h-.001L7.3 9.9l-.05-.498a.5.5 0 0 0-.45.498v1.153c0 .108-.11.176-.202.134L2.614 8.254a.503.503 0 0 0-.042-.028.147.147 0 0 1 0-.252.499.499 0 0 0 .042-.028l3.984-2.933zM7.8 10.386c.068 0 .143.003.223.006.434.02 1.034.086 1.7.271 1.326.368 2.896 1.202 3.94 3.08a.5.5 0 0 0 .933-.305c-.464-3.71-1.886-5.662-3.46-6.66-1.245-.79-2.527-.942-3.336-.971v-.66a1.144 1.144 0 0 0-1.767-.96l-3.994 2.94a1.147 1.147 0 0 0 0 1.946l3.994 2.94a1.144 1.144 0 0 0 1.767-.96v-.667z' />
                                    </svg>
                                    &nbsp; Regresar &nbsp;
                                </Link>
                            </div>
                        </div>
                    </div>

                    <div className='row'>
                        <p style={{ color: 'red' }}>{error}</p>
                        {Object.entries(errores).map(([key, value]) => {
                            return (
                                <>
                                    <p style={{ color: 'red' }}>{value}</p>
                                </>
                            );
                        })}
                        <div className='col-md-12' style={{ display: 'grid' }}>
                            <div className='card mb-3 '>
                                <div className='card-body text-start'>
                                    <label htmlFor='clave' className='form-label'>
                                        Clave
                                    </label>
                                    <input
                                        type='text'
                                        className='form-control'
                                        name='clave'
                                        required={true}
                                        maxLength={3}
                                        value={impuesto.clave}
                                        onChange={handleInputChange}
                                    />

                                    <label htmlFor='nombre' className='form-label'>
                                        Nombre
                                    </label>
                                    <input
                                        type='text'
                                        className='form-control'
                                        name='nombre'
                                        required={true}
                                        maxLength={50}
                                        value={impuesto.nombre}
                                        onChange={handleInputChange}
                                    />

                                    <label htmlFor='descripcion' className='form-label'>
                                        Descripcion
                                    </label>
                                    <input
                                        type='text'
                                        className='form-control'
                                        name='descripcion'
                                        required={true}
                                        maxLength={4}
                                        value={impuesto.descripcion}
                                        onChange={handleInputChange}
                                    />

                                    <label htmlFor='porcentaje' className='form-label'>
                                        Porcentaje
                                    </label>
                                    <input
                                        type='number'
                                        className='form-control'
                                        name='porcentaje'
                                        required={true}
                                        max={100}
                                        value={impuesto.porcentaje}
                                        onChange={handleInputChange}
                                    />

                                    <label htmlFor='tipo_Factor' className='form-label'>
                                        Tipo Factor
                                    </label>
                                    <input
                                        type='text'
                                        className='form-control'
                                        name='tipo_Factor'
                                        required={true}
                                        maxLength={100}
                                        value={impuesto.tipo_Factor}
                                        onChange={handleInputChange}
                                    />


                                    <label htmlFor='tipo_Factor' className='form-label'>
                                        Tipo Impuesto
                                    </label>
    
                                <select className='form-control' name='concepto' onChange={handleInputChange} value={impuesto.concepto}>
                                    <option key='0' value='0'>
                                        Seleccione un concepto
                                    </option>
                                    <option key='traslado' value='traslado'>
                                        Traslado
                                    </option>
                                    <option key='retencion' value='retencion'>
                                        Retencion
                                    </option>
                                    
                                </select>

                                </div>
                            </div>
                        </div>
                       
                    </div>
                </form>
            </div>
        </>
    );
};

// AddEdit.propTypes = {
//     saludo: PropTypes.string
// }

// AddEdit.defaultProps = {
//     subtitulo: "Soy subtitulo"
// }

export { AddEdit };
