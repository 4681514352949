import React, { useState } from 'react';
import logo from 'images/panel/Factuxin.svg';

const Soporte = () => {

        return (
            <div className='container-fluid'>
                <div className='col-md-12 card'>
            <div className='col-md-12'>
                <div className='col-md-12 text-center'>
                    <br></br>
                    <h2>¿Necesitas Ayuda?</h2>
                </div>
                <div className='col-md-12 text-center'>
                    <br></br>
                    <h4>Para dudas o soporte, por favor comunícate a:</h4>
                    <h5>Tel. 951 1671455</h5>
                    <h5>Email:  <a href='mailto:soporte@factuxin.com'>soporte@factuxin.com</a>, <a href='mailto:soporte@atti.com.mx'>soporte@atti.com.mx</a></h5>
                    <h5>Whatsapp:  5564560704</h5>
                    <a href='https://anydesk.com/es/downloads/windows' target='_blank'>Descargar anydesk</a>
                </div>
                <br></br>
                <div className='row'>
                <div className='col-md-3'></div>
                <div className='col-md-6 text-center'>
                <img src={logo} ></img>
                <br></br>
                </div>
                <div className='col-md-3'></div>
                </div>
                </div>
            </div>
             </div>
    );
};

export { Soporte };