import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { fetchConToken, fetchConTokenFile } from 'helpers/fetch';
import { getDataSession } from 'views/Utils/Common';
import Pagination from 'components/pagination/Pagination';
import TituloPagina from 'components/header/TituloPagina';
import { alertService } from '_services';
import * as FileSaver from 'file-saver';
import { currency } from 'resources/utilities';

import AmericanExpress from 'images/panel/carrito/forma_pago/AmericanExpress.svg'
import MasterCard from 'images/panel/carrito/forma_pago/MasterCard.svg'
import Visa from 'images/panel/carrito/forma_pago/Visa.svg'
import MercadoPago from 'images/panel/carrito/forma_pago/MercadoPago.svg'
import Paypal from 'images/panel/carrito/forma_pago/PayPal.svg'
import Transferencia from 'images/panel/carrito/forma_pago/Transferencia.svg'
import Swal from 'sweetalert2';


const Finalizar = ({ match, saludo, subtitulo }) => {
    const { path } = match;

    let [data, setData] = useState([]);
    const [totalxPagina, setTotalxPagina] = useState(10);
    const [busqueda, setbusqueda] = useState('');

    let [opcion, setOpcion] = useState(['']);
    let [error, setError] = useState(['']);
    let [erroresV, setErroresV] = useState({});

    async function getData() {
        const response = await fetchConToken(`Carritos`);
        setData(response);
        console.log(response);
    }

    async function finalizar() {
 
         var url = "";
        switch(opcion)
        {
            case "MercadoPago":
                url = `MercadoPago/GenerarPago?carrito_id=${data.id}`;
                break;
            case "PayPal":
                url = `PayPal/GenerarPago?carrito_id=${data.id}`;
                break;
                default:
                    Swal.info("Seleccione una forma de pago valida");
                    return;
                    break;
        }
        const response = await fetchConToken(url,null,'POST');
       
        setErroresV({});
        setError('');

        if (response.hasOwnProperty('status') ) {
            var errores = {};
            Object.entries(response.errors).map(([key, value]) => {
                errores[key] = value[0];
                setErroresV(errores);
            });
            setError('Revisar errores');
            return false;
        } else if (response.hasOwnProperty('codigo') ) {
            setError(response.mensajes[0]);
            return false;
        }

        window.open(response.url, '_blank');
        //window.location.href = response.url;
        
    }

    const handleInputChange = ({ target }) => {
        console.log(target.value)
        setOpcion(target.value);
    };

    React.useEffect(() => {
        getData();
    }, []);


    return (
        <>
            <div className='container-fluid'>
            <TituloPagina 
                titulo='Finalizar compra ' 
                 descripcion='' />
                <div className='col-md-12 row'>
                    <div className='col-md-6' >
                    <div className='card mb-3 ' style={{boxShadow: '0px 0px 14px rgb(30 56 136 / 50%)'}}>
                        <div className='card-body text-start'>
                            <h4><b>Tus Datos</b></h4>
                            <label htmlFor='clave_Prod_Serv' className='form-label'>
                                 Nombre 
                            </label>
                            <input
                                type='text'
                                className='form-control'
                                name='nombre'
                                required={true}
                                //value=''
                            />

                            <label htmlFor='clave_Prod_Serv' className='form-label'>
                                Apellidos
                            </label>
                            <input
                                type='text'
                                className='form-control'
                                name='apellidos'
                                required={true}
                                //value=''
                            />

                            <label htmlFor='clave_Prod_Serv' className='form-label'>
                                Nombre de la compañía (opcional)
                            </label>
                            <input
                                type='text'
                                className='form-control'
                                name='nombre_empresa'
                                required={true}
                                //value=''
                            />

                            <label htmlFor='clave_Prod_Serv' className='form-label'>
                                Nombre de la compañía (opcional)
                            </label>
                            <input
                                type='text'
                                className='form-control'
                                name='nombre_empresa'
                                required={true}
                                //value=''
                            />
                            <br></br>
                            <h4><b>Domicilio</b></h4>
                            <hr></hr>

                            <label htmlFor='clave_Prod_Serv' className='form-label'>
                                País
                            </label>
                            <input
                                type='text'
                                className='form-control'
                                name='pais'
                                required={true}
                                //value=''
                            />

                            <label htmlFor='clave_Prod_Serv' className='form-label'>
                                Código postal
                            </label>
                            <input
                                type='text'
                                className='form-control'
                                name='codigo_postal'
                                required={true}
                                //value=''
                            />

                            <label htmlFor='clave_Prod_Serv' className='form-label'>
                                Estado
                            </label>
                            <input
                                type='text'
                                className='form-control'
                                name='estado'
                                required={true}
                                //value=''
                            />

                            <label htmlFor='clave_Prod_Serv' className='form-label'>
                                Población
                            </label>
                            <input
                                type='text'
                                className='form-control'
                                name='poblacion'
                                required={true}
                                //value=''
                            />

                            <label htmlFor='clave_Prod_Serv' className='form-label'>
                                Dirección
                            </label>
                            <input
                                type='text'
                                className='form-control'
                                name='direccion'
                                required={true}
                                //value=''
                            />

                        </div>
                    </div>
                    </div>
                    <div className='col-md-6'>
                        <div style={{ border: '1px solid #F0F0F0', boxShadow: '0px 0px 14px rgb(30 56 136 / 50%)',padding: '22px 37px' }}>
                        <table style={{width: '100%'}}>
                        <thead>
                                
                                </thead>
                                <tbody>
                                <tr>
                                    <th style={{color: '#01295F'}}><h5><b>Total de la compra</b></h5></th>
                                    <th style={{color: '#01295F'}} className='text-right'><h5><b>{currency(data.total)} MXN</b></h5></th>
                                </tr>
                                <tr>
                                    <th style={{color: '#01295F'}} className='text-right' colSpan='2'><span>Total de la compra</span></th>
                                </tr>
                                </tbody>
                        </table>
                        <br></br>
                        <table style={{width: '100%'}}> 
                            <thead>
                                
                            </thead>
                            <tbody>
 
                                <tr>
                                    <th style={{color: '#01295F'}} colSpan='2'><h4><b>Seleccione un método de pago</b></h4></th>
                                </tr>
                                <tr>
                                    <th colSpan='2'><hr></hr></th>
                                </tr>

                                <tr>
                                    <td className='text-left'>
                                    <label style={{fontSize: '17px',color: '#232ED1'}}><input type='checkbox' disabled/>&nbsp;&nbsp;<b>Pago con tarjeta</b></label>
                                        <p>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Paga con tarjeta de crédito VISA, MASTERCARD o AMERICAN EXPRESS</p>
                                    </td>
                                    <td className='row'>
                                        <img src={Visa} style={{height: '25px',left: '0',width: '60px'}}/>
                                        <img src={MasterCard} style={{height: '25px',left: '0',width: '60px'}}/>
                                        {/*<img src={AmericanExpress} style={{width: '100px'}}/>*/}
                                    </td>
                                </tr>
                                <tr>
                                    <td colSpan='2'><hr></hr></td>
                                </tr>
                                <tr>
                                    <td className='text-left'>
                                    <label style={{fontSize: '17px',color: '#232ED1'}}><input type='checkbox' value={'MercadoPago'} onChange={handleInputChange} checked={opcion === 'MercadoPago'}/>&nbsp;&nbsp;<b>Pago con Mercado pago</b></label>
                                        <p>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Paga mediante tu credito de Mercado Pago</p>
                                    </td>
                                    <td className='text-right'>
                                        <img src={MercadoPago} style={{width: '100px'}}/>
                                        
                                    </td>
                                </tr>
                                <tr>
                                    <td colSpan='2'><hr></hr></td>
                                </tr>
                                <tr>
                                    <td className='text-left'>
                                    <label style={{fontSize: '17px',color: '#232ED1'}}><input type='checkbox' value={'PayPal'} onChange={handleInputChange} checked={opcion === 'PayPal'}/>&nbsp;&nbsp;<b>Pago con PayPal</b></label>
                                        <p>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Paga mediante tu cuenta de PayPal</p>
                                    </td>
                                    <td className='text-right'>
                                        <img src={Paypal} style={{width: '100px'}}/>
                                        
                                    </td>
                                </tr>
                                <tr>
                                    <td colSpan='2'><hr></hr></td>
                                </tr>
                                <tr>
                                    <td className='text-left'>
                                    <label style={{fontSize: '17px',color: '#232ED1'}}><input type='checkbox' disabled/>&nbsp;&nbsp;<b>Pago por transferencia</b></label>
                                        <p>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Realiza tu pago por transferencia bancaria</p>
                                    </td>
                                    <td className='text-right'>
                                        <img src={Transferencia} style={{width: '100px'}}/>
                                        
                                    </td>
                                </tr>
                                { /*
                                getDataSession('rol') === 'ADMINISTRADOR' ?
                                    <>
                                        <tr>
                                            <td colSpan='2'><hr></hr></td>
                                        </tr>
                                        <tr>
                                            <td className='text-left'>
                                            <label style={{fontSize: '17px',color: '#232ED1'}}><input type='checkbox' />&nbsp;&nbsp;<b>Pago para administrador</b></label>
                                                <p>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Compra para administrador, sin costo</p>
                                            </td>
                                            <td className='text-right'>
                                            </td>
                                        </tr> 
                                        </>
                                        : 
                                        "" 
                                */}
                               
                                <tr><td><br></br></td></tr>
                                <tr><td>
                                <div className='col-md-12'>
                                <p style={{ color: 'red' }}>{error}</p>
                                {Object.entries(setErroresV).map(([key, value]) => {
                                    return (
                                        <>
                                            <p style={{ color: 'red' }}>{value}</p>
                                        </>
                                        );
                                    })}
                                </div>
                                </td></tr>
                                <tr>
                                    <td colSpan='2'>
                                        <button className='btn' style={{width: '100%', background: '#3FD77B', color: '#fff'}} onClick={() => finalizar()}>
                                            <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="currentColor" className="bi bi-cart4" viewBox="0 0 16 16">
                                             <path d="M0 2.5A.5.5 0 0 1 .5 2H2a.5.5 0 0 1 .485.379L2.89 4H14.5a.5.5 0 0 1 .485.621l-1.5 6A.5.5 0 0 1 13 11H4a.5.5 0 0 1-.485-.379L1.61 3H.5a.5.5 0 0 1-.5-.5zM3.14 5l.5 2H5V5H3.14zM6 5v2h2V5H6zm3 0v2h2V5H9zm3 0v2h1.36l.5-2H12zm1.11 3H12v2h.61l.5-2zM11 8H9v2h2V8zM8 8H6v2h2V8zM5 8H3.89l.5 2H5V8zm0 5a1 1 0 1 0 0 2 1 1 0 0 0 0-2zm-2 1a2 2 0 1 1 4 0 2 2 0 0 1-4 0zm9-1a1 1 0 1 0 0 2 1 1 0 0 0 0-2zm-2 1a2 2 0 1 1 4 0 2 2 0 0 1-4 0z"/>
                                            </svg>
                                            &nbsp; Realizar compra
                                        </button>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                        </div>
                        <br></br>
                        <div className='text-center' style={{ color: '#01295F', fontWeight: '500' ,border: '1px solid #F0F0F0', boxShadow: '0px 0px 14px rgb(30 56 136 / 50%)', padding: '20px 20px 20px 0px', marginLeft: '0px' }}>
                           <p>¿Necesitas ayuda? Llámanos al</p>
                           <label>
                                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-telephone-fill" viewBox="0 0 16 16">
                                    <path fillRule="evenodd" d="M1.885.511a1.745 1.745 0 0 1 2.61.163L6.29 2.98c.329.423.445.974.315 1.494l-.547 2.19a.678.678 0 0 0 .178.643l2.457 2.457a.678.678 0 0 0 .644.178l2.189-.547a1.745 1.745 0 0 1 1.494.315l2.306 1.794c.829.645.905 1.87.163 2.611l-1.034 1.034c-.74.74-1.846 1.065-2.877.702a18.634 18.634 0 0 1-7.01-4.42 18.634 18.634 0 0 1-4.42-7.009c-.362-1.03-.037-2.137.703-2.877L1.885.511z"/>
                                </svg> 
                                &nbsp;55 324 2346
                            </label>
                            <p>Este sitio es seguro. Tu información personal está protegida</p>
                            <p>Tu información personal está protegida</p>
                        </div>
                        <br></br>
                    </div>
                    
                </div>

               
            </div>
        </>
    );
};


export { Finalizar };
