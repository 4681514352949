import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { fetchConToken, fetchConTokenFile,handleResponse } from 'helpers/fetch';
import { getDataSession } from 'views/Utils/Common';
import Pagination from 'components/pagination/Pagination';
import { descargarFactura } from 'actions/archivos';
import TituloPagina from 'components/header/TituloPagina';
import Table from 'components/table/Table'
import Modal from "components/modal/Modal";
import { useModal } from "hooks/useModal"
import Swal from 'sweetalert2'
import moment from 'moment';
import { currency } from 'resources/utilities';

const $ = require('jquery')

const List = ({ match, saludo, subtitulo }) => {
    const { path } = match;
    const [isOpenModal, openModal, closeModal] = useModal(false);
    const [PDF,setPDF] = useState(['']);

    let [data, setData] = useState([]);
    const [totalxPagina, setTotalxPagina] = useState(10);
    const [busqueda, setbusqueda] = useState('');
    const baseUrl = process.env.REACT_APP_API_URL;
    let [mes, setMes] = useState('');
    let [ejercicio, setEjercicio] = useState(moment().format('Y'));
    let [años, setAños] = useState([]);

    let [estatusSat, setEstatusSat] = useState('T');
    let [estatusPago, setEstatusPago] = useState('T');

    let [ultimoComplemento, setUltimoComplemento] = useState([]);
    let [montoTotalComp, setMontoTotalComp] = useState('');
    let [montoTotal, setMontoTotal] = useState('');
    let [option, setOption] = useState('');
    let [uuid, setUuid] = useState('');
    

    async function getData(page) {
        const response = await fetchConToken(
            `Cfdis/GetList?sucursal_id=${getDataSession('sucursal')}&paginas=${page}&totalXPagina=${totalxPagina}&tipo_factura=E&tipo_comprobante=I`
        );
        setData(response);
    }

    React.useEffect(() => {
        getData(1);
        montoTotalFunc();
        $("table").on("click", ".btn-visualizar", function (event) {
            var id = $(this).attr("data-id");
            var ruta = $(this).attr("data-ruta");
            var nombre = $(this).attr("data-nombre");
            verFactura(id,ruta,nombre);
        })
        let year = moment().format('Y')
        const beforeYear = year - 10
        for(let x = year; x >= beforeYear; x--)
        {
            años.push(x);           
        }

        $("table").on("click", ".btn-verComplemento", function (event) {
            
            var uuidAux = $(this).attr("data-id");
            uuid = uuidAux;
            setUuid(uuidAux);
            UltimoComplemento();
            montoTotalFuncComplementos();
            setOption('vistaComplementos');
            openModal();
            var tableAux = $('#TablaComplemento').DataTable();
            tableAux.ajax.url(baseUrl+`/Cfdis/List?sucursal_Id=${getDataSession('sucursal')}&tipo_factura=E&tipo_comprobante=P&uuidBusqueda=${uuid}`).load();
           
        })

    }, []);



    const verFactura =  (id,url,nombre) => {

        url = url + '?id=' + id
        fetchConTokenFile(url)
        .then(response => {
        
            if(response.status == 200)
            {
                return response.blob()
            }
            else{
                handleResponse(response).then(data=>{
                    if (data.hasOwnProperty('status')) {
                        var erroresA = {};
                        Object.entries(data?.errors).map(([key, value]) => {
                            Swal.fire('Error', value[0], 'error');
                            return;
                        });
                        return false;
                    } else if (data.hasOwnProperty('codigo')) {
                        Swal.fire('Error', data.mensajes[0], 'error');
                        return;
                    }
                })
            }
        })
        .then(function(blob) {
            const fileURL = URL.createObjectURL(blob);
            setPDF(fileURL);
            setOption('vistaFactura');
            openModal()
          })
    };

    const filtrarPorFecha = () => {
        let table = $('#table-js').DataTable();
        montoTotalFunc();
        table.ajax.url(baseUrl+`/Cfdis/List?sucursal_Id=${getDataSession('sucursal')}&tipo_factura=E&mes=${mes}&ejercicio=${ejercicio}&estatusSat=${estatusSat}&estatusPago=${estatusPago}&tipo_comprobante=I`).load();
    }

    const onChangeEjercicio = (e) =>{
        ejercicio = e.target.value
        setEjercicio(ejercicio)
        filtrarPorFecha()
    }

    const onChangeMes = (e) =>{
        mes = e.target.value
        setMes(mes)
        filtrarPorFecha()
    }

    const filtrarEstatusSat = (e) => {
        estatusSat = e.target.value;
        setEstatusSat(e.target.value);
        filtrarPorFecha();
        
    }

    const filtrarEstatusPago = (e) => {
        estatusPago = e.target.value;
        setEstatusPago(e.target.value);
        filtrarPorFecha();
        
    }

    const montoTotalFunc = () => {

        fetchConToken(`Cfdis/MontoTotalFacturasFiltro?sucursal_Id=${getDataSession('sucursal')}&tipo_factura=E&mes=${mes}&ejercicio=${ejercicio}&estatusSat=${estatusSat}&estatusPago=${estatusPago}&tipo_comprobante=I`).then(function (response) {
            if (response.hasOwnProperty('status')) {
                var erroresA = {};
                Object.entries(response.errors).map(([key, value]) => {
                    Swal.fire('Error', value[0], 'error');
                    return;
                });
                return false;
            } else if (response.hasOwnProperty('codigo') && response.codigo !== 200) {
                Swal.fire('Error', response.mensajes[0], 'error');
                return false;
            }
            setMontoTotal(response.montoTotal);
        });

    }

    const montoTotalFuncComplementos = () => {

        fetchConToken(`Cfdis/MontoTotalFacturasFiltro?sucursal_Id=${getDataSession('sucursal')}&tipo_factura=E&tipo_comprobante=P&uuidBusqueda=${uuid}`).then(function (response) {
            if (response.hasOwnProperty('status')) {
                var erroresA = {};
                Object.entries(response.errors).map(([key, value]) => {
                    Swal.fire('Error', value[0], 'error');
                    return;
                });
                return false;
            } else if (response.hasOwnProperty('codigo') && response.codigo !== 200) {
                Swal.fire('Error', response.mensajes[0], 'error');
                return false;
            }
            setMontoTotalComp(response.montoTotal);
        });

    }


    const UltimoComplemento = () => {

        fetchConToken(`Cfdis/UltimoComplemento?uuid=${uuid}`).then(function (response) {
            if (response.hasOwnProperty('status')) {
                var erroresA = {};
                Object.entries(response.errors).map(([key, value]) => {
                    Swal.fire('Error', value[0], 'error');
                    return;
                });
                return false;
            } else if (response.hasOwnProperty('codigo') && response.codigo !== 200) {
                Swal.fire('Error', response.mensajes[0], 'error');
                return false;
            }
            setUltimoComplemento(response);
        });

    }

    function descargarExcelCFDI(){
        descargarFactura(0,`Cfdis/DescargaCFDIsExcel?sucursal_Id=${getDataSession('sucursal')}&tipo_factura=E&mes=${mes}&ejercicio=${ejercicio}&estatusSat=${estatusSat}&estatusPago=${estatusPago}&tipo_comprobante=I`,"facturas.xlsx");
    }  


    return (
        <>
            <div className='container-fluid'>
                <TituloPagina 
                    titulo='Facturas emitidas' 
                    descripcion='Aquí se guarda el historial de facturas que has emitido a lo largo de periodos de tiempo, ajusta los filtros y aplica tu busqueda' 
                />
                
                <div className='col-md-12 row '>
                    <div className='col-md-4'>
                    <div className='d-flex'>
                        <label className='me-1'>Ejercicio</label>
                        <select className='selectCustomTwo m-2 w-100' onChange={onChangeEjercicio}>
                            {
                                años.map((item,i)=>(
                                    <option key={i} value={item}>{item}</option>
                                ))
                            }
                        </select> 
                    </div>
                    <div className='d-flex'>
                        <label className='me-1'>Periodo</label>
                        <select className='selectCustomTwo m-2 w-100' onChange={onChangeMes}>
                            <option value=''>Todos</option>
                            <option value='01'>Enero</option>
                            <option value='02'>Febrero</option>
                            <option value='03'>Marzo</option>
                            <option value='04'>Abril</option>
                            <option value='05'>Mayo</option>
                            <option value='06'>Junio</option>
                            <option value='07'>Julio</option>
                            <option value='08'>Agosto</option>
                            <option value='09'>Septiembre</option>
                            <option value='10'>Octubre</option>
                            <option value='11'>Noviembre</option>
                            <option value='12'>Diciembre</option>
                        </select>
                    </div>
                    </div>
                    <div className='col-md-4'>
                        <div className='d-flex'>
                            <label className='me-1'>Estatus SAT:</label>
                            <select className='selectCustomEstatusSat m-2 w-100' onChange={filtrarEstatusSat}>
                                <option value='T'>Todos</option>
                                <option value='V'>Vigente</option>
                                <option value='C'>Cancelado</option>
                            </select>
                        </div>
                        <div className='d-flex'>
                            <label className='me-1'>Estatus Pago:</label>
                            <select className='selectCustomEstatusSat m-2 w-100' onChange={filtrarEstatusPago}>
                                <option value='T'>Todos</option>
                                <option value='P'>Pendiente Pago</option>
                                <option value='F'>Pagado</option>
                            </select>
                        </div>
                    </div>
                    <div className='col-md-4'>
                        <div className='d-flex'>
                            <button className='btn btn-outline-primary m-1' type='button' onClick={()=>descargarExcelCFDI()} >
                                <svg
                                    xmlns='http://www.w3.org/2000/svg'
                                    width='20'
                                    height='20'
                                    fill='currentColor'
                                    className='bi bi-file-excel'
                                    viewBox='0 0 16 16'
                                >
                                    <path d='M5.18 4.616a.5.5 0 0 1 .704.064L8 7.219l2.116-2.54a.5.5 0 1 1 .768.641L8.651 8l2.233 2.68a.5.5 0 0 1-.768.64L8 8.781l-2.116 2.54a.5.5 0 0 1-.768-.641L7.349 8 5.116 5.32a.5.5 0 0 1 .064-.704z' />
                                    <path d='M4 0a2 2 0 0 0-2 2v12a2 2 0 0 0 2 2h8a2 2 0 0 0 2-2V2a2 2 0 0 0-2-2H4zm0 1h8a1 1 0 0 1 1 1v12a1 1 0 0 1-1 1H4a1 1 0 0 1-1-1V2a1 1 0 0 1 1-1z' />
                                </svg>
                                Exportar</button>
                        </div>
                    </div>
                </div>
                <div className='col-md-12' >
                        <Table 
                        columns = {['Complemento','Fecha','Folio','RFC','Nombre','Uso CFDI','Total','Moneda','Estatus Pago','Fecha Pago','Estatus Cancelacion','Mensaje Cancelacion','Orden','Acciones']}
                        rows = {[
                                ['complemento','metodo_Pago'],
                                ['fecha','fecha_Timbrado'],
                                ['text','folio'],
                                ['text','rfc_Receptor'],
                                ['text','nombre_Receptor'],
                                ['text','uso_Cfdi'],
                                ['moneda','total'],
                                ['text','moneda'],
                                ['text','estatus_Pago'],
                                ['fecha','fecha_Pago'],
                                ['texto-largo','estatus_Uuid_Cancelacion'],
                                ['texto-largo','mensaje_Cancelacion'],
                                ['text','folioSerie'],
                                ['opciones','id'] 
                        ]}
                        opciones={"viewPdf,pdf,xml,MarcarPagado,cancelacionCfdi,estatusCfdi,EnvioCorreo,AdjuntarFactura"}
                        ruta = "Facturacion"
                        path = {path}
                        url={`/Cfdis/List?sucursal_Id=${getDataSession('sucursal')}&tipo_factura=E&tipo_comprobante=I`}>
                        </Table>
                </div>
                <div className='col-md-12 text-center' style={{paddingBotton: "50px;"}}>
                    <label>Total: {montoTotal}</label>
                </div>
            </div>
            <Modal isOpen={isOpenModal} closeModal={closeModal}>
                {option == "vistaFactura" ? 
                <> 
                    <div className="row" style={{padding: '21px'}}>
                    <embed
                        src={PDF}
                        type="application/pdf"
                        height={800}
                        width={500}
                    />
                    </div>
                </> : ""
                }
                {option == "vistaComplementos" ? 
                <> 
                   <div className='col-md-12' >
                        <Table 
                        columns = {['Fecha','Folio','RFC','Nombre','Uso CFDI','Total','Estatus Cancelacion','Mensaje Cancelacion','Acciones']}
                        rows = {[
                                ['fecha','fecha_Timbrado'],
                                ['text','folio'],
                                ['text','rfc_Receptor'],
                                ['text','nombre_Receptor'],
                                ['text','uso_Cfdi'],
                                ['moneda','total'],
                                ['texto-largo','estatus_Uuid_Cancelacion'],
                                ['texto-largo','mensaje_Cancelacion'],
                                ['opciones','id'] 
                        ]}
                        opciones={"pdf,xml,cancelacionCfdi,estatusCfdi"}
                        ruta = "Facturacion"
                        path = {path}
                        idTabla = "TablaComplemento"
                        url={`/Cfdis/List?sucursal_Id=${getDataSession('sucursal')}&tipo_factura=E&tipo_comprobante=P&uuidBusqueda=${uuid}`}>
                        </Table>
                </div>
                <div className='col-md-12 text-center' style={{paddingBotton: "50px;"}}>
                    <label>Total Complementos: {montoTotalComp}</label>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                    <label>Total Saldo anterior: {currency(ultimoComplemento != [] > 0 ?  ultimoComplemento?.importe_Saldo_Anterior : 0)}</label>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                    <label>Ultimo Pago: {currency(ultimoComplemento != [] > 0 ? ultimoComplemento?.importe_Pago : 0)}</label>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                    <label>Total Saldo restante: {currency(ultimoComplemento != [] > 0?ultimoComplemento?.importe_Saldo_Insoluto :0)}</label>
                </div>
                </> : ""
                }
            </Modal>

        </>
    );
};

List.propTypes = {
    saludo: PropTypes.string
};

List.defaultProps = {
    subtitulo: 'Soy subtitulo'
};

export { List };
