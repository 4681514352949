import React from 'react';

const TituloPagina = (props) => {
    return <>
            <div className='col-md-12'>
            <label style={{fontSize: '0.72rem', float: 'right'}}>{props.extra}</label>
              <h2><b>{props.titulo}</b></h2>
              <span>
                {props.descripcion}
              </span>             
            </div>
    </>;
}
export default TituloPagina;
