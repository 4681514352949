import React, { useState } from 'react';
import { fetchConToken } from 'helpers/fetch';
import { getDataSession } from 'views/Utils/Common';
import { alertService } from '_services';
import { Link } from 'react-router-dom';
import { descargarFactura } from 'actions/archivos';
import Modal from 'components/modal/Modal';
import { useModal } from 'hooks/useModal';
import Pagination from 'components/pagination/Pagination';
import { useForm } from 'react-hook-form';
import { currency } from 'resources/utilities';

import PropTypes from 'prop-types';
import AsyncSelect from 'react-select/async';

import Swal from 'sweetalert2';

const AddEdit = ({ history, match, saludo, subtitulo }) => {
    const [facturado, setFacturado] = useState(false);
    const { handleSubmit } = useForm();

    const { id } = match.params;

    const { documento } = match.params;
    const [isOrden, setIsOrden] = useState(documento == 'ordenes');
    console.log(documento);

    const isAddMode = !id;

    const [isOpenModal, openModal, closeModal] = useModal(false);
    const [modalValor, setModalValor] = useState(false);
    let [cfdi, setCfdi] = useState([]);

    let [cliente, setCliente] = useState([]);
    let [clientes, setClientes] = useState([]);
    let [direcciones, setDirecciones] = useState([]);
    let [direccionesList, setDireccionesList] = useState([]);
    let [venta, setVenta] = useState({
        id: 0,
        empresa_Id: getDataSession('empresa'),
        sucursal_Id: getDataSession('sucursal'),
        cliente_Id: 0,
        direccion_Id: 0,
        no_Orden: '',
        folio: '',
        moneda: 'MXN',
        descuento_Importe: 0,
        estatus: 'P',
        importe_Total_Impuestos: 0,
        metodo_Pago: '',
        clave_Envio: '',
        forma_De_Pago: '',
        tipo_Documento: isOrden ? 'V' : 'C',
        descripcion: ''
    });
    let [ventaDetalles, setVentaDetalles] = useState([]);
    let [articulos, setArticulos] = useState([]);

    let [serie, setSerie] = useState('');
    let [sucursal_id, setSucursal_id] = useState(getDataSession('sucursal'));

    let [sucursales, setSucursales] = useState([]);
    let [series, setSeries] = useState([]);

    var direccion_id = 0;

    const totalxPagina = 10;

    const busqueda = useFormInput('');
    let [error, setError] = useState(['']);
    let [erroresCliente, setErroresCliente] = useState({});
    let [erroresDirecciones, setErroresDirecciones] = useState({});

    let [erroresV, setErroresV] = useState({});
    let [erroresArticulos, setErroresArticulos] = useState({});
    let [requiereRevision, setRequiereRevision] = useState(false);

    const [satFormaPago, setSatFormaPago] = useState(['']);
    const [satPais, setSatPais] = useState(['']);

    async function getSatFormaPago() {
        const response = await fetchConToken(`CatalogosSat/ListFormaPago?pagina=1&totalXpagina=300`);
        setSatFormaPago(response);
    }
    async function getSatPais() {
        const response = await fetchConToken(`CatalogosSat/ListPais?pagina=1&totalXpagina=300`);
        setSatPais(response);
    }
    async function getSucursales() {
        const response = await fetchConToken(
            `Sucursales/GetList?empresa_id=${getDataSession('empresa')}&pagina=1&totalXpagina=50`
        );
        setSucursales(response);
    }

    if (getDataSession('sucursal') === '0') {
        Swal.fire('Info', 'Seleccione una conexión para continuar.', 'info');
    }

    async function getSeries(sucursal_id) {
        const response = await fetchConToken(
            `Sucursales/GetFoliosSucursales?sucursal_Id=${sucursal_id}&tipo_Documento=F`
        );
        setSeries(response);
    }

    async function getDireccion(direccion_id) {
        const direccionAux = await fetchConToken(`DireccionesClientes?id=${direccion_id}`);
        setDirecciones(direccionAux);
        getCliente(direccionAux.cliente_Id);
    }

    async function getCliente(cliente_id) {
        const clienteAux = await fetchConToken(
            `Clientes?id=${cliente_id}&sucursal_Id=${getDataSession('sucursal')}`
        );
        clienteAux.regimen_Fiscal_Receptor =
            clienteAux.regimen_Fiscal_Receptor.replace(' ') === ''
                ? '601'
                : clienteAux.regimen_Fiscal_Receptor;
        clienteAux.uso_Cfdi = clienteAux.uso_Cfdi.replace(' ') === '' ? 'G01' : clienteAux.uso_Cfdi;
        clienteAux.estatus = clienteAux.estatus.replace(' ') === '' ? 'A' : clienteAux.estatus;
        clienteAux.rfc = clienteAux.estatus.replace(' ') === '' ? '' : clienteAux.rfc;
        setCliente(clienteAux);
        console.log(clienteAux);
        console.log(clienteAux.clientes_Direcciones[0]);
    }

    const facturar = () => {
        saveCliente();
    };

    
    const cancelar = () => {
        fetchConToken(
            `Ventas/Cancelar?documento_id=${id}&sucursal_id=${getDataSession('sucursal')}`,
            null, 'POST'
        ).then((response) => {
            setErroresV({});
            if (response.hasOwnProperty('status') && response.status === 400) {
                var erroresVenta = {};
                Object.entries(response.errors).map(([key, value]) => {
                    Swal.fire('Error', value[0], 'error');
                    return;
                });
                return false;
            } else if (response.hasOwnProperty('codigo') && response.codigo !== 200) {
                Swal.fire('Error', response.mensajes[0], 'error');
                return false;
            }
    
            Swal.fire('Exito', isOrden ? 'Orden Terminada' : 'Cotizacion Terminada', 'success');
            venta.id > 0 ? history.push('..') : history.push('.');
            return true;
        });
        
    }

    const terminar = () => {
        fetchConToken(
            `Ventas/Terminar?documento_id=${id}&sucursal_id=${getDataSession('sucursal')}`,
            null, 'POST'
        ).then((response) => {
            setErroresV({});
            if (response.hasOwnProperty('status') && response.status === 400) {
                var erroresVenta = {};
                Object.entries(response.errors).map(([key, value]) => {
                    Swal.fire('Error', value[0], 'error');
                    return;
                });
                return false;
            } else if (response.hasOwnProperty('codigo') && response.codigo !== 200) {
                Swal.fire('Error', response.mensajes[0], 'error');
                return false;
            }
    
            Swal.fire('Exito', isOrden ? 'Orden Terminada' : 'Cotizacion Terminada', 'success');
            venta.id > 0 ? history.push('..') : history.push('.');
            return true;
        });
    }

    const saveCliente = () => {
        cliente.empresa_Id = getDataSession('empresa');
        cliente.sucursal_Id = getDataSession('sucursal');
        if (!cliente.hasOwnProperty('uso_Cfdi')) cliente.uso_Cfdi = 'G01';
        if (!cliente.hasOwnProperty('regimen_Fiscal_Receptor'))
            cliente.regimen_Fiscal_Receptor = '601';

        if (!cliente.hasOwnProperty('no_Cliente')) cliente.no_Cliente = 'local';
        cliente.clientes_Direcciones = null;
        cliente.sucursal_Id = getDataSession('sucursal');
        fetchConToken(
            `Clientes?sucursal_Id=${getDataSession('sucursal')}`,
            cliente,
            cliente.hasOwnProperty('id') && cliente.id !== null ? 'PUT' : 'POST'
        ).then((response) => {
            setError(['']);
            setErroresV({});
            setErroresCliente('');
            if (response.hasOwnProperty('status') && response.status === 400) {
                var erroresC = {};
                Object.entries(response.errors).map(([key, value]) => {
                    Swal.fire('Error', value[0], 'error');
                    return;
                });
                return false;
            } else if (response.hasOwnProperty('codigo') && response.codigo !== 200) {
                Swal.fire('Error', response.mensajes[0], 'error');
                return false;
            }
            setCliente([]);
            setCliente(response);
            saveDireccion(response.id);

            return true;
        });
        return true;
        //.catch(alertService.error("91"));
    };

    const saveDireccion = (id) => {
        console.log(id);
        direcciones.cliente_Id = id;
        console.log(direcciones);

        if (!direcciones.hasOwnProperty('no_Direccion')) direcciones.no_Direccion = 'local';

        fetchConToken(
            `DireccionesClientes`,
            direcciones,
            direcciones.hasOwnProperty('id') && direcciones.id !== null ? 'PUT' : 'POST'
        ).then((response) => {
            setError(['']);
            setErroresV({});
            setErroresDirecciones('');
            if (response.hasOwnProperty('status') && response.status === 400) {
                var erroresC = {};
                Object.entries(response.errors).map(([key, value]) => {
                    Swal.fire('Error', value[0], 'error');
                    return;
                });
                setError('Revisar errores');
                return false;
            } else if (response.hasOwnProperty('codigo') && response.codigo !== 200) {
                Swal.fire('Error', response.mensajes[0], 'error');
                return false;
            }
            setDirecciones([]);
            setDirecciones(response);
            direccion_id = response.id;
            guardarVenta(response.id);

            return true;
        });
        return true;
        //.catch(alertService.error("107"));
    };

    ////////Guardar Venta
    ///////////////////////////////////////////
    const guardarVenta = (id) => {

        console.log('entro guardar venta');
        venta.sucursal_Id = getDataSession('sucursal');
        venta.direccion_Id = id;
        if (venta.direccion_Id === 0) {
            Swal.fire('Error', 'Seleccione una direccion', 'error');
            return false;
        }
        if (ventaDetalles.length === 0) {
            Swal.fire('Error', 'Seleccione por los menos un articulo', 'error');
            return false;
        }

        if (venta.forma_De_Pago.length === 0 || !venta.hasOwnProperty('forma_De_Pago'))
            venta.forma_De_Pago = '01';

        if (venta.metodo_Pago.length === 0 || !venta.hasOwnProperty('metodo_Pago'))
            venta.metodo_Pago = 'PUE';

        if (venta.length === 0 || !venta.hasOwnProperty('moneda')) venta.moneda = 'MXN';

        venta.venta_Detalles = ventaDetalles;

        fetchConToken(
            `Ventas?sucursal_Id=${getDataSession('sucursal')}`,
            venta,
            venta.hasOwnProperty('id') && venta.id > 0 ? 'PUT' : 'POST'
        ).then((response) => {
            setErroresV({});
            if (response.hasOwnProperty('status') && response.status === 400) {
                var erroresVenta = {};
                Object.entries(response.errors).map(([key, value]) => {
                    Swal.fire('Error', value[0], 'error');
                    return;
                });
                return false;
            } else if (response.hasOwnProperty('codigo') && response.codigo !== 200) {
                Swal.fire('Error', response.mensajes[0], 'error');
                return false;
            }
            //alertService.success('Venta guardada', { keepAfterRouteChange: true });
            Swal.fire('Exito', 'Venta guardada', 'success');
            venta.id > 0 ? history.push('..') : history.push('.');
            
            setError('');
            setVenta(response);

            setVentaDetalles([]);
            setVentaDetalles(response.venta_Detalles);
            setFacturado(true);
            return true;
        });
        return true;
        //.catch(alertService.error("131"));
    };
    ///////////////////////////////////////////
/*
    const saveArticulos = () => {
        setErroresArticulos({});
        setError('');
        ventaDetalles.map((art) => {
            art.articulo.descripcion =
                art.articulo.descripcion.replace(' ') === ' ' ? '' : art.articulo.descripcion;
            fetchConToken(
                `Articulos?sucursal_Id=${getDataSession('sucursal')}`,
                art.articulo,
                'PUT'
            ).then((response) => {
                if (response.hasOwnProperty('status')) {
                    var erroresA = {};
                    Object.entries(response.errors).map(([key, value]) => {
                        Swal.fire('Error', value[0] + ' Clave: ' + art.articulo.clave, 'error');
                        return;
                    });
                    setError('Revisar errores');
                    return false;
                } else if (response.hasOwnProperty('codigo') && response.codigo !== 200) {
                    Swal.fire('Error', response.mensajes[0], 'error');
                    return false;
                }
            });
            return true;
            //.catch(alertService.error("153"));
        });

        if (Object.entries(erroresArticulos).length > 0) return false;
        if (erroresArticulos[0] !== undefined) return false;

        return true;
    };*/


    async function getVenta() {
        const ventaAux = await fetchConToken(
            `Ventas?id=${id}&sucursal_Id=${getDataSession('sucursal')}`
        );
        ventaAux.forma_De_Pago =
            ventaAux.forma_De_Pago.replace(' ') === '' ? '01' : ventaAux.forma_De_Pago;
        ventaAux.metodo_Pago =
            ventaAux.metodo_Pago.replace(' ') === '' ? 'PUE' : ventaAux.metodo_Pago;

        setVenta(ventaAux);
        getDireccion(ventaAux.direccion_Id);

        /*console.log('get direccion');
        getDireccion(ventaAux.direccion_Id);
        articulos.length = 0;
        ventaAux.venta_Detalles?.map((item) => {
            articulos.push(item?.articulo);
        });
        console.log(ventaAux.venta_Detalles); 

        const newTodos = [...ventaDetalles];
        ventaAux.venta_Detalles.map(venDet=>{      
            
            var artAux = ventaAux.venta_Detalles.filter((d) => d.id == venDet.id);

            console.log(artAux)
            artAux[0]['impuesto'] = artAux[0]['impuesto_Total'] / artAux[0]['cantidad'];
            artAux[0]['precioSinImpuesto'] = artAux[0]['precio_Unitario'];
            
            artAux[0]['subtotal'] = artAux[0]['precio_Unitario'] * artAux[0]['cantidad'];
            artAux[0]['iva'] = artAux[0]['impuesto'] * artAux[0]['cantidad'];
            artAux[0]['precio_Unitario'] = artAux[0]['precio_Unitario'];
            artAux[0]['precio_Total'] = artAux[0]['iva'] + artAux[0]['subtotal'];

            newTodos.push(artAux[0]);
        });
        setVentaDetalles(newTodos);
        setRequiereRevision(ventaAux.venta_Detalles.filter(e => e.requiere_Revision === true).length > 0);
*/
       console.log(ventaAux.venta_Detalles);
       setVentaDetalles( ventaAux.venta_Detalles );
        return ventaAux.venta_Detalles.filter(e => e.requiere_Revision === true).length > 0;
    }


    React.useEffect(() => {
        getSucursales();
        getSatFormaPago();
        getSatPais();
        getSeries(getDataSession('sucursal'));

        if(!isAddMode)
        {
            getVenta();
        }
    }, []);

    const handleInputChangeCliente = ({ target }) => {

        setCliente({
            ...cliente,
            [target.name]: target.value
        });

    };

    const handleInputChangeDireccion = ({ target }) => {
        setDirecciones({
            ...direcciones,
            [target.name]: target.value
        });
    };
    const handleInputChangeVenta = ({ target }) => {
        setVenta({
            ...venta,
            [target.name]: target.value
        });
    };

    const handleInputChangeSerie = ({ target }) => {
        setSerie(target.value);
    };

    const handleInputChangeSucursal = ({ target }) => {
        venta.sucursal_id = target.value;
        setSucursal_id(target.value);
        getSeries(target.value);
    };

    const handleInputChangeArticulos = (event, key, campo) => {
        let detalleV = ventaDetalles.filter((det) => det.articulo_Id === key);
        detalleV[0].articulo[campo] = event.target.value;

        setVentaDetalles(ventaDetalles.map((det) => (det.articulo_Id === key ? detalleV[0] : det)));
    };

    async function eliminar(id) {
        //setVentaDetalles([]);

        if(isAddMode)
            setVentaDetalles(ventaDetalles.filter((item) => item.articulo_Id !== id));
        else
        {
            var det = ventaDetalles.filter((d) => d.articulo_Id == id);
            console.log(det)
            det.map(detV=>{
                console.log(detV)
                if(detV?.id > 0)
                {
                    console.log("entro cantidad 0");
                    detV.cantidad = 0;
                    setVentaDetalles(ventaDetalles.map((d) => (d.articulo_Id === id ? detV : d)));
                }
                else
                {
                    console.log("entro eliminar");
                    setVentaDetalles(ventaDetalles.filter((item) => item.articulo_Id !== id));
                }
           })
        }
        console.log(ventaDetalles)
    }

    ////Clientes
    /////////////////////////////////////////////
    async function getClientes(page) {
        setModalValor('cliente');
        const response = await fetchConToken(
            `clientes/GetList?sucursal_id=${getDataSession('sucursal')}&empresa_id=${getDataSession(
                'empresa'
            )}&paginas=${page}&totalXpagina=${totalxPagina}&busqueda=${busqueda.value}`
        );
        setClientes(response);
        console.log(response);
        console.log(clientes);
        console.log(clientes.length);
        //getDirecciones();
    }

    async function getCliente(cliente_id) {
        const clienteAux = await fetchConToken(
            `Clientes?id=${cliente_id}&sucursal_Id=${getDataSession('sucursal')}`
        );
        clienteAux.regimen_Fiscal_Receptor =
            clienteAux.regimen_Fiscal_Receptor.replace(' ') === ''
                ? '601'
                : clienteAux.regimen_Fiscal_Receptor;
        clienteAux.uso_Cfdi = clienteAux.uso_Cfdi.replace(' ') === '' ? 'G01' : clienteAux.uso_Cfdi;
        clienteAux.estatus = clienteAux.estatus.replace(' ') === '' ? 'A' : clienteAux.estatus;
        clienteAux.rfc = clienteAux.estatus.replace(' ') === '' ? '' : clienteAux.rfc;
        setCliente(clienteAux);
        setDirecciones(clienteAux.clientes_Direcciones[0]);
        venta.direccion_Id = clienteAux.clientes_Direcciones[0].id;
        console.log(clienteAux.clientes_Direcciones[0].id);
    }
    ////////////////////////////////////////////

    /////////Direcciones
    ///////////////////////////////////////////
    async function getDirecciones(page) {
        console.log(cliente);
        if (cliente.length === 0) {
            setError('Seleccione un cliente');
            return;
        }
        setModalValor('direccion');
        const direccionAux = await fetchConToken(
            `DireccionesClientes/GetList?paginas=${page}&totalXpagina=${totalxPagina}&busqueda=${cliente.id}`
        );

        console.log(direccionAux);
        setDireccionesList(direccionAux);
        getCliente(direccionAux.cliente_Id);
        openModal();
    }

    async function getDireccion(direccion_id) {
        console.log(direccion_id);
        venta.direccion_Id = direccion_id;
        const direccionAux = await fetchConToken(`DireccionesClientes?id=${direccion_id}`);
        setDirecciones(direccionAux);
        console.log(direccionAux);
        getCliente(direccionAux.cliente_Id);
        console.log(direccionAux.cliente_Id);
    }
    ///////////////////////////////////////////

    ///////////Articulos
    ///////////////////////////////////////////
    async function getArticulos(page) {
        //busqueda.value='';
        setModalValor('articulo');
        console.log(totalxPagina);
        const articulosAux = await fetchConToken(
            `Articulos/GetList?paginas=${page}&totalXpagina=${totalxPagina}&busqueda=${
                busqueda.value
            }&sucursal_Id=${getDataSession('sucursal')}`
        );
        setArticulos(articulosAux);
    }
    async function getArticulo(id) {

        if (!Number.isInteger(id)) return;

        var artAux = ventaDetalles.filter((d) => d.articulo_Id == id);
        console.log(artAux);
        if (artAux.length > 0) {
            artAux[0].cantidad = isNaN(artAux[0].cantidad) ? 1 : (parseInt(artAux[0].cantidad) + 1);

            setVentaDetalles(
                ventaDetalles.map((det) => (det.articulo_Id === id ? artAux[0] : det))
            );

            ImpuestosArticulo(ventaDetalles.filter(x=> x.articulo_Id === id ),false);

            return;
        }

        const articulosAux = await fetchConToken(
            `Articulos?id=${id}&sucursal_Id=${getDataSession('sucursal')}`
        );

        const detalleVenta = {
            id: 0,
            venta_Id: 0,
            articulo_Id: articulosAux.id,
            no_Detalle: 'local',
            posicion: 0,
            cantidad: 1,
            clave_Unidad: articulosAux.clave_Unidad,
            precio_Unitario: articulosAux.precio_Sin_Impuesto ,
            descuento_Total: 0,
            precio_Total: articulosAux.costo_Unidad,
            impuesto_Total: 0,
            estatus: 'A',
            articulo: articulosAux,
            nombre_Articulo: articulosAux.nombre,
            impuesto: 0,
            precioSinImpuesto: articulosAux.costo_Unidad
        };

     
        ImpuestosArticulo(detalleVenta,true);
    }

    ///////////////////////////////////////////

    async function ImpuestosArticulo(ventaDet, nuevaLinea)
    {
        console.log(ventaDet);
        if(nuevaLinea)
        {
            console.log("entro nueva linea");
            await fetchConToken(`ventas/CalculaDetalle?sucursal_Id=` + getDataSession('sucursal'),ventaDet,'POST' ).then((detVentaResp) =>{
                setVentaDetalles([...ventaDetalles,detVentaResp]);
            });
        }
        else
        {
            console.log("entro linea registrada");
            await fetchConToken(`ventas/CalculaDetalle?sucursal_Id=` + getDataSession('sucursal'),ventaDet[0],'POST' ).then((detVentaResp) =>{
                setVentaDetalles(ventaDetalles.map((det) => (det.articulo_Id === detVentaResp.articulo_Id ? detVentaResp : det)));
            });
        }
    }
    
    /////////// Detalles venta
    //////////////////////////////////////////
    const handleInputChangeDetallesVenta = (event, key, campo) => {

        let detalleV = ventaDetalles.filter((det) => det.articulo_Id === key);
      
        console.log(event.target.value === '');
        //if(isNaN(event.target.value) || event.target.value === '') return;
        switch(campo)
        {
            case "cantidad":
                detalleV[0][campo] = (isNaN(event.target.value) || event.target.value === '') ?  '' :parseFloat(event.target.value);
                break;
            case "precio_Unitario":
                detalleV[0]["precio_Unitario"] = (isNaN(event.target.value) || event.target.value === '') ?  '' : parseFloat(event.target.value);
                break;
            default:
                detalleV[0][campo] = event.target.value;
                break;
        }
        setVentaDetalles(ventaDetalles.map((det) => (det.articulo_Id === key ? detalleV[0] : det)));

        if(campo !== "nombre_Articulo")
            ImpuestosArticulo(ventaDetalles.filter(x=> x.articulo_Id === key ),false);
/*
        let detalleV = ventaDetalles.filter((det) => det.articulo_Id === key);
        detalleV[0][campo] = event.target.value;

        if (campo === 'cantidad') {
            console.log(detalleV[0]);
            console.log('entro cantidad');
            detalleV[0]['iva'] = detalleV[0]['impuesto'] * detalleV[0]['cantidad'];
            detalleV[0]['subtotal'] = detalleV[0]['precioSinImpuesto'] * detalleV[0]['cantidad'];

            detalleV[0]['precio_Total'] = detalleV[0]['iva'] + detalleV[0]['subtotal'];
        }

        setVentaDetalles(ventaDetalles.map((det) => (det.articulo_Id === key ? detalleV[0] : det)));*/
    };
    //////////

    const loadClientes = async (query) => {
        if (getDataSession('sucursal') === '0') {
            Swal.fire('Info', 'Seleccione una conexión para continuar.', 'info');
            return;
        }
        var resp = await fetchConToken(
            `clientes/GetList?sucursal_id=${getDataSession('sucursal')}&empresa_id=${getDataSession(
                'empresa'
            )}&paginas=1&totalXpagina=50&busqueda=${query}`
        );
        return resp;
    };

    const loadArticulos = async (query) => {
        if (getDataSession('sucursal') === '0') {
            Swal.fire('Info', 'Seleccione una conexión para continuar.', 'info');
            return;
        }
        var resp = await fetchConToken(
            `Articulos/GetList?paginas=1&totalXpagina=50&busqueda=${query}&sucursal_Id=${getDataSession(
                'sucursal'
            )}`
        );
        return resp;
    };

    /*const loadDireccion = async (query) => {
        var resp = await fetchConToken(
            `DireccionesClientes/GetList?paginas=1&totalXpagina=50&busqueda=${cliente.id}`
        );
        return resp; 
    };*/


    async function descripcionDetalle(id) {
        //setVentaDetalles([]);
        var detAux = ventaDetalles.filter((d) => d.articulo_Id == id);

           Swal.fire({
            title: 'Agregar descripción al detalle',
            input: 'textarea',
            inputValue: detAux[0]?.descripcion,
            inputAttributes: {
              autocapitalize: 'off'
            },
            showCancelButton: true,
            cancelButtonText: 'Cancelar',
            confirmButtonText: 'Guardar',
            showLoaderOnConfirm: true,

          }).then((result) => {
            if (result.isConfirmed) {
                
                detAux[0].descripcion = result.value;
                console.log(detAux)
                setVentaDetalles(ventaDetalles.map((det) => (det.articulo_Id === id ? detAux[0] : det)));
            }
          })
        
        console.log(ventaDetalles)
    }


    const sumarTraslado = (item) => {
        const filtrados = item?.impuestos_Ventas_Detalles?.filter(objeto => objeto?.concepto === "traslado");
        var totalImp =  filtrados?.reduce((acc, obj) => acc + obj?.importe_Impuesto, 0);
        return totalImp;
      };

    const sumarRetencion = (item) => {
        const filtrados = item?.impuestos_Ventas_Detalles?.filter(objeto => objeto?.concepto === "retencion");
        var totalImp = filtrados?.reduce((acc, obj) => acc + obj?.importe_Impuesto, 0); 
        return totalImp;
      };

      const obtenerNombresUnicosImpuestos = (datos) => {
        const nombresUnicos = new Set();
        datos?.forEach(item => {
          item.impuestos_Ventas_Detalles?.forEach(impuesto => {
            nombresUnicos?.add(impuesto?.tipo_Impuesto?.nombre);
          });
        });
        return Array.from(nombresUnicos);
      };


      const sumarImpuestosConcepto = (datos,nombre) => {
        const filtrados = datos?.flatMap(item => 
            item?.impuestos_Ventas_Detalles?.filter(objeto => objeto?.tipo_Impuesto?.nombre === nombre)
        );
        var totalImp = filtrados?.reduce((acc, obj) => acc + obj?.importe_Impuesto, 0);
        return totalImp;
      };
    
    return (
        <>
            <div className='container-fluid'>
                <form onSubmit={handleSubmit(facturar)} className='facturar' /*onReset=""*/>
                    <div className=''>
                        <div className='row' style={{ textAlign: 'left' }}>
                           {isAddMode ? <h3>Registrar {isOrden ? 'Orden' : 'Cotización'}</h3> : <h3>Editar {isOrden ? 'Orden' : 'Cotización'}</h3> }
                        </div>
                    </div>

                    <div className='col-md-12 text-right'>
                        { (venta.id > 0 && venta.folio_venta == null && venta.estatus !== 'C' && venta.estatus !== 'T' ) ?
                        <>
                            <button
                            className='btn btn-outline-success'
                            onClick={ (e) => terminar() }
                            type='button'
                        >
                            &nbsp; Terminar {isOrden ? 'Orden' : 'Cotización'}
                        </button>
                        &nbsp; 
                        </>
                    : ""
                    }

                        <button
                            hidden={facturado || venta.estatus === 'C' || venta.estatus === 'T'  }
                            className='btn btn-outline-success'
                            //onClick={ (e) => facturar() }
                            type='submit'
                        >
                            <svg
                                            xmlns='http://www.w3.org/2000/svg'
                                            width='20'
                                            height='20'
                                            fill='currentColor'
                                            className='bi bi-check-lg'
                                            viewBox='0 0 16 16'
                                        >
                                            <path d='M12.736 3.97a.733.733 0 0 1 1.047 0c.286.289.29.756.01 1.05L7.88 12.01a.733.733 0 0 1-1.065.02L3.217 8.384a.757.757 0 0 1 0-1.06.733.733 0 0 1 1.047 0l3.052 3.093 5.4-6.425a.247.247 0 0 1 .02-.022Z' />
                                        </svg>
                            &nbsp; Guardar 
                        </button>
                        &nbsp;
                        <button
                            hidden={ facturado || venta.estatus === 'C' || venta.id == 0 }
                            className='btn btn-outline-success'
                            onClick={ (e) => cancelar() }
                            type='button'
                        >
                            &nbsp; Cancelar {isOrden ? 'Orden' : 'Cotización'}
                        </button>
                        &nbsp;
                        <Link to={ isAddMode ? '.' : '..'} className='btn btn-outline-warning'>
                            <svg
                                xmlns='http://www.w3.org/2000/svg'
                                width='15'
                                height='15'
                                fill='currentColor'
                                className='bi bi-reply'
                                viewBox='0 0 16 16'
                            >
                                <path d='M6.598 5.013a.144.144 0 0 1 .202.134V6.3a.5.5 0 0 0 .5.5c.667 0 2.013.005 3.3.822.984.624 1.99 1.76 2.595 3.876-1.02-.983-2.185-1.516-3.205-1.799a8.74 8.74 0 0 0-1.921-.306 7.404 7.404 0 0 0-.798.008h-.013l-.005.001h-.001L7.3 9.9l-.05-.498a.5.5 0 0 0-.45.498v1.153c0 .108-.11.176-.202.134L2.614 8.254a.503.503 0 0 0-.042-.028.147.147 0 0 1 0-.252.499.499 0 0 0 .042-.028l3.984-2.933zM7.8 10.386c.068 0 .143.003.223.006.434.02 1.034.086 1.7.271 1.326.368 2.896 1.202 3.94 3.08a.5.5 0 0 0 .933-.305c-.464-3.71-1.886-5.662-3.46-6.66-1.245-.79-2.527-.942-3.336-.971v-.66a1.144 1.144 0 0 0-1.767-.96l-3.994 2.94a1.147 1.147 0 0 0 0 1.946l3.994 2.94a1.144 1.144 0 0 0 1.767-.96v-.667z' />
                            </svg>
                            &nbsp; Regresar
                        </Link>
                    </div>
                    <p style={{ color: 'red' }}>{error}</p>
                    <div className='row'>
                        <div className='row col-md-12'>
                        <h5>Información del cliente</h5>
                            
                            {Object.entries(erroresCliente).map(([key, value]) => {
                                return (
                                    <>
                                        <p style={{ color: 'red' }}>{value}</p>
                                    </>
                                );
                            })}
                        
                        <div className='col-md-4' style={{ display: 'grid' }}>
                            <div className='card mb-2 '>
                                <div className='card-body text-start'>
                                    <input
                                        type='hidden'
                                        className='form-control'
                                        name='no_cliente'
                                        value={cliente.no_Cliente}
                                        onChange={handleInputChangeCliente}
                                        disabled={false/*facturado*/}
                                        required
                                    />

                                    <label htmlFor='id'>Cliente:</label>
                                    <AsyncSelect
                                    className='react-select-placeholder'
                                        isClearable
                                        getOptionLabel={(option) =>
                                            option.rfc + '-' + option.nombre
                                        }
                                        getOptionValue={(option) => option.id}
                                        //defaultValue={initialObject.clave}
                                        loadOptions={loadClientes}
                                        onChange={(selectedItem) => {
                                            const val =
                                                selectedItem === null ? '' : selectedItem?.id;
                                            getCliente(val);
                                        }}
                                    />

                                    <label htmlFor='nombre'>Nombre*</label>
                                    <input
                                        type='text'
                                        className='form-control'
                                        name='nombre'
                                        value={cliente.nombre}
                                        onChange={handleInputChangeCliente}
                                        disabled={facturado}
                                        required
                                    />

                                    <label htmlFor='rfc'>RFC*</label>
                                    <input
                                        type='text'
                                        className='form-control'
                                        name='rfc'
                                        value={cliente?.rfc || ''}
                                        onChange={handleInputChangeCliente}
                                        disabled={facturado}
                                        required
                                    />

                                    <label htmlFor='cp'>Codigo Postal*</label>
                                    <input
                                        type='text'
                                        className='form-control'
                                        name='cp'
                                        value={direcciones?.cp || ''}
                                        onChange={handleInputChangeDireccion}
                                        disabled={facturado}
                                        required
                                    />

                                </div>
                            </div>
                        </div>
                        <div className='col-md-4' style={{ display: 'grid' }}>
                            <div className='card mb-2 '>
                                <div className='card-body text-start'>

                                    <label htmlFor='correo'>Correo*</label>
                                    <input
                                        type='email'
                                        className='form-control'
                                        name='correo'
                                        value={cliente?.correo || ''}
                                        onChange={handleInputChangeCliente}
                                        disabled={facturado}
                                        required
                                    />

                                    
                                    
                                            <label htmlFor='correo'>Descripción </label>
                                            <textarea  rows="4"
                                                    className='form-control'
                                                    name='descripcion'
                                                    value={venta?.descripcion || ''}
                                                    onChange={handleInputChangeVenta}
                                                    disabled={facturado}
                                            />
                                             
                                    

                                </div>
                            </div>
                        </div>

                        <div className='col-md-4' style={{ display: 'grid' }}>
                            <div className='card mb-2 '>
                                <div className='card-body text-start'>
                                    <div className='col-md-12'></div>
                                    <label htmlFor='forma_De_Pago'>Forma de Pago</label>
                                    <select
                                        className='form-control'
                                        name='forma_De_Pago'
                                        value={venta.forma_De_Pago}
                                        onChange={handleInputChangeVenta}
                                        disabled={facturado}
                                        required
                                    >
                                       {satFormaPago?.map((formapago) => {
                                        return (
                                            <option key={formapago.id} value={formapago.clave}>
                                            {formapago.clave + ' - ' + formapago.nombre}
                                            </option>
                                        );
                                        })}
                                    </select>

                                    <label htmlFor='metodo_Pago'>Metodo de Pago</label>
                                    <select
                                        className='form-control'
                                        name='metodo_Pago'
                                        value={venta.metodo_Pago}
                                        onChange={handleInputChangeVenta}
                                        disabled={facturado}
                                        required
                                    >
                                        <option value='PUE'>
                                            (PUE) Pago en una sola exhibición
                                        </option>
                                        <option value='PPD'>
                                            (PPD) Pago en Parcialidades o Diferido
                                        </option>
                                    </select>
                                </div>
                            </div>
                        </div>

                        </div>

                        
                        {Object.entries(erroresDirecciones).map(([key, value]) => {
                            return (
                                <>
                                    <p style={{ color: 'red' }}>{value}</p>
                                </>
                            );
                        })}
                        {/*<div
                            className='col-md-12 text-left'
                            style={{ marginTop: '-10px', marginBottom: '5px' }}
                        >
                            <label htmlFor='id'>
                        
                                    Buscar direccion
                             
                            </label>
                            <AsyncSelect
                                        isClearable
                                        getOptionLabel={(option) =>
                                            option.clave + '-' + option.nombre + "- $" + option.costo_Unidad
                                        }
                                        getOptionValue={(option) => option.id}
                                        //defaultValue={initialObject.clave}
                                        loadOptions={loadDireccion}
                                        onChange={(selectedItem) => {
                                            const val =
                                                selectedItem === null ? '' : selectedItem?.id;
                                                getArticulo(val);
                                        }}
                                        />
                        </div>*/}

                        <div className='accordion' id='accordionExample'>
                            <div className='accordion-item'>
                               
                                <h2 className='accordion-header' id='headingOne'>
                                    <button
                                        className='accordion-button collapsed'
                                        type='button'
                                        data-bs-toggle='collapse'
                                        data-bs-target='#collapseOne'
                                        aria-expanded='true'
                                        aria-controls='collapseOne'
                                        style={{ color: '#0c63e4', backgroundColor: '#e7f1ff' }}
                                    >
                                        <h6>Datos Opcionales</h6>
                                    </button>
                                </h2>

                                <div
                                    id='collapseOne'
                                    className='accordion-collapse collapse'
                                    aria-labelledby='headingOne'
                                    data-bs-parent='#accordionExample'
                                >
                                    <div className='accordion-body row'>
                                        <div className='col-md-4' style={{ display: 'grid' }}>
                                            <div className='card'>
                                                <div className='card-body text-start'>
                                                    <label htmlFor='calle'>Calle</label>
                                                    <input
                                                        type='text'
                                                        className='form-control'
                                                        name='calle'
                                                        value={direcciones?.calle || ''}
                                                        onChange={handleInputChangeDireccion}
                                                        disabled={facturado}
                                                    />

                                                    <label htmlFor='no_Exterior'>No Exterior</label>
                                                    <input
                                                        type='text'
                                                        className='form-control'
                                                        name='no_Exterior'
                                                        value={direcciones?.no_Exterior || ''}
                                                        onChange={handleInputChangeDireccion}
                                                        disabled={facturado}
                                                    />

                                                    <label htmlFor='no_Interior'>No Interior</label>
                                                    <input
                                                        type='text'
                                                        className='form-control'
                                                        name='no_Interior'
                                                        value={direcciones?.no_Interior || ''}
                                                        onChange={handleInputChangeDireccion}
                                                        disabled={facturado}
                                                    />
                                                </div>
                                            </div>
                                        </div>

                                        <div className='col-md-4' style={{ display: 'grid' }}>
                                            <div className='card'>
                                                <div className='card-body text-start'>
                                                    <label htmlFor='colonia'>Colonia</label>
                                                    <input
                                                        type='text'
                                                        className='form-control'
                                                        name='colonia'
                                                        value={direcciones?.colonia || ''}
                                                        onChange={handleInputChangeDireccion}
                                                        disabled={facturado}
                                                    />

                                                    <label htmlFor='poblacion'>Poblacion</label>
                                                    <input
                                                        type='text'
                                                        className='form-control'
                                                        name='poblacion'
                                                        value={direcciones?.poblacion || ''}
                                                        onChange={handleInputChangeDireccion}
                                                        disabled={facturado}
                                                    />

                                                    <label htmlFor='ciudad'>Ciudad</label>
                                                    <input
                                                        type='text'
                                                        className='form-control'
                                                        name='ciudad'
                                                        value={direcciones?.ciudad || ''}
                                                        onChange={handleInputChangeDireccion}
                                                        disabled={facturado}
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                        <div className='col-md-4' style={{ display: 'grid' }}>
                                            <div className='card'>
                                                <div className='card-body text-start'>
                                                    <label htmlFor='estado'>Municipio</label>
                                                    <input
                                                        type='text'
                                                        className='form-control'
                                                        name='municipio'
                                                        value={direcciones?.municipio || ''}
                                                        onChange={handleInputChangeDireccion}
                                                        disabled={facturado}
                                                    />

                                                    <label htmlFor='estado'>Estado</label>
                                                    <input
                                                        type='text'
                                                        className='form-control'
                                                        name='estado'
                                                        value={direcciones?.estado || ''}
                                                        onChange={handleInputChangeDireccion}
                                                        disabled={facturado}
                                                    />

                                                <label htmlFor='pais'>Pais:</label>
                                                <select
                                                    className='form-control'
                                                    name='pais'
                                                    value={direcciones?.pais || 'México'}
                                                    onChange={handleInputChangeDireccion}
                                                    disabled={facturado}
                                                    required
                                                >
                                                    {satPais.map((pais) => {
                                                    return (
                                                        <option key={pais.id} value={pais.nombre}>
                                                        {pais.clave + ' - ' + pais.nombre}
                                                        </option>
                                                    );
                                                    })}

                                                </select>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className='col-md-12'>
                            <div className='card-title'>
                                <div className='col-md-12'>
                                    {Object.entries(erroresV).map(([key, value]) => {
                                        return (
                                            <>
                                                <p style={{ color: 'red' }}>{value}</p>
                                            </>
                                        );
                                    })}
                                </div>
                            </div>
                        </div>

                        <div
                            className='col-md-12 row'
                            style={{ paddingTop: '7px', paddingBottom: '7px' }}
                        >
                            {/* <div className='col-4 col-sm-4 col-md-4 '>
                            <label >Sucursal</label>
                            <select className='form-control' name='sucursal_Id' onChange={handleInputChangeSucursal} disabled={facturado}>
                                {sucursales.map(sucursal=>{
                                    return (
                                      (sucursal_id == sucursal.id) ?  (<option value={sucursal.id} selected>{sucursal.nombre}</option>) : (<option value={sucursal.id}>{sucursal.nombre}</option>)
                                    );
                                })}
                            </select>
                            </div>
                            <div className='col-4 col-sm-4 col-md-4 '>
                            <label>Serie</label>
                            <select className='form-control' onChange={handleInputChangeSerie} disabled={facturado}>
                                {series.map(s=>{
                                    return (
                                       s === s.id ? <option value={s.id} selected>{s.serie}</option> : <option value={s.id}>{s.serie}</option>
                                    );
                                })}
                            </select>
                            </div>
                            */}
                            <div className='col-12 col-sm-12 col-md-12 text-left'>
                                <label htmlFor='id'>Buscar Articulo</label>
                                <AsyncSelect
                                className='react-select-placeholder'
                                    isClearable
                                    getOptionLabel={(option) =>
                                        option.clave +
                                        '-' +
                                        option.nombre +
                                        '- $' +
                                        option.costo_Unidad
                                    }
                                    value=''
                                    getOptionValue={(option) => option.id}
                                    //defaultValue={initialObject.clave}
                                    loadOptions={loadArticulos}
                                    onChange={(selectedItem) => {
                                        const val = selectedItem === null ? '' : selectedItem?.id;
                                        getArticulo(val);
                                    }}
                                />
                            </div>
                        </div>
                        <div className='col-md-12' style={{ display: 'grid' }}>
                            <div className='card mb-3 '>
                                <div className='text-start'>
                                    <div className='card col-12 col-sm-12 col-md-12'>
                                        {Object.entries(erroresArticulos).map(([key, value]) => {
                                            return (
                                                <>
                                                    <p style={{ color: 'red' }}>{value}</p>
                                                </>
                                            );
                                        })}
                                        <div style={{ overflowX: 'auto' }}>
                                            <table className='table'>
                                                <thead>
                                                    <tr>
                                                        <th>ID</th>
                                                        <th>Articulo</th>
                                                        <th>Clave</th>
                                                        <th>NoIdentificacion</th>
                                                        <th>Cantidad</th>
                                                        <th>Precio Unitario <br></br> (sin impuestos)</th>
                                                        <th>Impuestos</th>
                                                        <th>Precio Total</th>
                                                        <th style={{ width: "50px"}} hidden={facturado}></th>
                                                        <th style={{ width: "50px"}} hidden={facturado}></th>
                                                        <th></th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    {ventaDetalles.filter(i=> parseInt(i.cantidad)>0  || i.cantidad.length >= 0)?.map((item) => {
                                                        return (
                                                            <tr key={item.id}>
                                                                <td className='text-center'>
                                                                    {item.articulo.id}
                                                                </td>
                                                                <td>
                                                                <textarea
                                                                        disabled={facturado}
                                                                        type='text'
                                                                        className='form-control'
                                                                        name='item.nombre_Articulo'
                                                                        value={
                                                                            item.nombre_Articulo
                                                                        }
                                                                        onChange={(event) =>
                                                                            handleInputChangeDetallesVenta(
                                                                                event,
                                                                                item.articulo.id,
                                                                                'nombre_Articulo'
                                                                            )
                                                                        }
                                                                        cols="170"
                                                                        rows="3"
                                                                    />
                                                                </td>
                                                                <td className='text-center'>
                                                                    {item.articulo.clave}
                                                                </td>
                                                                <td className='text-center'>
                                                                    <input
                                                                        disabled={facturado}
                                                                        type='text'
                                                                        className='form-control'
                                                                        name='item.articulo.no_Identificacion'
                                                                        value={
                                                                            item.articulo
                                                                                .no_Identificacion
                                                                        }
                                                                        onChange={(event) =>
                                                                            handleInputChangeArticulos(
                                                                                event,
                                                                                item.articulo.id,
                                                                                'no_Identificacion'
                                                                            )
                                                                        }
                                                                    />
                                                                </td>
                                                                <td className='text-right'>
                                                                    <input
                                                                        type='number'
                                                                        className='form-control'
                                                                        name='item.cantidad'
                                                                        value={item.cantidad}
                                                                        onChange={(event) =>
                                                                            handleInputChangeDetallesVenta(
                                                                                event,
                                                                                item.articulo.id,
                                                                                'cantidad'
                                                                            )
                                                                        }
                                                                        min="1"
                                                                        disabled={facturado}
                                                                    />
                                                                </td>
                                                                <td className='text-right'>
                                                                <input
                                                                        type='number'
                                                                        className='form-control'
                                                                        name='item.precio_Unitario'
                                                                        value={item.precio_Unitario}
                                                                        min=".01"
                                                                        step=".01"
                                                                        onChange={(event) =>
                                                                            handleInputChangeDetallesVenta(
                                                                                event,
                                                                                item.articulo.id,
                                                                                'precio_Unitario'
                                                                            )
                                                                        }
                                                                        disabled={facturado}
                                                                    />
                                                                </td>
                                                                <td className='text-right'>
                                                                {item?.impuestos_Ventas_Detalles?.map(imp=>{
                                                                        return <>
                                                                         <div className='row'>
                                                                            <div className='card'>
                                                                                <div className='card-title text-center'>
                                                                                    <small><b>{imp?.tipo_Impuesto?.nombre}</b></small>
                                                                                </div>
                                                                                <div className='card-body text-center'>
                                                                                    <div className='col-md-12'>
                                                                                            <p>{currency(imp?.importe_Impuesto)}</p>
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                         </div>
                                                                        </>
                                                                    })}
                                                                </td>
                                                                <td className='text-right'>
                                                                {currency(item.precio_Total + sumarTraslado(item) - sumarRetencion(item))}
                                                                </td>
                                                                <td style={{ width: "50px"}} hidden={facturado}>
                                                                    <button
                                                                        type='button'
                                                                        className='btn  mr-1'
                                                                        onClick={() =>
                                                                            eliminar(
                                                                                item.articulo.id
                                                                            )
                                                                        }
                                                                    >
                                                                        <svg
                                                                            xmlns='http://www.w3.org/2000/svg'
                                                                            width='25'
                                                                            height='25'
                                                                            fill='currentColor'
                                                                            className='bi bi-x-circle-fill'
                                                                            viewBox='0 0 16 16'
                                                                        >
                                                                            <path d='M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0zM5.354 4.646a.5.5 0 1 0-.708.708L7.293 8l-2.647 2.646a.5.5 0 0 0 .708.708L8 8.707l2.646 2.647a.5.5 0 0 0 .708-.708L8.707 8l2.647-2.646a.5.5 0 0 0-.708-.708L8 7.293 5.354 4.646z' />
                                                                        </svg>
                                                                    </button>
                                                                </td>
                                                                <td style={{ width: "50px"}}>
                                                                    <button
                                                                        type='button'
                                                                        className='btn  mr-1'
                                                                        onClick={() =>
                                                                            descripcionDetalle(
                                                                                item.articulo.id
                                                                            )
                                                                        }
                                                                    >
                                                                        <svg xmlns="http://www.w3.org/2000/svg" width="25" height="25" fill="currentColor" className="bi bi-chat-left-text" viewBox="0 0 16 16">
                                                                        <path d="M14 1a1 1 0 0 1 1 1v8a1 1 0 0 1-1 1H4.414A2 2 0 0 0 3 11.586l-2 2V2a1 1 0 0 1 1-1h12zM2 0a2 2 0 0 0-2 2v12.793a.5.5 0 0 0 .854.353l2.853-2.853A1 1 0 0 1 4.414 12H14a2 2 0 0 0 2-2V2a2 2 0 0 0-2-2H2z"/>
                                                                        <path d="M3 3.5a.5.5 0 0 1 .5-.5h9a.5.5 0 0 1 0 1h-9a.5.5 0 0 1-.5-.5zM3 6a.5.5 0 0 1 .5-.5h9a.5.5 0 0 1 0 1h-9A.5.5 0 0 1 3 6zm0 2.5a.5.5 0 0 1 .5-.5h5a.5.5 0 0 1 0 1h-5a.5.5 0 0 1-.5-.5z"/>
                                                                        </svg>
                                                                    </button>
                                                                </td>
                                                            </tr>
                                                        );
                                                    })}
                                                    <tr>
                                                        <td colSpan={10} className='text-right'>
                                                            Subtotal:{'   '}
                                                            {currency(
                                                                ventaDetalles.filter(i=> parseInt(i.cantidad)>0  || i.cantidad.length >= 0).reduce(
                                                                    (a, v) => (a = a + v.precio_Total),
                                                                    0
                                                                )
                                                            )}

                                                            {obtenerNombresUnicosImpuestos(ventaDetalles).map(imp=>{
                                                                    return <><p>{imp}: {'   '}
                                                                    {currency( sumarImpuestosConcepto(ventaDetalles,imp))}
                                                                    </p></>
                                                                })}

                                                            <p>Total:{'   '}
                                                            {currency(
                                                                  ventaDetalles.reduce(
                                                                    (a, v) => (a = a + v.precio_Total + sumarTraslado(v) - sumarRetencion(v)),
                                                                    0
                                                                )                                                              
                                                            )}</p> 

                                                        </td>
                                                       
                                                    </tr>
                                                </tbody>
                                            </table>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </form>
            </div>
            <Modal isOpen={isOpenModal} closeModal={closeModal}>
                {modalValor === 'cliente' ? (
                    <>
                        {' '}
                        <br />
                        <div className='row'>
                            <div className='col-md-2 tex-left'>
                                <label>Buscar</label>
                            </div>
                            <div className='col-md-8 '>
                                <input type='text' className='form-control' {...busqueda} />
                            </div>
                            <div className='col-md-2 text-left'>
                                <button
                                    type='button'
                                    className='btn btn-outline-secondary'
                                    onClick={() => getClientes(1)}
                                    style={{ border: 'none' }}
                                >
                                    <svg
                                        xmlns='http://www.w3.org/2000/svg'
                                        width='16'
                                        height='16'
                                        fill='currentColor'
                                        className='bi bi-search'
                                        viewBox='0 0 16 16'
                                    >
                                        <path d='M11.742 10.344a6.5 6.5 0 1 0-1.397 1.398h-.001c.03.04.062.078.098.115l3.85 3.85a1 1 0 0 0 1.415-1.414l-3.85-3.85a1.007 1.007 0 0 0-.115-.1zM12 6.5a5.5 5.5 0 1 1-11 0 5.5 5.5 0 0 1 11 0z' />
                                    </svg>
                                </button>
                            </div>
                        </div>
                        <br></br>
                        <table className='table'>
                            <thead>
                                <tr>
                                    <th width='40px'>#No</th>
                                    <th>Clave</th>
                                    <th>Nombre</th>
                                    <th className='text-center'>RFC</th>
                                    <th width='160px'>Estatus</th>
                                    <th className='text-center'>Editar</th>
                                </tr>
                            </thead>
                            <tbody>
                                {clientes.map((item) => {
                                    return (
                                        <tr key={item.id}>
                                            <td>{item.id}</td>
                                            <td>{item.no_cliente}</td>
                                            <td>{item.nombre}</td>
                                            <td>{item.rfc}</td>
                                            <td>{item.estatus === 'A' ? 'Activo' : 'Inactivo'}</td>
                                            <td className='text-center'>
                                                <button
                                                    className='btn btn-outline-warning'
                                                    type='button'
                                                    onClick={function (event) {
                                                        getCliente(item.id);
                                                        closeModal();
                                                    }}
                                                >
                                                    <svg
                                                        xmlns='http://www.w3.org/2000/svg'
                                                        width='25'
                                                        height='25'
                                                        fill='currentColor'
                                                        className='bi bi-check2'
                                                        viewBox='0 0 16 16'
                                                    >
                                                        <path d='M13.854 3.646a.5.5 0 0 1 0 .708l-7 7a.5.5 0 0 1-.708 0l-3.5-3.5a.5.5 0 1 1 .708-.708L6.5 10.293l6.646-6.647a.5.5 0 0 1 .708 0z' />
                                                    </svg>
                                                </button>
                                            </td>
                                        </tr>
                                    );
                                })}
                            </tbody>
                        </table>
                        <Pagination
                            consumirPaginacion={getClientes}
                            elementos={clientes.length}
                            totalxPagina={totalxPagina}
                        />
                    </>
                ) : (
                    ''
                )}
                {modalValor === 'direccion' ? (
                    <>
                        <table className='table'>
                            <thead>
                                <tr>
                                    <th width='40px'>#No</th>
                                    <th>Codigo Postal</th>
                                    <th>Calle</th>
                                    <th className='text-center'>No. Exterior</th>
                                    <th width='160px'>No. Interior</th>
                                    <th className='text-center'>Colonia</th>
                                    <th className='text-center'>Poblacion</th>
                                    <th className='text-center'>Estado</th>
                                    <th></th>
                                </tr>
                            </thead>
                            <tbody>
                                {direccionesList.map((item) => {
                                    return (
                                        <tr key={item.id}>
                                            <td>{item.id}</td>
                                            <td>{item.cp}</td>
                                            <td>{item.calle}</td>
                                            <td>{item.no_Exterior}</td>
                                            <td>{item.no_Interior}</td>
                                            <td>{item.colonia}</td>
                                            <td>{item.poblacion}</td>
                                            <td>{item.estado}</td>
                                            <td className='text-center'>
                                                <button
                                                    className='btn btn-outline-warning'
                                                    type='button'
                                                    onClick={function (event) {
                                                        getDireccion(item.id);
                                                        closeModal();
                                                    }}
                                                >
                                                    <svg
                                                        xmlns='http://www.w3.org/2000/svg'
                                                        width='25'
                                                        height='25'
                                                        fill='currentColor'
                                                        className='bi bi-check2'
                                                        viewBox='0 0 16 16'
                                                    >
                                                        <path d='M13.854 3.646a.5.5 0 0 1 0 .708l-7 7a.5.5 0 0 1-.708 0l-3.5-3.5a.5.5 0 1 1 .708-.708L6.5 10.293l6.646-6.647a.5.5 0 0 1 .708 0z' />
                                                    </svg>
                                                </button>
                                            </td>
                                        </tr>
                                    );
                                })}
                            </tbody>
                        </table>
                        <Pagination
                            consumirPaginacion={getDirecciones}
                            elementos={direcciones.length}
                            totalxPagina={totalxPagina}
                        />
                    </>
                ) : (
                    ''
                )}

                {modalValor === 'articulo' ? (
                    <>
                        {' '}
                        <br />
                        <div className='row'>
                            <div className='col-md-2 tex-left'>
                                <label>Buscar</label>
                            </div>
                            <div className='col-md-8 '>
                                <input type='text' className='form-control' {...busqueda} />
                            </div>
                            <div className='col-md-2 text-left'>
                                <button
                                    type='button'
                                    className='btn btn-outline-secondary'
                                    onClick={() => getArticulos(1)}
                                    style={{ border: 'none' }}
                                >
                                    <svg
                                        xmlns='http://www.w3.org/2000/svg'
                                        width='16'
                                        height='16'
                                        fill='currentColor'
                                        className='bi bi-search'
                                        viewBox='0 0 16 16'
                                    >
                                        <path d='M11.742 10.344a6.5 6.5 0 1 0-1.397 1.398h-.001c.03.04.062.078.098.115l3.85 3.85a1 1 0 0 0 1.415-1.414l-3.85-3.85a1.007 1.007 0 0 0-.115-.1zM12 6.5a5.5 5.5 0 1 1-11 0 5.5 5.5 0 0 1 11 0z' />
                                    </svg>
                                </button>
                            </div>
                        </div>
                        <br></br>
                        <table className='table'>
                            <thead>
                                <tr>
                                    <th width='40px'>#No</th>
                                    <th>Clave</th>
                                    <th>Nombre</th>
                                    <th className='text-center'>No Identificacion</th>
                                    <th width='160px'>Clave Prod.Ser.</th>
                                    <th className='text-center'></th>
                                </tr>
                            </thead>
                            <tbody>
                                {articulos.map((item) => {
                                    return (
                                        <tr key={item.id}>
                                            <td>{item.id}</td>
                                            <td>{item.clave}</td>
                                            <td>{item.nombre}</td>
                                            <td>{item.no_Identificacion}</td>
                                            <td>{item.clave_Prod_Serv}</td>
                                            <td className='text-center'>
                                                <button
                                                    className='btn btn-outline-warning'
                                                    type='button'
                                                    onClick={function (event) {
                                                        getArticulo(item.id);
                                                        closeModal();
                                                    }}
                                                >
                                                    <svg
                                                        xmlns='http://www.w3.org/2000/svg'
                                                        width='25'
                                                        height='25'
                                                        fill='currentColor'
                                                        className='bi bi-check2'
                                                        viewBox='0 0 16 16'
                                                    >
                                                        <path d='M13.854 3.646a.5.5 0 0 1 0 .708l-7 7a.5.5 0 0 1-.708 0l-3.5-3.5a.5.5 0 1 1 .708-.708L6.5 10.293l6.646-6.647a.5.5 0 0 1 .708 0z' />
                                                    </svg>
                                                </button>
                                            </td>
                                        </tr>
                                    );
                                })}
                            </tbody>
                        </table>
                        <Pagination
                            consumirPaginacion={getArticulos}
                            elementos={articulos.length}
                            totalxPagina={totalxPagina}
                        />
                    </>
                ) : (
                    ''
                )}
            </Modal>
        </>
    );
};

const useFormInput = (initialValue) => {
    const [value, setValue] = useState(initialValue);

    const handleChange = (e) => {
        setValue(e.target.value);
    };
    return {
        value,
        onChange: handleChange
    };
};
export { AddEdit };
