import 'datatables.net-dt/js/dataTables.dataTables';
import 'datatables.net-dt/css/jquery.dataTables.min.css';
import React,{useRef , useEffect} from 'react';
import { getToken,getDataSession } from "views/Utils/Common";
import { fetchConToken } from 'helpers/fetch';
import { currency } from 'resources/utilities';
import { descargarFactura } from 'actions/archivos';
import Swal from 'sweetalert2';
import edit from 'images/panel/Icons/edit.svg'
import crearventa from 'images/panel/Icons/Convert.svg'
import cancelar from 'images/panel/Icons/Cancel.svg'
import pdf from 'images/panel/Icons/PDF.svg'
import coin from 'images/panel/Icons/Coin.svg'
import envelope from 'images/panel/Icons/envelope.svg'
import facturar from 'images/panel/Icons/New.svg'
import xml from 'images/panel/Icons/XML.svg'
import carpeta from 'images/panel/dashboard/recibidas.svg'

import { Redirect } from 'react-router-dom';

const $ = require('jquery')
$.DataTable = require('datatables.net-select')


const baseUrl = process.env.REACT_APP_API_URL;

const Table = (props) => {

     
     var idTabla = props.idTabla ?? "table-js";

    var detailRows = [];
    let table = '';
    function format(d) {
        if(d.length > 0)
        {
            var variantes = "";
            d.map((art) => {
                    variantes += '<tr>'+
                    '  <td>'+art.sku+'</td>'+
                    '  <td>'+art.nombre+'</td>'+
                    '  <td>'+art.clave_Prod_Serv+'</td>'+
                    '  <td>'+art.no_Identificacion+'</td>'+
                    '  <td>'+art.clave_Unidad+'</td>'+
                    '</tr>'
               }
               )

            return ('<table class="table" style="min-width: 60%;">'+
               '<tr>'+
               '<th>SKU</th>'+
               '<th>Nombre</th>'+
               '<th>Clave SAT</th>'+
               '<th>No. Identificación</th>'+
               '<th>Clave Unidad</th>'+
               '</tr>'+
               '<tbody>'+
               variantes
               +
               '</tbody>'+
               '</table>'
               );
        }
    }

    var ArrayData = [];
    const tableRef = useRef()

    var clicando= false; 
    //var table;
    function reload(url){
          table.ajax.url(url).load();
    }

    function rellenarTable()
    {
        var url = props.url;
        props.rows.map((i) => {           
            switch (i[0]) {
               case "desplegable":
               ArrayData.push({
                    class: 'details-control',
                    orderable: false,
                    data:  function (data, type, row, meta) {
                         
                         if(data['tiene_Variante'] === 'S')
                           return '<spam  data-id="'+data[i[1]]+'" style="display:none"></spam>';
                         else
                           return '<spam class="varianteClass" data-id="'+data[i[1]]+'" style="display:none"></spam>';
                   },
               }); 
               break;
               case "complemento":
               ArrayData.push({
                    class: 'ver-complemento',
                    orderable: false,
                    data:  function (data, type, row, meta) {
                         
                         if(data['metodo_Pago'] === 'PPD')
                              return '<button class="btn-verComplemento btn" type="button" data-id="'+data["uuid"]+'" ><img src="'+carpeta+'" title="Ver Complementos" /></button>';
                         else
                              return '';
                   },
               }); 
               break;
               case "pdf":
               ArrayData.push({
                    "class": "td-button text-center",
                    data: null,
                    "render": function (data, type, row, meta) {
                          if (data[i[1]] == null || data[i[1]].length == 0)
                              return "";
                          
                      return '<button class="btn-descargar btn" type="button" data-id="'+data[i[1]]+'"  data-ruta="'+props.ruta+'/DescargarPDF" data-nombre="'+data['folio']+'.pdf" ><img src="'+pdf+'" title="Descargar PDF" /></button>';
                    }
               });
               break;
               case "xml":
                    ArrayData.push({
                         "class": "text-center td-button",
                         data: null,
                         "render": function (data, type, row, meta) {
                               if (data[i[1]] == null || data[i[1]].length == 0)
                                   return "";
                               
                           return '<button class="btn btn-descargar" type="button" data-id="'+data[i[1]]+'"  data-ruta="Facturacion/DescargarXML" data-nombre="'+data['folio']+'.xml" ><img src="'+xml+'" title="Descargar XML" /></button>';
                         }
                    });
                    break;
               case "edit":
                  ArrayData.push({
                      "class": "text-center td-button",
                       data: null,
                       "render": function (data, type, row, meta) {
                             if (data[i[1]] == null || data[i[1]].length == 0)
                                 return "";
                             
                         return '<a class="btn" href="'+props.path+"/edit/"+data[i[1]]+'"><img src="'+edit+'" title="Editar" /></a>';

                       }
                  });
                  break;
               case "cancelar":
                  ArrayData.push({
                      "class": "text-center td-button",
                       data: null,
                       "render": function (data, type, row, meta) {
                             if (data[i[1]] == null || data[i[1]].length == 0)
                                 return "";
                        if(data.estatus != "CANCELADA")
                        {
                         return '<button class="btn td-crearventa" type="button" data-evento="cancelar el documento" data-ruta="'+props.ruta+ '/Cancelar?documento_id='+data[i[1]]+'&sucursal_id='+props.sucursal_id+'" ><img src="'+cancelar+'" title="Cancelar" /></button>';
                        }
                        else{
                         return '';
                        }

                       }
                  });
                  break; 
               case "CrearVenta":
                    ArrayData.push({
                        "class": "text-center td-button",
                         data: null,
                         "render": function (data, type, row, meta) {
                               if (data[i[1]] == null || data[i[1]].length == 0)
                                   return "";
                         
                          if(data.estatus != "CANCELADA" && data.folio_Venta == null)
                          {
                           return '<button class="btn td-crearventa" type="button" data-evento="crear venta de esta cotizacion" data-ruta="'+props.ruta+'/Terminar?documento_id='+data[i[1]]+'&sucursal_id='+props.sucursal_id+'" ><img src="'+crearventa+'"  title="Convertir a venta" /></button>';
                          }
                          else{
                           return '';
                          }
  
                         }
                    });
                    break;
               case "terminar":
                    ArrayData.push({
                        "class": "text-center td-button",
                         data: null,
                         "render": function (data, type, row, meta) {
                               if (data[i[1]] == null || data[i[1]].length == 0)
                                   return "";
                          if(data.estatus != "CANCELADA" && data.estatus != "TERMINADA" && data.folio_Venta == null)
                          {
                           return '<button class="btn td-crearventa" type="button" data-evento="terminar la venta" data-ruta="'+props.ruta+'/Terminar?documento_id='+data[i[1]]+'&sucursal_id='+props.sucursal_id+'" ><img src="'+crearventa+'"  title="terminar la venta" /></button>';
                          }
                          else{
                           return '';
                          }
  
                         }
                    });
                    break;
               case "urlExtra":
                    ArrayData.push({
                         "class": "text-center",
                         data: null,
                         "render": function (data, type, row, meta) {
                               if (data[i[1]] == null || data[i[1]].length == 0)
                                   return "";
                               
                           return '<a class="btn" href="'+props.pathExtra+data[i[1]]+'"><img src="'+edit+'" title="Editar" /></a>';
  
                         }
                    });
                    break;
               case "opciones":
                    ArrayData.push({
                         "class": "text-center td-button",
                         data: null,
                         "render": function (data, type, row, meta) {

                         var dropdown = '<div class="btn-group" role="group">'+
                         '<div class="btn-group" role="group">'+
                         '  <button type="button" class="btn btn-primary dropdown-toggle" data-bs-toggle="dropdown" aria-expanded="false">'+
                         '    Opciones'+
                         '  </button>'+
                         '  <ul class="dropdown-menu">';

                         const opciones = props.opciones.split(",");
                         for (i = 0; i < opciones.length; i++) {
                              switch (opciones[i]) {
                                  case 'actualizar':
                                        if(data.no_Orden != data.folio){
                                             dropdown += '<li><button class="btn td-actualizarventa" data-orden="'+data.no_Orden+'"  type="button" ><img src="'+edit+'"/>&nbsp;Actualizar</button></li>';
                                        }
                                  break;
                                  case 'eliminar':
                                        dropdown += '<li><button class="btn td-crearventa"  type="button" data-evento="eliminar esta conexión" data-ruta="'+props.path+"/Eliminar?id="+data["id"]+'"><img src="'+edit+'"/>&nbsp;eliminar</button></li>';
                                  break;
                                  case 'edit':
                                        if(data.estatus != "CANCELADA" && data.estatus != "TERMINADA" && data.estatus != "FACTURADA")
                                        {
                                     dropdown += '<li><a class="btn" href="'+props.path+"/edit/"+data["id"]+'"><img src="'+edit+'"/>&nbsp;Editar</a></li>';
                                        }
                                     break;

                                  case 'viewPdf':
                                      dropdown += '<li><button class="btn-visualizar btn" type="button" data-id="'+data["id"]+'"  data-ruta="'+props.ruta+'/DescargarPDF" data-nombre="'+data['folio']+'.pdf" ><img src="'+pdf+'" />&nbsp;Ver factura</button></li>';
                                  break;   
                                  case 'pdf':
                                     dropdown += '<li><button class="btn-descargar btn" type="button" data-id="'+data["id"]+'"  data-ruta="'+props.ruta+'/DescargarPDF" data-nombre="'+data['folio']+'.pdf" ><img src="'+pdf+'" />&nbsp;Descargar PDF</button></li>';
                                     break;
                                  case 'xml':
                                     dropdown +='<li><button class="btn btn-descargar" type="button" data-id="'+data["id"]+'"  data-ruta="Facturacion/DescargarXML" data-nombre="'+data['folio']+'.xml" ><img src="'+xml+'" />&nbsp;Descargar XML</button></li>';
                                     break;
                                  case 'cancelar':
                                        if(data.estatus != "CANCELADA" && data.estatus != "TERMINADA" && data.estatus != "FACTURADA")
                                        {
                                             dropdown += '<li><button class="btn td-crearventa" type="button" data-evento="cancelar el documento" data-ruta="'+props.ruta+ '/Cancelar?documento_id='+data["id"]+'&sucursal_id='+props.sucursal_id+'" ><img src="'+cancelar+'" />&nbsp;Cancelar</button></li>';
                                        }
                                  break;
                                  case 'CrearVenta':
                                        if(data.estatus != "CANCELADA" && data.folio_Venta == null)
                                        {
                                             dropdown +=  '<li><button class="btn td-crearventa" type="button" data-evento="crear venta de esta cotizacion" data-ruta="'+props.ruta+'/Terminar?documento_id='+data["id"]+'&sucursal_id='+props.sucursal_id+'" ><img src="'+crearventa+'"  />&nbsp;Convertir a venta</button></li>';
                                        }     
                                  break;
                                  case 'terminar':
                                        if(data.estatus != "CANCELADA" && data.estatus != "TERMINADA" && data.folio_Venta == null && data.estatus != "FACTURADA")
                                        {
                                             dropdown +=  '<li><button class="btn td-crearventa" type="button" data-evento="terminar la venta" data-ruta="'+props.ruta+'/Terminar?documento_id='+data["id"]+'&sucursal_id='+props.sucursal_id+'" ><img src="'+crearventa+'" />&nbsp;Terminar la venta</button></li>';
                                        }
                                  break;
                                  case 'facturar':
                                   if(data.estatus != "CANCELADA" && data.estatus == "TERMINADA")
                                       dropdown +=  '<li><a class="btn " href="'+props.path+"/facturacion/"+data["id"]+'"><img src="'+facturar+'" />&nbsp;Facturar</a></li>';
                                  break;
                                  case 'reenviarLink':
                                       dropdown += '<li><button class="btn-reenviarlink btn" type="button" data-id="'+data["id"]+'" data-orden="'+data.no_Orden+'"  data-ruta="'+props.ruta+'/reenviarlink" data-nombre="'+data['id']+'" ><img src="'+envelope+'" />&nbsp;Reenviar Link</button></li>';
                                  break;
                                  case 'AdjuntarFactura':
                                   dropdown += '<li><button class="btn-AdjuntarFactura btn" type="button" data-id="'+data["id"]+'" data-orden="'+data.no_Orden+'"  data-ruta="'+props.ruta+'/AdjuntarFactura" data-nombre="'+data['id']+'" ><img src="'+envelope+'" />&nbsp;Adjuntar Factura</button></li>';
                                  break;
                                  case 'urlExtra':
                                        dropdown += '<li><a class="btn" href="'+props.pathExtra+data["id"]+'">&nbsp;<img src="'+edit+'"/> Editar</a></li>';
                                  break;
                                  case 'cancelacionCfdi':
                                        if(data["estatus_Uuid_Cancelacion"] === null)
                                        {
                                             dropdown += '<li><button class="btn td-cancelar-cfdi" type="button" data-id="'+data["id"]+'">&nbsp;<img src="'+edit+'"/> Cancelar factura</button></li>';
                                        }
                                  break;
                                  case 'estatusCfdi':
                                        if(data["estatus_Uuid_Cancelacion"] !== null && data["estatus_Uuid_Cancelacion"].length > 0) 
                                             dropdown += '<li><button class="btn td-estatusCfdi" type="button" data-id="'+data["id"]+'">&nbsp;<img src="'+edit+'"/> Estatus factura</button></li>';
                                  break;    
                                  case 'MarcarPagado':
                                        if(data["estatus_Pago"] === 'Pendiente Pago' ) 
                                        dropdown += '<li><button class="btn-marcarpagado btn" type="button" data-id="'+data["id"]+'"  data-ruta="'+props.ruta+'/MarcarPagado" data-nombre="'+data['folio']+'" ><img src="'+coin+'" />&nbsp;Marcar como Pagado</button></li>';
                                  break; 
                                  case 'EnvioCorreo':
                                   dropdown += '<li><button class="btn-enviocorreo btn" type="button" data-id="'+data["id"]+'"  data-ruta="'+props.ruta+'/EnviarCorreo" data-nombre="'+data['id']+'" ><img src="'+envelope+'" />&nbsp;Enviar Correo</button></li>';
                                  break; 

                                  default:
                                  break; 
                                 }
                         } 

                         dropdown +='</ul></div></div>';

                          return dropdown;
                    
                         }
                    });
                    break;
               case "check":
               ArrayData.push(
                    {
                      data: null,
                      "render": function (data, type, row, meta) {
                          return  '<input type="checkbox" class="check-select check-select-'+data[i[1]]+'" data-id="'+data[i[1]]+'" />';
                      }
                     }
                 );
                 
                  break;
               case "id":
                    ArrayData.push({
          
                         data: null,
                         "render": function (data, type, row, meta) {
                              return ' <label class="classID" data-id="' + data[i[1]] + '">' + data[i[1]] + '</label>';
                         }
                    });
                    break;
               case "bool":
                  ArrayData.push({
                    "class": "text-center td-button",
                       data: null,
                       "render": function (data, type, row, meta) {
                             if (data[i[1]] == null || data[i[1]].length == 0 )
                                 return "";
                                 if(data[i[1]] === 'A' || data[i[1]] === 'S' || data[i[1]] === '1' || data[i[1]] === true)
                                     return "<input type='checkbox' onclick='return false;' checked/>";
                                 else
                                      return "<input type='checkbox' onclick='return false;'/>";

                       }
                  });
                  break;
               case "conexion":
                  ArrayData.push({
                       "class": "text-center",
                       data: null,
                       "render": function (data, type, row, meta) {
                             if (data[i[1]] == null || data[i[1]].length == 0 )
                                 return "";
                                 if(data["matriz"] === true)
                                     return "<label style='color: blue'>Local</label>";

                                 if(data[i[1]] === '1')
                                     return "<label style='color: blue'>Exitosa</label>";
                                 if(data[i[1]] === '0')
                                      return "<label style='color: red'>Incorrecta</label>";
                                 if(data[i[1]] === '-1')
                                      return "<label style='color: red'>Pendiente de autorización</label>";

                       }
                  });
                  break;
               case "moneda":
                  ArrayData.push({
                       "class": "text-right",
                       data: null,
                       "render": function (data, type, row, meta) {
                             if (data[i[1]] == null || data[i[1]].length == 0 )
                                 return "";
                                 
                              return "<p>"+currency(data[i[1]])+"</p>";

                       }
                  });
                  break;
               case "fecha":
                  ArrayData.push({
                       "class": "text-center fecha-table",
                       data: null,
                       "render": function (data, type, row, meta) {
                             if (data[i[1]] == null || data[i[1]].length == 0 )
                                 return "";
                                 
                              return "<p>"+data[i[1]]?.substring(0, 10)+"</p>";

                       }
                  });
                  break;
               case "url":
                  ArrayData.push({
                       "class": "td-url",
                       data: null,
                       "render": function (data, type, row, meta) {
                             if (data[i[1]] == null || data[i[1]].length == 0)
                                 return "";
                            return "<a href='"+data[i[1]]+"' target='_blank' rel='noopener noreferrer'>"+data[i[1]]?.substring(0, 30)+"</a>";
                       }
                  });
                  break;
               case "tipo_documento":
                    ArrayData.push({
                         "class": "text-center",
                         data: null,
                         "render": function (data, type, row, meta) {
                               if (data[i[1]] == "F")
                                   return "Factura";
                               if (data[i[1]] == "C")
                                   return "Cotización";
                               if (data[i[1]] == "V")
                                   return "Venta";
                                   if (data[i[1]] == "P")
                                   return "Complemento de pago";
                                return "";
                         }
                    });
                    break;
                    case "estado_comprobante":
                         ArrayData.push({
                              "class": "text-center",
                              data: null,
                              "render": function (data, type, row, meta) {
                                   console.log(data);
                                    if (data[i[1]] == "1")
                                        return "Activos";
                                    if (data[i[1]] == "0")
                                        return "Cancelados";
                                     return "";
                              }
                         });
                         break;
                    case "solicitud_mensaje":
                              ArrayData.push({
                                   "class": "text-center",
                                   data: null,
                                   "render": function (data, type, row, meta) {
                                        console.log(data);
                                   

                                        switch(data[i[1]]){
                                                  case "300":
                                                       return "Usuario No Válido";
                                                  break;
                                                  case "301":
                                                       return "XML Mal Formado";
                                                  break;
                                                  case "302":
                                                       return "Sello Mal Formado";
                                                  break;
                                                  case "303":
                                                       return "Sello no corresponde con RfcSolicitante";
                                                  break;
                                                  case "304":
                                                       return "Certificado Revocado o Caduco";
                                                  break;
                                                  case "305":
                                                       return "Certificado Inválido";
                                                  break;
                                                  case "5000":
                                                       return "Solicitud recibida con éxito";
                                                  break;
                                                  case "5003":
                                                       return "Tope máximo de elementos de la consulta";
                                                  break;
                                                  case "5004":
                                                       return "No se encontró la información";
                                                  break;
                                                  case "5011":
                                                       return "Límite de descargas por folio por día";
                                                  break;
                                                  case "5002":
                                                       return "Se agotó las solicitudes de por vida";
                                                  break;
                                                  case "5003":
                                                       return "Tope máximo";
                                                  break;
                                                  case "5004":
                                                       return "No se encontró la información";
                                                  break;
                                                  case "5005":
                                                       return "Solicitud duplicada";
                                                  break;
                                                  case "404":
                                                       return "Error no controlado";
                                                  break;
                                                  default:
                                                       return data[i[1]];
                                                  break;
                                        }


                                   }
                              });
                              break;
                    case "tipo_documento_cfdi":
                    ArrayData.push({
                         "class": "text-center",
                         data: null,
                         "render": function (data, type, row, meta) {
                               if (data[i[1]] == "I")
                                   return "Factura";
                              if (data[i[1]] == "P")
                                   return "Complemento de pago";
                                return "";
                         }
                    });
                    break;
               case "object":
               ArrayData.push({
                    "class": "text-center",
                    data: null,
                    "render": function (data, type, row, meta) {                          
                         if(data[i[1]] != null)
                        return data[i[1]][i[2]];
                        else
                        return "";

                    }
               });
               break;
               case "texto-largo":
                    ArrayData.push({
                         "class": "texto-largo",
                         data: null,
                         "render": function (data, type, row, meta) {
                               if (data[i[1]] == null || data[i[1]].length == 0 )
                                   return "";
                              
                                if(data[i[1]]?.length > 35)
                                   return "<p  title='"+data[i[1]]+"'>"+data[i[1]]?.substring(0, 35)+"...</p>";
                               else
                                   return "<p>"+data[i[1]]+"</p>";

  
                         }
                    });
                    break;
               case "vacio":
                    break;
               default:
                 ArrayData.push({ "data": i[1] })
               break;
             }
         })
          //new $.fn.dataTable.Editor
         table = $(tableRef.current).DataTable(
         {
               //stateSave: true,
               responsive: true,
               proccessing: true,
               serverSide: true,
               scrollX: true,
               search: {
                    return: true
                },
               ajax: {
                    url: baseUrl+props.url,
                    type: 'POST',
                    headers: {'Authorization': 'Bearer '+getToken()},
               },
               /* columnDefs: [ {
                    orderable: false,
                    className: 'select-checkbox',
                    targets:   0
               } ],
               select: {
                    style:    'os',
                    selector: 'td:first-child'
               },*/
               order: [[1, 'asc']],
               language:
                    {
                         processing: "Procesando...",
                         lengthMenu: "Mostrar _MENU_ registros",
                         zeroRecords: "No se encontraron resultados",
                         emptyTable: "Ningún dato disponible en esta tabla",
                         info: "Mostrando registros del _START_ al _END_ de un total de _TOTAL_ registros",
                         infoEmpty: "Mostrando registros del 0 al 0 de un total de 0 registros",
                         infoFiltered: "(filtrado de un total de _TOTAL_ registros)",
                         search: "Buscar:",
                         infoThousands: ",",
                         loadingRecords: "Cargando...",
                         paginate: {
                              first: "Primero",
                              last: "Último",
                              next: "Siguiente",
                              previous: "Anterior"
                         },
                         aria: {
                              sortAscending: ": Activar para ordenar la columna de manera ascendente",
                              sortDescending: ": Activar para ordenar la columna de manera descendente"
                         },
                         autoFill: {
                              "cancel": "Cancelar",
                              "fill": "Rellene todas las celdas con <i>%d<\/i>",
                              "fillHorizontal": "Rellenar celdas horizontalmente",
                              "fillVertical": "Rellenar celdas verticalmentemente"
                         },
                         decimal: ",",
                         searchBuilder: {
                              add: "Añadir condición",
                              button: {
                                   "0": "Constructor de búsqueda",
                                   "_": "Constructor de búsqueda (%d)"
                              },
                              clearAll: "Borrar todo",
                              condition: "Condición",
                              conditions: {
                                   "date": {
                                        "after": "Despues",
                                        "before": "Antes",
                                        "between": "Entre",
                                        "empty": "Vacío",
                                        "equals": "Igual a",
                                        "not": "No",
                                        "notBetween": "No entre",
                                        "notEmpty": "No Vacio"
                                   },
                                   "moment": {
                                        "after": "Despues",
                                        "before": "Antes",
                                        "between": "Entre",
                                        "empty": "Vacío",
                                        "equals": "Igual a",
                                        "not": "No",
                                        "notBetween": "No entre",
                                        "notEmpty": "No vacio"
                                   },
                                   "number": {
                                        "between": "Entre",
                                        "empty": "Vacio",
                                        "equals": "Igual a",
                                        "gt": "Mayor a",
                                        "gte": "Mayor o igual a",
                                        "lt": "Menor que",
                                        "lte": "Menor o igual que",
                                        "not": "No",
                                        "notBetween": "No entre",
                                        "notEmpty": "No vacío"
                                   },
                                   "string": {
                                        "contains": "Contiene",
                                        "empty": "Vacío",
                                        "endsWith": "Termina en",
                                        "equals": "Igual a",
                                        "not": "No",
                                        "notEmpty": "No Vacio",
                                        "startsWith": "Empieza con"
                                   }
                              },
                              data: "Data",
                              deleteTitle: "Eliminar regla de filtrado",
                              leftTitle: "Criterios anulados",
                              logicAnd: "Y",
                              logicOr: "O",
                              rightTitle: "Criterios de sangría",
                              title: {
                                   "0": "Constructor de búsqueda",
                                   "_": "Constructor de búsqueda (%d)"
                              },
                              "value": "Valor"
                         },
                         searchPanes: {
                              "clearMessage": "Borrar todo",
                              "collapse": {
                                   "0": "Paneles de búsqueda",
                                   "_": "Paneles de búsqueda (%d)"
                              },
                              "count": "{total}",
                              "countFiltered": "{shown} ({total})",
                              "emptyPanes": "Sin paneles de búsqueda",
                              "loadMessage": "Cargando paneles de búsqueda",
                              "title": "Filtros Activos - %d"
                         },
                         select: {
                              "1": "%d fila seleccionada",
                              "_": "%d filas seleccionadas",

                         },
                         "thousands": "."
                    },
               columns: ArrayData,
               drawCallback: function () {
                    this.api().state.clear();
                    $(".varianteClass").each(function(){
                         $(this).parent().removeClass('details-control');
                    });
               }
         })
         return function() {
             table.destroy()
         }
         $(".varianteClass").each(function(){
              $(this).parent().removeClass('details-control');
         });

    }
    
    
    useEffect(()=>{
        rellenarTable();

        //$('#mySelect').selectpicker();
        $("table").on("click", ".td-estatusCfdi", function (event) {
          var id = $(this).attr("data-id");
          fetchConToken(`facturacion/estatusFactura?cfdi_id=${id}`).then((response) => {

               if (response.hasOwnProperty('status') && response.status === 400) {
                    Object.entries(response.errors).map(([key, value]) => {
                         Swal.fire('Error', value[0], 'error');
                         return;
                    });
                    return false;
                    } else if (response.hasOwnProperty('codigo') && response.codigo !== 200) {
                    Swal.fire('Error', response.mensajes[0], 'error');
                    return false;
               }
               

               Swal.fire('Exito', 'Solicitud actualizada', 'success');
               var table = $('#'+idTabla).DataTable();
               table.ajax.reload();
          })
    });

     $("table").on("click", ".btn-descargar", function (event) {
          var id = $(this).attr("data-id");
          var ruta = $(this).attr("data-ruta");
          var nombre = $(this).attr("data-nombre");
          descargarFactura(id,ruta,nombre);
    });

    
    $("table").on("click", ".btn-enviocorreo", function (event) {
     var id = $(this).attr("data-id");
     var ruta = $(this).attr("data-ruta");
     Swal.fire({
          title: 'Envio por correo',
          input: 'email',
          inputLabel: 'Correo',
          inputPlaceholder: 'Correo para enviar la factura',
          showCancelButton: true,
          inputValidator: (value) => {
              if (!value) {
                  return '¡Necesitas escribir un correo!'
              } else if (!/\S+@\S+\.\S+/.test(value)) {
                  return '¡Debes ingresar una dirección de correo electrónico válida!'
              }
          }
      }).then((result) => {
          if (result.isConfirmed) {
               fetchConToken(
               ruta + "?id_cfdi=" + id+"&correo="+result.value
          ).then(function (response) {
               if (response.hasOwnProperty('status') && response.status === 400) {
               Object.entries(response.errors).map(([key, value]) => {
                    Swal.fire('Error', value[0], 'error');
               });
               return;
               } else if (response.hasOwnProperty('codigo') && response.codigo !== 200) {
               Swal.fire('Error', response.mensajes[0], 'error');
               return;
               }
               
               if(response.mensaje.length > 0)
                    Swal.fire('info', response.mensaje, 'info');
               else
                    Swal.fire('Exitoso', 'Envio Correo Exitoso', 'success');
          });
          }
      });
    });

    $("table").on("click", ".btn-reenviarlink", function (event) {
     var orden = $(this).attr("data-orden");
     var ruta = $(this).attr("data-ruta");

     Swal.fire('Info', 'Enviando link', 'info');
         fetchConToken(
          `Ventas/reenviarlink?empresa_id=${getDataSession(
               'empresa'
           )}&sucursal_id=${getDataSession('sucursal')}&id=${orden}`,
           null,
           'POST'
          ).then(function (response) {
               if (response.hasOwnProperty('status') && response.status === 400) {
               Object.entries(response.errors).map(([key, value]) => {
                    Swal.fire('Error', value[0], 'error');
               });
               return;
               } else if (response.hasOwnProperty('codigo') && response.codigo !== 200) {
               Swal.fire('Error', response.mensajes[0], 'error');
               return;
               }
               
              Swal.fire('Exitoso', 'Link enviado correctamente', 'success');
          });
          });

          $("table").on("click", ".btn-AdjuntarFactura", function (event) {
               var orden = $(this).attr("data-id");
               var ruta = $(this).attr("data-ruta");
          
               Swal.fire('Info', 'Adjuntando factura', 'info');
                   fetchConToken(
                    `facturacion/AdjuntarFactura?cfdi_id=${orden}`
                    ).then(function (response) {
                         if (response.hasOwnProperty('status') && response.status === 400) {
                         Object.entries(response.errors).map(([key, value]) => {
                              Swal.fire('Error', value[0], 'error');
                         });
                         return;
                         } else if (response.hasOwnProperty('codigo') && response.codigo !== 200) {
                         Swal.fire('Error', response.mensajes[0], 'error');
                         return;
                         }
                         
                        Swal.fire('Exitoso', 'Adjuntado correctamente', 'success');
                    });
                    });

    $("table").on("click", ".btn-marcarpagado", function (event) {
          var id = $(this).attr("data-id");
          var ruta = $(this).attr("data-ruta");
          var nombre = $(this).attr("data-nombre");
          Swal.fire({
               icon: 'warning',
               html:'Esta seguro de registrar como pagada la factura: '+nombre+'<br/><input id="datetimepicker" type="date" class="form-control" autofocus>',
               showCancelButton: true,
               confirmButtonText: "Registrar Pago",
               cancelButtonText: 'Cancelar'
           }).then((result) => {
               if (result.isConfirmed) {
                    if($('#datetimepicker').val() !== undefined && $('#datetimepicker').val().length > 0 ){                    
                         fetchConToken(
                              ruta + "?fac_id=" + id+"&fecha="+$('#datetimepicker').val(),
                              null,
                              'POST'
                         ).then(function (response) {
                              if (response.hasOwnProperty('status') && response.status === 400) {
                              Object.entries(response.errors).map(([key, value]) => {
                                   Swal.fire('Error', value[0], 'error');
                              });
                              table.ajax.reload();
                              return;
                              } else if (response.hasOwnProperty('codigo') && response.codigo !== 200) {
                              Swal.fire('Error', response.mensajes[0], 'error');
                              table.ajax.reload();
                              return;
                              }
                              table.ajax.reload();
                              Swal.fire('Exitoso', 'Orden Actualizada', 'success');
                         });
                    }
                    else
                    {
                         Swal.fire('Info', 'Seleccione una fecha de pago', 'info');
                    }
     }
     })
     });

    $("table").on("click", ".td-actualizarventa", function (event) {
     var table = $('#'+idTabla).DataTable();
          var orden = $(this).attr("data-orden");
          fetchConToken(
               `Ventas/DescargarVentaById?empresa_id=${getDataSession(
                   'empresa'
               )}&sucursal_id=${getDataSession('sucursal')}&id=${orden}`,
               null,
               'POST'
           ).then(function (response) {
               if (response.hasOwnProperty('status') && response.status === 400) {
                   Object.entries(response.errors).map(([key, value]) => {
                       Swal.fire('Error', value[0], 'error');
                   });
                   table.ajax.reload();
                   return;
               } else if (response.hasOwnProperty('codigo') && response.codigo !== 200) {
                   Swal.fire('Error', response.mensajes[0], 'error');
                   table.ajax.reload();
                   return;
               }
               
                           table.ajax.reload();
               Swal.fire('Exitoso', 'Orden Actualizada', 'success');
           });
     
     });

    
     $("table").on("click", ".td-crearventa", function (event) {
          var ruta = $(this).attr("data-ruta");
          var evento = $(this).attr("data-evento");
          var table = $('#'+idTabla).DataTable();
          Swal.fire({
               text: 'Esta seguro de ' + evento,
               icon: 'warning',
               showCancelButton: true,
               confirmButtonText: evento,
               cancelButtonText: 'Cancelar'
           }).then((result) => {
               if (result.isConfirmed) {
     
                    fetchConToken(ruta,null, 'POST').then((response) => {
                         if (response.hasOwnProperty('status') && response.status === 400) {
                         Object.entries(response.errors).map(([key, value]) => {
                              Swal.fire('Error', value[0], 'error');
                              table.ajax.reload();
                              return;
                         });
                         return false;
                         } else if (response.hasOwnProperty('codigo') && response.codigo !== 200) {
                         Swal.fire('Error', response.mensajes[0], 'error');
                         table.ajax.reload();
                         return false;
                         }
                         
                         table.ajax.reload();
                         window.location.reload()
                    })
               }
               })
           });


          


     $("table").on("click", ".td-cancelar-cfdi", function (event) {
               var id = $(this).attr("data-id");
               Swal.fire({
                    text: 'Esta seguro de cancelar la factura',
                    icon: 'warning',
                    showCancelButton: true,
                    confirmButtonText: "Cancelar factura",
                    cancelButtonText: 'Salir',
                    customClass: 'swal-wide-size',
                    html:
                    '<label>Motivo cancelación</label>'+
                    '<select class="swal2-select" id="select-motivo" style="display: flex;">'+
                    '<option value="01">Comprobante emitido con errores con relación</option>'+
                    '<option value="02">Comprobante emitido con errores sin relación</option>'+
                    '<option value="03">No se llevó a cabo la operación</option>'+
                    '<option value="04">Operación nominativa relacionada en la factura global</option>'+
                    '</select> <br/>' +
                    '<label class="factura-relacionar ">Folio o UUID de la factura que sustituye al cancelado:</label><input id="folio-relacionar" class="swal2-input factura-relacionar ">',
                    preConfirm: () => {
                         
                         if($('#select-motivo').val() == "01")
                              $('.factura-relacionar').removeClass("ocultar");
                         else
                              $('.factura-relacionar').addClass("ocultar");

                         if ($('#select-motivo').val() == "01") 
                         {
                            if($('#folio-relacionar').val().length == 0)
                              Swal.showValidationMessage('Escriba el Folio o UUID de la nueva factura a relacionar')   
                         }

                    },
                }).then((result) => {
                    if (result.isConfirmed) {
                         fetchConToken(`facturacion/CancelarCfdi?cfdi_id=${id}&motivo=${$('#select-motivo').val()}&folio=${$('#folio-relacionar').val()}`,).then((response) => {
                              if (response.hasOwnProperty('status') && response.status === 400) {
                              Object.entries(response.errors).map(([key, value]) => {
                                   Swal.fire('Error', value[0], 'error');
                                   return;
                              });
                              return false;
                              } else if (response.hasOwnProperty('codigo') && response.codigo !== 200) {
                              Swal.fire('Error', response.mensajes[0], 'error');
                              return false;
                              }
                              
                              Swal.fire('Exito', 'Solicitud enviada', 'success');
                              var table = $('#'+idTabla).DataTable();
                              table.ajax.reload();
                         })
                    }
               })
               const select = document.getElementById("select-motivo");
               select.addEventListener(
                  'change',
                  function() { 
                    if($('#select-motivo').val() == "01")
                         $('.factura-relacionar').removeClass("ocultar");
                    else
                         $('.factura-relacionar').addClass("ocultar"); },
                  false
               );
     });

     var table2 = $('#'+idTabla).DataTable();

     $('#'+idTabla+' tbody').on('click', 'tr td.details-control', function () {
         var tr = $(this).closest('tr');
         var articulo_id = $(this.firstChild).attr('data-id');

         var row = table2.row(tr);
         var idx = detailRows.indexOf(tr.attr('id'));
         if (row.child.isShown()) {
             tr.removeClass('details');
             row.child.hide();
  
             // Remove from the 'open' array
             detailRows.splice(idx, 1);
         } else {
             tr.addClass('details');

             fetchConToken(`articulos/Variantes?articulo_id=${articulo_id}`).then((imp) => {
                  row.child(format(imp)).show();
             });
             
             // Add to the 'open' array
             if (idx === -1) {
                 detailRows.push(tr.attr('id'));
             }
         }
     });
     


     /*var editor = new $.fn.dataTable.Editor( {
          table: "#table-js"
      } );
     $('#table-js').on( 'click', 'tbody td:not(:first-child)', function (e) {
          editor.inline( this );
      } );*/

     // On each draw, loop over the `detailRows` array and show any child rows
     /*
     table2.on('draw', function () {
         detailRows.forEach(function(id, i) {
             $('#' + id + ' td.details-control').trigger('click');
         });
     });*/


    },[])
    
        return <div className="datetable-scroll">
        <div>
            <table id={idTabla} className="display nowrap" width="100%" ref={tableRef}>
                <thead>
                    <tr>
                     {props.columns.filter(item => item !== 'vacio').map((item)=>{
                          return <th key={item} >{item}</th>
                     })}
                    </tr>
                </thead>
            </table>
            </div>
        </div>
}

export default Table;