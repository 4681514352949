import React from 'react';
import { Redirect, Route, Switch } from "react-router";
import { AddEdit } from "./AddEdit";
import { List } from "./List";
import { PlanesYPrecios } from "./PlanesYPrecios";

function Paquetes({ match }) {
    const { path } = match;
    console.log(match);
    return (
        <Switch>
            <Route exact path={path} component={List} />
            <Route path={`${path}/add`} component={AddEdit} />
            <Route path={`${path}/edit/:id`} component={AddEdit} />
            <Redirect from="*" to={{
                                pathname: List
                            }}/>

        </Switch>
    );
}

export { Paquetes };
