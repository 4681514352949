import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { fetchConToken } from 'helpers/fetch';
import { getDataSession } from 'views/Utils/Common';
import { useForm } from 'react-hook-form';

import PropTypes from 'prop-types';
import AsyncSelect from 'react-select/async';

import Swal from 'sweetalert2';

const AddEdit = ({ history, match }) => {
    const { handleSubmit } = useForm();
    const { id } = match.params;
    const isAddMode = !id;

    const [initialObjectEmpresa, setInitialObjectEmpresa] = useState(true);
    const [initialObjectPaquete, setInitialObjectPaquete] = useState(true);

    let [agregarPaquete, setAgregarPaquete] = useState({
        paquete_Id: 0,
        empresa_Id: 0,
        sucursal_Id: getDataSession('sucursal'),
        tipo_Pago: '',
        referencia: '',
        cantidad: 0

    });

    const loadPaquetes = async (query) => {
        var resp = await fetchConToken(
            `PaquetesTimbres/GetList?paginas=${1}&totalXpagina=${50}&busqueda=${query}`
        );
        return resp;
    };

    const loadEmpresas = async (query) => {
        var resp = await fetchConToken(
            `Empresas/GetList?pagina=${1}&totalXpagina=${50}&busqueda=${query}`
        );
        return resp;
    };

    function onSubmit(data) {
        if (isAddMode && getDataSession('sucursal') === '0') {
            Swal.fire('Info', 'Seleccione una conexión para continuar.', 'info');
            return;
        }
        return isAddMode ? create(data) : update(id, data);
    }

    function create(data) {
        fetchConToken(
            `MovimientosPacs/AgregarPaquete`,
            agregarPaquete,
            'POST'
        ).then(function (response) {
            if (response.hasOwnProperty('status') && response.status === 400) {
                Object.entries(response.errors).map(([key, value]) => {
                    Swal.fire('Error', value[0], 'error');
                });
                return;
            } else if (response.hasOwnProperty('codigo') && response.codigo !== 200) {
                Swal.fire('Error', response.mensajes[0], 'error'); 
                return;
            }
            Swal.fire('Exito', 'Paquete Asignado', 'success');
            history.push('.');
        });
    }
    function update(id, data) {}

    function handleInputChange(event) {
        const target = event.target;
        const value = target.type === 'checkbox' ? target.checked : target.value;
        const name = target.name;

        setAgregarPaquete({
            ...agregarPaquete,
            [name]: value
        });
    }

    return (
        <>
            <div className='container-fluid'>
                <form onSubmit={handleSubmit(onSubmit)} /*onReset=""*/>
                    <div className='mb-3'>
                        <div className='col-md-12'>
                            <div className='col-sm-12  col-md-12'>
                                <h3>{isAddMode ? 'Asignar Paquete' : 'Editar paquete'}</h3>
                            </div>
                            <div className='col-md-12 text-right'>
                            <button className='btn btn-outline-success' type='submit'>
                                        <svg
                                            xmlns='http://www.w3.org/2000/svg'
                                            width='20'
                                            height='20'
                                            fill='currentColor'
                                            className='bi bi-check-lg'
                                            viewBox='0 0 16 16'
                                        >
                                            <path d='M12.736 3.97a.733.733 0 0 1 1.047 0c.286.289.29.756.01 1.05L7.88 12.01a.733.733 0 0 1-1.065.02L3.217 8.384a.757.757 0 0 1 0-1.06.733.733 0 0 1 1.047 0l3.052 3.093 5.4-6.425a.247.247 0 0 1 .02-.022Z' />
                                        </svg>
                                        &nbsp; Guardar
                                    </button>
                                    </div>
                                    </div>
                        
                    </div>
                    <div className='row'>
                        <div className='col-md-12' style={{ display: 'grid' }}>
                            <div className='card mb-3 '>
                            <label className='form-label title2'>Datos obligatorios</label>
                                <div className='card-body text-start'>
                                    <label htmlFor='clave_Prod_Serv' className='form-label'>
                                        Empresa
                                    </label>
                                    <AsyncSelect
                                        isClearable
                                        className='react-select-placeholder'
                                        getOptionLabel={(option) =>
                                            '( ' + option.rfc + ') ' + option.nombre
                                        }
                                        getOptionValue={(option) => option.id}
                                        defaultOptions
                                        value={initialObjectEmpresa}
                                        loadOptions={loadEmpresas}
                                        onChange={(selectedItem) => {
                                            const val =
                                                selectedItem === null ? '' : selectedItem?.id;
                                            console.log(val);
                                            agregarPaquete.empresa_Id = val;
                                            setInitialObjectEmpresa(selectedItem);
                                        }}
                                    />
                                   
                                    <label htmlFor='no_Identificacion' className='form-label'>
                                        Paquete
                                    </label>
                                    <AsyncSelect
                                        isClearable
                                        className='react-select-placeholder'
                                        getOptionLabel={(option) =>
                                            '( ' + option.cantidad_Timbres + ') ' + option.nombre
                                        }
                                        getOptionValue={(option) => option.id}
                                        defaultOptions
                                        value={initialObjectPaquete}
                                        loadOptions={loadPaquetes}
                                        onChange={(selectedItem) => {
                                            const val =
                                                selectedItem === null ? '' : selectedItem?.id;
                                            console.log(val);
                                            agregarPaquete.paquete_Id = val;
                                            setInitialObjectPaquete(selectedItem);
                                        }}
                                    />

                                    <label htmlFor='no_Identificacion' className='form-label'>
                                     Tipo pago
                                    </label>
                                    <select
                                        className='form-control'
                                        name='tipo_Pago'
                                        value={agregarPaquete.tipo_Pago || ''}
                                        onChange={handleInputChange}
                                    >
                                        <option value='Tarjeta de crédito'>Tarjeta de crédito</option>
                                        <option value='Tarjetas de débito'>Tarjetas de débito </option>
                                        <option value='Mercado Crédito'>Mercado Crédito</option>
                                        <option value='En efectivo con transferencia o depósito bancario'>En efectivo con transferencia o depósito bancario</option>
                                        <option value='Saldo en cuenta de Mercado Pago Wallet'>Saldo en cuenta de Mercado Pago Wallet</option>
                                        <option value='En efectivo con un ticket'>En efectivo con un ticket</option>
                                        
                                     </select>

                                    <label htmlFor='no_Identificacion' className='form-label'>
                                     Referencia
                                    </label>
                                    <input
                                        type='text'
                                        className='form-control'
                                        name='referencia'
                                        maxLength={30}
                                        value={agregarPaquete.referencia || ''}
                                        onChange={handleInputChange}
                                    />

                                </div>
                            </div>
                        </div>
                    </div>
                </form>
            </div>
        </>
    );
};

export { AddEdit };
