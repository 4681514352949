import React, { useState } from 'react';
import { fetchConToken } from 'helpers/fetch';
import { getDataSession } from 'views/Utils/Common';
import { alertService } from '_services';
import { Link } from 'react-router-dom';
import { descargarFactura } from 'actions/archivos';
import Modal from 'components/modal/Modal';
import { useModal } from 'hooks/useModal';
import Pagination from 'components/pagination/Pagination';
import { useForm } from 'react-hook-form';
import { currency, rfcValido } from 'resources/utilities';

import PropTypes from 'prop-types';
import AsyncSelect from 'react-select/async';

import Swal from 'sweetalert2';
import SLUGS from 'resources/slugs';

const ComplementoPago = ({ history, match, saludo, subtitulo }) => {
    const { id } = match.params;
    const [facturado, setFacturado] = useState(false);
    const { handleSubmit } = useForm();

    const [initialObject, setInitialObject] = useState(true);
    const [initialObjectClaveUnidad, setInitialObjectClaveUnidad] = useState(true);


    const [isOpenModal, openModal, closeModal] = useModal(false);
    const [modalValor, setModalValor] = useState(false);
    let [cfdi, setCfdi] = useState([]);                                                                                                                                             

    let [cliente, setCliente] = useState([]);
    let [clientes, setClientes] = useState([]);
    let [direccionesList, setDireccionesList] = useState([]);


    let [facturas, setFacturas] = useState([]);
    let [pagos, setPagos] = useState([]);
    let [complemento, setComplemento] = useState({
        cliente_Id: 0,
        pagos: [],
        //cfdis: [],
        sucursal_Id: getDataSession('sucursal')
    });




    let [pago, setPago] = useState([]);

    let [serie, setSerie] = useState('');
    let [art_id, setArtId] = useState('');   

    let [sucursales, setSucursales] = useState([]);
    let [series, setSeries] = useState([]);

    var direccion_id = 0;

    const totalxPagina = 10;

    const busqueda = useFormInput('');
    let [error, setError] = useState(['']);                                                                                                                                                                                                                                                              
    let [errorRfc, setErrorRfc] = useState(['']);
    let [erroresCliente, setErroresCliente] = useState({});
    let [erroresDirecciones, setErroresDirecciones] = useState({});

    let [erroresV, setErroresV] = useState({});
    let [erroresArticulos, setErroresArticulos] = useState({});

    const [satUsoCfdi, setSatUsoCfdi] = useState([]);
    const [satRegimenFiscal, setSatRegimenFiscal] = useState([]);
    const [satFormaPago, setSatFormaPago] = useState([]);
    const [satPais, setSatPais] = useState(['']);

    

    React.useEffect(() => {
        getSucursales();
        getSatUsoCfdi();
        //getSatRegimelFiscal();
        getSatFormaPago();
        getSatPais();
        getSeries(getDataSession('sucursal'));

        if (getDataSession('sucursal') === '0') {
            Swal.fire('Info', 'Seleccione una conexión para continuar.', 'info');
            return;
        }
    }, []);

    /////////////////////
    const loadData = async (query) => {
        var resp = await fetchConToken(
            `CatalogosSat/ListClaveProd?pagina=${1}&totalXpagina=${50}&busqueda=${query}`
        );
        return resp;
    };
    const loadDataClaveUnidad = async (query) => {
        var resp = await fetchConToken(
            `CatalogosSat/ListClaveUnidad?pagina=${1}&totalXpagina=${50}&busqueda=${query}`
        );
        return resp;
    };



    const getInitialClaveUnidad = async (art) => {

        await fetchConToken(
            `CatalogosSat/ClaveUnidad?claveUnidad=${art?.clave_Unidad}`
        ).then((resp) => {
            setInitialObjectClaveUnidad(resp);
        });


        await fetchConToken(
            `CatalogosSat/ClaveProd?ClaveProd=${art?.clave_Prod_Serv}`
        ).then((resp) => {
            setInitialObject(resp);
            
            /*var aux = resp.find((e) => e.clave === art?.clave_Prod_Serv);
            setInitialObject(aux);*/
        });

        
    };

  

    //////////////////////////
    async function getSatUsoCfdi() {
        const response = await fetchConToken(`CatalogosSat/ListUsoCfdi?pagina=1&totalXpagina=300`);
        setSatUsoCfdi(response);
    }

    async function getSatRegimelFiscal(uso_Cfdi_clave) {
        const response = await fetchConToken(`CatalogosSat/ListRegimenFiscal?pagina=1&totalXpagina=300&uso_cfdi_clave=${uso_Cfdi_clave}`);
        setSatRegimenFiscal(response);
    }

    async function getSatFormaPago() {
        const response = await fetchConToken(`CatalogosSat/ListFormaPago?pagina=1&totalXpagina=300`);
        setSatFormaPago(response.filter(r=>r.clave !== "99"));
    }

    async function getSatPais() {
        const response = await fetchConToken(`CatalogosSat/ListPais?pagina=1&totalXpagina=300`);
        setSatPais(response);
    }

    async function getSucursales() {
        const response = await fetchConToken(
            `Sucursales/GetList?empresa_id=${getDataSession('empresa')}&pagina=1&totalXpagina=50`
        );
        setSucursales(response);
    }

    async function getSeries(sucursal_id) {
        const response = await fetchConToken(
            `Sucursales/GetFoliosSucursales?sucursal_Id=${sucursal_id}&tipo_Documento=P`
        );
        setSeries(response);
        setSerie(response[0]?.id);
    }



    const facturar = () => {
        if (getDataSession('sucursal') === '0') {
            Swal.fire('Info', 'Seleccione una conexión para continuar.', 'info');
            return;
        }
        if (cliente.rfc > 0) {
            setError('Escriba correctamente el RFC');
            return '';
        }
        console.log(complemento);
        console.log(cliente.id);
        complemento.cliente_Id = cliente.id;

        pagos.map(p=>{
            p.cfdis = facturas.filter(f=>f.pago_id == p.id);
        });

        //pagos[0].cfdis = facturas;
        complemento.pagos = pagos;
        //complemento.cfdis = facturas;
        complemento.sucursal_Id = getDataSession('sucursal');

        var totalPagos = pagos.reduce( (a, v) => (a = a + parseFloat(v.importe_Pago)),0 );
        var totalFacturasPagadas = facturas.reduce( (a, v) => (a = a + parseFloat(v.importe_Pago)),0 );

        if(totalPagos != totalFacturasPagadas)
        {
            Swal.fire('Info', 'Los importes de los pagos y las facturas no coinciden', 'info');
            return;
        }

        facturarVenta();

    };

    const facturarVenta = () => {
        fetchConToken(`Facturacion/FacturaComplementoPago?serie_Id=${serie}`,complemento,'POST').then((response) => {
            setError('');
            if (response.hasOwnProperty('status') && response.status === 400) {
                Object.entries(response.errors).map(([key, value]) => {
                    Swal.fire('Error', value[0], 'error');
                    return false;
                });
                return;
            } else if (response.hasOwnProperty('codigo') && response.codigo !== 200) {
                Swal.fire('Error', response.mensajes[0], 'error');
                return;
            }
            Swal.fire('Exitoso', 'Facturado', 'success');
            setFacturado(true);
            setCfdi(response);
            //setPagos(pagos?.pagos);
            //setFacturas(pagos?.cfdis);
        });
    };


    const G = ({ target }) => {
        setCliente({                                              
            ...cliente,
            [target.name]: target.value
        });

        if (target.name === 'rfc') {
            var rfcCorrecto = rfcValido(target.value, true);
            if (!rfcCorrecto) {
                setErrorRfc('RFC no valido');
            } else {
                setErrorRfc('');
            }
        }

        if(target.name === 'uso_Cfdi')
        {
            getSatRegimelFiscal(target.value);
        }
    };

    const handleInputChangeSerie = ({ target }) => {
        setSerie(target.value);
    };



    async function eliminar(id) {
        setFacturas(facturas.filter((item) => item.cfdi_Id !== id));
    }

    async function eliminarPago(id) {

        var pagosAux = pagos.filter((item) => item.id === id)

        pagosAux.map(x=>{
            setFacturas(  facturas.filter(f=>f.pago_id !== id) );
        });

        setPagos(pagos.filter((item) => item.id !== id));
    }

    

    ////Clientes
    /////////////////////////////////////////////
    async function getClientes(page) {
        setModalValor('cliente');
        const response = await fetchConToken(
            `clientes/GetList?sucursal_id=${getDataSession('sucursal')}&empresa_id=${getDataSession(
                'empresa'
            )}&paginas=${page}&totalXpagina=${totalxPagina}&busqueda=${busqueda.value}`
        );
        setClientes(response);
        //getDirecciones();
    }

    async function getCliente(cliente_id) {
        const clienteAux = await fetchConToken(                                                                                                                                                                                                                                                                                                                             
            `Clientes?id=${cliente_id}&sucursal_Id=${getDataSession('sucursal')}`
        );
        if(cliente.uso_Cfdi !== null)
         getSatRegimelFiscal(clienteAux.uso_Cfdi);
        
        clienteAux.estatus = clienteAux.estatus.replace(' ') === '' ? 'A' : clienteAux.estatus;
        clienteAux.rfc = clienteAux.estatus.replace(' ') === '' ? '' : clienteAux.rfc;
        setCliente(clienteAux);
        
    }
    ////////////////////////////////////////////

    /////////Direcciones
    ///////////////////////////////////////////
    async function getDirecciones(page) {
        if (cliente.length === 0) {
            Swal.fire('Error', 'Seleccione un cliente', 'error');                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                           
            //setError('Seleccione un cliente');                  
            return;
        }
        setModalValor('direccion');
        const direccionAux = await fetchConToken(
            `DireccionesClientes/GetList?paginas=${page}&totalXpagina=${totalxPagina}&busqueda=${cliente.id}`
        );                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                        

        setDireccionesList(direccionAux);
        getCliente(direccionAux.cliente_Id);
        openModal();
    }

    ///////////////////////////////////////////

    ///////////////////////////////////////////

    async function getFactura(factura,pago_id) {
        console.log(pago_id);
        factura.pago_id = pago_id;

        var facturaAux = facturas.filter(f=>f.cfdi_Id == factura.cfdi_Id);

        if(facturaAux?.length > 0)
        {
            Swal.fire('Error', 'Ya se agrego la factura a un pago', 'error');      
            return;
        }

        setFacturas([...facturas,factura]);


    }

   

    ///////////////////////////////////////////


    const AgregarPago = () => {
        setModalValor('agregarPago');
        openModal();
    };
         
    const AgregarPagoComprobante = () => {

        var pagosAux = pagos.filter(f=>f.forma_Pago == pago.forma_Pago);
        if(pagosAux?.length > 0)
        {
            Swal.fire('Error', 'Ya se agrego la forma de pago', 'error');      
            return;
        }

        if(pago.fechapago?.length > 0)
        {
            pago.id = pagos.length;

            var pagoNuevo = { ...pago };
            setPagos([...pagos,pagoNuevo]);
            closeModal();

            console.log(facturas);
            console.log(pagos);
            return;
        }
        Swal.fire('Error', 'Agrege una fecha de pago', 'error');        

       
    };
    

    /////////// Detalles Pago
    //////////////////////////////////////////
    const handleInputChangeDetallesPago = (event, key, campo) => {

        let factura = facturas.filter((f) => f.cfdi_Id === key);
        if(parseFloat(event.target.value) >  parseFloat(factura[0]["importe_Saldo_Anterior"] ))
        {
            Swal.fire('Info', 'El importe es mayor al de la factura.', 'info');
            setFacturas(facturas.map((f) => (f.cfdi_Id === key ? factura[0] : f)));
            return;
        }

        factura[0][campo] = parseFloat(event.target.value.length > 0 ? event.target.value : 0);
        factura[0]["importe_Saldo_Insoluto"] = factura[0].importe_Saldo_Anterior - factura[0].importe_Pago;
        setFacturas(facturas.map((f) => (f.cfdi_Id === key ? factura[0] : f)));

    };
    //////////
    //////////////////select2
    const loadClientes = async (query) => {
        if (getDataSession('sucursal') === '0') {
            Swal.fire('Info', 'Seleccione una conexión para continuar.', 'info');
            return;
        }
        var resp = await fetchConToken(
            `clientes/GetList?sucursal_id=${getDataSession('sucursal')}&empresa_id=${getDataSession(
                'empresa'
            )}&paginas=1&totalXpagina=50&busqueda=${query}`
        );
        return resp;
    };

    const loadFacturas = async (query) => {
        if (getDataSession('sucursal') === '0') {
            Swal.fire('Info', 'Seleccione una conexión para continuar.', 'info');
            return;
        }
        if (cliente?.nombre === 'undefined' || cliente?.nombre === undefined || cliente?.nombre?.length == 0) {
            Swal.fire('Info', 'Seleccione un cliente para continuar.', 'info');
            return;
        }
        var resp = await fetchConToken(
            `Cfdis/GetListPPD?paginas=1&totalXpagina=50&busqueda=${query}&cliente=${cliente.nombre}`
        );
        return resp;
    };

    const loadDirecciones = async (query) => {
        if (getDataSession('sucursal') === '0') {
            Swal.fire('Info', 'Seleccione una conexión para continuar.', 'info');
            return;
        }
        var resp = await fetchConToken(
            `DireccionesClientes/GetList?paginas=1&totalXpagina=50&busqueda=${cliente.id}`
        );
        return resp;
    };

    function handleInputChangePago({target}) {
        
        if(target.name === "forma_Pago")
        {
           
            var forma = satFormaPago.filter((f) => f.clave === target.value);

           pago.forma_Pago_Nombre = forma[0].nombre;
        }



        setPago({
            ...pago,
            [target.name]: target.value
        });
        console.log(pago);
    }

    function handleInputChangeFacturas({target}) {
        if(target.value === "01")
           history.push(SLUGS.facturar);
    }


    return (
        <>
            <div className='container-fluid'>
                <form onSubmit={handleSubmit(facturar)} className='facturar' /*onReset=""*/>

                    <div className='col-md-12'>
                        <div className='row' style={{ textAlign: 'left' }}>
                            <h3>Facturacion</h3>
                        </div>
                        <div className='col-md-12 text-right'>
                            <button
                                hidden={facturado}
                                className='btn btn-outline-success'
                                //onClick={ (e) => facturar() }
                                type='submit'
                            >
                                &nbsp; Facturar
                            </button>
                            &nbsp;
                            <button
                                type='button'
                                hidden={!facturado}
                                className='btn btn-outline-danger'
                                onClick={() =>
                                    descargarFactura(
                                        cfdi.id,
                                        'Facturacion/DescargarPDF',
                                        cfdi.folio + '.pdf'
                                    )
                                }
                            >
                                <svg
                                    xmlns='http://www.w3.org/2000/svg'
                                    width='20'
                                    height='20'
                                    fill='currentColor'
                                    className='bi bi-file-pdf'
                                    viewBox='0 0 16 16'
                                >
                                    <path d='M4 0a2 2 0 0 0-2 2v12a2 2 0 0 0 2 2h8a2 2 0 0 0 2-2V2a2 2 0 0 0-2-2H4zm0 1h8a1 1 0 0 1 1 1v12a1 1 0 0 1-1 1H4a1 1 0 0 1-1-1V2a1 1 0 0 1 1-1z' />
                                    <path d='M4.603 12.087a.81.81 0 0 1-.438-.42c-.195-.388-.13-.776.08-1.102.198-.307.526-.568.897-.787a7.68 7.68 0 0 1 1.482-.645 19.701 19.701 0 0 0 1.062-2.227 7.269 7.269 0 0 1-.43-1.295c-.086-.4-.119-.796-.046-1.136.075-.354.274-.672.65-.823.192-.077.4-.12.602-.077a.7.7 0 0 1 .477.365c.088.164.12.356.127.538.007.187-.012.395-.047.614-.084.51-.27 1.134-.52 1.794a10.954 10.954 0 0 0 .98 1.686 5.753 5.753 0 0 1 1.334.05c.364.065.734.195.96.465.12.144.193.32.2.518.007.192-.047.382-.138.563a1.04 1.04 0 0 1-.354.416.856.856 0 0 1-.51.138c-.331-.014-.654-.196-.933-.417a5.716 5.716 0 0 1-.911-.95 11.642 11.642 0 0 0-1.997.406 11.311 11.311 0 0 1-1.021 1.51c-.29.35-.608.655-.926.787a.793.793 0 0 1-.58.029zm1.379-1.901c-.166.076-.32.156-.459.238-.328.194-.541.383-.647.547-.094.145-.096.25-.04.361.01.022.02.036.026.044a.27.27 0 0 0 .035-.012c.137-.056.355-.235.635-.572a8.18 8.18 0 0 0 .45-.606zm1.64-1.33a12.647 12.647 0 0 1 1.01-.193 11.666 11.666 0 0 1-.51-.858 20.741 20.741 0 0 1-.5 1.05zm2.446.45c.15.162.296.3.435.41.24.19.407.253.498.256a.107.107 0 0 0 .07-.015.307.307 0 0 0 .094-.125.436.436 0 0 0 .059-.2.095.095 0 0 0-.026-.063c-.052-.062-.2-.152-.518-.209a3.881 3.881 0 0 0-.612-.053zM8.078 5.8a6.7 6.7 0 0 0 .2-.828c.031-.188.043-.343.038-.465a.613.613 0 0 0-.032-.198.517.517 0 0 0-.145.04c-.087.035-.158.106-.196.283-.04.192-.03.469.046.822.024.111.054.227.09.346z' />
                                </svg>
                                &nbsp; Descargar PDF
                            </button>
                            &nbsp; &nbsp; &nbsp;
                            <button
                                type='button'
                                hidden={!facturado}
                                className='btn btn-outline-warning'
                                onClick={() =>
                                    descargarFactura(
                                        cfdi.id,
                                        'Facturacion/DescargarXML',
                                        cfdi.folio + '.xml'
                                    )
                                }
                            >
                                <svg
                                    xmlns='http://www.w3.org/2000/svg'
                                    width='20'
                                    height='20'
                                    fill='currentColor'
                                    className='bi bi-filetype-xml'
                                    viewBox='0 0 16 16'
                                >
                                    <path
                                        fillRule='evenodd'
                                        d='M14 4.5V14a2 2 0 0 1-2 2v-1a1 1 0 0 0 1-1V4.5h-2A1.5 1.5 0 0 1 9.5 3V1H4a1 1 0 0 0-1 1v9H2V2a2 2 0 0 1 2-2h5.5L14 4.5ZM3.527 11.85h-.893l-.823 1.439h-.036L.943 11.85H.012l1.227 1.983L0 15.85h.861l.853-1.415h.035l.85 1.415h.908l-1.254-1.992 1.274-2.007Zm.954 3.999v-2.66h.038l.952 2.159h.516l.946-2.16h.038v2.661h.715V11.85h-.8l-1.14 2.596h-.025L4.58 11.85h-.806v3.999h.706Zm4.71-.674h1.696v.674H8.4V11.85h.791v3.325Z'
                                    />
                                </svg>
                                &nbsp; Descargar XML
                            </button>
                        </div>
                    </div>
                    <p style={{ color: 'red' }}>{error}</p>
                    <div className='row'>
                        <div className='col-md-12'>
                            <div className='card-title col-md-6'>
                                <h5>Información del cliente</h5>
                            </div>
                            <div className='col-md-6'>
                                <label>Tipo de Comprobante</label>
                                <select className='form-control' onChange={handleInputChangeFacturas}>
                                    <option value="01">Factura</option>
                                    <option value="02" selected={true}>Complemento de pago</option>
                                    <option disabled>Factura con retención</option>
                                    <option disabled>Nota de crédito</option>
                                    <option disabled>Carta porte</option>
                                    <option disabled>Honorarios</option>
                                </select>
                                <br></br>
                            </div>
                            {Object.entries(erroresCliente).map(([key, value]) => {
                                return (
                                    <>
                                        <p style={{ color: 'red' }}>{value}</p>
                                    </>
                                );
                            })}
                        </div>
                        <div className='col-md-6' style={{ display: 'grid' }}>
                            <div className='card mb-3 '>
                                <div className='card-body text-start'>
                                    <label htmlFor='id'>Cliente:</label>
                                    <AsyncSelect
                                    className='react-select-placeholder'
                                        isClearable
                                        getOptionLabel={(option) => option.rfc + '-' + option.nombre}
                                        getOptionValue={(option) => option.id}
                                        //defaultValue={initialObject.clave}
                                        loadOptions={loadClientes}
                                        onChange={(selectedItem) => {
                                            const val = selectedItem === null ? '' : selectedItem?.id;
                                            getCliente(val);
                                        }}
                                    />

                                    <label htmlFor='rfc'>RFC*:</label>
                                    <input
                                        type='text'
                                        className='form-control'
                                        name='rfc'
                                        value={cliente.rfc}
                                        disabled={true}
                                        required
                                    />
                                    


                                </div>
                            </div>
                        </div>
                        <div className='col-md-6' style={{ display: 'grid' }}>
                            <div className='card mb-3 '>
                                <div className='card-body text-start'>
                                  
                                <label htmlFor='nombre'>Razón Social*:</label>
                                    <input
                                        type='text'
                                        className='form-control'
                                        name='nombre'
                                        value={cliente.nombre}
                                        disabled={true}
                                        required
                                    />

                                <label htmlFor='correo'>Correo*:</label>
                                    <input
                                        type='email'
                                        className='form-control'
                                        name='correo'
                                        value={cliente.correo}
                                        disabled={true}
                                        required
                                    />
                                </div>
                            </div>
                        </div>


                        
                        <div className='col-md-12'>
                            <div className='card-title'>
                                <div className='col-md-12'>
                                    {Object.entries(erroresV).map(([key, value]) => {
                                        return (
                                            <>
                                                <p style={{ color: 'red' }}>{value}</p>
                                            </>
                                        );
                                    })}
                                </div>
                            </div>
                        </div>

                        <div
                            className='col-md-12 row'
                            style={{ paddingTop: '7px', paddingBottom: '7px' }}
                        >
                            <div className='col-4 col-sm-4 col-md-4 '>
                                <label>Serie:</label>
                                <select
                                    className='form-control'
                                    value={serie}
                                    onChange={handleInputChangeSerie}
                                    disabled={facturado}
                                >
                                    {series.map((s) => {
                                        return (
                                            <option key={s.id} value={s.id}>
                                                {s.serie}
                                            </option>
                                        );
                                    })}
                                </select>
                            </div>
                        </div>
                        {/* Registrar pago */}
                       
                         <div className='col-md-12' style={{ display: 'grid' }}>
                            
                            <div className='card mb-3 '>
                                
                                <div className='text-start'>
                                <button
                                    hidden={facturado}
                                    className='btn btn-outline-success'
                                    onClick={ (e) => AgregarPago() }
                                    type='button'
                                >
                                &nbsp; Agregar Pago
                            </button>
                                    <div className='card col-12 col-sm-12 col-md-12'>
                                        {Object.entries(erroresArticulos).map(([key, value]) => {
                                            return (
                                                <>
                                                    <p style={{ color: 'red' }}>{value}</p>
                                                </>
                                            );
                                        })}
                                        <div style={{ overflowX: 'auto' }}>
                                            <table className='table'>
                                                <thead>
                                                    <tr>
                                                        <th>Forma de pago</th>
                                                        <th>Fecha Pago</th>
                                                        <th>Importe</th>
                                                        <th hidden= {facturado}>Agregar Factura</th>
                                                        <th></th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    { pagos != undefined ? pagos?.map((item) => {
                                                        return (
                                                           <>
                                                            <tr key={item.forma_Pago}>
                                                                <td>{item.forma_Pago} - {item.forma_Pago_Nombre}</td>
                                                                <td>{item.fechapago}</td>
                                                                <td>{currency(item.importe_Pago)}</td>
                                                                <td hidden= {facturado}><AsyncSelect
                                                                        className='react-select-placeholder'
                                                                            
                                                                            isClearable
                                                                            getOptionLabel={(option) =>
                                                                                '( Folio: '+option.folio+')' +
                                                                                ' - ' +
                                                                                '(Fecha: '+option.fecha?.substring(0, 10) +')'
                                                                            }
                                                                            value=''
                                                                            getOptionValue={(option) => option.id}
                                                                            loadOptions={loadFacturas}
                                                                            onChange={(selectedItem) => {
                                                                                const val = selectedItem === null ? '' : selectedItem;
                                                                                getFactura(val,item.id);
                                                                            }}
                                                                        />
                                                                </td>

                                                                <td style={{ width: "50px"}} hidden={facturado}>
                                                                    <button
                                                                        type='button'
                                                                        className='btn  mr-1'
                                                                        hidden= {facturado}
                                                                        onClick={() =>
                                                                            eliminarPago(
                                                                                item.id
                                                                            )
                                                                        }
                                                                    >
                                                                        <svg
                                                                            xmlns='http://www.w3.org/2000/svg'
                                                                            width='25'
                                                                            height='25'
                                                                            fill='currentColor'
                                                                            className='bi bi-x-circle-fill'
                                                                            viewBox='0 0 16 16'
                                                                        >
                                                                            <path d='M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0zM5.354 4.646a.5.5 0 1 0-.708.708L7.293 8l-2.647 2.646a.5.5 0 0 0 .708.708L8 8.707l2.646 2.647a.5.5 0 0 0 .708-.708L8.707 8l2.647-2.646a.5.5 0 0 0-.708-.708L8 7.293 5.354 4.646z' />
                                                                        </svg>
                                                                    </button>
                                                                </td>
                                                            </tr>
                                                            <tr>
                                                                <td colSpan={5}>
                                                                <table className='table'>
                                                                    <thead>
                                                                        <tr>
                                                                            <th>Folio</th>
                                                                            <th style={{width: '330px'}}>UUID</th>
                                                                            <th>Fecha Factura</th>
                                                                            <th>Imp. Deuda</th>
                                                                            <th>Imp. Pago</th>
                                                                            <th>Imp. Restante</th>
                                                                            <th>Parcialidad</th>
                                                                            <th></th>
                                                                        </tr>
                                                                    </thead>
                                                                    <tbody>
                                                                        {facturas?.filter(f=>f.pago_id == item.id).map((fac) => {
                                                                            return (
                                                                                
                                                                                <tr key={fac.Cfdi_Id}>
                                                                                    <td>{fac.folio}</td>
                                                                                    <td>{fac.uuid}</td>
                                                                                    <td>{fac.fecha?.substring(0, 10)}</td>
                                                                                    <td>{currency(fac.importe_Saldo_Anterior)}</td>
                                                                                    <td> <input
                                                                                            type='number'
                                                                                            className='form-control'
                                                                                            name='fac.importe_Pago'
                                                                                            value={fac.importe_Pago}
                                                                                            onChange={(event) =>
                                                                                                handleInputChangeDetallesPago(
                                                                                                    event,
                                                                                                    fac.cfdi_Id,
                                                                                                    'importe_Pago'
                                                                                                )
                                                                                            }
                                                                                            style={{width: '150px'}}
                                                                                            disabled={facturado}
                                                                                        />
                                                                                    </td>
                                                                                    <td>{currency(fac.importe_Saldo_Insoluto)}</td>
                                                                                    <td>{fac.parcialidad}</td>
                                                                                    <td style={{ width: "50px"}} hidden={facturado}>
                                                                                        <button
                                                                                            hidden= {facturado}
                                                                                            type='button'
                                                                                            className='btn  mr-1'
                                                                                            onClick={() =>
                                                                                                eliminar(
                                                                                                    fac.cfdi_Id
                                                                                                )
                                                                                            }
                                                                                        >
                                                                                            <svg
                                                                                                xmlns='http://www.w3.org/2000/svg'
                                                                                                width='25'
                                                                                                height='25'
                                                                                                fill='currentColor'
                                                                                                className='bi bi-x-circle-fill'
                                                                                                viewBox='0 0 16 16'
                                                                                            >
                                                                                                <path d='M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0zM5.354 4.646a.5.5 0 1 0-.708.708L7.293 8l-2.647 2.646a.5.5 0 0 0 .708.708L8 8.707l2.646 2.647a.5.5 0 0 0 .708-.708L8.707 8l2.647-2.646a.5.5 0 0 0-.708-.708L8 7.293 5.354 4.646z' />
                                                                                            </svg>
                                                                                        </button>
                                                                                    </td>
                                                                                </tr>
                                                                            );
                                                                        })}
                                                                            <tr>
                                                                                    <td colSpan={8} className='text-right'>
                                                                                        <label>Pagado: </label>
                                                                                        {currency(
                                                                                            facturas.filter(f=>f.pago_id == item.id).reduce( (a, v) => (a = a + v.importe_Pago),0 )
                                                                                        )}
                                                                                    </td>
                                                                                </tr>
                                                                    </tbody>
                                                                </table>
                                                                </td>
                                                       </tr>
                                                       </>
                                                        );
                                                    }) : ''}
                                                     <tr>
                                                                <td colSpan={8} className='text-right'>
                                                                    <label>Total Pago: </label>
                                                                    {currency(
                                                                        facturas?.reduce( (a, v) => (a = a + v.importe_Pago),0 )
                                                                    )}
                                                                </td>
                                                            </tr>
                                                </tbody>
                                            </table>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        {/*
                        <div className='col-md-12' style={{ display: 'grid' }}>
                            <div className='card mb-3 '>
                                <div className='text-start'>
                                    <div className='card col-12 col-sm-12 col-md-12'>
                                        {Object.entries(erroresArticulos).map(([key, value]) => {
                                            return (
                                                <>
                                                    <p style={{ color: 'red' }}>{value}</p>
                                                </>
                                            );
                                        })}
                                        <div style={{ overflowX: 'auto' }}>
                                            <table className='table'>
                                                <thead>
                                                    <tr>
                                                        <th>Folio</th>
                                                        <th style={{width: '330px'}}>UUID</th>
                                                        <th>Fecha Factura</th>
                                                        <th>Imp. Deuda</th>
                                                        <th>Imp. Pago</th>
                                                        <th>Imp. Restante</th>
                                                        <th>Parcialidad</th>
                                                        <th></th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    {facturas?.map((item) => {
                                                        return (
                                                           
                                                            <tr key={item.Cfdi_Id}>
                                                                <td>{item.folio}</td>
                                                                <td>{item.uuid}</td>
                                                                <td>{item.fecha?.substring(0, 10)}</td>
                                                                <td>{currency(item.importe_Saldo_Anterior)}</td>
                                                                <td> <input
                                                                        type='number'
                                                                        className='form-control'
                                                                        name='item.importe_Pago'
                                                                        value={item.importe_Pago}
                                                                        onChange={(event) =>
                                                                            handleInputChangeDetallesPago(
                                                                                event,
                                                                                item.cfdi_Id,
                                                                                'importe_Pago'
                                                                            )
                                                                        }
                                                                        style={{width: '150px'}}
                                                                        disabled={facturado}
                                                                    />
                                                                </td>
                                                                <td>{currency(item.importe_Saldo_Insoluto)}</td>
                                                                <td>{item.parcialidad}</td>
                                                                <td style={{ width: "50px"}} hidden={facturado}>
                                                                    <button
                                                                        type='button'
                                                                        className='btn  mr-1'
                                                                        onClick={() =>
                                                                            eliminar(
                                                                                item.cfdi_Id
                                                                            )
                                                                        }
                                                                    >
                                                                        <svg
                                                                            xmlns='http://www.w3.org/2000/svg'
                                                                            width='25'
                                                                            height='25'
                                                                            fill='currentColor'
                                                                            className='bi bi-x-circle-fill'
                                                                            viewBox='0 0 16 16'
                                                                        >
                                                                            <path d='M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0zM5.354 4.646a.5.5 0 1 0-.708.708L7.293 8l-2.647 2.646a.5.5 0 0 0 .708.708L8 8.707l2.646 2.647a.5.5 0 0 0 .708-.708L8.707 8l2.647-2.646a.5.5 0 0 0-.708-.708L8 7.293 5.354 4.646z' />
                                                                        </svg>
                                                                    </button>
                                                                </td>
                                                            </tr>
                                                        );
                                                    })}
                                                     <tr>
                                                                <td colSpan={8} className='text-right'>
                                                                    <label>Total Pago: </label>
                                                                    {currency(
                                                                        facturas.reduce( (a, v) => (a = a + v.importe_Pago),0 )
                                                                    )}
                                                                </td>
                                                            </tr>
                                                </tbody>
                                            </table>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                                                                    */}
                    </div>
                </form>
            </div>
            <Modal isOpen={isOpenModal} closeModal={closeModal}>
            
                {modalValor === 'agregarPago' ? (
                    <>
                        {' '}
                        <br />
                        <div className='card-body text-start'>
                        <div className='row'>
                            <div className='col-md-10 tex-left'>
                            <h3 className='form-label'>Agregar Pago</h3>
                            </div>
                            <div className='col-md-2 '>
                                <button
                                    type='button'
                                    className='btn btn-success'
                                    onClick={() => AgregarPagoComprobante()}
                                >
                                  Agregar
                                </button>
                            </div>
                        </div>
                      
                       
<div className='col-md-12 row'>
    <div className='col-md-6'>
            <label htmlFor='forma_De_Pago'>Forma de Pago:</label>
            <select
                className='form-control'
                name='forma_Pago'
                onChange={handleInputChangePago}
                disabled={facturado}
                required
            >
                <option value=""></option>
                {satFormaPago.map((formapago) => {
                return (
                        <option key={formapago.id} value={formapago.clave}>
                        {formapago.clave + ' - ' + formapago.nombre}
                        </option>
                        );
                })}
            </select>    
            <label htmlFor='importe_Pago' className='form-label'>
                Monto*
            </label>
            <input
                type='text'
                className='form-control'
                name='importe_Pago'
                required={true}
                maxLength={100}
                value={pago.importe_Pago || ''}
                onChange={handleInputChangePago}
            />       

            { (pago.forma_Pago !== "01" && pago.forma_Pago?.length > 0)?
<>
<label htmlFor='monto' className='form-label'>
    Num Operacion:
    </label>
    <input
        type='text'
        className='form-control'
        name='num_Operacion'
        required={true}
        maxLength={100}
        value={pago.num_Operacion || ''}
        onChange={handleInputChangePago}
    />

<label htmlFor='monto' className='form-label'>
    RFC de la entidad emisora:
    </label>
    <input
        type='text'
        className='form-control'
        name='rfcEmisorCtaOrd'
        required={true}
        maxLength={100}
        value={pago.rfcEmisorCtaOrd || ''}
        onChange={handleInputChangePago}
    />

    <label htmlFor='monto' className='form-label'>
    Nombre del banco emisor:
    </label>
    <input
        type='text'
        className='form-control'
        name='nomBancoOrdExt'
        required={true}
        maxLength={100}
        value={pago.nomBancoOrdExt || ''}
        onChange={handleInputChangePago}
    /> 
                </>
                : ''}
    </div>
    <div className='col-md-6'>

        <label htmlFor='importe_Pago' className='form-label'>
            Fecha de pago:*
        </label>
        <input type="datetime-local"  className='form-control' name="fechapago" onChange={handleInputChangePago}/>

        { (pago.forma_Pago !== "01" && pago.forma_Pago?.length > 0)?
            <>
            <label htmlFor='monto' className='form-label'>
            Número de la cuenta con la que se realizó el pago:
                </label>
                <input
                    type='text'
                    className='form-control'
                    name='ctaOrdenante'
                    required={true}
                    maxLength={100}
                    value={pago.ctaOrdenante || ''}
                    onChange={handleInputChangePago}
                />

            <label htmlFor='monto' className='form-label'>
            RFC de la entidad destino:
                </label>
                <input
                    type='text'
                    className='form-control'
                    name='rfcEmisorCtaBen'
                    required={true}
                    maxLength={100}
                    value={pago.rfcEmisorCtaBen || ''}
                    onChange={handleInputChangePago}
                />

            <label htmlFor='monto' className='form-label'>
            Número de cuenta en donde se recibió el pago:
                </label>
                <input
                    type='text'
                    className='form-control'
                    name='ctaBeneficiario'
                    required={true}
                    maxLength={100}
                    value={pago.ctaBeneficiario || ''}
                    onChange={handleInputChangePago}
                />
            </>
                : ''}
    </div>
</div>
 


</div>
                    </>
                ) : (
                    ''
                )}
            </Modal>
        </>
    );
};

const useFormInput = (initialValue) => {
    const [value, setValue] = useState(initialValue);

    const handleChange = (e) => {
        setValue(e.target.value);
    };
    return {
        value,
        onChange: handleChange
    };
};
export { ComplementoPago };
