import React from 'react';
import { Redirect, Route, Switch } from "react-router";
import { OpinionCumplimiento } from "./OpinionCumplimiento";

function HerramientasSAT({ match }) {
    const { path } = match;

    return (
        <Switch>
            <Route exact path={path} component={OpinionCumplimiento} />
            <Redirect from="*" to={{
                                pathname: OpinionCumplimiento
                            }}/>
        </Switch>
    ); 
}

export { HerramientasSAT };
