import React, {useState, useEffect} from 'react';
import { Link } from 'react-router-dom';
import { useForm } from "react-hook-form";

import { fetchConToken } from 'helpers/fetch';
import { alertService } from '_services';

import Swal from 'sweetalert2'

const Add = ({ history, match}) => {    

    const path = match.url;
    const { id } = match.params;
    const isAddMode = !id;

    let [filtroFecha, setFiltroFecha] = useState([]);
    let [rangoFecha, setRangoFecha] = useState([]);
    let [anioFecha, setAnioFecha] = useState([]);

    const [isfecha, setIsfecha] = useState(false);



    const filtroFechas  = [
        //{filtro: "mes",texto: "Por mes"},
        //{filtro: "bimestre",texto: "Por Bimestral"},
        //{filtro: "trimestre",texto: "Por Trimestral"},
        //{filtro: "semestre",texto: "Por Semestral"},
        //{filtro: "anual",texto: "Por Anual"},
        {filtro: "fecha",texto: "Por rango de fecha"},
    ];

    const anioActual = new Date().getFullYear();

    const anios  = [
        {anio: anioActual},
        {anio: anioActual-1},
        {anio: anioActual-2},
        {anio: anioActual-3},
        {anio: anioActual-4},
        {anio: anioActual-5},
    ];

    const mensual  = [
        {filtro: "1",texto: "Enero"},
        {filtro: "2",texto: "Febrero"},
        {filtro: "3",texto: "Marzo"},
        {filtro: "4",texto: "Abril"},
        {filtro: "5",texto: "Mayo"},
        {filtro: "6",texto: "Junio"},
        {filtro: "7",texto: "Julio"},
        {filtro: "8",texto: "Agosto"},
        {filtro: "9",texto: "Septiembre"},
        {filtro: "10",texto: "Octubre"},
        {filtro: "11",texto: "Noviembre"},
        {filtro: "12",texto: "Diciembre"},
    ];

    const bimestral  = [
        {filtro: "1",texto: "Enero-Febrero"},
        {filtro: "2",texto: "Marzo-Abril"},
        {filtro: "3",texto: "Mayo-Junio"},
        {filtro: "4",texto: "Julio-Agosto"},
        {filtro: "5",texto: "Septiembre-Octubre"},
        {filtro: "6",texto: "Noviembre-Diciembre"}
    ];

    const trimestral  = [
        {mes: "1",texto: "Enero-Febrero-Marzo"},
        {filtro: "2",texto: "Abril-Mayo-Junio"},
        {filtro: "3",texto: "Julio-Agosto-Septiembre"},
        {filtro: "4",texto: "Octubre-Noviembre-Diciembre"}
    ];

    const semestral  = [
        {mes: "1",texto: "Enero-Junio"},
        {filtro: "2",texto: "Julio-Diciembre"}
    ];
    


    
    let [tipoComprobante, setTipoComprobante] = useState([]);



    const { register, handleSubmit, reset } = useForm();

    function onSubmit() {
        return solicitudSAT();
    }

    async function getTipoComprobante() {
        const response = await fetchConToken(
            `CatalogosSat/ListTipoComprobante`
        );
        setTipoComprobante(response);
    }

    function solicitudSAT() {

        if(rfc_emisor.length === 0 && rfc_receptor.length === 0)
        {
            alertService.error("Rellene por lo menos un RFC.");
            return;
        }
        return fetchConToken(`DescargaXML/solicitardescarga?fecha_inicio=${fecha_inicio}&fecha_fin=${fecha_fin}&rfc_emisor=${rfc_emisor}&rfc_receptor=${rfc_receptor}&tipo_comprobante=${tipo_comprobante}&estado_comprobante=${estado_comprobante}`)
            .then((response) => {
                if (response.hasOwnProperty('status')) {
                    var erroresA = {};
                    Object.entries(response?.errors).map(([key, value]) => {
                        Swal.fire('Error',value[0],'error')
                        return;
                });
                    return false;
                } else if (response.hasOwnProperty('codigo') ) {
                    Swal.fire('Error',response.mensajes[0],'error')
                    return false;
                }

                Swal.fire('Exito','Solicitud enviada','success')
                //alertService.success('Solicitud enviada', { keepAfterRouteChange: true });
                history.push('.');
            })
            .catch(alertService.error);
    }


    let [solicitud, setSolicitud] = useState(['']);
    const {
        fecha_inicio="",
        fecha_fin= "",
        rfc_emisor="",
        rfc_receptor="",
        tipo_comprobante = null,
        estado_comprobante = '1'
      } = solicitud;

    useEffect(() => {
        getTipoComprobante();

        const date = new Date();
        solicitud.fecha_inicio = String(date.getFullYear()) +"-"+ String(date.getMonth()+1).padStart(2, '0')+"-" + "01";
        solicitud.fecha_fin = String(date.getFullYear()) +"-"+ String(date.getMonth()+1).padStart(2, '0')+"-" + String(date.getDate()).padStart(2, '0');
    }, []);


        function handleInputChange(event) {
            const target = event.target;
            const value = target.type === 'checkbox' ? target.checked : target.value;
            const name = target.name;

            console.log(name);
            console.log(value);
            setSolicitud({
                ...solicitud,
                [name]: value
            });

        }
        
        const handleInputChangeFiltroFecha = (event) => {
        //function handleInputChangeFiltroFecha(event) {
            const target = event.target;
            setFiltroFecha(target.value);
        }
        function handleInputChangeElegirRango(event) {
            const target = event.target;
            setRangoFecha(target.value);
        }
        function handleInputChangeElegirAnios(event) {
            const target = event.target;
            setAnioFecha(target.value);
        }
        
        const seleccionarRango = () => {
        //function seleccionarRango(param) {
            console.log(filtroFecha)
            //setIsfecha(true);
            switch(filtroFecha) {
                case 'mes':
                    //setIsfecha(false);
                  return <select onChange={ handleInputChangeElegirRango }   className="form-control"> 
                            {mensual?.map(tipo=>{
                            return <option value={tipo.filtro}>{tipo.texto}</option>
                            })}
                        </select>;
                break;

                case 'bimestre':
                    //setIsfecha(false);
                  return <select onChange={ handleInputChangeElegirRango }   className="form-control"> 
                            {bimestral?.map(tipo=>{
                            return <option value={tipo.filtro}>{tipo.texto}</option>
                            })}
                        </select>;
                break;

                case 'trimestre':
                    //setIsfecha(false);
                  return <select onChange={ handleInputChangeElegirRango }   className="form-control"> 
                            {trimestral?.map(tipo=>{
                            return <option value={tipo.filtro}>{tipo.texto}</option>
                            })}
                        </select>;
                break;

                case 'semestre':
                    //setIsfecha(false);
                  return <select onChange={ handleInputChangeElegirRango }   className="form-control"> 
                            {semestral?.map(tipo=>{
                            return <option value={tipo.filtro}>{tipo.texto}</option>
                            })}
                        </select>;
                break;

                case 'fecha':
                    //setIsfecha(true);
                  return <>
                  <br></br>
                            <label htmlFor="nombre" className="form-label">Fecha Inicio</label>
                            <input type="date" className="form-control" name="fecha_inicio" value={fecha_inicio} onChange={ handleInputChange } required={true}/>

                            <label htmlFor="nombre" className="form-label">Fecha Fin</label>
                            <input type="date" className="form-control" name="fecha_fin" value={fecha_fin} onChange={ handleInputChange } required={true}/>
                        </>;
                break;

                case 'anual':
                    //setIsfecha(true);
                break;

                

                default:
                   
                 break;

            }
            
          }

          


    return (
        <> 
        <div className="container-fluid" >
         <form  onSubmit={handleSubmit(onSubmit)} >
         <div className="mb-3" >
            <div className="col-md-12">
                <div className="col-md-12">
                  <h3>Solicitud SAT</h3>
                </div>
                
                                    
                                        <div className='col-md-12 text-right'>

                 <button className="btn btn-outline-success" type="submit">
                        <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="currentColor" className="bi bi-check-lg" viewBox="0 0 16 16">
                         <path d="M12.736 3.97a.733.733 0 0 1 1.047 0c.286.289.29.756.01 1.05L7.88 12.01a.733.733 0 0 1-1.065.02L3.217 8.384a.757.757 0 0 1 0-1.06.733.733 0 0 1 1.047 0l3.052 3.093 5.4-6.425a.247.247 0 0 1 .02-.022Z"/>
                        </svg>
                        &nbsp;
                        Solicitud 
                    </button>
                  &nbsp;
                  <Link to={isAddMode ? '.' : '..'} className="btn btn-outline-secondary"> 
                    <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="currentColor" className="bi bi-reply" viewBox="0 0 16 16">
                        <path d="M6.598 5.013a.144.144 0 0 1 .202.134V6.3a.5.5 0 0 0 .5.5c.667 0 2.013.005 3.3.822.984.624 1.99 1.76 2.595 3.876-1.02-.983-2.185-1.516-3.205-1.799a8.74 8.74 0 0 0-1.921-.306 7.404 7.404 0 0 0-.798.008h-.013l-.005.001h-.001L7.3 9.9l-.05-.498a.5.5 0 0 0-.45.498v1.153c0 .108-.11.176-.202.134L2.614 8.254a.503.503 0 0 0-.042-.028.147.147 0 0 1 0-.252.499.499 0 0 0 .042-.028l3.984-2.933zM7.8 10.386c.068 0 .143.003.223.006.434.02 1.034.086 1.7.271 1.326.368 2.896 1.202 3.94 3.08a.5.5 0 0 0 .933-.305c-.464-3.71-1.886-5.662-3.46-6.66-1.245-.79-2.527-.942-3.336-.971v-.66a1.144 1.144 0 0 0-1.767-.96l-3.994 2.94a1.147 1.147 0 0 0 0 1.946l3.994 2.94a1.144 1.144 0 0 0 1.767-.96v-.667z"/>
                    </svg> 
                    &nbsp;
                    Regresar 
                    &nbsp; 
                  </Link>
        
                
                </div>
            </div>              
          </div>

          <div className="row">
            <div className="col-md-12" style={{display: "grid"}}>
                <div className="card mb-3 ">
                <div className="card-body text-start">
                    <div className='row'>

                       <div className='col-md-6'>
                            <label htmlFor="nombre" className="form-label">Filtro de Fecha</label>
                            <select  onChange={ handleInputChangeFiltroFecha }  className="form-control"> 
                            {filtroFechas?.map(tipo=>{
                               return <option value={tipo.filtro}>{tipo.texto}</option>
                            })}
                          </select>
                        </div>

                        <div className='col-md-6' style={{display: isfecha? "block" : "none" }}>
                        <label htmlFor="nombre" className="form-label">Año</label>
                            <select name={filtroFecha} onChange={ handleInputChangeElegirAnios }  value={anioFecha} className="form-control"> 
                            {anios?.map(tipo=>{
                               return <option value={tipo.anio}>{tipo.anio}</option>
                            })}
                          </select>
                        </div>

                        <div className='col-md-6' >
                            {/*<label htmlFor="nombre" className="form-label">Seleccionar <br/> {filtroFecha}</label>*/}
                            {/* seleccionarRango() */}
                            <label htmlFor="nombre" className="form-label">Fecha Inicio</label>
                            <input type="date" className="form-control" name="fecha_inicio" value={fecha_inicio} onChange={ handleInputChange } required={true}/>

                            <label htmlFor="nombre" className="form-label">Fecha Fin</label>
                            <input type="date" className="form-control" name="fecha_fin" value={fecha_fin} onChange={ handleInputChange } required={true}/>
                
                        </div>


                        {/*<div className='col-md-6'>
                            <label htmlFor="nombre" className="form-label">Fecha Inicio</label>
                            <input type="date" className="form-control" name="fecha_inicio" value={fecha_inicio} onChange={ handleInputChange } required={true}/>

                            <label htmlFor="nombre" className="form-label">Fecha Fin</label>
                            <input type="date" className="form-control" name="fecha_fin" value={fecha_fin} onChange={ handleInputChange } required={true}/>
                        </div>*/}
                        <div className='col-md-6'>
                            <label htmlFor="nombre" className="form-label">RFC Emisor</label>
                            <input type="text" className="form-control" name="rfc_emisor" value={rfc_emisor} onChange={ handleInputChange } />

                            <label htmlFor="tipo" className="form-label">RFC Receptor</label>
                            <input type="text" className="form-control" name="rfc_receptor" value={rfc_receptor} onChange={ handleInputChange } />
                        </div>

                        <div className='col-md-6'>
                            <label htmlFor="tipofactura" className="form-label">Tipo Factura</label>
                            <select name='tipo_comprobante' onChange={ handleInputChange }  value={tipo_comprobante} className="form-control"> 
                            {tipoComprobante?.map(tipo=>{
                               return <option value={tipo.clave}>{tipo.nombre}</option>
                            })}
                          </select>

                          <label htmlFor="estado_comprobante" className="form-label">Estado Facturas</label>
                            <select name='estado_comprobante' onChange={ handleInputChange }  value={estado_comprobante} className="form-control"> 
                                <option value='0'>Cancelado</option>
                                <option value='1' selected>Vigente</option>
                          </select>
                        </div>

               

                          
                    </div>
                </div>
                <div>
            </div>
          </div>
          </div>
 
          </div> 
          </form>
        </div>
        </>
    );    


}


export {Add};