// return the user data from the session storage
export const getUser = () => {
  //removeUserSession;
  const userStr = localStorage.getItem('user');
  if (userStr === 'undefined')
    return null;
  if (userStr) return JSON.parse(userStr);
  else return null;
}

export const getNombre = () => {
  return localStorage.getItem('nombre') || null;
}

export const getImagenPerfil = () => {
  return localStorage.getItem('imagenPerfil') || null;
}

// return the token from the session storage
export const getToken = () => {
  return localStorage.getItem('token') || null;
}

// return data key from the session storage
export const getDataSession = (keyData) => {
  return localStorage.getItem(keyData) || null;
}

// set the token and user from the session storage
export const setDataSession = (keyData, valueData) => {
  localStorage.setItem(keyData, valueData);
}

// remove the token and user from the session storage
export const removeUserSession = () => {
  localStorage.removeItem('token');
  localStorage.removeItem('user');
  localStorage.removeItem('empresa');
  localStorage.removeItem('sucursal');
  localStorage.removeItem('rol');
  localStorage.removeItem('menu');
  localStorage.removeItem('nombre');
  localStorage.removeItem('imagenPerfil');


}

// set the token and user from the session storage
export const setUserSession = (token, user) => {
  localStorage.setItem('token', token);
  localStorage.setItem('user', JSON.stringify(user));
}

export const daysInMonth = (month, year) => {
  return new Date(year, month, 0).getDate();
}

export const getFirstDay = () => {
  return new Date(new Date().getFullYear(), new Date().getMonth(), 1).toISOString().substr(0, 10);
}

export const getLastDay = () => {
  return new Date(new Date().getFullYear(), new Date().getMonth(), daysInMonth(new Date().getMonth() + 1, new Date().getFullYear())).toISOString().substr(0, 10);
}