import React, { useState,useEffect } from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { fetchConToken, fetchConTokenFile } from 'helpers/fetch';
import { getDataSession } from 'views/Utils/Common';
import TituloPagina from 'components/header/TituloPagina';


const PagoMercadoPago = () => {



    return (
        <>
        <div>
      </div>
        </>
    );
};


export { PagoMercadoPago };
