import React, { useState } from 'react'

const Pagination = (props) => {

    const [page, setPage] = useState(1);
    const [isAnt, setIsAnt] = useState(false);
    const [isSig, setIsSig] = useState(true);

    const ant = () =>
    {
      if(page > 1){
        setPage(page-1);
        setIsAnt(true)
        props.consumirPaginacion(page-1);
      } 
      else
      {
        setIsAnt(false)
      }     
    }

    const sig = () =>
    {
      console.log("entro sig");
      console.log(props);
      console.log(props.elementos);
      console.log(props.totalxPagina);
      if(props.elementos === props.totalxPagina){
        setPage(page+1);
        setIsSig(true)
        props.consumirPaginacion(page+1);
      }
      else
      {
        setIsSig(false)
      }
      if((page+1) > 1){
        setIsAnt(true)       
      }   
    }


  return (
    <>
    <div className="col-md-12 text-center">
                        <div className="d-flex justify-content-center">
                            <button type='button' className="btn" onClick={ant} style={{opacity: isAnt? "1" : "0.5"}}> 
                                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-chevron-left" viewBox="0 0 16 16">
                                <path fillRule="evenodd" d="M11.354 1.646a.5.5 0 0 1 0 .708L5.707 8l5.647 5.646a.5.5 0 0 1-.708.708l-6-6a.5.5 0 0 1 0-.708l6-6a.5.5 0 0 1 .708 0z"/>
                                </svg>
                            </button>
                            &nbsp;
                            <label>Pagina {page}</label>
                            <button type='button' className="btn" onClick={sig} style={{opacity:  isSig? "1" : "0.5"}}>
                                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-chevron-right" viewBox="0 0 16 16">
                                <path fillRule="evenodd" d="M4.646 1.646a.5.5 0 0 1 .708 0l6 6a.5.5 0 0 1 0 .708l-6 6a.5.5 0 0 1-.708-.708L10.293 8 4.646 2.354a.5.5 0 0 1 0-.708z"/>
                                </svg>
                            </button>
                        </div>
     </div>
     </>
  );
};

export default Pagination;