import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { fetchConToken, fetchConTokenFile } from 'helpers/fetch';
import { getDataSession } from 'views/Utils/Common';
import Pagination from 'components/pagination/Pagination';
import TituloPagina from 'components/header/TituloPagina';
import { alertService } from '_services';
import * as FileSaver from 'file-saver';

import Modal from 'components/modal/Modal';
import { useModal } from 'hooks/useModal';

import Table from 'components/table/Table';

import Swal from 'sweetalert2';

const List = ({ match, saludo, subtitulo }) => {
    const { path } = match;

    return (
        <>
            <div className='container-fluid'>
                <TituloPagina
                    titulo='Pagos realizados'
                    descripcion='Aquí se guardan los pagos que se realizaron para la compra de servicios.'
                />
                
                <div className='col-md-12'>
                    <Table
                        columns={[
                            'Folio',
                            'Monto',
                            'Concepto',
                            'Moneda',
                            'Estado',
                            'Metodo Pago',
                            'Fecha Pago',
                            'Fecha Aprovacion',
                            'Pasarela'
                        ]}
                        rows={[
                            ['text', 'id'],
                            ['moneda', 'monto'],
                            ['text', 'concepto'],
                            ['text', 'moneda'],
                            ['text', 'estatus'],
                            ['text', 'metodo_Pago'],
                            ['text', 'fecha_Pago'],
                            ['text', 'fecha_Pago_Aprovado'],
                            ['text', 'pasarela']
                        ]}
                        /*eliminar = {eliminar}*/
                        path={path}
                        url={`/Pagos/List?empresa_id=${getDataSession('empresa')}`}
                    ></Table>
                </div>
            </div>

           
        </>
    );
};

export { List };
