import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { useForm } from 'react-hook-form';

import { fetchConToken } from 'helpers/fetch';

import Swal from 'sweetalert2';

const AddEdit = ({ history, match }) => {
    const path = match.url;
    const { id } = match.params;
    const isAddMode = !id;
    const { register, handleSubmit, reset } = useForm();

    let [errores, setErrores] = useState({});
    let [error, setError] = useState(['']);

    function onSubmit() {
        return /*isAddMode ?*/ create() //: update(id, data);
    }

    function create() {

        setErrores({});
        setError('');
        console.log(data);
        if (data.hasOwnProperty('fecha'))
        data.fecha =  curr.toISOString().substring(0,10)

        if (data.hasOwnProperty('referencia_De_Pago'))
        data.referencia_De_Pago = "001"

        if (data.hasOwnProperty('estatus'))
        data.estatus = 'A'

        return fetchConToken('MovimientosPacs', data, 'POST')
            .then((response) => {

                if (response.hasOwnProperty('status')) {
                    var erroresA = {};
                    Object.entries(response.errors).map(([key, value]) => {
                        Swal.fire('Error', value[0], 'error');
                        return;
                    });
                    return false;
                } else if (response.hasOwnProperty('codigo')) {
                    Swal.fire('Error', response.mensajes[0], 'error');
                    return false;
                }
                Swal.fire('Exito', 'Movimiento agregado', 'success');
                history.push('.');
            })
            //.catch(alertService.error);
    }
    var curr = new Date();
    const [data, setData] = useState({
        timbres_Comprados: 0,
        precio: 0,
        fecha: curr.toISOString().substring(0,10),
        referencia_De_Pago: "001",
        estatus: true
    });


    useEffect(() => {
        
    }, []);


    const handleInputChange = ({ target }) => {
        setData({
            ...data,
            [target.name]: target.value
        });
        
    }

    return (
        <>
            <div className='container-fluid'>
                <form onSubmit={handleSubmit(onSubmit)} /*onReset=""*/>
                    <div className='mb-3'>
                        <div className='row' style={{ textAlign: 'left' }}>
                            <div className='col-sm-6  col-md-6'>
                                <h3>{isAddMode ? 'Agregar compra de timbre' : 'Agregar compra de timbre'}</h3>
                            </div>
                            <div className='col-sm-6  col-md-6' style={{ textAlign: 'right' }}>
                                <button className='btn btn-outline-success' type='submit'>
                                    <svg
                                        xmlns='http://www.w3.org/2000/svg'
                                        width='20'
                                        height='20'
                                        fill='currentColor'
                                        className='bi bi-check-lg'
                                        viewBox='0 0 16 16'
                                    >
                                        <path d='M12.736 3.97a.733.733 0 0 1 1.047 0c.286.289.29.756.01 1.05L7.88 12.01a.733.733 0 0 1-1.065.02L3.217 8.384a.757.757 0 0 1 0-1.06.733.733 0 0 1 1.047 0l3.052 3.093 5.4-6.425a.247.247 0 0 1 .02-.022Z' />
                                    </svg>
                                    &nbsp; Guardar
                                </button>
                                &nbsp;
                                <Link
                                    to={isAddMode ? '.' : '..'}
                                    className='btn btn-outline-warning'
                                >
                                    <svg
                                        xmlns='http://www.w3.org/2000/svg'
                                        width='20'
                                        height='20'
                                        fill='currentColor'
                                        className='bi bi-reply'
                                        viewBox='0 0 16 16'
                                    >
                                        <path d='M6.598 5.013a.144.144 0 0 1 .202.134V6.3a.5.5 0 0 0 .5.5c.667 0 2.013.005 3.3.822.984.624 1.99 1.76 2.595 3.876-1.02-.983-2.185-1.516-3.205-1.799a8.74 8.74 0 0 0-1.921-.306 7.404 7.404 0 0 0-.798.008h-.013l-.005.001h-.001L7.3 9.9l-.05-.498a.5.5 0 0 0-.45.498v1.153c0 .108-.11.176-.202.134L2.614 8.254a.503.503 0 0 0-.042-.028.147.147 0 0 1 0-.252.499.499 0 0 0 .042-.028l3.984-2.933zM7.8 10.386c.068 0 .143.003.223.006.434.02 1.034.086 1.7.271 1.326.368 2.896 1.202 3.94 3.08a.5.5 0 0 0 .933-.305c-.464-3.71-1.886-5.662-3.46-6.66-1.245-.79-2.527-.942-3.336-.971v-.66a1.144 1.144 0 0 0-1.767-.96l-3.994 2.94a1.147 1.147 0 0 0 0 1.946l3.994 2.94a1.144 1.144 0 0 0 1.767-.96v-.667z' />
                                    </svg>
                                    &nbsp; Regresar &nbsp;
                                </Link>
                            </div>
                        </div>
                    </div>
                    <p style={{ color: 'red' }}>{error}</p>
   {Object.entries(errores).map(([key, value]) => {
                            return (
                                <>
                                    <p style={{ color: 'red' }}>{value}</p>
                                </>
                            );
                        })}
                    <div className='row'>
                        <div className='col-md-12' style={{ display: 'grid' }}>
                            <div className='card mb-3 '>
                                <div className='card-body text-start'>
                                    <label htmlFor='timbres_Comprados' className='form-label'>
                                    Timbres Comprados
                                    </label>
                                    <input
                                        type='number'
                                        className='form-control'
                                        name='timbres_Comprados'
                                        required={true}
                                        value={data.timbres_Comprados}
                                        onChange={handleInputChange}
                                    />

                                    <label htmlFor='precio' className='form-label'>
                                    Precio
                                    </label>
                                    <input
                                        type='text'
                                        className='form-control'
                                        name='precio'
                                        required={true}
                                        value={data.precio}
                                        onChange={handleInputChange}
                                    />

                                    <label htmlFor='fecha' className='form-label'>
                                    Fecha
                                    </label>
                                    <input
                                        type='date'
                                        className='form-control'
                                        name='fecha'
                                        required={true}
                                        value={data.fecha}
                                        onChange={handleInputChange}
                                    />

                                    <label htmlFor='porcentaje' className='form-label'>
                                    referencia_De_Pago
                                    </label>
                                    <input
                                        type='text'
                                        className='form-control'
                                        name='referencia_De_Pago'
                                        value={data.referencia_De_Pago}
                                        onChange={handleInputChange}
                                    />

                                </div>
                            </div>
                        </div>
                       
                    </div>
                </form>
            </div>
        </>
    );
};

// AddEdit.propTypes = {
//     saludo: PropTypes.string
// }

// AddEdit.defaultProps = {
//     subtitulo: "Soy subtitulo"
// }

export { AddEdit };
