import React, { useState } from 'react';
// import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { fetchReset } from 'helpers/fetch';
import SLUGS from 'resources/slugs';

import fondo from 'images/login/recuperacion_contrasena/fondo.png';
import fondo_movil from 'images/login/recuperacion_contrasena/fondo_movil.png';
import logo from 'images/login/logo.svg';

import amazon from 'images/login/recuperacion_contrasena/amazon2.png'
import shopify from 'images/login/recuperacion_contrasena/shopify2.png'
import mercado_libre from 'images/login/recuperacion_contrasena/mercado_libre2.png'
import woocomerce from 'images/login/recuperacion_contrasena/woocomerce2.png'
 


import { alertService } from '_services';

const ResetPassword = ({ history, location, match }) => {
    const [loading, setLoading] = useState(false);
    const password = useFormInput('');
    //const { key } = match.params;
    const query = new URLSearchParams(location.search); //.get("id");
    const key = query.get('key');

    const { token } = match.params;

    let [error, setError] = useState(['']);
    let [errores, setErrores] = useState({});
    let [success, setSuccess] = useState(['']);

    const handleLogin = () => {
        setError(null);
        setLoading(true);
        setErrores('');
        setError('');
        setSuccess('');

        fetchReset(`Usuarios/ActualizaPassword?clave=${password.value}`, null, 'PUT', token)
            .then((response) => {
                if (response.hasOwnProperty('status') && response.status === 400) {
                    var erroresC = {};
                    Object.entries(response.errors).map(([key, value]) => {
                        erroresC[key] = value[0];
                        setErrores(erroresC);
                    });
                    setError('Revisar errores');
                    return false;
                } else if (response.hasOwnProperty('codigo') && response.codigo !== 200) {
                    setError(response.mensajes[0]);
                    return false;
                }
                setLoading(false);
                setSuccess('Contraseña modificada correctamente')

            })
            .catch((error) => {
                setLoading(false);
                setError('Error al modificar la contraseña. Por favor, intente más tarde.');
            });
    };

    return (
        <>
        <div className='col-md-12' >
          <img src={window.innerWidth < 768 ? fondo_movil : fondo }></img>
          {window.innerWidth < 768 ?
          <div className='text-center'>
               <img src={ window.innerWidth < 768 ? logo : logo } className="img-fyt" alt='' />
               <h2
                  style={{
                      color: '#01295F'
                   }}
              >
                  <b>Recuperación de cuentas</b>
              </h2>
              <br></br>
              <div className='col-md-12 text-center'>
                  <p
                      style={{
                          color: '#2D3277',
                          padding: "0px 10% 0px 10%"
                      }}
                  >
                      Cambio de contraseña.
                  </p>
              </div>
              <form>
              
                              <div>
                                         
                                  <div className='col-md-11 container text-center' style={{paddingBottom: '15px'}}>
                                      <div className='card-body text-start'>
                                      <label className='form-label'>Contraseña</label>
                                       <input 
                                            type="text" {...password} 
                                            className="form-control" 
                                            autoComplete="new-password" 
                                            placeholder="Ingresa tu nueva contraseña" />
                                   
                                      <br />
         
                                      <input style={{color: "white", borderRadius: "10px", background: 'linear-gradient(86.42deg, #232ED1 11.64%, #0F5EF6 80.17%)', width:"100%"}} type="button" className="btn btn-info col-md-8" value={loading ? 'Cargando...' : 'Recuperar mi cuenta'} onClick={handleLogin} disabled={loading} /><br />
                                      <div className='col-md-12 text-center'>
                                        <br></br>
                                        <Link to={SLUGS.login}  style={{ color: '#0F5EF6'}}>Volver a inicio de sesión</Link>
                                        <br></br>
                                        <br></br>
                                        <p>¿Aún no tienes una cuenta?<Link to={SLUGS.register}>Registrate aquí</Link></p>
                                     </div>
                                      <br></br>
                                      
                                  </div>
                              </div>
                          </div>
                         
                      </form>
          </div> : "" 
          }
        </div>
        <div className= { window.innerWidth > 768 ? 'col-md-12 row' : 'col-md-12'}>
          <div className='col-md-6' style={{ padding: window.innerWidth > 768 ? '2% 0% 0% 5%' : '0px 25px 0px 25px' }}>
              <div style={{ paddingLeft: window.innerWidth > 768 ? '15%' : '0%', padding: window.innerWidth > 768 ? '0px' : '10px', background: window.innerWidth > 768 ? '#FFFFFF' : 'rgba(244, 247, 255, 1)' }}>
                  <div className={window.innerWidth > 768 ? 'col-md-12' : 'col-md-12 text-center'}>
                      <h2 style={{color: "#0F5EF6"}}><b>¡Factura tus compras!</b></h2>
                      </div>
                  <br></br>
                  <p style={{color: "#01295F"}}>Recuerda que puedes integrar Factuxin a la facturación de tu tienda digital, contamos con integraciones para los eCommerce mas conocidas, WooCommerce, amazon, Mercado libre, shopify y Prestashop. Agrega paquetes de folios cuando necesites y olvidate de los tardados procesos de facturación</p>
                  <div className='col-md-12 row' style={{paddingTop: "5%"}}>
                              <div className='col-3 col-sm-3 col-md-3'>
                                  <img src={ woocomerce }  alt=''  />
                              </div>
                              <div className='col-3 col-sm-3 col-md-3'>
                                  <img src={ amazon }  alt=''  />
                              </div>
                              <div className='col-3 col-sm-3 col-md-3'>
                                  <img src={ mercado_libre }  alt='' />
                              </div>
                              <div className='col-3 col-sm-3 col-md-3'>
                                  <img src={ shopify }  alt='' />
                              </div>
                          </div>
                          <br></br><br></br>
                         
                  </div>
                  <div className='col-md-12 text-center row'>
                      <div className='col-4 col-sm-4 col-md-4'>
                          <a href="https://atti.com.mx" target="_blank" rel="noopener noreferrer">AttiSolutions</a>
                      </div>
                      <div className='col-4 col-sm-4 col-md-4'>
                      <a href={SLUGS.terminoscondiciones} target="_blank" rel="noopener noreferrer">Términos y condiciones</a>
                      </div>
                      <div className='col-4 col-sm-4 col-md-4'>
                      <a href={SLUGS.avisoprivacidad} target="_blank" rel="noopener noreferrer">Aviso de privacidad</a>
                      </div>
                  </div>
              </div>
              
          {window.innerWidth > 768 ?
          <div className='col-md-5' style={{ marginTop: "-25%"}}>
          <div
                      className='col-12'
                      style={{
                          backgroundRepeat: 'no-repeat',
                          backgroundPosition: 'center',
                          backgroundAttachment: 'fixed',
                          backgroundImage: window.innerWidth < 768 ? `linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)),url(${fondo})` : 'none',
                          backgroundSize: 'cover',
               
                      }}
                  >
                      <form>
                          <div className='card' style={{
                                         boxShadow: 'rgb(30 56 136 / 20%) 8px 8px 48px',
                                         borderRadius: '8px'
                                  }}>
                              <div>
                                  <div className='col-md-12 text-center'>
                                      <Link to={SLUGS.home}>
                                          <img src={ window.innerWidth < 768 ? logo : logo } className="img-fyt" alt='' />
                                      </Link>
                                      <h2
                                          style={{
                                              color: window.innerWidth < 768 ? '#ffff' : '#01295F'
                                          }}
                                      >
                                          <b>¡Recuperacion!</b>
                                      </h2>
                                      <br></br>
                                      <div className='col-md-12 text-center'>
                                      <p
                                          style={{
                                              color: window.innerWidth < 768 ? '#2D3277' : '#2D3277',
                                              padding: "0px 10% 0px 10%"
                                          }}
                                          
                                      >
                                        Cambio de contraseña.
                                      </p>
                                      </div>
                                  </div>
                                  
                                  <div className='col-md-11 container text-center' style={{paddingBottom: '15px'}}>
                                      <div className='card-body text-start'>
                                      <label className='form-label'>Contraseña</label>
                                      <input 
                                            type="text" {...password} 
                                            className="form-control" 
                                            autoComplete="new-password" 
                                            placeholder="Ingresa tu nueva contraseña" />
                                   
                                      <br />
                                      <br />
                                      <div className='col-md-12 text-center'>
                                          <p style={{ color: 'red' }}>{error}</p>
                                          <p style={{ color: 'blue' }}>{success}</p>
                                          {Object.entries(errores).map(([key, value]) => {
                                              return (
                                                  <>
                                                      <p style={{ color: 'red' }}>{value}</p>
                                                  </>
                                              );
                                          })}
                                      </div>  
                                      <input style={{color: "white", borderRadius: "15px", background:"#189ad6", width:"100%"}} type="button" className="btn btn-info col-md-8" value={loading ? 'Cargando...' : 'Cambiar contraseña'} onClick={handleLogin} disabled={loading} /><br />
                                      <div className='col-md-12 text-center'>
                                        <br></br>
                                        <b> <Link to={SLUGS.login}  style={{ color: window.innerWidth < 768 ? "##0F5EF6" : "##0F5EF6"}}>Volver a inicio de sesión</Link></b>
                                        <br></br>
                                        <br></br>
                                        <p>¿Aún no tienes una cuenta?<Link to={SLUGS.register}>Registrate aquí</Link></p>
                                     </div>
                                      <br></br>
                                      
                                  </div>
                              </div>
                          </div>
                          </div>
                      </form>
                  </div>
          </div> : '' }
        </div>
  
       
        </>
    );
};

const useFormInput = (initialValue) => {
    const [value, setValue] = useState(initialValue);

    const handleChange = (e) => {
        setValue(e.target.value);
    };
    return {
        value,
        onChange: handleChange
    };
};

export { ResetPassword };
