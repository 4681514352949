import React, { useState, useRef } from 'react';
import { fetchConToken } from 'helpers/fetch';
import { getDataSession,getToken } from 'views/Utils/Common';
import { descargarFactura } from 'actions/archivos';
import Modal from 'components/modal/Modal';
import { useModal } from 'hooks/useModal';
import { useForm } from 'react-hook-form';
import { currency, rfcValido } from 'resources/utilities';
import moment from 'moment';
import SLUGS from 'resources/slugs';
import Table from 'components/table/Table';
import Swal from 'sweetalert2';

const $ = require('jquery')
const baseUrl = process.env.REACT_APP_API_URL;

const FacturacionGlobal = ({ history, match, saludo, subtitulo }) => {

    const [cfdi, setCfdi] = useState(0);

    const [facturado, setFacturado] = useState(false);
    const [loading, setLoading] = useState(false);
    const { handleSubmit } = useForm();
    const idsValues = useRef({current:[]})
    const [isOpenModal, openModal, closeModal] = useModal(false);

    let [serieConfig, setSerieConfig] = useState(null);
    let [serie, setSerie] = useState(0);
    let [serieAux, setSerieAux] = useState('');
    let [series, setSeries] = useState([]);
    let [periodicidad, setPeriodicidad] = useState('');
    let [periodicidadList, setPeriodicidadList] = useState([]);
    let [mes, setMes] = useState('');
    let [fechaInicio, setFechaInicio] = useState('');
    let [fechaFin, setFechaFin] = useState('');
    let [ejercicio, setEjercicio] = useState('');
    let [subtotal, setSubtotal] = useState(0);
    let [IVA, setIVA] = useState(0)
    let [total, setTotal] = useState(0)
    let [mesesList, setMesesList] = useState([]);
    let [bimestresList, setBimestresList] = useState([]);
    let ArrayRows = [
        ['check', 'id'],
        ['text', 'no_Orden'],
        ['text', 'folio'],
        ['text', 'estatus'],
        ['fecha', 'fecha_Registro'],
        ['moneda', 'importe_Total'],
        ['moneda', 'impuestos'],
        ['moneda', 'importe_Total_Impuestos'],
        ['object', 'sucursal', 'nombre']
    ]
    
    let columns = [
        '#',
        'Orden',
        'Folio',
        'Estatus',
        'Fecha',
        'SubTotal',
        'Impuestos',
        'Total',
        'Conexión'
    ]

    const facturar = () => {
        var ids = "";
        getIdsValues().map(m=>{
            if(ids === "")
              ids+= m.id;
            else
              ids+= ","+m.id;
        });
        if(ids !== "")
        {   
            console.log(mes);
            fetchConToken(
                `Facturacion/FacturaGlobal?ventas=${ids}&mes=${mes}&periodicidad=${periodicidad}&serie_Id=${serie}`
            ).then(response => {
                if (response.hasOwnProperty('status') && response.status === 400) {
                    Object.entries(response.errors).map(([key, value]) => {
                        setFacturado(false);
                        Swal.fire('Error', value[0], 'error');
                        return false;
                    });
                    return;
                } else if (response.hasOwnProperty('codigo') && response.codigo !== 200) {
                    setFacturado(false);
                    Swal.fire('Error', response.mensajes[0], 'error');
                    return;
                }
                setFacturado(true);
                setCfdi(response)
                Swal.fire('Exitoso', 'Facturado', 'success');
            });
            
        }
        else{
            setFacturado(false);
            Swal.fire('', 'Selecciona al menos una factura.', 'warning');
        }
    };

    function getIdsValues(){
        if(idsValues.current.length > 0)
            return idsValues.current
        return []
    }

    function setIdsValues(id){
        if(Array.isArray(id))
        {
            idsValues.current = id
        }
        else{
            if(idsValues.current.length > 0){
                let array = idsValues.current
                array.push(id)
                idsValues.current = array
            }
            else{
                idsValues.current = [id];
            }
        }
        [subtotal,IVA,total] = calculateImportes()
        setSubtotal(subtotal)
        setIVA(IVA)
        setTotal(total)
    }

    const handleInputChangeSerie = ({ target }) => {
        setSerie(target.value);
    };

    const handleInputChangePeriodicidad = ({ target }) => {
        setPeriodicidad(target.value);
        setLoading(true)
        setFiltroFechas(target.value)

        setIdsValues([]) // reseteamos la lista de ordenes para evitar mandar con periodicidad diferentes

        setFilterTable()
    };

    const setFilterTable = () => {
        let table = $('#table-js').DataTable();
        table.ajax.url(baseUrl+`/Ventas/List?sucursal_Id=${getDataSession('sucursal')}&tipo_documento=V&fecha_inicio=${fechaInicio}&fecha_fin=${fechaFin}`).load();
    }

    const setFiltroFechas = (periodicidad) =>
    {
        // definimos periodicidad diaria por default
        let inicio = moment().subtract(1, 'days').format('Y-MM-DD') 
        let fin = moment().format('Y-MM-DD')
        const year = moment().format('Y')
        const mesIndex = moment().format('M')
        //let mesIndex = 1;
        let mesAnterior = year + '-' + (mesIndex -1) + '-01'
        if(mesIndex === 1)
            mesAnterior = (year-1) + '-12-01'
            
        let currentDay = moment().format('DD')
        //currentDay = '01'
        mes = moment().format('MM')
        console.log(moment().format('MM'));
        if(periodicidad === '02') // semanal
        {
            if(currentDay === '01')
                inicio = moment().subtract(7, 'days').format('Y-MM-DD') 
            else
            {
                if(currentDay <= 7)
                    inicio = moment().startOf('month').format('Y-MM-DD')
                else
                    inicio = moment().subtract(7, 'days').format('Y-MM-DD') 
            }
        }

        else if(periodicidad === '03') // quincenal
        {
            if(currentDay === '01')
            {
                inicio = moment(mesAnterior,'Y-MM-DD').add(15, 'days').format('Y-MM-DD') 
            }
            else
            {
                if(currentDay <= 15)
                    inicio = moment().startOf('month').format('Y-MM-DD')
                else
                    inicio = moment().format('Y-MM-') + '16'
            }
        }

        else if(periodicidad === '04') // mensual
        {
            
            if(currentDay === '01')
            {
                inicio = mesAnterior
                setMes((mesIndex -1));
            }
            else
            {
                inicio = moment().startOf('month').format('Y-MM-DD') 
            }        
           
            
        }

        else if(periodicidad === '05') // bimestral
        {
            if(mesIndex % 2 === 0) // segundo mes 
            {
                if(mesIndex === 1)
                    mesAnterior = (year-1) + '-12-01'

                inicio = moment(mesAnterior,'Y-MM-DD').format('Y-MM') + '-01' 
                let index = Math.ceil(mes / 2)
                mes = 12 + index
                setMes(mes)
                console.log(mes);
            }
            else{// primer mes, aún se puede facturar el bimestre anterior si el día es 01
                if(currentDay === '01')
                {
                    let bimestreAnterior = year + '-' + (mesIndex -2) + '-01'
                    if(mesIndex === 1)
                    {
                        bimestreAnterior = (year-1) + '-11-01'    
                    }
                    inicio = moment(bimestreAnterior,'Y-MM-DD').format('Y-MM-DD') 
                    let index = Math.ceil((mes-1) / 2)
                    mes = 12 + index
                    setMes(mes)
                    console.log(mes);
                }
                else
                {
                    inicio = moment().startOf('month').format('Y-MM-DD') 
                    let index = Math.ceil(mes / 2)
                    mes = 12 + index
                    setMes(mes)
                    console.log(mes);
                }
            }
        }
        fechaInicio = inicio
        setFechaInicio(fechaInicio);
        fechaFin = fin
        setFechaFin(fechaFin)
        ejercicio = year
        setEjercicio(ejercicio)
        setLoading(false)
    }

    async function eliminar(id) {
        let list = getIdsValues()
        list = (list.filter((item) => item.id !== id));
        setIdsValues(list)
        $('table input.check-select[data-id="'+id+'"]').prop('checked',false)
        $('table #row'+id).addClass('d-none')
    }

    React.useEffect(() => {
        setFiltroFechas()
        setFilterTable()
        $("table").on("click", ".check-select", function (event) {
            var id = $(this).attr("data-id");
            if(this.checked)
            {
                fetchConToken(`Ventas?id=${id}`).then(item=>{
                    setIdsValues(item)
                });
            }
            else
            {
                let list = getIdsValues()
                list = (list.filter((item) => item.id !== parseInt(id)));
                setIdsValues(list)
            }
         });
        
        getPeriodicidad();
        getMeses();

        
        var table = $('#table-js').DataTable();
        table.columns(1).search( "TERMINADA" ).draw();
    }, []);

    React.useEffect(() => {getSeries(getDataSession('sucursal'));}, []);

    function getSeries(sucursal_id) {

       
        fetchConToken(
            `Sucursales/GetFoliosSucursales?sucursal_Id=${sucursal_id}&tipo_Documento=F`
        ).then(async reponse=>{

            await setSeries(reponse);     
            
            await fetchConToken(
                `ConfiguracionesSucursales?sucursal_id=${sucursal_id}&nombre=serie_factura`
            ).then(config=>{
                setSerieAux(config?.valor || 0);
            });
        })
    }
       

    async function getPeriodicidad() {
        const response = await fetchConToken(
            `CatalogosSat/ListPeriodicidad`
        );
        setPeriodicidadList(response);
        setPeriodicidad(response[0].clave)
    }

    async function getMeses() {
        const response = await fetchConToken(
            `CatalogosSat/ListMesesPeridicidad?pagina=1&totalXpagina=20`
        );
        response.map(item=>{
            let clave = Number(item.clave)
            if(clave <= 12)
                mesesList.push(item)
            else
                bimestresList.push(item)
        })
        setMesesList(mesesList);
        setBimestresList(bimestresList);
        setMes(mes)
        console.log(response);
    }

    const calculateImportes = () => {
        let sub = 0
        let iva = 0
        let tot = 0
        getIdsValues().map(item => {
            sub += item.importe_Total
            iva += item.importe_Total_Impuestos - item.importe_Total
            tot += item.importe_Total_Impuestos
        })

        return [
            currency(sub),
            currency(iva),
            currency(tot)
            ]
    }

    return (
        <>
            <div className='container-fluid'>
                <form onSubmit={handleSubmit(facturar)} className='facturar' /*onReset=""*/>
                    <div className='col-md-12'>
                        <div className='row' style={{ textAlign: 'left' }}>
                            <h3>Facturación</h3>
                        </div>
                        <div className='col-md-12 text-right'>
                            <button
                                hidden={facturado}
                                className='btn btn-outline-success'
                                type='button'
                                onClick={() =>
                                    facturar()
                                }
                            >
                                &nbsp; Facturar
                            </button>
                            &nbsp;
                            <button
                                type='button'
                                hidden={!facturado}
                                className='btn btn-outline-danger'
                                onClick={() =>
                                    descargarFactura( cfdi.id,
                                        'Facturacion/DescargarPDF',
                                        cfdi.folio + '.pdf')
                                }
                            >
                                <svg
                                    xmlns='http://www.w3.org/2000/svg'
                                    width='20'
                                    height='20'
                                    fill='currentColor'
                                    className='bi bi-file-pdf'
                                    viewBox='0 0 16 16'
                                >
                                    <path d='M4 0a2 2 0 0 0-2 2v12a2 2 0 0 0 2 2h8a2 2 0 0 0 2-2V2a2 2 0 0 0-2-2H4zm0 1h8a1 1 0 0 1 1 1v12a1 1 0 0 1-1 1H4a1 1 0 0 1-1-1V2a1 1 0 0 1 1-1z' />
                                    <path d='M4.603 12.087a.81.81 0 0 1-.438-.42c-.195-.388-.13-.776.08-1.102.198-.307.526-.568.897-.787a7.68 7.68 0 0 1 1.482-.645 19.701 19.701 0 0 0 1.062-2.227 7.269 7.269 0 0 1-.43-1.295c-.086-.4-.119-.796-.046-1.136.075-.354.274-.672.65-.823.192-.077.4-.12.602-.077a.7.7 0 0 1 .477.365c.088.164.12.356.127.538.007.187-.012.395-.047.614-.084.51-.27 1.134-.52 1.794a10.954 10.954 0 0 0 .98 1.686 5.753 5.753 0 0 1 1.334.05c.364.065.734.195.96.465.12.144.193.32.2.518.007.192-.047.382-.138.563a1.04 1.04 0 0 1-.354.416.856.856 0 0 1-.51.138c-.331-.014-.654-.196-.933-.417a5.716 5.716 0 0 1-.911-.95 11.642 11.642 0 0 0-1.997.406 11.311 11.311 0 0 1-1.021 1.51c-.29.35-.608.655-.926.787a.793.793 0 0 1-.58.029zm1.379-1.901c-.166.076-.32.156-.459.238-.328.194-.541.383-.647.547-.094.145-.096.25-.04.361.01.022.02.036.026.044a.27.27 0 0 0 .035-.012c.137-.056.355-.235.635-.572a8.18 8.18 0 0 0 .45-.606zm1.64-1.33a12.647 12.647 0 0 1 1.01-.193 11.666 11.666 0 0 1-.51-.858 20.741 20.741 0 0 1-.5 1.05zm2.446.45c.15.162.296.3.435.41.24.19.407.253.498.256a.107.107 0 0 0 .07-.015.307.307 0 0 0 .094-.125.436.436 0 0 0 .059-.2.095.095 0 0 0-.026-.063c-.052-.062-.2-.152-.518-.209a3.881 3.881 0 0 0-.612-.053zM8.078 5.8a6.7 6.7 0 0 0 .2-.828c.031-.188.043-.343.038-.465a.613.613 0 0 0-.032-.198.517.517 0 0 0-.145.04c-.087.035-.158.106-.196.283-.04.192-.03.469.046.822.024.111.054.227.09.346z' />
                                </svg>
                                &nbsp; Descargar PDF
                            </button>
                            &nbsp; &nbsp; &nbsp;
                            <button
                                type='button'
                                hidden={!facturado}
                                className='btn btn-outline-warning'
                                onClick={() =>
                                    descargarFactura( cfdi.id,
                                        'Facturacion/DescargarXML',
                                        cfdi.folio + '.xml')
                                }
                            >
                                <svg
                                    xmlns='http://www.w3.org/2000/svg'
                                    width='20'
                                    height='20'
                                    fill='currentColor'
                                    className='bi bi-filetype-xml'
                                    viewBox='0 0 16 16'
                                >
                                    <path
                                        fillRule='evenodd'
                                        d='M14 4.5V14a2 2 0 0 1-2 2v-1a1 1 0 0 0 1-1V4.5h-2A1.5 1.5 0 0 1 9.5 3V1H4a1 1 0 0 0-1 1v9H2V2a2 2 0 0 1 2-2h5.5L14 4.5ZM3.527 11.85h-.893l-.823 1.439h-.036L.943 11.85H.012l1.227 1.983L0 15.85h.861l.853-1.415h.035l.85 1.415h.908l-1.254-1.992 1.274-2.007Zm.954 3.999v-2.66h.038l.952 2.159h.516l.946-2.16h.038v2.661h.715V11.85h-.8l-1.14 2.596h-.025L4.58 11.85h-.806v3.999h.706Zm4.71-.674h1.696v.674H8.4V11.85h.791v3.325Z'
                                    />
                                </svg>
                                &nbsp; Descargar XML
                            </button>
                        </div>
                    </div>
                    <br></br>
                    <div className='row'>
                        <div className='col-md-6' style={{ display: 'grid' }}>
                            <div className='card mb-3 '>
                                <div className='card-body text-start'>
                                    <label htmlFor='id'>Cliente:</label>
                                    <input
                                        value='(XAXX010101000) PUBLICO EN GENERAL'
                                        className='form-control'
                                        enabled={false}
                                    />
                                </div>
                            </div>
                        </div>
                        <div className='col-md-6' style={{ display: 'grid' }}>
                            <div className='card mb-3 '>
                                <div className='card-body text-start'>
                                    <div className='col-md-12'></div>
                                    <label htmlFor='forma_De_Pago'>Descripción:</label>
                                    <input
                                        className='form-control'
                                    />
                                </div>
                            </div>
                        </div>
                        <div
                            className='col-md-12 row'
                            style={{ paddingTop: '7px', paddingBottom: '7px' }}
                        >
                            <div className='col-3 col-sm-4 col-md-3 '>
                                <label>Serie:</label>
                                <select
                                    className='form-control'
                                    onChange={handleInputChangeSerie}
                                    disabled={facturado}

                                >

                                    {series?.map((s) => {
                                        return (
                                            serieAux === s.serie ?
                                            <option key={s.id} value={s.id} selected >
                                                {s.serie}
                                            </option>
                                             : 
                                             <option key={s.id} value={s.id}  >
                                             {s.serie}
                                         </option>
                                        );
                                    })}
                                </select>
                            </div>
                            <div className='col-3 col-sm-4 col-md-3 '>
                                <label>Periodicidad:</label>
                                <select
                                    className='form-control'
                                    value={periodicidad}
                                    onChange={handleInputChangePeriodicidad}
                                    disabled={facturado}
                                >
                                    {periodicidadList.map((s) => {
                                        return (
                                            <option key={s.id} value={s.clave}>
                                                {s.nombre}
                                            </option>
                                        );
                                    })}
                                </select>
                            </div>  
                            <div className='col-6 col-sm-12 col-md- text-right'>
                                <button
                                        hidden={facturado}
                                        type='button'
                                        className='btn btn-outline-danger'
                                        onClick={function (event) {
                                            openModal();
                                        }}
                                    >
                                        Seleccionar Ordenes
                                </button>
                            </div>
                        </div>
                        <div className='col-md-12' style={{ display: 'grid' }}>
                            <div className='card mb-3 '>
                                <div className='text-start'>
                                    <div className='card col-12 col-sm-12 col-md-12'>
                                        <div style={{ overflowX: 'auto' }}>
                                            <table className='table'>
                                                <thead>
                                                    <tr>
                                                        <th>Orden</th>
                                                        <th>Folio</th>
                                                        <th>Conexión</th>
                                                        <th>Subtotal</th>
                                                        <th>Impuesto</th>
                                                        <th>Total</th>
                                                        <th></th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    {
                                                    getIdsValues()?.map((item) => {
                                                        return (
                                                            <tr key={item.id} id={'row'+item.id}>
                                                                <td>{item.no_Orden}</td>
                                                                <td>{item.folio}</td>
                                                                <td>{item.Sucursal?.Nombre}</td>
                                                                <td className='text-right'>
                                                                    {currency(item.importe_Total)}
                                                                </td>
                                                                <td className='text-right'>
                                                                    {currency(item.importe_Total_Impuestos - item.importe_Total)}
                                                                </td>
                                                                <td className='text-right'>
                                                                    {currency(item.importe_Total_Impuestos)}
                                                                </td>

                                                                <td hidden={facturado}>
                                                                    <button
                                                                        type='button'
                                                                        className='btn  mr-1'
                                                                        onClick={() =>
                                                                            eliminar(
                                                                                item.id
                                                                            )
                                                                        }
                                                                    >
                                                                        <svg
                                                                            xmlns='http://www.w3.org/2000/svg'
                                                                            width='25'
                                                                            height='25'
                                                                            fill='currentColor'
                                                                            className='bi bi-x-circle-fill'
                                                                            viewBox='0 0 16 16'
                                                                        >
                                                                            <path d='M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0zM5.354 4.646a.5.5 0 1 0-.708.708L7.293 8l-2.647 2.646a.5.5 0 0 0 .708.708L8 8.707l2.646 2.647a.5.5 0 0 0 .708-.708L8.707 8l2.647-2.646a.5.5 0 0 0-.708-.708L8 7.293 5.354 4.646z' />
                                                                        </svg>
                                                                    </button>
                                                                </td>
                                                            </tr>
                                                        );
                                                    })
                                                }
                                                    <tr>
                                                        <td colSpan={5} className='text-right'>
                                                            Subtotal:
                                                        </td> 
                                                        <td colSpan={2} className='text-right'>
                                                            {subtotal}
                                                        </td> 
                                                        </tr>
                                                        <tr>
                                                        <td colSpan={5} className='text-right'>
                                                            IVA:{' '}
                                                        </td>
                                                        <td colSpan={2} className='text-right'>
                                                            {IVA}
                                                        </td>
                                                        </tr>
                                                        <tr>
                                                        <td colSpan={5} className='text-right'>
                                                            Total:
                                                        </td>
                                                        <td colSpan={2} className='text-right'>
                                                            {total}
                                                        </td>
                                                    </tr>
                                                </tbody>
                                            </table>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </form>
            </div>
            {
                !loading
                ?
                <Modal isOpen={isOpenModal} closeModal={closeModal}>
                    <div className='col-md-12'>
                        <div className='mt-4 text-right'>
                            <label>Periodicidad:</label>
                            <select
                                className='form-control d-inline w-25'
                                value={periodicidad}
                                onChange={handleInputChangePeriodicidad}
                                disabled={facturado}
                            >
                                {periodicidadList.map((s) => {
                                    return (
                                        <option key={s.id} value={s.clave}>
                                            {s.nombre}
                                        </option>
                                    );
                                })}
                            </select>
                        </div>
                        
                        <div className="datetable-scroll">
                            <div>
                                <Table
                                    columns={columns}
                                    rows={ArrayRows}
                                    ruta="Ventas"
                                    sucursal_id={getDataSession('sucursal')}
                                    path={ SLUGS.ordenes }
                                    url={`/Ventas/List?sucursal_Id=${getDataSession('sucursal')}&tipo_documento=V`}
                                ></Table>
                                </div>
                            </div>
                    </div>
                </Modal>
                : ''
            }
                
        </>
    );
};

export { FacturacionGlobal };