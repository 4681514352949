import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { fetchConToken } from 'helpers/fetch';
import { getDataSession } from 'views/Utils/Common';

import Modal from 'components/modal/Modal';
import { useModal } from 'hooks/useModal';
import Pagination from 'components/pagination/Pagination';
import { useForm } from 'react-hook-form';

import PropTypes from 'prop-types';
import AsyncSelect from 'react-select/async';

import Swal from 'sweetalert2';
import { currency } from 'resources/utilities';

const AddEdit = ({ history, match }) => {
    
    const { handleSubmit } = useForm();
    const { id } = match.params;
    const isAddMode = !id;

    const [initialObject, setInitialObject] = useState(true);
    const [initialObjectClaveUnidad, setInitialObjectClaveUnidad] = useState(true);

    const [isOpenModal, openModal, closeModal] = useModal(false);

    let [errores, setErrores] = useState({});
    let [error, setError] = useState(['']);

    const busqueda = useFormInput('');
    const totalxPagina = 10;

    let [datos, setDatos] = useState([]);
    let [catalogoSat, setCatalogoSat] = useState(['']);

    
    let [sucursal, setSucursal] = useState([]);
    let [impuestos, setImpuestos] = useState([]);
    let [listaImpuestosArt, setListaImpuestosArt] = useState([]);
    let [impuestoArticulo, setImpuestoArticulo] = useState({
        id: 0,
        articulo_Id: id,
        tipo_Impuestos_Id: 0,
        tipo_impuesto: {}
    });

    let [clavesSat, setClavesSat] = useState([]);

    let [articulo, setArticulo] = useState(
        [] /*{
        sucursal_Id: getDataSession('sucursal'),
        empresa_Id: getDataSession('empresa'),
        clave_Prod_Serv: '',
        no_Identificacion: '',
        clave_Unidad: '',
        descripcion: '',
        precio_Base: 0,
        estatus: 'A',
        clave: '',
        articulop_Id: '',
        sku: '',
        nombre: '',
        url: '',
        es_Paquete: 'N',
        tiene_Variante: 'N',
        existencias: 0,
        envio_Gratis: 'N',
        costo_Envio: 0,
        costo_Comision: 0,
        costo_Unidad: 0,
        metodo_Envio: '',
        tipo_Publicacion: '',
        clave_Variante: '',
        clave_Categoria: ''
    }*/
    );

    function onSubmit(data) {
        if (isAddMode && getDataSession('sucursal') === '0') {
            Swal.fire('Info', 'Seleccione una conexión para continuar.', 'info');
            return;
        }
        return isAddMode ? createArt(data) : updateArt(id, data);
    }

    async function eliminar() {
        Swal.fire({
            title: '¿Desea eliminar este articulo?',
            icon: 'question',
            showCancelButton: true,
            confirmButtonText: 'Eliminar',
            cancelButtonText: 'Cancelar'
        }).then((result) => {
            if (result.isConfirmed) {
               fetchConToken(`Articulos?articulo_id=${id}`, null, 'DELETE')  
               .then((response) => {
                    if (response.hasOwnProperty('status')) {
                        var erroresA = {};
                        Object.entries(response?.errors).map(([key, value]) => {
                            Swal.fire('Error', value[0], 'error');
                            return;
                        });
                        return false;
                    } else if (response.hasOwnProperty('codigo')) {
                        Swal.fire('Error', response.mensajes[0], 'error');
                        return;
                    }
                    Swal.fire('Exito', 'Articulo eliminado', 'success');
                    history.push('..');
                })
            }}) 
    }

    function createArt(data) {
        setErrores({});
        setError('');
        articulo.sucursal_Id = getDataSession('sucursal');
        articulo.empresa_Id = getDataSession('empresa');
        fetchConToken('Articulos', articulo, 'POST')
            .then((response) => {
                if (response.hasOwnProperty('status')) {
                    Object.entries(response?.errors).map(([key, value]) => {
                        Swal.fire('Error', value[0], 'error');
                        return;
                    });
                    return false;
                } else if (response.hasOwnProperty('codigo')) {
                    Swal.fire('Error', response.mensajes[0], 'error');
                    return false;
                }
                subirImpuestos(response.id);
                Swal.fire('Exito', 'Articulo agregado', 'success');
                history.push('.');
            })
            //.catch(alertService.error);
    }

    function updateArt(id, data) {
        setErrores({});
        setError('');

        return fetchConToken('Articulos', articulo, 'PUT')
            .then((response) => {
                if (response.hasOwnProperty('status') && response.status === 400) {
                    var erroresA = {};
                    Object.entries(response.errors).map(([key, value]) => {
                        erroresA[key] = value[0];
                        Swal.fire('Error', value[0], 'error');
                    });
                    return false;
                } else if (response.hasOwnProperty('codigo') && response.codigo !== 200) {
                    Swal.fire('Error', response.mensajes[0], 'error');
                    return false;
                }
                subirImpuestos(response.id);
                //alertService.success('Articulo modificado', { keepAfterRouteChange: true });
                Swal.fire('Exito', 'Articulo modificado', 'success');
                history.push('..');
            })
            //.catch(alertService.error);
    }

    function impuestosByArticulo(id) {
        fetchConToken(`ImpuestosArticulos/GetList?articulo_Id=${id}`).then((imp) => {
            setImpuestos(imp);
        });
    }

    function getSucursal(id) {
        fetchConToken(`sucursales?id=${getDataSession('sucursal')}`).then((suc) => {
            setSucursal(suc);
        });
    }

    function listaImpuestos() {
        fetchConToken(`TiposImpuestos/GetList?paginas=1&totalXpagina=100`).then((imp) => {
            setListaImpuestosArt(imp);
        });
    }

    function getArticulo() {
        fetchConToken(`articulos?id=${id}&sucursal_Id=${getDataSession('sucursal')}`).then(
            (art) => {
                if (art.hasOwnProperty('status')) {
                    var erroresA = {};
                    Object.entries(art?.errors).map(([key, value]) => {
                        Swal.fire('Error', value[0], 'error');
                        return;
                    });
                    return false;
                } else if (art.hasOwnProperty('codigo')) {
                    Swal.fire('Error', art.mensajes[0], 'error');
                    return;
                }

                setArticulo(art);
                impuestosByArticulo(id);
                console.log(art);
                getInitialClaveUnidad(art);

                fetchConToken(
                    `CatalogosSat/ListClaveProd?pagina=${1}&totalXpagina=${50}&busqueda=${
                        art?.clave_Prod_Serv
                    }`
                ).then((resp) => {
                    var aux = resp.find((e) => e.clave === art?.clave_Prod_Serv);
                    setInitialObject(aux);
                    //initialObject = aux;
                });
            }
        );
    }
    

    useEffect(() => {

        getSucursal(getDataSession('sucursal'));
        if (!isAddMode) {
            getArticulo();
        } else {
            if (getDataSession('sucursal') === '0') {
                Swal.fire('Info', 'Seleccione una conexión para continuar.', 'info');
                return;
            }
        }

        listaImpuestos();
    }, []);

    // effect runs when user state is updated
    useEffect(() => {
        // reset form with user data
        // eslint-disable-next-line
    }, [articulo]);

    const onChangeCheck = ({ target }) => {
        const xCheck = target.checked ? 'S' : 'N';
        setArticulo({
            ...articulo,
            [target.name]: xCheck
        });
    };

    const onChangeCheckActivo = ({ target }) => {
        const xCheck = target.checked ? 'A' : 'I';
        setArticulo({
            ...articulo,
            [target.name]: xCheck
        });
    };

    function handleInputChange(event) {
        const target = event.target;
        const value = target.type === 'checkbox' ? target.checked : target.value;
        const name = target.name;

        setArticulo({
            ...articulo,
            [name]: value
        });
    }

    const registrarImpuesto = () => {
        if (String(impuestoArticulo.tipo_Impuestos_Id) === '0') {
            Swal.fire('', 'Seleccione un impuesto', 'info');
            //alertService.error('Seleccione un impuesto', { keepAfterRouteChange: true });
            return;
        }
        var impuestoRepetido = impuestos.filter(
            (i) => i.tipo_Impuestos_Id == impuestoArticulo.tipo_Impuestos_Id
        );

        if (impuestoRepetido.length > 0) {
            Swal.fire('', 'Ya se agrego este impuesto', 'info');
            return;
        }

    
        var TipoimpuestoRepetido = impuestos.filter(
            (i) => i.tipo_impuesto.clave == impuestoArticulo.tipo_impuesto.clave && i.tipo_impuesto.concepto == impuestoArticulo.tipo_impuesto.concepto
        );

        if (TipoimpuestoRepetido.length > 0) {
            Swal.fire('', 'Ya se agrego este tipo de impuesto: '+ impuestoArticulo.tipo_impuesto.clave+'-'+impuestoArticulo.tipo_impuesto.concepto , 'info');
            return;
        }


        var TipoimpuestoIepsRetencion = impuestos.filter(
            (i) => (i.tipo_impuesto.clave == '003' && i.tipo_impuesto.concepto  == "retencion" )
        );
        if (TipoimpuestoIepsRetencion.length > 0 && (impuestoArticulo.tipo_impuesto.clave == '002' && impuestoArticulo.tipo_impuesto.concepto  == "retencion")) {
            Swal.fire('', 'No puede agregar retención de IVA si ya asigno IEPS ', 'info');
            return;
        }

        var TipoimpuestoIvaRetencion = impuestos.filter(
            (i) => (i.tipo_impuesto.clave == '002' && i.tipo_impuesto.concepto  == "retencion" ) 
        );
        if (TipoimpuestoIvaRetencion.length > 0 && (impuestoArticulo.tipo_impuesto.clave == '003' && impuestoArticulo.tipo_impuesto.concepto  == "retencion")) {
            Swal.fire('', 'No puede agregar IEPS si ya asigno Retención de IVA ', 'info');
            return;
        }


        console.log(impuestoArticulo);
        if (isAddMode) {
            setImpuestos([...impuestos, impuestoArticulo]);
            return;
        }

        fetchConToken(`ImpuestosArticulos`, impuestoArticulo, 'POST').then(function (response) {
            if (response.hasOwnProperty('status')) {
                var erroresA = {};
                Object.entries(response.errors).map(([key, value]) => {
                    Swal.fire('Error', value[0], 'error');
                    return;
                });
                return false;
            } else if (response.hasOwnProperty('codigo') && response.codigo !== 200) {
                Swal.fire('Error', response.mensajes[0], 'error');
                return false;
            }
            Swal.fire('Exitoso', 'Impuesto agregado', 'success');
            //alertService.success('Impuesto agregado', { keepAfterRouteChange: true });
            setImpuestos([...impuestos, response]);
        });
    };

    const subirImpuestos = (articulo_id) => {
        console.log(impuestos);

        impuestos
            .filter((imp) => !imp.hasOwnProperty('id') || imp.id < 1)
            .map((item) => {
                item.articulo_Id = articulo_id;
                fetchConToken(`ImpuestosArticulos`, item, 'POST').then(function (response) {
                    if (response.hasOwnProperty('status')) {
                        var erroresA = {};
                        Object.entries(response.errors).map(([key, value]) => {
                            Swal.fire('Error', value[0], 'error');
                            return;
                        });
                        return false;
                    } else if (response.hasOwnProperty('codigo') && response.codigo !== 200) {
                        Swal.fire('Error', response.mensajes[0], 'error');
                        return false;
                    }

                    setImpuestos([...impuestos, response]);
                });
            });
    };

    const eliminarImpuesto = (id_imp, tipo_Impuestos_Id) => {
        Swal.fire({
            title: '¿Desea eliminar este impuesto?',
            showCancelButton: true,
            confirmButtonText: 'Eliminar',
            cancelButtonText: 'Cancelar'
        }).then((result) => {
            if (result.isConfirmed) {
                if (id_imp !== undefined) {
                    fetchConToken(`ImpuestosArticulos?id=${id_imp}`, null, 'DELETE').then(function (
                        response
                    ) {
                        if (response.hasOwnProperty('status')) {
                            var erroresA = {};
                            Object.entries(response.errors).map(([key, value]) => {
                                Swal.fire('Error', value[0], 'error');
                                return;
                            });
                            return false;
                        } else if (response.hasOwnProperty('codigo') && response.codigo !== 200) {
                            Swal.fire('Error', response.mensajes[0], 'error');
                            return false;
                        }
                        Swal.fire('Impuesto Eliminado!', '', 'success');
                        //alertService.success('Impuesto Eliminado', { keepAfterRouteChange: true });
                        const arrayFiltrado = impuestos.filter(
                            (impuesto) => impuesto.id !== id_imp
                        );
                        setImpuestos(arrayFiltrado);
                    });
                } else {
                    console.log(impuestos);
                    setImpuestos(
                        impuestos.filter((imp) => imp.tipo_Impuestos_Id !== tipo_Impuestos_Id)
                    );
                }
            }
        });
    };

    function handleChange(e) {
        var impuesto = listaImpuestosArt.filter((imp) => String(imp.id) === e.target.value);
        console.log(impuesto);
        console.log(impuesto[0]);
        console.log(listaImpuestosArt);
        console.log(e.target.value);
        setImpuestoArticulo({
            tipo_Impuestos_Id: e.target.value,
            articulo_Id: id,
            tipo_impuesto: impuesto[0]
        });
    }
    /////Catalogos////
    /////////////////
    function getDatos(page, catalogo = catalogoSat) {
        switch (catalogo) {
            case 'clave_Prod_Serv':
                fetchConToken(
                    `CatalogosSat/ListClaveProd?pagina=${page}&totalXpagina=${totalxPagina}&busqueda=${busqueda.value}`
                ).then((claves) => {
                    setDatos(claves);
                });
                break;
            case 'clave_Unidad':
                fetchConToken(
                    `CatalogosSat/ListClaveUnidad?pagina=${page}&totalXpagina=${totalxPagina}&busqueda=${busqueda.value}`
                ).then((claves) => {
                    setDatos(claves);
                });
                break;
        }

        setCatalogoSat(catalogo);
    }
    /////////////////

    //const [data, setData] = useState(null);

    const getInitialClaveUnidad = async (art) => {

        await fetchConToken(
            `CatalogosSat/ClaveUnidad?claveUnidad=${art?.clave_Unidad}`
        ).then((resp) => {
            setInitialObjectClaveUnidad(resp);
            console.log(resp);
        });


        await fetchConToken(
            `CatalogosSat/ClaveProd?ClaveProd=${art?.clave_Prod_Serv}`
        ).then((resp) => {
            console.log(resp.find((e) => e.clave === art?.clave_Prod_Serv));
            var aux = resp.find((e) => e.clave === art?.clave_Prod_Serv);
            setInitialObject(aux);
        });

        
    };

    const loadData = async (query) => {
        var resp = await fetchConToken(
            `CatalogosSat/ListClaveProd?pagina=${1}&totalXpagina=${50}&busqueda=${query}`
        );
        return resp;
    };

    const loadDataClaveUnidad = async (query) => {
        var resp = await fetchConToken(
            `CatalogosSat/ListClaveUnidad?pagina=${1}&totalXpagina=${50}&busqueda=${query}`
        );
        return resp;
    };

    const ActualizarArticulo = () => {
        Swal.fire({
            title: '¿Desea actualizar la informacion de este articulo desde la tienda?',
            text: 'Al actualizar la información de este articulo desde la tienda se eliminaran todos los ajustes realizados excepto: impuestos, claves para facturación ',
            showCancelButton: true,
            confirmButtonText: 'Actualizar',
            cancelButtonText: 'Cancelar'
        }).then((result) => {
            if (result.isConfirmed) {
        fetchConToken(
            `Articulos/DescargarArticulosById?empresa_id=${getDataSession(
                'empresa'
            )}&sucursal_id=${getDataSession('sucursal')}&id=${articulo.clave}`,
            null,
            'POST'
        ).then(function (response) {
            getArticulo();
            if (response.hasOwnProperty('status') && response.status === 400) {
                Object.entries(response.errors).map(([key, value]) => {
                    Swal.fire('Error', value[0], 'error');
                });
                return;
            } else if (response.hasOwnProperty('codigo') && response.codigo !== 200) {
                Swal.fire('Error', response.mensajes[0], 'error'); 
                return;
            }
            Swal.fire('Exito', 'Articulo Actualizado', 'success');
        });
    }})
    };

    return (
        <>
            <div className='container-fluid'>
                <form onSubmit={handleSubmit(onSubmit)} /*onReset=""*/>
                    <div className='mb-3'>
                        <div className='col-md-12'>
                            <div className='col-sm-6  col-md-6'>
                                <h3>{isAddMode ? 'Agregar artículo' : 'Editar artículo'}</h3>
                            </div>
                            
                                <div className='col-md-12 text-right'>
                                    {!isAddMode ? (
                                        <button
                                            className='btn btn-outline-danger'
                                            type='button'
                                            style={{ float: 'left' }}
                                            onClick={() => eliminar()}
                                        >
                                            <svg
                                                xmlns='http://www.w3.org/2000/svg'
                                                width='20'
                                                height='20'
                                                fill='currentColor'
                                                className='bi bi-x-lg'
                                                viewBox='0 0 16 16'
                                            >
                                                <path d='M2.146 2.854a.5.5 0 1 1 .708-.708L8 7.293l5.146-5.147a.5.5 0 0 1 .708.708L8.707 8l5.147 5.146a.5.5 0 0 1-.708.708L8 8.707l-5.146 5.147a.5.5 0 0 1-.708-.708L7.293 8 2.146 2.854Z' />
                                            </svg>
                                            &nbsp; &nbsp; Eliminar
                                        </button>
                                    ) : (
                                        ''
                                    )}
                                    &nbsp;
                                    {!isAddMode && articulo?.url?.length > 0 ? (
                                    <button className='btn btn-outline-info' type='button' onClick={(e) => ActualizarArticulo()}>
                                        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-arrow-clockwise" viewBox="0 0 16 16">
                                        <path fill-rule="evenodd" d="M8 3a5 5 0 1 0 4.546 2.914.5.5 0 0 1 .908-.417A6 6 0 1 1 8 2v1z"/>
                                        <path d="M8 4.466V.534a.25.25 0 0 1 .41-.192l2.36 1.966c.12.1.12.284 0 .384L8.41 4.658A.25.25 0 0 1 8 4.466z"/>
                                        </svg>
                                        &nbsp; Actualizar
                                    </button> ) : '' }
                                    &nbsp;
                                    <button className='btn btn-outline-success' type='submit'>
                                        <svg
                                            xmlns='http://www.w3.org/2000/svg'
                                            width='20'
                                            height='20'
                                            fill='currentColor'
                                            className='bi bi-check-lg'
                                            viewBox='0 0 16 16'
                                        >
                                            <path d='M12.736 3.97a.733.733 0 0 1 1.047 0c.286.289.29.756.01 1.05L7.88 12.01a.733.733 0 0 1-1.065.02L3.217 8.384a.757.757 0 0 1 0-1.06.733.733 0 0 1 1.047 0l3.052 3.093 5.4-6.425a.247.247 0 0 1 .02-.022Z' />
                                        </svg>
                                        &nbsp; Guardar
                                    </button>
                                    &nbsp;
                                    <Link
                                        to={isAddMode ? '.' : '..'}
                                        className='btn btn-outline-secondary'
                                    >
                                        <svg
                                            xmlns='http://www.w3.org/2000/svg'
                                            width='20'
                                            height='20'
                                            fill='currentColor'
                                            className='bi bi-reply'
                                            viewBox='0 0 16 16'
                                        >
                                            <path d='M6.598 5.013a.144.144 0 0 1 .202.134V6.3a.5.5 0 0 0 .5.5c.667 0 2.013.005 3.3.822.984.624 1.99 1.76 2.595 3.876-1.02-.983-2.185-1.516-3.205-1.799a8.74 8.74 0 0 0-1.921-.306 7.404 7.404 0 0 0-.798.008h-.013l-.005.001h-.001L7.3 9.9l-.05-.498a.5.5 0 0 0-.45.498v1.153c0 .108-.11.176-.202.134L2.614 8.254a.503.503 0 0 0-.042-.028.147.147 0 0 1 0-.252.499.499 0 0 0 .042-.028l3.984-2.933zM7.8 10.386c.068 0 .143.003.223.006.434.02 1.034.086 1.7.271 1.326.368 2.896 1.202 3.94 3.08a.5.5 0 0 0 .933-.305c-.464-3.71-1.886-5.662-3.46-6.66-1.245-.79-2.527-.942-3.336-.971v-.66a1.144 1.144 0 0 0-1.767-.96l-3.994 2.94a1.147 1.147 0 0 0 0 1.946l3.994 2.94a1.144 1.144 0 0 0 1.767-.96v-.667z' />
                                        </svg>
                                        &nbsp; Regresar &nbsp;
                                    </Link>
                                </div>
                            
                        </div>
                    </div>
                    <div className='row'>
                        <div className='col-md-6' style={{ display: 'grid' }}>
                            <div className='card mb-3 '>
                            <label className='form-label title2'>Datos obligatorios</label>
                                <div className='card-body text-start'>

                                <label htmlFor='clave' className='form-label'>
                                        Clave*
                                    </label>
                                    <input
                                        type='text'
                                        className='form-control'
                                        name='clave'
                                        required={true}
                                        maxLength={100}
                                        value={articulo.clave || ''}
                                        onChange={handleInputChange}
                                    />

                                    <label htmlFor='nombre' className='form-label'>
                                        Nombre*
                                    </label>
                                    <input
                                        type='text'
                                        className='form-control'
                                        name='nombre'
                                        required={true}
                                        maxLength={100}
                                        value={articulo.nombre || ''}
                                        onChange={handleInputChange}
                                    />

                                    <label htmlFor='descripcion' className='form-label'>
                                        Descripción*
                                    </label>
                                    <input
                                        type='text'
                                        className='form-control'
                                        name='descripcion'
                                        required={true}
                                        maxLength={100}
                                        value={articulo.descripcion || ''}
                                        onChange={handleInputChange}
                                    />

                                    <label htmlFor='costo_Unidad' className='form-label'>
                                        Precio {sucursal.desglosar_Impuesto ? '(Con Impuestos)*' : '(Sin Impuestos)*'}
                                    </label>
                                    <input
                                        type='number'
                                        className='form-control'
                                        name='costo_Unidad'
                                        required={true}
                                        maxLength={100}
                                        value={articulo.costo_Unidad || ''}
                                        onChange={handleInputChange}
                                    />

                                    <label htmlFor='existencias' className='form-label'>
                                        Existencias*
                                    </label>
                                    <input
                                        type='number'
                                        className='form-control'
                                        name='existencias'
                                        required={true}
                                        maxLength={100}
                                        value={articulo.existencias || ''}
                                        onChange={handleInputChange}
                                    />


                                    <label htmlFor='clave_Prod_Serv' className='form-label'>
                                        Clave del Producto o Servicio SAT
                                    </label>
                                    <AsyncSelect
                                        isClearable
                                        className='react-select-placeholder'
                                        getOptionLabel={(option) =>
                                            option.clave + '-' + option.nombre
                                        }
                                        getOptionValue={(option) => option.clave}
                                        defaultOptions
                                        value={initialObject}
                                        loadOptions={loadData}
                                        onChange={(selectedItem) => {
                                            const val =
                                                selectedItem === null ? '' : selectedItem?.clave;
                                            console.log(val);
                                            articulo.clave_Prod_Serv = val;
                                            setInitialObject(selectedItem);
                                        }}
                                    />

                                    <label htmlFor='no_Identificacion' className='form-label'>
                                        Código de Identificación*
                                    </label>
                                    <input
                                        type='text'
                                        className='form-control'
                                        name='no_Identificacion'
                                        
                                        maxLength={100}
                                        placeholder="Escriba código de identificación"
                                        value={articulo.no_Identificacion || ''}
                                        onChange={handleInputChange}
                                    />

                                    <label htmlFor='clave_Unidad' className='form-label'>
                                        Clave de Unidad (SAT)*
                                    </label>
                                    <AsyncSelect
                                    className='react-select-placeholder'
                                        isClearable
                                        getOptionLabel={(option) =>
                                            option.clave + '-' + option.nombre
                                        }
                                        getOptionValue={(option) => option.clave}
                                        defaultOptions //={initialObjectClaveUnidad}
                                        value={initialObjectClaveUnidad}
                                        loadOptions={loadDataClaveUnidad}
                                        onChange={(selectedItem) => {
                                            const val =
                                                selectedItem === null ? '' : selectedItem?.clave;
                                            console.log(val);
                                            articulo.clave_Unidad = val; //setFieldValue(fieldName, val)
                                            setInitialObjectClaveUnidad(selectedItem);
                                        }}
                                    />
                                    

                                    

                                </div>
                            </div>
                        </div>
                        <div className='col-md-6' style={{ display: 'grid' }}>
                            <div className='card mb-3 '>
                            <label className='form-label title2'>Datos opcionales</label>
                                <div className='card-body text-start'>

                                <label htmlFor='sku' className='form-label'>SKU</label>
                                    <input
                                        type='text'
                                        className='form-control'
                                        name='sku'
                                        maxLength={100}
                                        value={articulo.sku || ''}
                                        onChange={handleInputChange}
                                    />

                                    <label htmlFor='url' className='form-label'>
                                        URL
                                    </label>
                                    <input
                                        type='text'
                                        className='form-control'
                                        name='url'
                                        maxLength={100}
                                        value={articulo.url || ''}
                                        onChange={handleInputChange}
                                        disabled={true}
                                    />


                                    <label htmlFor='metodo_Envio' className='form-label'>
                                        Método de Envío
                                    </label>
                                    <input
                                        type='text'
                                        className='form-control'
                                        name='metodo_Envio'
                                        maxLength={100}
                                        value={articulo.metodo_Envio || ''}
                                        onChange={handleInputChange}
                                    />
                                    <label htmlFor='tipo_Publicacion' className='form-label'>
                                        Tipo de Publicación
                                    </label>
                                    <input
                                        type='text'
                                        className='form-control'
                                        name='tipo_Publicacion'
                                        maxLength={100}
                                        value={articulo.tipo_Publicacion || ''}
                                        onChange={handleInputChange}
                                    />

                                    {/*<label htmlFor='clave_Variante' className='form-label'>
                                        Clave de Variante
                                    </label>
                                    <input
                                        type='text'
                                        className='form-control'
                                        name='clave_Variante'
                                        maxLength={100}
                                        value={articulo.clave_Variante}
                                        onChange={handleInputChange}
    />*/}

                                    {/*  <label htmlFor='clave_Categoria' className='form-label'>
                                        Clave de Categoria
                                    </label>
                                    <input
                                        type='text'
                                        className='form-control'
                                        name='clave_Categoria'
                                        required={true}
                                        maxLength={100}
                                        value={articulo.clave_Categoria}
                                        onChange={handleInputChange}
/>*/}

                                    <div className='form-check form-switch'>
                                        <input
                                            type='checkbox'
                                            className='form-check-input'
                                            name='estatus'
                                            role='switch'
                                            onChange={onChangeCheckActivo}
                                            checked={articulo.estatus === 'A'}
                                        />
                                        <label htmlFor='estatus'>Estatus</label> &nbsp;&nbsp;
                                    </div>

                                    <div className='form-check form-switch'>
                                        <input
                                            type='checkbox'
                                            className='form-check-input'
                                            name='es_Paquete'
                                            role='switch'
                                            onChange={onChangeCheck}
                                            checked={articulo.es_Paquete === 'S'}
                                        />
                                        <label htmlFor='es_Paquete'>Es Paquete</label> &nbsp;&nbsp;
                                    </div>

                                    {/*<div className='form-check form-switch'>
                                        <input
                                            type='checkbox'
                                            className='form-check-input'
                                            name='tiene_Variante'
                                            role='switch'
                                            onChange={onChangeCheck}
                                            checked={articulo.tiene_Variante === 'S'}
                                        />
                                        <label htmlFor='tiene_Variante'>Tiene Variante</label>{' '}
                                        &nbsp;&nbsp;
</div>*/}

                                    <div className='form-check form-switch'>
                                        <input
                                            type='checkbox'
                                            className='form-check-input'
                                            name='envio_Gratis'
                                            role='switch'
                                            onChange={onChangeCheck}
                                            checked={articulo.envio_Gratis === 'S'}
                                        />
                                        <label htmlFor='envio_Gratis'>Envío Gratis</label>{' '}
                                        &nbsp;&nbsp;
                                    </div>

                                    <label htmlFor='costo_Envio' className='form-label'>
                                        Costo de Envío
                                    </label>
                                    <input
                                        type='number'
                                        className='form-control'
                                        name='costo_Envio'
                                        maxLength={100}
                                        value={articulo.costo_Envio || ''}
                                        onChange={handleInputChange}
                                    />

                                    <label htmlFor='costo_Comision' className='form-label'>
                                        Costo de Comisión
                                    </label>
                                    <input
                                        type='number'
                                        className='form-control'
                                        name='costo_Comision'
                                        maxLength={100}
                                        value={articulo.costo_Comision || ''}
                                        onChange={handleInputChange}

                                    />

                                    
                                </div>
                            </div>
                        </div>
                        <div className='container-fluid'></div>
                    </div>
                </form>
                <div className='card col-12 col-sm-12 col-md-12'>
                    <br></br>
                    <div className='col-md-12 row text-right'>
                        <div className='col-md-8'>
                            <select className='form-control' onChange={handleChange}>
                                <option key={0} value='0'>
                                    Seleccione un impuesto
                                </option>
                                {listaImpuestosArt.map((item) => {
                                    return (
                                        <option key={item.id} value={item.id}>
                                            {item.nombre}
                                        </option>
                                    );
                                })}
                            </select>
                        </div>
                        <div className='col-md-4 text-right'>
                            <button
                                type='button'
                                className='btn btn-outline-primary'
                                onClick={(e) => registrarImpuesto()}
                            >
                                {' '}
                                Agregar Impuesto
                            </button>
                        </div>
                    </div>
                    <br></br>
                    <div style={{ overflowX: 'auto' }}>
                        <table className='table'>
                            <thead>
                                <tr>
                                    <th>#</th>
                                    <th>Clave</th>
                                    <th>Nombre</th>
                                    <th>Descripcion</th>
                                    <th>Porcentaje</th>
                                    <th width='25'></th>
                                </tr>
                            </thead>
                            <tbody>
                                {impuestos.map((item) => {
                                    return (
                                        <tr key={item.id}>
                                            <td>{item.id}</td>
                                            <td>{item.tipo_impuesto?.clave}</td>
                                            <td>{item.tipo_impuesto?.nombre}</td>
                                            <td>{item.tipo_impuesto?.descripcion}</td>
                                            <td>{item.tipo_impuesto?.porcentaje}</td>
                                            <td width='25'>
                                                <button
                                                    type='button'
                                                    className='btn'
                                                    style={{ color: 'red' }}
                                                    onClick={(e) =>
                                                        eliminarImpuesto(
                                                            item.id,
                                                            item.tipo_Impuestos_Id
                                                        )
                                                    }
                                                >
                                                    <svg
                                                        xmlns='http://www.w3.org/2000/svg'
                                                        width='25'
                                                        height='25'
                                                        fill='currentColor'
                                                        className='bi bi-x-circle-fill'
                                                        viewBox='0 0 16 16'
                                                    >
                                                        <path d='M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0zM5.354 4.646a.5.5 0 1 0-.708.708L7.293 8l-2.647 2.646a.5.5 0 0 0 .708.708L8 8.707l2.646 2.647a.5.5 0 0 0 .708-.708L8.707 8l2.647-2.646a.5.5 0 0 0-.708-.708L8 7.293 5.354 4.646z'></path>
                                                    </svg>
                                                </button>
                                            </td>
                                        </tr>
                                    ); 
                                })}
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
            <Modal isOpen={isOpenModal} closeModal={closeModal}>
                <>
                    <div className='col-12 col-sm-12 col-md-12 text-right'>
                        <label htmlFor='id'>
                            <button
                                type='button'
                                className='btn btn-outline-danger'
                                onClick={function (event) {
                                    openModal();
                                }}
                            >
                                Buscar
                            </button>
                        </label>
                    </div>
                    <table className='table'>
                        <thead>
                            <tr>
                                <th width='40px'>#No</th>
                                <th>Clave</th>
                                <th>Nombre</th>
                                <th></th>
                            </tr>
                        </thead>
                        <tbody>
                            {datos.map((item) => {
                                return (
                                    <tr key={item.id}>
                                        <td>{item.id}</td>
                                        <td>{item.clave}</td>
                                        <td>{item.nombre}</td>
                                        <td className='text-center'>
                                            <button
                                                className='btn btn-outline-warning'
                                                type='button'
                                                onClick={function (event) {
                                                    closeModal();
                                                }}
                                            >
                                                <svg
                                                    xmlns='http://www.w3.org/2000/svg'
                                                    width='25'
                                                    height='25'
                                                    fill='currentColor'
                                                    className='bi bi-check2'
                                                    viewBox='0 0 16 16'
                                                >
                                                    <path d='M13.854 3.646a.5.5 0 0 1 0 .708l-7 7a.5.5 0 0 1-.708 0l-3.5-3.5a.5.5 0 1 1 .708-.708L6.5 10.293l6.646-6.647a.5.5 0 0 1 .708 0z' />
                                                </svg>
                                            </button>
                                        </td>
                                    </tr>
                                );
                            })}
                        </tbody>
                    </table>
                    <Pagination
                        consumirPaginacion={getDatos}
                        elementos={datos.length}
                        totalxPagina={totalxPagina}
                    />
                </>
            </Modal>
        </>
    );
};

const useFormInput = (initialValue) => {
    const [value, setValue] = useState(initialValue);

    const handleChange = (e) => {
        setValue(e.target.value);
    };
    return {
        value,
        onChange: handleChange
    };
};

export { AddEdit };
