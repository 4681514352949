import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { fetchConToken } from 'helpers/fetch';
import { alertService } from '_services';
import { getDataSession,setDataSession } from 'views/Utils/Common';
import Swal from 'sweetalert2';

const MiPerfil = ({ history, match }) => {
    const path = match.url;
    const baseUrl = process.env.REACT_APP_SERVER_URL;

    let [datos, setDatos] = useState(['']);
    let [datosExtra, setDatosExtra] = useState(['']);

    let [imagenPerfil, setImagenPerfil] = useState('');

    var rol = getDataSession('rol');

    async function getDatos() {
        const response = await fetchConToken(
            `usuarios?id=0&empresa_id=${getDataSession('empresa')}`
        );
        setImagenPerfil(baseUrl+response.imagen_perfil)
        setDatos(response);
    }

    async function getDatosExtra() {
        const response = await fetchConToken(`usuarios/DatosExtraPerfil`);
        setDatosExtra(response);
    }

    useEffect(() => {
        getDatos();
        getDatosExtra();
    }, []);

    const handleInputChange = ({ target }) => {
        setDatos({
            ...datos,
            [target.name]: target.value
        });
    };

    const handleInputChangeFileConfig = ({ target }) => {
        const formData = new FormData();

        formData.append('empresa_id', getDataSession('empresa'));
        formData.append('sucursal_id', getDataSession('sucursal'));
        formData.append('tipo', 'imagenes');

        formData.append('FileToUpload', target.files[0], target.files[0].name);

        fetchConToken('Archivos', formData, 'POST', true)
            .then((response) => {
                setDatos({
                    ...datos,
                    ['imagen_perfil']: response.url
                });
                setImagenPerfil(response.url_Api);
                console.log(datos)
                console.log(response.url_Api)
            })
            .catch(alertService.error);
    };

    function save() {
        const regex =/^[0-9]{10}$/i;
       if(!regex.test(datos.telefono))
       {
            Swal.fire('Error', "Escriba un numero de telefono valido", 'error');
            return false;
       }

        datos.rol = null;
        datos.sucursales_Ids = null;
        return fetchConToken('Usuarios', datos, 'PUT')
            .then((response) => {
                if (response.hasOwnProperty('status')) {
                    var erroresA = {};
                    Object.entries(response.errors).map(([key, value]) => {
                        //erroresA[key] = value[0] ;
                        //setErrores(erroresA);
                        Swal.fire('Error', value[0], 'error');
                        return;
                    });
                    //setError('Revisar errores:');
                    return false;
                } else if (response.hasOwnProperty('codigo') && response.codigo !== 200) {
                    //setError(response.mensajes[0]);
                    Swal.fire('Error', response.mensajes[0], 'error');
                    return false;
                }
                
                Swal.fire('Exito', 'Perfil modificado', 'success');
                console.log(imagenPerfil)
                setDataSession('imagenPerfil', imagenPerfil);

            })
            .catch(alertService.error);
    }

    function regresar() {
        window.history.back()
    }
    return (
        <>
            <div className='container-fluid'>
                <div className='mb-3'>
                    <div className='col-md-12' style={{ textAlign: 'left' }}>
                        <div className='col-md-4'>
                            <h4>Mi Perfil</h4>
                           
                        </div>

                        <div className='col-md-12 text-right'>
                            <button
                                className='btn btn-outline-success'
                                type='button'
                                onClick={save}
                            >
                                <svg
                                    xmlns='http://www.w3.org/2000/svg'
                                    width='20'
                                    height='20'
                                    fill='currentColor'
                                    className='bi bi-check-lg'
                                    viewBox='0 0 16 16'
                                >
                                    <path d='M12.736 3.97a.733.733 0 0 1 1.047 0c.286.289.29.756.01 1.05L7.88 12.01a.733.733 0 0 1-1.065.02L3.217 8.384a.757.757 0 0 1 0-1.06.733.733 0 0 1 1.047 0l3.052 3.093 5.4-6.425a.247.247 0 0 1 .02-.022Z' />
                                </svg>
                                &nbsp; Guardar
                            </button>
                            &nbsp;
                            <button type='button' onClick={regresar} className='btn btn-outline-secondary'>
                                <svg
                                    xmlns='http://www.w3.org/2000/svg'
                                    width='20'
                                    height='20'
                                    fill='currentColor'
                                    className='bi bi-reply'
                                    viewBox='0 0 16 16'
                                >
                                    <path d='M6.598 5.013a.144.144 0 0 1 .202.134V6.3a.5.5 0 0 0 .5.5c.667 0 2.013.005 3.3.822.984.624 1.99 1.76 2.595 3.876-1.02-.983-2.185-1.516-3.205-1.799a8.74 8.74 0 0 0-1.921-.306 7.404 7.404 0 0 0-.798.008h-.013l-.005.001h-.001L7.3 9.9l-.05-.498a.5.5 0 0 0-.45.498v1.153c0 .108-.11.176-.202.134L2.614 8.254a.503.503 0 0 0-.042-.028.147.147 0 0 1 0-.252.499.499 0 0 0 .042-.028l3.984-2.933zM7.8 10.386c.068 0 .143.003.223.006.434.02 1.034.086 1.7.271 1.326.368 2.896 1.202 3.94 3.08a.5.5 0 0 0 .933-.305c-.464-3.71-1.886-5.662-3.46-6.66-1.245-.79-2.527-.942-3.336-.971v-.66a1.144 1.144 0 0 0-1.767-.96l-3.994 2.94a1.147 1.147 0 0 0 0 1.946l3.994 2.94a1.144 1.144 0 0 0 1.767-.96v-.667z' />
                                </svg>
                                &nbsp; Regresar &nbsp;
                            </button>
                        </div>
                    </div>
                </div>

                <div className='row'>
                    <div className='col-md-6'>
                        <div className='card mb-3 '>
                            <div className='card-body text-start'>
                                <div className='card-title'>
                                    <label className='fs-6 fw-bold'>Datos Personales</label>
                                </div>
                                <label className='fs-6 mt-2'>Nombre Completo: </label>
                                <input
                                    type='text'
                                    className='form-control'
                                    name='nombre'
                                    value={datos.nombre || ''}
                                    onChange={handleInputChange}
                                />
                                <label className='fs-6 mt-2'>Correo: </label>
                                <input
                                    type='text'
                                    className='form-control'
                                    name='nombre'
                                    value={datos.correo || ''}
                                    onChange={handleInputChange}
                                />

                                <label htmlFor='pwd' className='form-label'>
                                    Contraseña
                                </label>
                                <input
                                    type='password'
                                    className='form-control'
                                    name='pwd'
                                    required={true}
                                    maxLength={100}
                                    value={datos.pwd || ''}
                                    onChange={handleInputChange}
                                />

                                <label htmlFor='telefono' className='form-label'>
                                    Telefono
                                </label>
                                <input
                                    type='text'
                                    className='form-control'
                                    name='telefono'
                                    required={true}
                                    value={datos.telefono || ''}
                                    onChange={handleInputChange}
                                />

                                <br></br>
                                <label className='fs-6 mt-2 '>
                                    Fecha de alta: <br></br>
                                    {datos?.fecha_Registro?.substring(0, 10)}{' '}
                                </label>
                            </div>
                            <div className='card-body text-start'>
                                <img src={imagenPerfil} style={{ width: '250px' }}></img>
                                <label className='fs-6 mt-2'>Imagen de perfil:</label>
                                <input
                                    type='file'
                                    className='form-control'
                                    name='imagen_perfil'
                                    onChange={handleInputChangeFileConfig}
                                />
                            </div>
                        </div>
                    </div>
                    {rol !== 'SUCURSAL' ? (
                        <div className='col-md-6'>
                            <div className='card mb-3 '>
                                <div className='card-body text-start'>
                                    <div className='card-title'>
                                        <label className='fs-6 fw-bold'>Información</label>
                                    </div>
                                    <label className='fs-6 mt-2'>
                                        Total de conexiones que administro:{' '}
                                        <b>{datosExtra?.num_Sucursales}</b>
                                    </label>
                                    <br></br>
                                    <label className='fs-6 mt-2'>Conexiones de mi empresa: </label>
                                    <ul>
                                        {datosExtra?.sucursales?.map((item) => {
                                            return <li id={item.id}>{item}</li>;
                                        })}
                                    </ul>

                                    <br></br>
                                    <label className='fs-6 mt-2'>
                                        Total de usuarios de mi empresa:{' '}
                                        <b>{datosExtra?.num_Usuarios}</b>
                                    </label>
                                    <br></br>
                                    <label className='fs-6 mt-2'>
                                        Total de folios de mi empresa:{' '}
                                        <b>{datosExtra?.timbres_Disponibles}</b>
                                    </label>
                                </div>
                            </div>
                        </div>
                    ) : (
                        ''
                    )}
                </div>
            </div>
        </>
    );
};

const useFormInput = (initialValue) => {
    const [value, setValue] = useState(initialValue);

    const handleChange = (e) => {
        setValue(e.target.value);
    };
    return {
        value,
        onChange: handleChange
    };
};

export { MiPerfil };
