import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { fetchConToken, fetchConTokenFile } from 'helpers/fetch';
import { getDataSession } from 'views/Utils/Common';
import Pagination from 'components/pagination/Pagination';
import { descargarPDF } from 'actions/archivos';
import TituloPagina from 'components/header/TituloPagina';
import Table from 'components/table/Table'
import Swal from 'sweetalert2'
import moment from 'moment';
import { currency } from 'resources/utilities';

const $ = require('jquery')

const TotalesFacturas = ({ match, saludo, subtitulo }) => {
    const { path } = match;
    const baseUrl = process.env.REACT_APP_API_URL;
    let [mes, setMes] = useState('');
    let [ejercicio, setEjercicio] = useState(moment().format('Y'));

    let [estatusSat, setEstatusSat] = useState('T');
    let [estatusPago, setEstatusPago] = useState('T');
     let [factura, setFactura] = useState('E');

    let [montoTotal, setMontoTotal] = useState('');

    let [Reportes, setReportes] = useState([]);

    const SelectAnios = (props) => {
        const year = props.year
        const beforeYear = year - 10
        let años = []
        for(let x = year; x >= beforeYear; x--)
        {
            años.push(x);           
        }

        return (
            <select className='selectCustomTwo m-2 w-100' onChange={props.onChange}>
                {
                años.map((item,i)=>(

                        props.selected == item ?
                        <option key={i} selected value={item}>{item}</option>
                        :
                        <option key={i} value={item}>{item}</option>
                ))
                }
             </select> 
        );
    }

    React.useEffect(() => {
        filtrarPorFecha();
    }, []);



    const filtrarPorFecha = () => {

        fetchConToken(`Cfdis/Reportes_Impuestos?sucursal_Id=${getDataSession('sucursal')}&tipo_factura=${factura}&mes=${mes}&ejercicio=${ejercicio}&estatusSat=${estatusSat}&estatusPago=${estatusPago}`).then(function (response) {
            if (response.hasOwnProperty('status')) {
                var erroresA = {};
                Object.entries(response.errors).map(([key, value]) => {
                    Swal.fire('Error', value[0], 'error');
                    return;
                });
                return false;
            } else if (response.hasOwnProperty('codigo') && response.codigo !== 200) {
                Swal.fire('Error', response.mensajes[0], 'error');
                return false;
            }
            setReportes(response);
        });
    }

    const onChangeEjercicio = (e) =>{
        ejercicio = e.target.value
        setEjercicio(ejercicio)
        filtrarPorFecha()
    }

    const onChangeMes = (e) =>{
        mes = e.target.value
        setMes(mes)
        filtrarPorFecha()
    }
    const filtrarEstatusSat = (e) => {
        estatusSat = e.target.value;
        setEstatusSat(e.target.value);
        filtrarPorFecha();
        
    }

    const filtrarTipoFactura = (e) => {
        factura = e.target.value;
        setFactura(e.target.value);
        filtrarPorFecha();
        
    }

    

    const filtrarEstatusPago = (e) => {
        estatusPago = e.target.value;
        setEstatusPago(e.target.value);
        filtrarPorFecha();
        
    }


    return (
        <>
            <div className='container-fluid'>
                <TituloPagina 
                    titulo='Facturas Recibidas' 
                    descripcion='Aquí se guarda el historial de facturas que has emitido a lo largo de periodos de tiempo, ajusta los filtros y aplica tu busqueda' 
                />
                  <button
                                type='button'
                                className='btn btn-outline-danger'
                                onClick={() =>
                                    descargarPDF(
                                        `cfdis/Reportes_Impuestos_PDF?sucursal_Id=${getDataSession('sucursal')}&tipo_factura=${factura}&mes=${mes}&ejercicio=${ejercicio}&estatusSat=${estatusSat}&estatusPago=${estatusPago}`,
                                        "Reporte ejercicio " + ejercicio + '.pdf'
                                    )
                                }
                            >
                                <svg
                                    xmlns='http://www.w3.org/2000/svg'
                                    width='20'
                                    height='20'
                                    fill='currentColor'
                                    className='bi bi-file-pdf'
                                    viewBox='0 0 16 16'
                                >
                                    <path d='M4 0a2 2 0 0 0-2 2v12a2 2 0 0 0 2 2h8a2 2 0 0 0 2-2V2a2 2 0 0 0-2-2H4zm0 1h8a1 1 0 0 1 1 1v12a1 1 0 0 1-1 1H4a1 1 0 0 1-1-1V2a1 1 0 0 1 1-1z' />
                                    <path d='M4.603 12.087a.81.81 0 0 1-.438-.42c-.195-.388-.13-.776.08-1.102.198-.307.526-.568.897-.787a7.68 7.68 0 0 1 1.482-.645 19.701 19.701 0 0 0 1.062-2.227 7.269 7.269 0 0 1-.43-1.295c-.086-.4-.119-.796-.046-1.136.075-.354.274-.672.65-.823.192-.077.4-.12.602-.077a.7.7 0 0 1 .477.365c.088.164.12.356.127.538.007.187-.012.395-.047.614-.084.51-.27 1.134-.52 1.794a10.954 10.954 0 0 0 .98 1.686 5.753 5.753 0 0 1 1.334.05c.364.065.734.195.96.465.12.144.193.32.2.518.007.192-.047.382-.138.563a1.04 1.04 0 0 1-.354.416.856.856 0 0 1-.51.138c-.331-.014-.654-.196-.933-.417a5.716 5.716 0 0 1-.911-.95 11.642 11.642 0 0 0-1.997.406 11.311 11.311 0 0 1-1.021 1.51c-.29.35-.608.655-.926.787a.793.793 0 0 1-.58.029zm1.379-1.901c-.166.076-.32.156-.459.238-.328.194-.541.383-.647.547-.094.145-.096.25-.04.361.01.022.02.036.026.044a.27.27 0 0 0 .035-.012c.137-.056.355-.235.635-.572a8.18 8.18 0 0 0 .45-.606zm1.64-1.33a12.647 12.647 0 0 1 1.01-.193 11.666 11.666 0 0 1-.51-.858 20.741 20.741 0 0 1-.5 1.05zm2.446.45c.15.162.296.3.435.41.24.19.407.253.498.256a.107.107 0 0 0 .07-.015.307.307 0 0 0 .094-.125.436.436 0 0 0 .059-.2.095.095 0 0 0-.026-.063c-.052-.062-.2-.152-.518-.209a3.881 3.881 0 0 0-.612-.053zM8.078 5.8a6.7 6.7 0 0 0 .2-.828c.031-.188.043-.343.038-.465a.613.613 0 0 0-.032-.198.517.517 0 0 0-.145.04c-.087.035-.158.106-.196.283-.04.192-.03.469.046.822.024.111.054.227.09.346z' />
                                </svg>
                                &nbsp; Descargar PDF
                            </button>
                <div className='col-md-12 row '>
                    <div className='col-md-3'>
                    <div className='d-flex'>
                        <label className='me-1'>Ejercicio</label>
                        <SelectAnios year={moment().format('Y')} onChange={onChangeEjercicio} selected={ejercicio} />
                    </div>
                    <div className='d-flex'>
                        <label className='me-1'>Periodo</label>
                        <select className='selectCustomTwo m-2 w-100' onChange={onChangeMes}>
                            <option value=''>Todos</option>
                            <option value='01'>Enero</option>
                            <option value='02'>Febrero</option>
                            <option value='03'>Marzo</option>
                            <option value='04'>Abril</option>
                            <option value='05'>Mayo</option>
                            <option value='06'>Junio</option>
                            <option value='07'>Julio</option>
                            <option value='08'>Agosto</option>
                            <option value='09'>Septiembre</option>
                            <option value='10'>Octubre</option>
                            <option value='11'>Noviembre</option>
                            <option value='12'>Diciembre</option>
                        </select>
                    </div>
                    </div>
                    <div className='col-md-4'>
                        <div className='d-flex'>
                            <label className='me-1'>Estatus SAT:</label>
                            <select className='selectCustomEstatusSat m-2 w-100' onChange={filtrarEstatusSat}>
                                <option value='T'>Todos</option>
                                <option value='V'>Vigente</option>
                                <option value='C'>Cancelado</option>
                            </select>
                        </div>
                        <div className='d-flex'>
                            <label className='me-1'>Tipo Factura:</label>
                            <select className='selectCustomEstatusSat m-2 w-100' onChange={filtrarTipoFactura}>
                                <option value='E'>Emitida</option>
                                <option value='R'>Recibida</option>
                            </select>
                        </div>
                    </div>
                    <div className='col-md-4'>
                        <div className='d-flex'>
                            <label className='me-1'>Estatus Pago:</label>
                            <select className='selectCustomEstatusSat m-2 w-100' onChange={filtrarEstatusPago}>
                                <option value='T'>Todos</option>
                                <option value='P'>Pendiente Pago</option>
                                <option value='F'>Pagado</option>
                            </select>
                        </div>
                    </div>
                </div>
                <div className='col-12 col-sm-12 col-md-12'>

              
                <div className='col-md-12' >

                <div style={{ overflowX: 'auto' }}>
                                            <table className='table'>
                                            <thead>
                                            </thead>
                                                <tbody>
                                                    {
                                                        Reportes.map(r=>{

                                                           return <tr>
                                                                <td>{r.tipo}</td>
                                                                <td>{ r.tipo != "Total" && r.tipo != "SubTotal" && r.tipo != "Descuento" ? ("Impuesto:" + (r.impuesto == "001" ? "ISR" : (r.impuesto == "002" ? "IVA" : "IEPS"))) : "" }</td>
                                                                <td>{ r.tipo != "Total" && r.tipo != "SubTotal" && r.tipo != "Descuento" ? ("Factor:" + r.tipoFactor) : "" }</td>
                                                                <td>{ r.tipo != "Total" && r.tipo != "SubTotal" && r.tipo != "Descuento" ? ("Porcentaje:" + r.tasaOCuota) : "" }</td>
                                                                <td>{r.tipoFactor == "Exento" ? 'Base' : ( parseFloat(r.tasaOCuota) == 0 ? "Base" : "Importe") } :</td>
                                                                <td className='text-right'>{currency(r.importe)}</td>
                                                            </tr>
                                                        })
                                                    }
                                                    <tr>
                                                    </tr>
                                                </tbody>
                                            </table>
                                        </div>
                    </div> 
                    <div className='col-md-12 text-center' style={{paddingBotton: "50px;"}}>
                </div>
                </div>
            </div>
        </>
    );
};

export { TotalesFacturas };
