import React from 'react';
import { useLocation } from 'react-router-dom';
import useWindowSize from 'hooks/useWindowSize';
import PrivateSection from 'routes/PrivateSection';
import PublicRoutes from 'routes/PublicRoutes';
import { getToken,getDataSession } from 'views/Utils/Common';

function Routes() {
    const { pathname } = useLocation();
    const [width, height] = useWindowSize();
/*
    useEffect(() => {
        window.scrollTo(0, 0);
    }, [pathname]);
*/
    //console.log(getToken());

    //removeUserSession  ();

    const isUserLoggedIn = (getToken() != null) && (getDataSession('empresa') != null) && (getDataSession('sucursal') != null);
    

    if(pathname === "/")
       return <PublicRoutes />;
    return isUserLoggedIn ? <PrivateSection /> : <PublicRoutes />;
}

export default Routes;
