import React, { useState } from 'react';
import { createUseStyles, useTheme } from 'react-jss';
import { useHistory } from 'react-router-dom';
import SLUGS from 'resources/slugs';
import {
    IconAgents,
    IconArticles,
    IconContacts,
    IconIdeas,
    IconLogout,
    IconOverview,
    IconSettings,
    IconSubscription,
    IconTickets
} from 'assets/icons';
import { convertSlugToUrl } from 'resources/utilities';
import LogoComponent from './LogoComponent';
import Menu from './MenuComponent';
import MenuItem from './MenuItemComponent';
import { getDataSession, removeUserSession } from 'views/Utils/Common';
import { setDataSession } from 'views/Utils/Common';
import SidebarOptionsComponent from './SidebarOptionsComponent';

import Home from 'images/panel/menu/menu/home.svg';
import Facturacion from 'images/panel/menu/menu/facturacion.svg';
import Catalogos from 'images/panel/menu/menu/catalogos.svg';
import Reportes from 'images/panel/menu/menu/reportes.svg';
import Estadisticas from 'images/panel/menu/menu/estadisticas.svg';
import Ayuda from 'images/panel/menu/menu/ayuda.svg';
import Soporte from 'images/panel/menu/menu/soporte.svg';
import Salir from 'images/panel/menu/menu/salir.svg';

import Cotizaciones from 'images/panel/menu/menu_blanco/Cotizaciones_blanco.svg';
import Fiscal from 'images/panel/menu/menu_blanco/Fiscal_blanco.svg';


import Configuraciones from 'images/panel/menu/menu_blanco/Configuraciones_blanco.svg';

import Swal from 'sweetalert2';

const useStyles = createUseStyles({
    separator: {
        borderTop: ({ theme }) => `1px solid ${theme.color.lightGrayishBlue}`,
        marginTop: 16,
        marginBottom: 16,
        opacity: 0.06
    }
});

const subMenu = [];

function SidebarComponent() {
    const { push } = useHistory();
    const theme = useTheme();
    const classes = useStyles({ theme });
    const isMobile = window.innerWidth <= 1300;
    var menu = getDataSession('menu');
    const items = subMenu.map((item) => {
        return item.menu === menu ? item.id : '';
    });

    const lista = [
        {
            id: SLUGS.dashboard,
            imagen: Home,
            //items: { items },
            onClick: SLUGS.dashboard,
            menu: 'Dashboard',
            title: 'Dashboard',
            ADMINISTRADOR: 'ADMINISTRADOR',
            EMPRESA: 'EMPRESA',
            SUCURSAL: 'SUCURSAL'
        },
        {
            id: SLUGS.cotizaciones,
            imagen: Catalogos,
            //items: { items },
            onClick: SLUGS.cotizaciones,
            menu: 'Cotizaciones',
            title: 'Cotizaciones',
            ADMINISTRADOR: 'ADMINISTRADOR',
            EMPRESA: 'EMPRESA',
            SUCURSAL: 'SUCURSAL'
        },
        {
            id: SLUGS.ordenes,
            imagen: Reportes,
            //items: { items },
            onClick: SLUGS.ordenes,
            menu: 'Ordenes',
            title: 'Ordenes',
            ADMINISTRADOR: 'ADMINISTRADOR',
            EMPRESA: 'EMPRESA',
            SUCURSAL: 'SUCURSAL'
        },
        {
            id: SLUGS.facturar,
            imagen: Facturacion,
            //items: { items },
            onClick: SLUGS.facturar,
            menu: 'Facturacion',
            title: 'Facturacion',
            ADMINISTRADOR: 'ADMINISTRADOR',
            EMPRESA: 'EMPRESA',
            SUCURSAL: 'SUCURSAL'
        },
        {
            id: SLUGS.articulos,
            imagen: Catalogos,
            //items: { items },
            onClick: SLUGS.articulos,
            menu: 'Catalogos',
            title: 'Catalogos',
            ADMINISTRADOR: 'ADMINISTRADOR',
            EMPRESA: 'EMPRESA',
            SUCURSAL: 'SUCURSAL'
        },
        {
            id: SLUGS.estadisticas,
            imagen: Estadisticas,
            //items: { items },
            onClick: SLUGS.articulos,
            menu: 'Estadisticas',
            title: 'Estadisticas',
            ADMINISTRADOR: 'ADMINISTRADOR',
            EMPRESA: 'EMPRESA',
            SUCURSAL: 'SUCURSAL'
        },
        {
            id: SLUGS.herramientasSAT,
            imagen: Estadisticas,
            //items: { items },
            onClick: SLUGS.herramientasSAT,
            menu: 'Herramientas',
            title: 'Herramientas',
            ADMINISTRADOR: 'ADMINISTRADOR'
        },
        {
            id: SLUGS.ayuda,
            imagen: Ayuda,
            //items: { items },
            onClick: SLUGS.ayuda,
            menu: 'Ayuda',
            title: 'Ayuda',
            ADMINISTRADOR: 'ADMINISTRADOR',
            EMPRESA: 'EMPRESA',
            SUCURSAL: 'SUCURSAL'
        },
        {
            id: SLUGS.soporte,
            imagen: Soporte,
            //items: { items },
            onClick: SLUGS.soporte,
            menu: 'Soporte',
            title: 'Soporte',
            ADMINISTRADOR: 'ADMINISTRADOR',
            EMPRESA: 'EMPRESA',
            SUCURSAL: 'SUCURSAL'
        },
        /*{
            id: SLUGS.articulos,
            imagen: Reportes,
            items: { items },
            onClick: SLUGS.articulos,
            menu: 'Reportes',
            title: 'Reportes',
            ADMINISTRADOR: 'ADMINISTRADOR',
            EMPRESA: 'EMPRESA',
            SUCURSAL: 'SUCURSAL'
        },
        {
            id: SLUGS.empresa,
            imagen: Configuraciones,
            items: { items },
            onClick: SLUGS.empresa,
            menu: 'Configuraciones',
            title: 'Configuraciones',
            ADMINISTRADOR: 'ADMINISTRADOR',
        }*/
    ];

    function renderSwitch() {
        var rol = getDataSession('rol');
        return lista.map((item) => {
            return item.hasOwnProperty(rol) ? (
                <>
                <MenuItem
                    key={item.id}
                    id={item.id}
                    imagen={item.imagen}
                    title={item.title}
                    onClick={() => onClick(item.id,item.menu)}
                />
                <SidebarOptionsComponent menu={item.menu}/>
                </>
            ) : (
                ''
            );
        });
    }

    async function logout() {
        Swal.fire({
            text: 'Esta apunto de salir del panel',
            icon: 'warning',
            showCancelButton: true,
            confirmButtonText: 'Salir',
            cancelButtonText: 'Cancelar'
        }).then((result) => {
            if (result.isConfirmed) {
                removeUserSession();
                push('..');
            }
        })
    }

    function onClick(slug, submenu) {
        setDataSession('menu', submenu);
        console.log(slug)
        console.log(submenu)

        push(slug);
    }

    return (
        <>
            <Menu isMobile={isMobile}>
                <div>
                    
                        <div style={{ paddingTop: 30, paddingBottom: 10 }}>
                            <LogoComponent />
                            <div className='text-center' style={{    paddingTop: 10}}>
                                <h6>{getDataSession('rol')}</h6>
                            </div>
                        </div>
                       
                        
                        <div style={{overflow: 'auto',height: '80vh'}}>
                        {renderSwitch()}
                         <MenuItem id='logout' title='Salir' onClick={logout} imagen={Salir} />
                        </div>
                        
                   
                    
                    <div className='submenu-sidebar-scroll'>
                        <SidebarOptionsComponent />
                     </div>
                </div>
            </Menu>
        </>
    );
}

export default SidebarComponent;

//<MenuItem
//                id=SLUGS.subscription}
//                title='Newsletter'
//                icon={IconSubscription}
//                onClick={() => onClick(SLUGS.subscription)}
//            />
