import React from 'react';
import { createUseStyles, useTheme } from 'react-jss';
import { Link, useHistory } from 'react-router-dom';
import SLUGS from 'resources/slugs';
/*import {
    IconAgents,
    IconArticles,
    IconContacts,
    IconIdeas,
    IconLogout,
    IconOverview,
    IconSettings,
    IconSubscription,
    IconTickets
} from 'assets/icons';*/
import { convertSlugToUrl } from 'resources/utilities';
//import LogoComponent from './LogoComponent';
import SubMenuComponent from './SubMenuComponent';
import SubMenuItemComponent from './SubMenuItemComponent';
import { getDataSession, setDataSession, removeUserSession } from 'views/Utils/Common';

import { useSidebar } from 'hooks/useSidebar';

//import Productos from 'images/panel/menu/menu_color/Productososervicios.svg';
//import Crearfactura from 'images/panel/menu/menu_color/Crear_facturacion.svg';
//import Facturasemitidas from 'images/panel/menu/menu_color/Facturas_emitidas.svg';
//import Facturasrecibidas from 'images/panel/menu/menu_color/Facturas_recibidas.svg';
//import UnidadesMedida from 'images/panel/menu/menu_color/UnidadesMedida.svg';
//import Clientes from 'images/panel/menu/menu_color/Clientes.svg';
//import Sucursales from 'images/panel/menu/menu_color/Sucursales.svg';
//import Roles from 'images/panel/menu/menu_color/Roles.svg';
//import Estadisticas from 'images/panel/menu/menu/estadisticas.svg';

import Crear_factura from 'images/panel/menu/menu_light/crear_factura.svg';
import Fact_emitidas from 'images/panel/menu/menu_light/facturas_emitidas.svg';
import Fact_recibidas from 'images/panel/menu/menu_light/facturas_recibidas.svg';
import Desc_masiva from 'images/panel/menu/menu_light/descarga_masiva.svg';
import Estadisticas from 'images/panel/menu/menu/estadisticas.svg';
import IngresosDiarios from 'images/panel/menu/menu/ic_ingresos_diarios.svg';
import IngresosMensuales from 'images/panel/menu/menu/ic_ingresos_mensuales.svg';
import EgresosMensuales from 'images/panel/menu/menu/ic_egresos_mensuales.svg';
import IngresosEgresos from 'images/panel/menu/menu/ic_ingresos_egresos.svg';
import TopClientes from 'images/panel/menu/menu/ic_top_clientes.svg';
import TopProveedores from 'images/panel/menu/menu/ic_top_proveedores.svg';


import Articulos from 'images/panel/menu/menu_light/articulos.svg';
import Bolsa_timbres from 'images/panel/menu/menu_light/bolsa_timbres.svg';
import Clientes from 'images/panel/menu/menu_light/clientes.svg';
import Impuestos from 'images/panel/menu/menu_light/impuestos.svg';
import Movimientos from 'images/panel/menu/menu_light/movimientos.svg';
import Pagos from 'images/panel/menu/menu_light/pagos.svg';
import Paquetes from 'images/panel/menu/menu_light/paquetes.svg';
import Usuarios from 'images/panel/menu/menu_light/usuarios.svg';



import facturacionImagen from 'images/panel/menu/facturacionImagen.svg';
import icon from 'images/panel/menu/Iconbtn.svg';

const useStyles = createUseStyles({
    separator: {
        borderTop: ({ theme }) => `1px solid ${theme.color.lightGrayishBlue}`,
        marginTop: 16,
        marginBottom: 16,
        opacity: 0.06
    }
});


const lista = [
    
    {
        menu: 'Facturacion',
        id: SLUGS.facturar,
        title: 'Crear factura',
        imagen: Crear_factura,
        onClick: SLUGS.facturar,
        ADMINISTRADOR: 'ADMINISTRADOR',
        EMPRESA: 'EMPRESA',
        SUCURSAL: 'SUCURSAL'
    },
    {
        menu: 'Facturacion',
        id: SLUGS.FacturacionGlobal,
        title: 'Factura Global',
        imagen: Fact_emitidas,
        onClick: SLUGS.FacturacionGlobal,
        ADMINISTRADOR: 'ADMINISTRADOR',
        EMPRESA: 'EMPRESA',
        SUCURSAL: 'SUCURSAL'
    },
    {
        menu: 'Facturacion',
        id: SLUGS.facturas,
        title: 'Facturas emitidas',
        imagen: Fact_emitidas,
        onClick: SLUGS.facturas,
        ADMINISTRADOR: 'ADMINISTRADOR',
        EMPRESA: 'EMPRESA',
        SUCURSAL: 'SUCURSAL'
    },
    {
        menu: 'Facturacion',
        id: SLUGS.facturasRecibidas,
        title: 'Facturas recibidas',
        imagen: Fact_recibidas,
        onClick: SLUGS.facturasRecibidas,
        ADMINISTRADOR: 'ADMINISTRADOR',
        EMPRESA: 'EMPRESA',
        SUCURSAL: 'SUCURSAL'
    },
    {
        menu: 'Facturacion',
        id: SLUGS.facturasComplemento,
        title: 'Complementos de pago',
        imagen: Fact_recibidas,
        onClick: SLUGS.facturasComplemento,
        ADMINISTRADOR: 'ADMINISTRADOR',
        EMPRESA: 'EMPRESA',
        SUCURSAL: 'SUCURSAL'
    },
    {
        menu: 'Facturacion',
        id: SLUGS.descargasat,
        title: 'Descarga Masiva',
        imagen: Desc_masiva,
        onClick: SLUGS.descargasat,
        ADMINISTRADOR: 'ADMINISTRADOR',
        EMPRESA: 'EMPRESA',
        SUCURSAL: 'SUCURSAL'
    },
    {
        menu: 'Facturacion',
        id: SLUGS.totalesFacturas,
        title: 'Reporte General de Impuestos',
        imagen: IngresosDiarios,
        onClick: SLUGS.totalesFacturas,
        ADMINISTRADOR: 'ADMINISTRADOR',
        EMPRESA: 'EMPRESA',
        SUCURSAL: 'SUCURSAL'
    },
    {
        menu: 'Catalogos',
        id: SLUGS.impuestos,
        title: 'Impuestos',
        imagen: Impuestos,
        onClick: SLUGS.impuestos,
        ADMINISTRADOR: 'ADMINISTRADOR'
    },
    {
        menu: 'Catalogos',
        id: SLUGS.articulos,
        title: 'Articulos',
        imagen: Articulos,
        onClick: SLUGS.articulos,
        ADMINISTRADOR: 'ADMINISTRADOR',
        EMPRESA: 'EMPRESA',
        SUCURSAL: 'SUCURSAL'
    },
    {
        menu: 'Catalogos',
        id: SLUGS.clientes,
        title: 'Clientes',
        imagen: Clientes,
        onClick: SLUGS.clientes,
        ADMINISTRADOR: 'ADMINISTRADOR',
        EMPRESA: 'EMPRESA',
        SUCURSAL: 'SUCURSAL'
    },
    /*{
        menu: 'Catalogos',
        id: SLUGS.pagos,
        title: 'Pagos',
        imagen: Pagos,
        onClick: SLUGS.pagos,
        ADMINISTRADOR: 'ADMINISTRADOR',
        EMPRESA: 'EMPRESA'
    },*/
    {
        menu: 'Catalogos',
        id: SLUGS.usuarios,
        title: 'Usuarios',
        imagen: Usuarios,
        onClick: SLUGS.usuarios,
        ADMINISTRADOR: 'ADMINISTRADOR',
        EMPRESA: 'EMPRESA'
    },
    /*{
        menu: 'Catalogos',
        id: SLUGS.movimientos,
        title: 'Movimientos',
        imagen: Movimientos,
        onClick: SLUGS.movimientos,
        ADMINISTRADOR: 'ADMINISTRADOR',
        EMPRESA: 'EMPRESA'
    },*/
    {
        menu: 'Catalogos',
        id: SLUGS.bolsatimbres,
        title: 'Bolsa de timbres',
        imagen: Bolsa_timbres,
        onClick: SLUGS.bolsatimbres,
        ADMINISTRADOR: 'ADMINISTRADOR'
    },
    {
        menu: 'Catalogos',
        id: SLUGS.paquetes,
        title: 'Paquetes',
        imagen: Paquetes,
        onClick: SLUGS.paquetes,
        ADMINISTRADOR: 'ADMINISTRADOR'
    },
    {
        menu: 'Catalogos',
        id: SLUGS.lognotificaciones,
        title: 'Notificaciones',
        imagen: Desc_masiva,
        onClick: SLUGS.lognotificaciones,
        ADMINISTRADOR: 'ADMINISTRADOR',
        EMPRESA: 'EMPRESA',
    },
    /*{
        menu: 'Catalogos',
        id: SLUGS.planesyprecios,
        title: 'Planes y precios',
        imagen: Pagos,
        onClick: SLUGS.planesyprecios,
        ADMINISTRADOR: 'ADMINISTRADOR',
        EMPRESA: 'EMPRESA',
    },*/
    {
        menu: 'Estadisticas',
        id: SLUGS.est_ingresos_diarios,
        title: 'Ingresos diarios',
        imagen: IngresosDiarios,
        onClick: SLUGS.est_ingresos_diarios,
        ADMINISTRADOR: 'ADMINISTRADOR',
        EMPRESA: 'EMPRESA',
        SUCURSAL: 'SUCURSAL'
    },
    {
        menu: 'Estadisticas',
        id: SLUGS.est_ingresos_mensuales,
        title: 'Ingresos mensuales',
        imagen: IngresosMensuales,
        onClick: SLUGS.est_ingresos_mensuales,
        ADMINISTRADOR: 'ADMINISTRADOR',
        EMPRESA: 'EMPRESA',
        SUCURSAL: 'SUCURSAL'
    },
    {
        menu: 'Estadisticas',
        id: SLUGS.est_egresos_mensuales,
        title: 'Egresos mensuales',
        imagen: EgresosMensuales,
        onClick: SLUGS.est_egresos_mensuales,
        ADMINISTRADOR: 'ADMINISTRADOR',
        EMPRESA: 'EMPRESA',
        SUCURSAL: 'SUCURSAL'
    },
    {
        menu: 'Estadisticas',
        id: SLUGS.est_ingresos_vs_egresos_mensuales,
        title: 'Ingresos vs Egresos mensuales',
        imagen: IngresosEgresos,
        onClick: SLUGS.est_ingresos_vs_egresos_mensuales,
        ADMINISTRADOR: 'ADMINISTRADOR',
        EMPRESA: 'EMPRESA',
        SUCURSAL: 'SUCURSAL'
    },
    {
        menu: 'Estadisticas',
        id: SLUGS.est_top_clientes,
        title: 'Top 10 clientes',
        imagen: TopClientes,
        onClick: SLUGS.est_top_clientes,
        ADMINISTRADOR: 'ADMINISTRADOR',
        EMPRESA: 'EMPRESA',
        SUCURSAL: 'SUCURSAL'
    },
    {
        menu: 'Estadisticas',
        id: SLUGS.est_top_proveedores,
        title: 'Top 10 proveedores',
        imagen: TopProveedores,
        onClick: SLUGS.est_top_proveedores,
        ADMINISTRADOR: 'ADMINISTRADOR',
        EMPRESA: 'EMPRESA',
        SUCURSAL: 'SUCURSAL'
    },
    {
        menu: 'Herramientas',
        id: SLUGS.herramientasSAT,
        title: 'Opinión de cumplimiento',
        imagen: TopProveedores,
        onClick: SLUGS.herramientasSAT,
        ADMINISTRADOR: 'ADMINISTRADOR'
    },
    {
        menu: 'Configuraciones',
        id: SLUGS.empresa,
        title: 'Empresa',
        imagen: Movimientos,
        onClick: SLUGS.empresa,
        ADMINISTRADOR: 'ADMINISTRADOR',
        EMPRESA: 'EMPRESA',
        SUCURSAL: 'SUCURSAL'
    },
    {
        menu: 'Configuraciones',
        id: SLUGS.camposempresa,
        title: 'Campos Empresa',
        imagen: Paquetes,
        onClick: SLUGS.camposempresa,
        ADMINISTRADOR: 'ADMINISTRADOR'
    },
    /*{
        menu: 'Ayuda',
        id: SLUGS.ayuda,
        title: 'Ayuda',
        imagen: Productos,
        onClick: SLUGS.ayuda,
        ADMINISTRADOR: 'ADMINISTRADOR',
        EMPRESA: 'EMPRESA',
        SUCURSAL: 'SUCURSAL'
    },*/
];

function SidebarOptionsComponent( {menu}) {
    const { push } = useHistory();
    const theme = useTheme();
    const classes = useStyles({ theme });
    const isMobile = window.innerWidth <= 1300;
 
    async function logout() {
        removeUserSession();
        push('..'); //SLUGS.login);
    }

    function onClick(slug, parameters = {}) {
        push(convertSlugToUrl(slug, parameters));
    }
    
    function renderSwitch() {
        var rol = getDataSession('rol');
        return lista.map((item) => {
            return (item.menu === menu && item.hasOwnProperty(rol) )  ? (
                <SubMenuItemComponent
                    key={item.id}
                    id={item.id}
                    title={item.title}
                    imagen={item.imagen}
                    onClick={() => onClick(item.id)}
                />
            ) : (
                ''
            );
        });
    }

    function facturar() {
        setDataSession('menu', 'Facturacion');
    }

    return (
        <>
        
           {renderSwitch()}

        </>
    );
}

export default SidebarOptionsComponent;
