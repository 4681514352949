import { fetchConTokenFile,handleResponse } from 'helpers/fetch';
import * as FileSaver from 'file-saver';
import Swal from 'sweetalert2'


const descargarFactura =  (id, ruta, nombre) => {
        fetchConTokenFile( 
            id > 0 ? ruta+`?id=${id}` : ruta
        ).then(response => {
        
            if(response.status == 200)
            {
                return response.blob();
            }
            else{
                handleResponse(response).then(data=>{
                    if (data.hasOwnProperty('status')) {
                        var erroresA = {};
                        Object.entries(data?.errors).map(([key, value]) => {
                            Swal.fire('Error', value[0], 'error');
                            return;
                        });
                        return false;
                    } else if (data.hasOwnProperty('codigo')) {
                        Swal.fire('Error', data.mensajes[0], 'error');
                        return;
                    }
                })
            }
        }).then(function(blob) {
            FileSaver.saveAs(blob, nombre);
            Swal.fire('','Archivo descargado.','success')
        })
    };


    const descargarPDF =  ( ruta, nombre) => {
        fetchConTokenFile( 
            ruta
        ).then(response => {
        
            if(response.status == 200)
            {
                return response.blob();
            }
            else{
                handleResponse(response).then(data=>{
                    if (data.hasOwnProperty('status')) {
                        var erroresA = {};
                        Object.entries(data?.errors).map(([key, value]) => {
                            Swal.fire('Error', value[0], 'error');
                            return;
                        });
                        return false;
                    } else if (data.hasOwnProperty('codigo')) {
                        Swal.fire('Error', data.mensajes[0], 'error');
                        return;
                    }
                })
            }
        }).then(function(blob) {
            FileSaver.saveAs(blob, nombre);
            Swal.fire('','Archivo descargado.','success')
        })
    };

    export {
        descargarPDF,
        descargarFactura
    }