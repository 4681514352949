import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { fetchConToken, fetchConTokenFile } from 'helpers/fetch';
import { getDataSession } from 'views/Utils/Common';
import Pagination from 'components/pagination/Pagination';
import TituloPagina from 'components/header/TituloPagina';
import { alertService } from '_services';
import * as FileSaver from 'file-saver';
import { currency } from 'resources/utilities';

import Table from 'components/table/Table';

const List = ({ match, saludo, subtitulo }) => {
    const { path } = match;

    let [data, setData] = useState([]);
    const [totalxPagina, setTotalxPagina] = useState(10);
    const [busqueda, setbusqueda] = useState('');

    let [pagina, setPagina] = useState('');

    let [errores, setErrores] = useState({});
    let [error, setError] = useState(['']);

    async function getData(page) {
        setPagina(page);
        const response = await fetchConToken(
            `PaquetesTimbres/GetList?paginas=${page}&totalXpagina=${totalxPagina}`
        );
        setData(response);
    }

    async function eliminar(id) {
        setErrores({});
        setError('');
        await fetchConToken(`PaquetesTimbres?paquete_id=${id}`, null, 'DELETE').then((response) => {
            if (response.hasOwnProperty('status')) {
                var erroresA = {};
                Object.entries(response.errors).map(([key, value]) => {
                    erroresA[key] = value[0];
                    setErrores(erroresA);
                });
                return false;
            } else if (response.hasOwnProperty('codigo')) {
                setError(response.mensajes[0]);
                return false;
            }

            alertService.success('Paquete  eliminado', {
                keepAfterRouteChange: true,
                autoClose: true
            });

            getData(pagina);
        });
    }

    React.useEffect(() => {
        getData(1);
    }, []);

    const handleChangeBusqueda = ({ target }) => {
        setbusqueda(target.value);
    };
    return (
        <>
            <div className='container-fluid'>
                <TituloPagina
                    titulo='Paquetes de timbres'
                    descripcion='Aquí se muestran los paquetes de timbres.'
                />
                <div className='col-md-12'>
                    <Link to={`${path}/add`} className='btn btn-outline-success'>
                        <svg
                            xmlns='http://www.w3.org/2000/svg'
                            width='20'
                            height='20'
                            fill='currentColor'
                            className='bi bi-plus-square'
                            viewBox='0 0 16 16'
                        >
                            <path d='M14 1a1 1 0 0 1 1 1v12a1 1 0 0 1-1 1H2a1 1 0 0 1-1-1V2a1 1 0 0 1 1-1h12zM2 0a2 2 0 0 0-2 2v12a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2V2a2 2 0 0 0-2-2H2z' />
                            <path d='M8 4a.5.5 0 0 1 .5.5v3h3a.5.5 0 0 1 0 1h-3v3a.5.5 0 0 1-1 0v-3h-3a.5.5 0 0 1 0-1h3v-3A.5.5 0 0 1 8 4z' />
                        </svg>
                        &nbsp; Nuevo
                    </Link>
                    &nbsp; &nbsp;
                </div>
                &nbsp;
                <div className='col-12 col-sm-12 col-md-12 '>
                    <p style={{ color: 'red' }}>{error}</p>
                    {Object.entries(errores).map(([key, value]) => {
                        return (
                            <>
                                <p style={{ color: 'red' }}>{value}</p>
                            </>
                        );
                    })}
                </div>
               
                <div className='card col-12 col-sm-12 col-md-12'>
                    <div style={{ overflowX: 'auto' }}>
                        <Table
                            columns={[
                                'Paquete',
                                'Descripcion',
                                'Costo',
                                'Tipo Paquete',
                                'Dias Duracion',
                                'Acciones'
                            ]}
                            rows={[
                                ['text', 'nombre'],
                                ['text', 'descripcion'],
                                ['moneda', 'costo'],
                                ['text', 'tipo_Paquete'],
                                ['text', 'dias_Duracion'],
                                ['opciones','id']
                            ]}
                            opciones={"edit"}
                            path={path}
                            url={`/PaquetesTimbres/List?sucursal_Id=${getDataSession('sucursal')}`}
                        ></Table>
                    </div>
                </div>
            </div>
        </>
    );
};

List.propTypes = {
    saludo: PropTypes.string
};

List.defaultProps = {
    subtitulo: 'Soy subtitulo'
};

export { List };
