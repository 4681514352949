import React, { useState } from 'react';
import Chart from 'react-apexcharts'
import {COLORS,COLOR_1} from './Constants'
import { fetchConToken } from 'helpers/fetch';
import { moneyFormat,numberFormat } from 'helpers/formats';
import { getDataSession } from 'views/Utils/Common';
import moment from 'moment';
import './styles.css';

const IngresosMensuales = () => {

    let [series, setSeries] = useState([]);
    let [options, setOptions] = useState({});
    let [seriesTotales, setSeriesTotales] = useState([]);
    let [optionsTotales, setOptionsTotales] = useState({});
    let [ingresos, setIngresos] = useState({});
    let [esteMes, setEsteMes] = useState(0);
    let [anteriorMes, setAnteriorMes] = useState(0);
    let [esteMesNombre, setEsteMesNombre] = useState([]);
    let [anteriorMesNombre, setAnteriorMesNombre] = useState([]);

    React.useEffect(() => {
        getDataIngresos()
    }, []);

    const getDataIngresos = async () => {
        const date = new Date();
        const year = date.getFullYear()
        const sucursal = getDataSession('sucursal')
        const start = year + '-01-01'
        const end = year + '-12-31'
        const mesIndex = moment().format('M')
        const mesAnterior = year + '-' + (mesIndex -1) + '-01'
        const mesNombre = moment().format('MMM')
        const mesAnteriorNombre = moment(mesAnterior,'Y-MM-DD').format('MMM')
        const meses = ['Enero','Febrero','Marzo','Abril','Mayo','Junio','Julio','Agosto','Septiembre','Octubre','Noviembre','Diciembre']
        const response = await fetchConToken(
            'Dashboard/estadisticaXrango?tipo_factura=E&fechainicio='+start+'&fechafin='+end+'&sucursal_id='+sucursal
        );

        let dataMeses = [
            {
                nombre: 'Jan',
                value: 0
            },
            {
                nombre: 'Feb',
                value: 0
            },
            {
                nombre: 'Mar',
                value: 0
            },
            {
                nombre: 'Apr',
                value: 0
            },
            {
                nombre: 'May',
                value: 0
            },
            {
                nombre: 'Jun',
                value: 0
            },
            {
                nombre: 'Jul',
                value: 0
            },
            {
                nombre: 'Aug',
                value: 0
            },
            {
                nombre: 'Sep',
                value: 0
            },
            {
                nombre: 'Oct',
                value: 0
            },
            {
                nombre: 'Nov',
                value: 0
            },
            {
                nombre: 'Dec',
                value: 0
            }
        ]
        let array = []

        if(response.dias !== undefined)
        {
            response.dias.map((item,i)=>{
                if(moment(item.fecha).format('MMM') === 'Jan')
                    dataMeses[0].value += item.total
                else if(moment(item.fecha).format('MMM') === 'Feb')
                    dataMeses[1].value += item.total
                else if(moment(item.fecha).format('MMM') === 'Mar')
                    dataMeses[2].value += item.total
                else if(moment(item.fecha).format('MMM') === 'Apr')
                    dataMeses[3].value += item.total
                else if(moment(item.fecha).format('MMM') === 'May')
                    dataMeses[4].value += item.total
                else if(moment(item.fecha).format('MMM') === 'Jun')
                    dataMeses[5].value += item.total
                else if(moment(item.fecha).format('MMM') === 'Jul')
                    dataMeses[6].value += item.total
                else if(moment(item.fecha).format('MMM') === 'Aug')
                    dataMeses[7].value += item.total
                else if(moment(item.fecha).format('MMM') === 'Sep')
                    dataMeses[8].value += item.total
                else if(moment(item.fecha).format('MMM') === 'Oct')
                    dataMeses[9].value += item.total
                else if(moment(item.fecha).format('MMM') === 'Nov')
                    dataMeses[10].value += item.total
                else if(moment(item.fecha).format('MMM') === 'Dec')
                    dataMeses[11].value += item.total
            })
            
            dataMeses.map((item,i)=>{
                console.log(item.value,numberFormat(item.value))
                array.push(numberFormat(item.value))
                if(item.nombre === mesNombre){
                    esteMes = numberFormat(item.value)
                    setEsteMes(item.value)
                    esteMesNombre = meses[i]
                    setEsteMesNombre(meses[i])
                }
                else if(item.nombre === mesAnteriorNombre){
                    anteriorMes = numberFormat(item.value)
                    setAnteriorMes(item.value)
                    anteriorMesNombre = meses[i]
                    setAnteriorMesNombre(meses[i])
                }
            })
        }

        setSeries([{
            name: 'Ingresos mensuales',
            data: array
        }])
    
        setOptions({
            chart: {
				height: 350,
				type: 'line',
				zoom: {
					enabled: false
				},
			},
            colors: COLORS,
            stroke: {
                show: true,
                dashArray: 5,
                width: 1,
            },
            xaxis: {
                categories: meses,
            },
            dataLabels: {
                enabled: true,
                offsetX: -6,
                style: {
                    fontSize: '10px',
                    colors: ['#fff']
                },
                background: {
                    enabled: true,
                    foreColor: '#fff',
                    padding: 1,
                    borderRadius: 9,
                    borderWidth: 3,
                    borderColor: COLOR_1,
                    opacity: 0.9,
                  },    
            },
        })

        setIngresos({
            total: response.total,
            actual: esteMes,
            anterior: anteriorMes
        })
        setSeriesTotales([{
            name: anteriorMesNombre,
            data: [anteriorMes]
        },
        {
            name: esteMesNombre,
            data: [esteMes]
        }])
        setOptionsTotales({
            plotOptions: {
                bar: {
                    horizontal: false,
                    dataLabels: {
                        position: 'bottom',
                    }
                }
            },
            colors: COLORS,
            dataLabels: {
                enabled: true
            },
            xaxis: {
                categories: [''],
            },
            legend: {
                position: 'bottom',
                horizontalAlign: 'center',
                offsetX: 40
            },
        })
    }

    return (
        <div className='row contentEst' >
            <div className='col-md-8'>
                <div className='cardGraph'>
                    <h1 className='colorDarkBlue'>Ingresos mensuales</h1>
                    <p>Gráfico de ingresos mensuales</p>
                    <span className='divider'></span>
                    {
                        ingresos !== {} 
                        ?
                        <div className='blockNumbers'>
                            <div className='itemNumber'>
                                <p>Totales</p>
                                <p className='positionRelative'>{ ingresos.total !== undefined ? moneyFormat(ingresos.total) : '$0.00' } <span className='spanBlue'>MXN</span></p>
                            </div>
                            <div className='itemNumber'>
                                <p>Mes actual</p>
                                <p className='positionRelative'>{ moneyFormat(ingresos.actual) }  <span className='spanBlue'>MXN</span></p>
                            </div>
                            <div className='itemNumber'>
                                <p>Mes anterior</p>
                                <p className='positionRelative'>{ moneyFormat(ingresos.anterior) }  <span className='spanBlue'>MXN</span></p>
                            </div>
                        </div>
                    : ''
                    }
                    
                    {
                        series.length > 0 && options !== {}
                        ?
                        <Chart
                            options={options}
                            series={series}
                            height= {350}
                        />
                        : ''
                    }
                </div>
            </div>
            <div className='col-md-4'>
                <div className='cardGraph'>
                    <h1 className='colorDarkBlue'>Totales</h1>
                    <p>Resumen mensual de ingresos</p>
                    {
                        seriesTotales.length > 0 && optionsTotales !== {}
                        ?
                        <Chart
                            options={optionsTotales}
                            series={seriesTotales}
                            height= {350}
                            type="bar"
                        />
                        : ''
                    }
                </div>
            </div>
        </div>
    )
}
export {IngresosMensuales};