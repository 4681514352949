import React, { useState } from 'react';
import Chart from 'react-apexcharts'
import {COLORS} from './Constants'
import { fetchConToken } from 'helpers/fetch';
import { moneyFormat } from 'helpers/formats';
import { getDataSession } from 'views/Utils/Common';
import moment from 'moment';
import './styles.css'; 

const IngresosVsEgresosMensuales = () => {

    let [series, setSeries] = useState([]);
    let [options, setOptions] = useState({});
    let [seriesTotales, setSeriesTotales] = useState([]);
    let [optionsTotales, setOptionsTotales] = useState({});
    let [ingresos, setIngresos] = useState({});
    let [esteMes, setEsteMes] = useState(0);
    let [anteriorMes, setAnteriorMes] = useState(0);
    let [esteMesNombre, setEsteMesNombre] = useState([]);
    let [anteriorMesNombre, setAnteriorMesNombre] = useState([]);

    React.useEffect(() => {
        getDataIngresosEgresos()
    }, []);

    const getDataIngresosEgresos = async () =>{
        const date = new Date();
        const year = date.getFullYear()
        const sucursal = getDataSession('sucursal')
        const start = year + '-01-01'
        const end = year + '-12-31'
        const mesIndex = moment().format('M')
        const mesAnterior = year + '-' + (mesIndex -1) + '-01'
        const mesNombre = moment().format('MMM')
        const mesAnteriorNombre = moment(mesAnterior,'Y-MM-DD').format('MMM')
        const meses = ['Enero','Febrero','Marzo','Abril','Mayo','Junio','Julio','Agosto','Septiembre','Octubre','Noviembre','Diciembre']
        const responseIngreso = await fetchConToken(
            'Dashboard/estadisticaXrango?tipo_factura=E&fechainicio='+start+'&fechafin='+end+'&sucursal_id='+sucursal
        );
        const responseEgreso = await fetchConToken(
            'Dashboard/estadisticaXrango?tipo_factura=R&fechainicio='+start+'&fechafin='+end+'&sucursal_id='+sucursal
        );

        let dataMeses = [
            {
                nombre: 'Jan',
                ingreso: 0,
                egreso: 0
            },
            {
                nombre: 'Feb',
                ingreso: 0,
                egreso: 0
            },
            {
                nombre: 'Mar',
                ingreso: 0,
                egreso: 0
            },
            {
                nombre: 'Apr',
                ingreso: 0,
                egreso: 0
            },
            {
                nombre: 'May',
                ingreso: 0,
                egreso: 0
            },
            {
                nombre: 'Jun',
                ingreso: 0,
                egreso: 0
            },
            {
                nombre: 'Jul',
                ingreso: 0,
                egreso: 0
            },
            {
                nombre: 'Aug',
                ingreso: 0,
                egreso: 0
            },
            {
                nombre: 'Sep',
                ingreso: 0,
                egreso: 0
            },
            {
                nombre: 'Oct',
                ingreso: 0,
                egreso: 0
            },
            {
                nombre: 'Nov',
                ingreso: 0,
                egreso: 0
            },
            {
                nombre: 'Dec',
                ingreso: 0,
                egreso: 0
            }
        ]
        let arrayIngreso = []
        let arrayEgreso = []
        let sumIngresos = 0
        if(responseIngreso.dias !== undefined)
        {
            responseIngreso.dias.map((item,i)=>{
                sumIngresos += item.total
                if(moment(item.fecha).format('MMM') === 'Jan')
                    dataMeses[0].ingreso += item.total
                else if(moment(item.fecha).format('MMM') === 'Feb')
                    dataMeses[1].ingreso += item.total
                else if(moment(item.fecha).format('MMM') === 'Mar')
                    dataMeses[2].ingreso += item.total
                else if(moment(item.fecha).format('MMM') === 'Apr')
                    dataMeses[3].ingreso += item.total
                else if(moment(item.fecha).format('MMM') === 'May')
                    dataMeses[4].ingreso += item.total
                else if(moment(item.fecha).format('MMM') === 'Jun')
                    dataMeses[5].ingreso += item.total
                else if(moment(item.fecha).format('MMM') === 'Jul')
                    dataMeses[6].ingreso += item.total
                else if(moment(item.fecha).format('MMM') === 'Aug')
                    dataMeses[7].ingreso += item.total
                else if(moment(item.fecha).format('MMM') === 'Sep')
                    dataMeses[8].ingreso += item.total
                else if(moment(item.fecha).format('MMM') === 'Oct')
                    dataMeses[9].ingreso += item.total
                else if(moment(item.fecha).format('MMM') === 'Nov')
                    dataMeses[10].ingreso += item.total
                else if(moment(item.fecha).format('MMM') === 'Dec')
                    dataMeses[11].ingreso += item.total
            })
            
            dataMeses.map((item,i)=>{
                arrayIngreso.push(item.ingreso)
                if(item.nombre === mesNombre){
                    esteMes = item.ingreso
                    setEsteMes(item.ingreso)
                    esteMesNombre = meses[i]
                    setEsteMesNombre(meses[i])
                }
                else if(item.nombre === mesAnteriorNombre){
                    anteriorMes = item.ingreso
                    setAnteriorMes(item.ingreso)
                    anteriorMesNombre = meses[i]
                    setAnteriorMesNombre(meses[i])
                }
            })
        }

        let sumEgresos = 0
        if(responseEgreso.dias !== undefined)
        {
            responseEgreso.dias.map((item,i)=>{
                sumEgresos += item.total
                if(moment(item.fecha).format('MMM') === 'Jan')
                    dataMeses[0].egreso += item.total
                else if(moment(item.fecha).format('MMM') === 'Feb')
                    dataMeses[1].egreso += item.total
                else if(moment(item.fecha).format('MMM') === 'Mar')
                    dataMeses[2].egreso += item.total
                else if(moment(item.fecha).format('MMM') === 'Apr')
                    dataMeses[3].egreso += item.total
                else if(moment(item.fecha).format('MMM') === 'May')
                    dataMeses[4].egreso += item.total
                else if(moment(item.fecha).format('MMM') === 'Jun')
                    dataMeses[5].egreso += item.total
                else if(moment(item.fecha).format('MMM') === 'Jul')
                    dataMeses[6].egreso += item.total
                else if(moment(item.fecha).format('MMM') === 'Aug')
                    dataMeses[7].egreso += item.total
                else if(moment(item.fecha).format('MMM') === 'Sep')
                    dataMeses[8].egreso += item.total
                else if(moment(item.fecha).format('MMM') === 'Oct')
                    dataMeses[9].egreso += item.total
                else if(moment(item.fecha).format('MMM') === 'Nov')
                    dataMeses[10].egreso += item.total
                else if(moment(item.fecha).format('MMM') === 'Dec')
                    dataMeses[11].egreso += item.total
            })
            
            dataMeses.map((item,i)=>{
                arrayEgreso.push(item.egreso)
            })
        }
        setSeries([{
            name: 'Ingresos mensuales',
            data: arrayIngreso
        },
        {
            name: 'Egresos mensuales',
            data: arrayEgreso
        }])
    
        /*setOptions({
            chart: {
				height: 350,
				type: 'line',
				zoom: {
					enabled: false
				},
			},
            colors: COLORS,
            xaxis: {
                categories: meses,
            },
            dataLabels: {
                enabled: true,
                offsetX: -6,   
            },
        })*/



        setOptions({
            chart: {
              id: 'line-chart',
            },
            xaxis: {
              categories: ['Ene', 'Feb', 'Mar', 'Abr', 'May', 'Jun', 'Jul', 'Ago', 'Sep', 'Oct', 'Nov', 'Dic'],
              labels: {
                style: {
                  colors: '#000000', // Color negro para las etiquetas del eje x
                },
              },
              title: {
                text: 'Meses',
                style: {
                  color: '#000000', // Color negro para el título del eje x
                },
              },
            },
            yaxis: {
              labels: {
                formatter: function (value) {
                  return '$' + new Intl.NumberFormat('es-ES').format(value);
                },
                style: {
                  colors: '#000000', // Color negro para las etiquetas del eje y
                },
              },
              title: {
                text: '($)',
                style: {
                  color: '#000000', // Color negro para el título del eje y
                },
              },
            },
            legend: {
              labels: {
                colors: '#000000', // Color negro para las etiquetas de la leyenda
              },
            },
            tooltip: {
              y: {
                formatter: function (value) {
                  return '$' + new Intl.NumberFormat('es-ES').format(value);
                },
              },
              style: {
                fontSize: '12px',
                colors: ['#000000'], // Color negro para el texto del tooltip
              },
            },
            dataLabels: {
              enabled: true,
              formatter: function (value) {
                return '$' + new Intl.NumberFormat('es-ES').format(value);
              },
              style: {
                colors: ['#000000'], // Color negro para las etiquetas de datos
              },
            },
            title: {
              text: '',
              style: {
                color: '#000000', // Color negro para el título principal de la gráfica
              },
            },
          });

        setIngresos({
            total: responseIngreso.total,
            actual: esteMes,
            anterior: anteriorMes
        })

        setSeriesTotales([{
            name: 'Ingresos',
            data: [sumIngresos]
        },
        {
            name: 'Egresos',
            data: [sumEgresos]
        }])
    
        setOptionsTotales({
            plotOptions: {
                bar: {
                    horizontal: false,
                    dataLabels: {
                        position: 'bottom',
                    }
                }
            },
            colors: COLORS,
            dataLabels: {
                enabled: true
            },
            xaxis: {
                categories: [''],
            },
            legend: {
                position: 'bottom',
                horizontalAlign: 'center',
                offsetX: 40
            },
            yaxis: {
                labels: {
                  formatter: function (value) {
                    return '$' + new Intl.NumberFormat('es-ES').format(value);
                  },
                  style: {
                    colors: '#000000', // Color negro para las etiquetas del eje y
                  },
                },
                title: {
                  text: '($)',
                  style: {
                    color: '#000000', // Color negro para el título del eje y
                  },
                },
              },
              tooltip: {
                y: {
                  formatter: function (value) {
                    return '$' + new Intl.NumberFormat('es-ES').format(value);
                  },
                },
                style: {
                  fontSize: '12px',
                  colors: ['#000000'], // Color negro para el texto del tooltip
                },
              },
              dataLabels: {
                enabled: true,
                formatter: function (value) {
                  return '$' + new Intl.NumberFormat('es-ES').format(value);
                },
                style: {
                  colors: ['#000000'], // Color negro para las etiquetas de datos
                },
              },
        });

       /* setOptionsTotales({
            chart: {
              id: 'line-chart',
            },
            xaxis: {
              categories: ['Ene', 'Feb', 'Mar', 'Abr', 'May', 'Jun', 'Jul', 'Ago', 'Sep', 'Oct', 'Nov', 'Dic'],
              labels: {
                style: {
                  colors: '#000000', // Color negro para las etiquetas del eje x
                },
              },
              title: {
                text: 'Meses',
                style: {
                  color: '#000000', // Color negro para el título del eje x
                },
              },
            },
            yaxis: {
              labels: {
                formatter: function (value) {
                  return '$' + new Intl.NumberFormat('es-ES').format(value);
                },
                style: {
                  colors: '#000000', // Color negro para las etiquetas del eje y
                },
              },
              title: {
                text: '($)',
                style: {
                  color: '#000000', // Color negro para el título del eje y
                },
              },
            },
            legend: {
              labels: {
                colors: '#000000', // Color negro para las etiquetas de la leyenda
              },
            },
            tooltip: {
              y: {
                formatter: function (value) {
                  return '$' + new Intl.NumberFormat('es-ES').format(value);
                },
              },
              style: {
                fontSize: '12px',
                colors: ['#000000'], // Color negro para el texto del tooltip
              },
            },
            dataLabels: {
              enabled: true,
              formatter: function (value) {
                return '$' + new Intl.NumberFormat('es-ES').format(value);
              },
              style: {
                colors: ['#000000'], // Color negro para las etiquetas de datos
              },
            },
            title: {
              text: '',
              style: {
                color: '#000000', // Color negro para el título principal de la gráfica
              },
            },
          });*/
    }

    return (
        <div className='row contentEst' >
            <div className='col-md-8'>
                <div className='cardGraph'>
                    <h1 className='colorDarkBlue'>Ingresos & Egresos mensuales</h1>
                    <p>Gráfico de ingresos y egresos mensuales</p>
                    <span className='divider'></span>
                    <div className='blockNumbers'>
                        <div className='itemNumber'>
                            <p>Totales</p>
                            <p className='positionRelative'>{ ingresos.total !== undefined ? moneyFormat(ingresos.total) : '$0.00' }  <span className='spanBlue'>MXN</span></p>
                        </div>
                        <div className='itemNumber'>
                            <p>Mes actual</p>
                            <p className='positionRelative'>{ moneyFormat(ingresos.actual) } <span className='spanBlue'>MXN</span></p>
                        </div>
                        <div className='itemNumber'>
                            <p>Mes anterior</p>
                            <p className='positionRelative'>{ moneyFormat(ingresos.anterior) } <span className='spanBlue'>MXN</span></p>
                        </div>
                    </div>
                    <Chart
                        options={options}
                        series={series}
                        height= {350}
                        type='bar'
                    />
                </div>
            </div>
            <div className='col-md-4'>
                <div className='cardGraph'>
                    <h1 className='colorDarkBlue'>Totales</h1>
                    <p>Resumen mensual de ingresos y egresos</p>
                    <Chart
                        options={optionsTotales}
                        series={seriesTotales}
                        height= {350}
                        type="bar"
                    />
                </div>
            </div>
        </div>
    )
}
export {IngresosVsEgresosMensuales};