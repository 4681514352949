import React from 'react';
import { Redirect, Route, Switch } from "react-router";
import { Principal } from "./Principal";
import { Ticket } from "./Ticket";
import { Facturar } from "./Facturar";
import { Descargar } from "./Descargar";

function Facturacion({ match }) {
    const { path } = match;
    console.log(match);
    return (
        <Switch>
            <Route exact path={path} component={Ticket} />
            <Route path={`${path}/ticket`} component={Ticket} />
            <Route path={`${path}/facturacion/:id`} component={Facturar} />
            <Route path={`${path}/descargar`} component={Descargar} />
            <Redirect from="*" to={{
                                pathname: Ticket
                            }}/>

        </Switch>
    );
}

export { Facturacion };
