import React, { useState } from 'react';
import PropTypes from 'prop-types';

const Principal = ({ history, match, saludo, subtitulo }) => {


    return (
        <>

        </>
    );
};

Principal.propTypes = {
    saludo: PropTypes.string
};

Principal.defaultProps = {
    subtitulo: 'Soy subtitulo'
};

export { Principal };
