import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { fetchConToken, fetchConTokenFile,handleResponse } from 'helpers/fetch';
import { getDataSession } from 'views/Utils/Common';
import Pagination from 'components/pagination/Pagination';
import { descargarFactura } from 'actions/archivos';
import TituloPagina from 'components/header/TituloPagina';
import Table from 'components/table/Table'
import Modal from "components/modal/Modal";
import { useModal } from "hooks/useModal"
import Swal from 'sweetalert2'
import moment from 'moment';

const $ = require('jquery')

const ListComplementos = ({ match, saludo, subtitulo }) => {
    const { path } = match;
    const [isOpenModal, openModal, closeModal] = useModal(false);
    const [PDF,setPDF] = useState(['']);

    let [data, setData] = useState([]);
    const [totalxPagina, setTotalxPagina] = useState(10);
    const [busqueda, setbusqueda] = useState('');
    const baseUrl = process.env.REACT_APP_API_URL;
    let [mes, setMes] = useState('');
    let [ejercicio, setEjercicio] = useState(moment().format('Y'));
    let [años, setAños] = useState([]);

    let [estatusSat, setEstatusSat] = useState('T');
    let [estatusPago, setEstatusPago] = useState('T');

    let [uuid, setUuid] = useState('');

    let [montoTotal, setMontoTotal] = useState('');

    async function getData(page) {
        const response = await fetchConToken(
            `/Cfdis/List?sucursal_Id=${getDataSession('sucursal')}&tipo_factura=E&mes=${mes}&ejercicio=${ejercicio}&estatusSat=${estatusSat}&estatusPago=${estatusPago}&tipo_comprobante=P&uuidBusqueda=${uuid}`
        );
        setData(response);
    }

    React.useEffect(() => {
        getData(1);
        montoTotalFunc();
        $("table").on("click", ".btn-visualizar", function (event) {
            var id = $(this).attr("data-id");
            var ruta = $(this).attr("data-ruta");
            var nombre = $(this).attr("data-nombre");
            verFactura(id,ruta,nombre);
        })
        let year = moment().format('Y')
        const beforeYear = year - 10
        for(let x = year; x >= beforeYear; x--)
        {
            años.push(x);           
        }
    }, []);

    const verFactura =  (id,url,nombre) => {

        url = url + '?id=' + id
        fetchConTokenFile(url)
        .then(response => {
        
            if(response.status == 200)
            {
                return response.blob()
            }
            else{
                handleResponse(response).then(data=>{
                    if (data.hasOwnProperty('status')) {
                        var erroresA = {};
                        Object.entries(data?.errors).map(([key, value]) => {
                            Swal.fire('Error', value[0], 'error');
                            return;
                        });
                        return false;
                    } else if (data.hasOwnProperty('codigo')) {
                        Swal.fire('Error', data.mensajes[0], 'error');
                        return;
                    }
                })
            }
        })
        .then(function(blob) {
            const fileURL = URL.createObjectURL(blob);
            setPDF(fileURL);
            openModal()
          })
    };

    const filtrarPorFecha = () => {
        let table = $('#table-js').DataTable();
        montoTotalFunc();
        table.ajax.url(baseUrl+`/Cfdis/List?sucursal_Id=${getDataSession('sucursal')}&tipo_factura=E&mes=${mes}&ejercicio=${ejercicio}&estatusSat=${estatusSat}&estatusPago=${estatusPago}&tipo_comprobante=P&uuidBusqueda=${uuid}`).load();
    }

    const onChangeEjercicio = (e) =>{
        ejercicio = e.target.value
        setEjercicio(ejercicio)
        filtrarPorFecha()
    }

    const onChangeMes = (e) =>{
        mes = e.target.value
        setMes(mes)
        filtrarPorFecha()
    }

    const filtrarEstatusSat = (e) => {
        estatusSat = e.target.value;
        setEstatusSat(e.target.value);
        filtrarPorFecha();
        
    }

    const filtrarUuid = (e) => {
        uuid = e.target.value;
        setUuid(e.target.value);
        filtrarPorFecha();
        
    }

    


    const montoTotalFunc = () => {

        fetchConToken(`Cfdis/MontoTotalFacturasFiltro?sucursal_Id=${getDataSession('sucursal')}&tipo_factura=E&mes=${mes}&ejercicio=${ejercicio}&estatusSat=${estatusSat}&estatusPago=${estatusPago}&tipo_comprobante=P&uuidBusqueda=${uuid}`).then(function (response) {
            if (response.hasOwnProperty('status')) {
                var erroresA = {};
                Object.entries(response.errors).map(([key, value]) => {
                    Swal.fire('Error', value[0], 'error');
                    return;
                });
                return false;
            } else if (response.hasOwnProperty('codigo') && response.codigo !== 200) {
                Swal.fire('Error', response.mensajes[0], 'error');
                return false;
            }
            setMontoTotal(response.montoTotal);
        });

    }



    return (
        <>
            <div className='container-fluid'>
                <TituloPagina 
                    titulo='Complementos de pago' 
                    descripcion='Aquí se guarda el historial de facturas que has emitido a lo largo de periodos de tiempo, ajusta los filtros y aplica tu busqueda' 
                />
                <div className='col-md-12 row '>
                    <div className='col-md-3'>
                    <div className='d-flex'>
                        <label className='me-1'>Ejercicio</label>
                        <select className='selectCustomTwo m-2 w-100' onChange={onChangeEjercicio}>
                            {
                                años.map((item,i)=>(
                                    <option key={i} value={item}>{item}</option>
                                ))
                            }
                        </select> 
                    </div>
                    <div className='d-flex'>
                        <label className='me-1'>Periodo</label>
                        <select className='selectCustomTwo m-2 w-100' onChange={onChangeMes}>
                            <option value=''>Todos</option>
                            <option value='01'>Enero</option>
                            <option value='02'>Febrero</option>
                            <option value='03'>Marzo</option>
                            <option value='04'>Abril</option>
                            <option value='05'>Mayo</option>
                            <option value='06'>Junio</option>
                            <option value='07'>Julio</option>
                            <option value='08'>Agosto</option>
                            <option value='09'>Septiembre</option>
                            <option value='10'>Octubre</option>
                            <option value='11'>Noviembre</option>
                            <option value='12'>Diciembre</option>
                        </select>
                    </div>
                    </div>
                    <div className='col-md-4'>
                        <div className='d-flex'>
                            <label className='me-1'>Estatus SAT:</label>
                            <select className='selectCustomEstatusSat m-2 w-100' onChange={filtrarEstatusSat}>
                                <option value='T'>Todos</option>
                                <option value='V'>Vigente</option>
                                <option value='C'>Cancelado</option>
                            </select>
                        </div>
                    </div>
                    <div className='col-md-5'>
                        <div className='d-flex'>
                            <label className='me-1'>UUID de la Factura principal:</label>
                            <input
                                        type='text'
                                        className='form-control'
                                        name='uuid'
                                        value={uuid}
                                        onChange={filtrarUuid}
                                    />
                        </div>
                    </div>
                </div>
                <div className='col-md-12' >
                        <Table 
                        columns = {['Fecha','Folio','RFC','Nombre','Uso CFDI','Total','Estatus Cancelacion','Mensaje Cancelacion','Acciones'/*,'Correo'*/]}
                        rows = {[
                                ['fecha','fecha_Timbrado'],
                                ['text','folio'],
                                ['text','rfc_Receptor'],
                                ['text','nombre_Receptor'],
                                ['text','uso_Cfdi'],
                                ['moneda','total'],
                                ['texto-largo','estatus_Uuid_Cancelacion'],
                                ['texto-largo','mensaje_Cancelacion'],
                                ['opciones','id'] 
                                /*['correo','id']*/
                        ]}
                        opciones={"viewPdf,pdf,xml,cancelacionCfdi,estatusCfdi"}
                        ruta = "Facturacion"
                        path = {path}
                        url={`/Cfdis/List?sucursal_Id=${getDataSession('sucursal')}&tipo_factura=E&tipo_comprobante=P&uuidBusqueda=${uuid}`}>
                        </Table>
                </div>
                <div className='col-md-12 text-center' style={{paddingBotton: "50px;"}}>
                    <label>Total: {montoTotal}</label>
                </div>
            </div>
            <Modal isOpen={isOpenModal} closeModal={closeModal}>
                <> 
                    <div className="row" style={{padding: '21px'}}>
                    <embed
                        src={PDF}
                        type="application/pdf"
                        height={800}
                        width={500}
                    />
                    </div>
                </>
            </Modal>
        </>
    );
};

ListComplementos.propTypes = {
    saludo: PropTypes.string
};

ListComplementos.defaultProps = {
    subtitulo: 'Soy subtitulo'
};

export { ListComplementos };
