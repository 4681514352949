export const COLOR_1 = '#232ED1';  // blue
export const COLOR_2 = '#3e82f7';  // blue
export const COLOR_3 = '#ff6b72';  // volcano
export const COLOR_4 = '#ffc107';  // gold
export const COLOR_5 = '#a461d8';  // purple
export const COLOR_6 = '#fa8c16';  // orange
export const COLOR_7 = '#17bcff';  // geekblue
    
export const COLORS = [
        COLOR_1,
        COLOR_2,
        COLOR_3,
        COLOR_4,
        COLOR_5,
        COLOR_6,
        COLOR_7
    ];