import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { fetchConToken, fetchConTokenFile } from 'helpers/fetch';
import { getDataSession } from 'views/Utils/Common';
import Pagination from 'components/pagination/Pagination';
import TituloPagina from 'components/header/TituloPagina';
import { alertService } from '_services';
import * as FileSaver from 'file-saver';
import { useParams } from 'react-router-dom';
import { currency } from 'resources/utilities';

import AmericanExpress from 'images/panel/carrito/forma_pago/AmericanExpress.svg'
import MasterCard from 'images/panel/carrito/forma_pago/MasterCard.svg'
import Visa from 'images/panel/carrito/forma_pago/Visa.svg'
import MercadoPago from 'images/panel/carrito/forma_pago/MercadoPago.svg'
import Paypal from 'images/panel/carrito/forma_pago/PayPal.svg'
import Transferencia from 'images/panel/carrito/forma_pago/Transferencia.svg'
import Logo from 'images/panel/carrito/forma_pago/Logo.svg'

import { descargarFactura } from 'actions/archivos';



const DetalleOperacion = ({ match, saludo, subtitulo }) => {

    //console.log(match.params);
    const valores = window.location.search;
    //Creamos la instancia
    const urlParams = new URLSearchParams(valores);
    //Accedemos a los valores
    var collection_id = urlParams.get('collection_id');
    var collection_status = urlParams.get('collection_status');
    var payment_id = urlParams.get('payment_id');
    var status = urlParams.get('status');
    var external_reference = urlParams.get('external_reference');
    var payment_type = urlParams.get('payment_type');
    var merchant_order_id = urlParams.get('merchant_order_id');
    var preference_id = urlParams.get('preference_id');
    var site_id = urlParams.get('site_id');
    var processing_mode = urlParams.get('processing_mode');
    var merchant_account_id = urlParams.get('merchant_account_id');

    let [data, setData] = useState([]);

    let [error, setError] = useState(['']);
    let [erroresV, setErroresV] = useState({});

    function descargaRecibo(){
        descargarFactura(0,'carritos/DescargarRecibo?cobro_id='+payment_id,payment_id+".pdf");
    }


    async function getData() {



        const response = await fetchConToken(`MercadoPago/ReciboPago?collection_id=${collection_id}&collection_status=${collection_status}&payment_id=${payment_id}&status=${status}&external_reference=${external_reference}&payment_type=${payment_type}&merchant_order_id=${merchant_order_id}&preference_id=${preference_id}&site_id=${site_id}&processing_mode=${processing_mode}&merchant_account_id=${merchant_account_id}`,null,'POST');
    
        setErroresV({});
        setError('');

        if (response.hasOwnProperty('status') ) {
            var errores = {};
            Object.entries(response.errors).map(([key, value]) => {
                errores[key] = value[0];
                setErroresV(errores);
            });
            setError('Revisar errores');
            return false;
        } else if (response.hasOwnProperty('codigo') ) {
            setError(response.mensajes[0]);
            return false;
        }

        setData(response);

    }

    React.useEffect(() => {
        getData();
    }, []);


    return (
        <>
            <div className='container-fluid'>
            <TituloPagina 
                titulo='Finalizar compra ' 
                 descripcion='' />
                <div className='col-md-12 row'>
                    <div className='col-md-12 text-center' style={{background: '#232ED1', color: '#fff', paddingTop: '50px', paddingBottom: '50px', marginBottom: '30px',borderRadius: '8px' }}>
                       {error?.length === 0 ? 
                         <>
                            <svg xmlns="http://www.w3.org/2000/svg" width="30" height="30" fill="currentColor" className="bi bi-check-circle-fill" viewBox="0 0 16 16">
                            <path d="M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0zm-3.97-3.03a.75.75 0 0 0-1.08.022L7.477 9.417 5.384 7.323a.75.75 0 0 0-1.06 1.06L6.97 11.03a.75.75 0 0 0 1.079-.02l3.992-4.99a.75.75 0 0 0-.01-1.05z"/>
                            </svg>
                            <br></br>
                            <h4>¡Pago realizado con éxito !</h4> 
                         </> : 
                         <>
                            <svg xmlns="http://www.w3.org/2000/svg" width="30" height="30" fill="currentColor" className="bi bi-x-circle-fill" viewBox="0 0 16 16" color='red'>
                            <path d="M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0zM5.354 4.646a.5.5 0 1 0-.708.708L7.293 8l-2.647 2.646a.5.5 0 0 0 .708.708L8 8.707l2.646 2.647a.5.5 0 0 0 .708-.708L8.707 8l2.647-2.646a.5.5 0 0 0-.708-.708L8 7.293 5.354 4.646z"/>
                            </svg>
                            <br></br>
                            <h4>¡Ocurrio un error al registrar el pago !</h4> 
                         </>
                        }
                    </div>
                    <div className='col-md-8' >
                    <div className='card mb-3 ' style={{boxShadow: '0px 0px 14px rgb(30 56 136 / 50%)'}}>
                        <div className='card-body text-start'>
                        <p style={{ color: 'red' }}>{error}</p>
                            {Object.entries(erroresV).map(([key, value]) => {
                                return (
                                    <>
                                        <p style={{ color: 'red' }}>{value}</p>
                                    </>
                                );
                            })}
                            <table style={{width: '100%'}}>
                                <thead>
                                </thead>
                                <tbody>
                                    <tr>
                                        <th style={{color: '#01295F',width: '70px'}}><img src={Logo} style={{width: '70px'}}/></th>
                                        <th colSpan='2' style={{color: '#01295F'}} className='text-left'><h4>Detalles de la operación</h4></th>
                                    </tr>
                                    <tr>
                                        <th colSpan='3'>
                                            <br></br>
                                        </th>
                                    </tr>
                                    <tr>
                                        <th></th>
                                        <th style={{color: '#01295F'}} className='text-left' >Monto</th>
                                        <th style={{color: '#01295F'}} className='text-right'>{currency(data.monto || 0)} MXN</th>
                                    </tr>
                                    <tr>
                                        <th colSpan='3'>
                                            <br></br>
                                        </th>
                                    </tr>
                                    <tr>
                                        <th></th>
                                        <th style={{color: '#01295F'}} className='text-left' >Nombre de la empresa</th>
                                        <th style={{color: '#01295F'}} className='text-right'>{data.nombre_Empresa} </th>
                                    </tr>
                                    <tr>
                                        <th colSpan='3'>
                                            <br></br>
                                        </th>
                                    </tr>
                                    <tr>
                                        <th></th>
                                        <th style={{color: '#01295F'}} className='text-left' >Concepto</th>
                                        <th style={{color: '#01295F'}} className='text-right'>{data.concepto}</th>
                                    </tr>
                                    <tr>
                                        <th colSpan='3'>
                                            <br></br>
                                        </th>
                                    </tr>
                                    <tr>
                                        <th></th>
                                        <th style={{color: '#01295F'}} className='text-left' >Forma de pago</th>
                                        <th style={{color: '#01295F'}} className='text-right'>{data.forma_Pago}  </th>
                                    </tr>
                                    <tr>
                                        <th colSpan='3'>
                                            <br></br>
                                        </th>
                                    </tr>
                                    <tr>
                                        <th></th>
                                        <th style={{color: '#01295F'}} className='text-left' >Fecha y hora de pago </th>
                                        <th style={{color: '#01295F'}} className='text-right'>{data.fecha_Pago}</th>
                                    </tr>
                                    <tr>
                                        <th colSpan='3'>
                                            <br></br>
                                        </th>
                                    </tr>
                                    <tr>
                                        <th></th>
                                        <th style={{color: '#01295F'}} className='text-left' >Folio</th>
                                        <th style={{color: '#01295F'}} className='text-right'>{data.folio} </th>
                                    </tr>
                                    <tr>
                                        <th colSpan='3'>
                                            <br></br>
                                        </th>
                                    </tr>
                                    <tr>
                                        <th></th>
                                        <th colSpan='2'>
                                            <button className='btn' style={{width: '100%', background: '#3FD77B', color: '#fff'}} onClick={()=>descargaRecibo()}>
                                                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-save" viewBox="0 0 16 16">
                                                     <path d="M2 1a1 1 0 0 0-1 1v12a1 1 0 0 0 1 1h12a1 1 0 0 0 1-1V2a1 1 0 0 0-1-1H9.5a1 1 0 0 0-1 1v7.293l2.646-2.647a.5.5 0 0 1 .708.708l-3.5 3.5a.5.5 0 0 1-.708 0l-3.5-3.5a.5.5 0 1 1 .708-.708L7.5 9.293V2a2 2 0 0 1 2-2H14a2 2 0 0 1 2 2v12a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2V2a2 2 0 0 1 2-2h2.5a.5.5 0 0 1 0 1H2z"/>
                                                </svg>
                                                &nbsp;Descargar recibo
                                            </button>
                                        </th>
                                    </tr>
                                </tbody>

                            </table>
                        
                        </div>
                    </div>
                    </div>
                    <div className='col-md-4'>
                    <div className='card mb-3 ' style={{boxShadow: '0px 0px 14px rgb(30 56 136 / 50%)',padding: '25px'}}>
                        <div className='card-body text-start'>
                        <div className='text-left'>
                            <label>Nota</label>
                            <p>Para cualquier aclaración relacionada con tu compra, por favor contáctanos al servicio a clientes</p>
                            <ul>
                            <li><a href="tel:+9511671455">9511671455</a></li>
                            <li><a className="h1" href="whatsapp://send?text=Hola, necesito ayuda con mi compra.&amp;phone=+5215564560704"><i className="fa">WhatsApp</i> </a></li>
                            <li><a href="mailto:soporte@factuxin.com, soporte@atti.com.mx">Correo soporte</a></li>
                            </ul>
                            
                            <label>Gracias</label>
                        </div>
                    </div>
                    </div>

                    <div className='card mb-3 ' style={{boxShadow: '0px 0px 14px rgb(30 56 136 / 50%)', background: '#F4F7FF',padding: '25px'}}>
                        <div className='card-body text-start'>
                        <div className='text-center'>
                            <label>Powered by @attimx ©2022 @factuxin <br></br> Sistema de facturación | Todos los derechos reservados</label>
                        </div>
                    </div>
                    </div>
                    </div>
                </div>

               
            </div>
        </>
    );
};


export { DetalleOperacion };
