import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { setDataSession, setUserSession } from 'views/Utils/Common';
import { fetchSinToken } from 'helpers/fetch';
import { useForm } from 'react-hook-form';
import SLUGS from 'resources/slugs';

//////imagenes////
//////////////////
import fondo from 'images/home/Background.jpg';
import logo from 'images/login/logo.svg';
import header from 'images/home/header_factura.jpg';

import icon_factuxin from 'images/login/icon_factuxin.png';

import amazon from 'images/login/recuperacion_contrasena/amazon.png';
import shopify from 'images/login/recuperacion_contrasena/shopify.png';
import mercado_libre from 'images/login/recuperacion_contrasena/mercado_libre.png';
import woocomerce from 'images/login/recuperacion_contrasena/woocomerce.png';
/////////////////



import Swal from 'sweetalert2';

import {useLocation} from 'react-router';

const Ticket = ({ match, history, saludo, subtitulo }) => {

    const baseUrl = process.env.REACT_APP_SERVER_URL;
    const { empresa } = match.params;
    const { register, handleSubmit, reset } = useForm();
    const [loading, setLoading] = useState(false);


    let [sucursal, setSucursal] = useState(['']);

    let [errores, setErrores] = useState({});
    let [erroresV, setErroresV] = useState({});

    let [error, setError] = useState(['']);
    let [success, setSuccess] = useState(['']);

    const location = useLocation();
    let search = location.search;
    if (search.startsWith('?')) {
        search = search.substring(1);
      }
      search = search.replace('-orden', '&orden');
     const searchParams = new URLSearchParams(search);
     let [sc, setSC] = useState(searchParams.get('sc'));
    let orden = searchParams.get('orden');




    //let query = new URLSearchParams(search);

    //let [sc, setSC] = useState(query.get('sc'));

    let [venta, setVenta] = useState({
        nombre: ' ', 
        rfc: '', 
        uso_cfdi: '', 
        ticket: searchParams.get('orden'), 
        correo: '',
        sucursal_Id: 0
});


    let [configuracion, setConfiguracion] = useState(['']);

    const handleLogin = () => {
        setError(null);
        if(venta.rfc === undefined || venta.rfc.length == 0)
        {
            Swal.fire('Info', "Rellene el RFC", 'info');
            return;
        }
        venta.sc = sc;
        if(venta.sc === undefined || venta.sc == 0)
        {
            Swal.fire('Info', "No se proporciono la conexión", 'info');
            return;
        }

       /* if(venta.correo === undefined || venta.correo == 0)
        {
            Swal.fire('Info', "Rellene el correo", 'info');
            return;
        }*/
        setLoading(true);
        venta.uso_cfdi = '';
        venta.nombre = venta.rfc;
        venta.sucursal_Id = sc;

        fetchSinToken('Ventas/FacturaVenta', venta, 'POST')
            .then((response) => {
                setErroresV({});
                setErrores('');
                if (response.hasOwnProperty('status') && response.status === 400) {
                    var erroresC = {};
                    Object.entries(response.errors).map(([key, value]) => {
                        Swal.fire('Info', value[0], 'info');
                        setLoading(false)
                        return;
                    });
                    setError('Revisar errores');
                    setLoading(false);
                    return false;
                } else if (response.hasOwnProperty('codigo') && response.codigo !== 200) {
                    Swal.fire('Info', response.mensajes[0], 'info');
                    setLoading(false);
                    return false;
                }
                setLoading(false);

                var id_url = encodeURIComponent(response.id);
                history.push(`/facturacion/${empresa}/facturacion/${id_url}`);
            })
            .catch((error) => {
                console.log(error);
                setLoading(false);
                Swal.fire('Info', 'Ocurrio un error. Por favor, intente más tarde.', 'info');

            });
    };

    const handleInputChange = ({ target }) => {
        setVenta({
            ...venta,
            [target.name]: target.value
        });

        console.log(target.name);
        console.log(target.value);
        console.log(venta);
    };

    const handleInputChangeSC = ({ target }) => {
        setSC( target.value );
    };

    
    function handleInputChangeClienteSelect(event) {
        const target = event.target;
        const value = target.type === 'checkbox' ? target.checked : target.value;
        const name = target.name;
        setVenta({
            ...venta,
            [name]: value
        });

    }

    function getEmpresa(){
        fetchSinToken(`Empresas/GetUrlPersonalizada?url_personalizada=${empresa}`, null, 'GET').then((response) => {
            if (response.hasOwnProperty('status') && response.status === 400) {
                var erroresC = {};
                    Object.entries(response.errors).map(([key, value]) => {
                        Swal.fire('Info', value[0], 'info');
                        return;
                    });
                    setLoading(false);
                return false;
            } else if (response.hasOwnProperty('codigo') && response.codigo !== 200) {
                Swal.fire('Info', response.mensajes[0], 'info');
                return false;
            }

            fetchSinToken(`ConfiguracionesEmpresas/GetListSinToken?empresa_Id=${response.id}&pagina=1&totalXpagina=100&categoria=facturacion`, null, 'GET').then((resp) => {
                setConfiguracion(resp);
            });

            return true;
        });
        return true;
        //.catch(alertService.error("107"));
    }

    React.useEffect(() => {
        getEmpresa();
    }, []);
    return (
        <>
        <div className='col-md-12' style={{ backgroundColor: 'rgba(35, 46, 209, 1)',height: '11vh'}}>
            <div style={{float: 'left'}}>
            <img src={icon_factuxin}  style={{width: 'auto',display: 'block',marginLeft: '5vh',maxHeight: '11vh'}}/>
            </div>
            <div style={{float: 'left'}}>
                <h4 style={{color: 'rgba(255, 255, 255, 1)'}}> ¡Factura tu órden!</h4>
                <label style={{color: 'rgba(255, 255, 255, 1)'}}>Corrobora tus datos y genera tu facturación electrónica</label>
            </div>
        </div>
            <div className='col-md-12 row' style={{ height: '100%' }}>
                {window.innerWidth >= 767 ? 
                <div
                    className='col-md-6'
                    style={{
                        backgroundRepeat: 'no-repeat',
                        backgroundPosition: 'center',
                        backgroundImage: `url(${fondo})`,
                        backgroundSize: 'cover'
                    }}
                >
                    <div
                        className='container'
                        style={{
                            marginTop: '59vh',
                            width: '35vw',
                            marginLeft: '0px'
                        }}
                    >
                        <h2>
                        <b><span style={{color: '#3FD77B'}}>¡{configuracion?.filter(config => config.nombre === "nombre_empresa")[0]?.valor}</span></b><b style={{ color: '#FFFFFF' }}> Te agradece por tu adquisición!</b>
                        </h2>
                        <br></br>
                        <p style={{ color: '#FFFFFF' }}>
                        {configuracion?.filter(config => config.nombre === "mensaje_facturacion_empresa")[0]?.valor}
                        </p>
                        <br></br>
                    </div>
                </div> : '' }
                <div className='col-md-6'> 
                    <div
                        style={{
                            backgroundRepeat: 'no-repeat',
                            backgroundPosition: 'center',
                            backgroundAttachment: 'fixed',
                            backgroundImage:
                                window.innerWidth < 768
                                    ? `linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)),url(${fondo})`
                                    : 'none',
                            backgroundSize: 'cover',
                            marginTop: '50px',
                            marginLeft: window.innerWidth >= 767 ? '-15vw' : '0px'
                        }}
                    >
                        <form>
                            <div
                                className='card'
                                style={{
                                    boxShadow: 'rgb(30 56 136 / 20%) 8px 8px 48px',
                                    borderRadius: '8px',
                                    padding: window.innerWidth >= 767 ? '0px' : '0px 5% 0% 5%'
                                }}
                            >
                                <div>
                                    <div className='col-md-12 text-center'>
                                        <Link to={SLUGS.home}>
                                            <img
                                                src={baseUrl + configuracion?.filter(config => config.nombre === "logo_empresa")[0]?.valor}
                                                style={{
                                                    height: '100px',
                                                    width: 'auto',
                                                    marginLeft: 'auto',
                                                    marginRight: 'auto',
                                                    display: 'block',
                                                    marginTop: '15px'
                                                }}
                                            />
                                        </Link>
                                        <h2
                                            style={{
                                                color: window.innerWidth < 768 ? '#ffff' : '#01295F'
                                            }}
                                        >
                                            <b>¡Factura tu compra!</b>
                                        </h2>

                                        <div className='col-md-12 text-center'>
                                            <p
                                                style={{
                                                    color:
                                                        window.innerWidth < 768
                                                            ? '#01295F'
                                                            : '#01295F',
                                                    padding: '0px 10% 0px 10%'
                                                }}
                                            >
                                                Bienvenido. Captura los datos y
                                                factura tu órden.
                                            </p>
                                        </div>
                                    </div>

                                    <div
                                        className='col-md-12  text-center row'
                                        style={{ paddingBottom: '0px' }}
                                    >
                                    
                                        <div className='col-md-12 row'>
                                        <div className='col-md-1'></div>
                                            <div className='col-md-5'>

                                            <div className='text-start'>
                                                    <label className='form-label'>
                                                       Clave sucursal
                                                    </label>
                                                    <input
                                                        type='text'
                                                        value={sc}
                                                        name='sc'
                                                        className='form-control'
                                                        onChange={handleInputChangeSC}
                                                        required={true}
                                                    />
                                                </div>

                                            <div className='text-start'>
                                                    <label className='form-label'>
                                                        Número de orden
                                                    </label>
                                                    <input
                                                        type='text'
                                                        value={venta.ticket}
                                                        name='ticket'
                                                        className='form-control'
                                                        onChange={handleInputChange}
                                                        required={true}
                                                    />
                                                </div>
                                               
                                                {/*<div className='text-start'>
                                                    <label className='form-label'>
                                                        Correo electrónico
                                                    </label>
                                                    <input
                                                        type='text'
                                                        value={correo}
                                                        name='correo'
                                                        className='form-control'
                                                        onChange={handleInputChange}
                                                        required={true}
                                                    />
                                            </div>*/}
                                            </div>
                                            <div className='col-md-5'>
                                                
                                               <div className='text-start'>
                                                    <label className='form-label'>RFC</label>
                                                    <input
                                                        type='text'
                                                        value={venta.rfc}
                                                        name='rfc'
                                                        className='form-control'
                                                        onChange={handleInputChange}
                                                        required={true}
                                                    />
                                                </div>
                                               
                                                <div
                                                    className='text-start'
                                                    style={{ paddingTop: '35px' }}
                                                >
                                                    <input
                                                        type='button'
                                                        className='btn'
                                                        value={
                                                            loading
                                                                ? 'Cargando...'
                                                                : 'Generar factura'
                                                        }
                                                        onClick={handleLogin}
                                                        disabled={loading}
                                                        style={{ width: '100%', background: '#232ED1', color: '#FFFFFF', fontWeight: '300' }}
                                                    />
                                                </div>
                                            </div>
                                        </div>

                                        <div className='col-md-12'>
                                            <br></br>
                                            <Link to={SLUGS.login}>Entrar</Link>
                                        </div>
                                        <div className='text-start'>
                                            <div className='col-md-12 text-center'>
                                                <p style={{ color: 'blue' }}>{success}</p>
                                                {Object.entries(errores).map(([key, value]) => {
                                                    return (
                                                        <>
                                                            <p style={{ color: 'red' }}>{value}</p>
                                                        </>
                                                    );
                                                })}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </form>
                    </div>
                    <div className='col-md-12 row text-center class-ticket' style={{ paddingTop: '5%' }}>
                        
                        <div className='col-4 col-sm-4 col-md-4'>
                            <a href="https://atti.com.mx" target="_blank" rel="noopener noreferrer">AttiSolutions</a>
                        </div>
                        <div className='col-4 col-sm-4 col-md-4'>
                        <a href={SLUGS.terminoscondiciones} target="_blank" rel="noopener noreferrer">Términos y condiciones</a>
                        </div>
                        <div className='col-4 col-sm-4 col-md-4'>
                        <a href={SLUGS.avisoprivacidad} target="_blank" rel="noopener noreferrer">Aviso de privacidad</a>
                        </div>

                    </div>
                </div>
            </div>
        </>
    );
};

const useFormInput = (initialValue) => {
    const [value, setValue] = useState(initialValue);

    const handleChange = (e) => {
        setValue(e.target.value);
    };
    return {
        value,
        onChange: handleChange
    };
};

Ticket.propTypes = {
    saludo: PropTypes.string
};

Ticket.defaultProps = {
    subtitulo: 'Soy subtitulo'
};

export { Ticket };
