import React, { useState,useEffect } from 'react';
import PropTypes from 'prop-types';
import { fetchConToken } from 'helpers/fetch';
import { getDataSession } from 'views/Utils/Common';
import { Link } from 'react-router-dom';
import {useLocation} from 'react-router';

import { descargarFactura } from 'actions/archivos';

const File = ({ history, match, saludo, subtitulo }) => {

    let {search} = useLocation();
    let query = new URLSearchParams(search);
    
    let id = query.get('id');
    let ruta = query.get('ruta');
    let nombre = query.get('nombre');
    
    console.log(id)
    console.log(ruta)
    console.log(nombre)
    useEffect(() => {
        descargarFactura(id,ruta,nombre);
    },[]);

    
    return (
        <>
            <div className='container-fluid'>
                <div className='mb-3'>
                    <div className='row' style={{ textAlign: 'left' }}>
                        <div className='col-sm-6  col-md-6'>
                        </div>
                    </div>
                </div>

                <div className='row'>
                    <div className='col-md-12' style={{ display: 'grid' }}>
                        <div className='card mb-3 '>
                            <div className='card-body text-start'>
                                <div className='card-title text-center'>

                                </div>
                                <div className='row'>
                                    <div className='col-md-12 text-center'>
                                    
                                    </div>
                                   
                                </div>
                            </div>
                        </div>                      
                    </div>
                </div>
            </div>
        </>
    );
};


export { File };
