import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { setDataSession, setUserSession } from 'views/Utils/Common';
import { fetchSinToken } from 'helpers/fetch';
import { useForm } from 'react-hook-form';
import SLUGS from 'resources/slugs';
import { descargarFactura } from 'actions/archivos';
import Modal from "components/modal/Modal";
import { useModal } from "hooks/useModal"
import { alertService } from '_services';
//////imagenes////
//////////////////
import fondo from 'images/home/background_descargar.jpg';
import logo from 'images/login/logo.svg';
import header from 'images/home/header_factura.jpg';


import amazon from 'images/login/recuperacion_contrasena/amazon.png'
import shopify from 'images/login/recuperacion_contrasena/shopify.png'
import mercado_libre from 'images/login/recuperacion_contrasena/mercado_libre.png'
import woocomerce from 'images/login/recuperacion_contrasena/woocomerce.png'
 /////////////////
import {useLocation} from 'react-router';
import { fetchConTokenFile,handleResponse } from 'helpers/fetch';
import * as FileSaver from 'file-saver';
import Swal from 'sweetalert2'

const Descargar = ({ history, match }) => {
    const baseUrl = process.env.REACT_APP_SERVER_URL;
    const { id,empresa }   = match.params;
    const { register, handleSubmit, reset } = useForm();
    const [loading, setLoading] = useState(false);
    let [usuario, setUsuario] = useState(['']);
    const { nombre = '', rfc = '', uso_cfdi = '', ticket='', correo=''} = usuario;
    const [isOpenModal, openModal, closeModal] = useModal(false);
    let [error, setError] = useState(['']);
    let [errores, setErrores] = useState({});
    let [success, setSuccess] = useState(['']);
    let [configuracion, setConfiguracion] = useState(['']);
    const correoEnvio = useFormInput('');

    let {search} = useLocation();
    let query = new URLSearchParams(search);
    let cfdi = query.get('cfdi');

console.log(cfdi)
    const handleInputChange = ({ target }) => {
        setUsuario({
            ...usuario,
            [target.name]: target.value
        });
    };

    const descargarFactura =  (url,nombre) => {
            fetchConTokenFile( 
                url
            ).then(response => {
            
                if(response.status == 200)
                {
                    return response.blob();
                }
                else{
                handleResponse(response).then(data=>{
                    if (data.hasOwnProperty('status')) {
                        var erroresA = {};
                        Object.entries(data?.errors).map(([key, value]) => {
                            Swal.fire('Error', value[0], 'error');
                            return;
                        });
                        return false;
                    } else if (data.hasOwnProperty('codigo')) {
                        Swal.fire('Error', data.mensajes[0], 'error');
                        return;
                    }
                })
            }
              }).then(function(blob) {
                FileSaver.saveAs(blob, nombre);
                Swal.fire('','Archivo descargado.','success')
              })
        };


    function getEmpresa(){
        fetchSinToken(`Empresas/GetUrlPersonalizada?url_personalizada=${empresa}`, null, 'GET').then((response) => {
            if (response.hasOwnProperty('status') ) {
                var erroresC = {};
                    Object.entries(response.errors).map(([key, value]) => {
                        erroresC[key] = value[0];
                        setErrores(erroresC);
                    });
                return false;
            } else if (response.hasOwnProperty('codigo') ) {
                console.log(response);
                setError(response.mensajes[0]);
                return false;
            }

            fetchSinToken(`ConfiguracionesEmpresas/GetListSinToken?empresa_Id=${response.id}&pagina=1&totalXpagina=100&categoria=facturacion`, null, 'GET').then((resp) => {
                setConfiguracion(resp);
            });

            return true;
        });
        return true;
    }

    React.useEffect(() => {
        getEmpresa();
    }, []);

    function envioCorreo() {
        const direccionAux = fetchSinToken(`Facturacion/EnviarCorreoSinToken?id_cfdi=${cfdi}&correo=${correoEnvio.value}`)
        .then((data) => {
            if (data.hasOwnProperty('status')) {
                var erroresA = {};
                Object.entries(data?.errors).map(([key, value]) => {
                    Swal.fire('Error', value[0], 'error');
                    return;
                });
                return false;
            } else if (data.hasOwnProperty('codigo')) {
                Swal.fire('Error', data.mensajes[0], 'error');
                return;
            }
            Swal.fire('','Mensaje enviado.','success')
        });
    }

    return (
        <>
        <div className='col-md-12' style={{ marginBottom: '-23px'}}><img src={header}></img></div>
      <div className='col-md-12 row' style={{ height: '100vh'}}>
      <div
                    className='col-md-6'
                    style={{
                        backgroundRepeat: 'no-repeat',
                        backgroundPosition: 'center',
                        backgroundImage: `url(${fondo})`,
                        backgroundSize: 'cover'
                    }}
                >
                    <div
                        className='container'
                        style={{
                            marginTop: '59vh',
                            width: '35vw',
                            marginLeft: '0px'
                        }}
                    >
                        <h2>
                        <b><span style={{color: '#3FD77B'}}>¡{configuracion?.filter(config => config.nombre === "nombre_empresa")[0]?.valor}</span></b><b style={{ color: '#FFFFFF' }}> Te agradece por tu adquisición!</b>
                        </h2>
                        <br></br>
                        <p style={{ color: '#FFFFFF' }}>
                        {configuracion?.filter(config => config.nombre === "mensaje_facturacion_empresa")[0]?.valor}
                        </p>
                        <br></br>
                        <p style={{ color: '#FFFFFF' }}>
                            Visita nuestra tienda{' '}
                            <a href='#' style={{ color: '#3FD77B' }}>
                                Redirección a página de la tienda
                            </a>
                        </p>
                    </div>
                </div>
        <div className='col-md-6' style={{ marginTop: "2%"}}>
        <div
                    className='col-12'
                    style={{
                        backgroundRepeat: 'no-repeat',
                        backgroundPosition: 'center',
                        backgroundAttachment: 'fixed',
                        backgroundImage: window.innerWidth < 768 ? `linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)),url(${fondo})` : 'none',
                        backgroundSize: 'cover',
             
                    }}
                >
                    <form>
                        <div className='card' style={{
                                       boxShadow: 'rgb(30 56 136 / 20%) 8px 8px 48px',
                                       borderRadius: '8px',
                                       marginLeft: '-190px'
                                }}>
                            <div style={{ height: '80vh'}}>
                                <div className='col-md-12 text-center'>
                                    <Link to={SLUGS.home}>
                                            <img
                                                src={ baseUrl + configuracion?.filter(config => config.nombre === "logo_empresa")[0]?.valor}
                                                style={{
                                                    height: '100px',
                                                    width: 'auto',
                                                    marginLeft: 'auto',
                                                    marginRight: 'auto',
                                                    display: 'block',
                                                    marginTop: '15px'
                                                }}
                                            />
                                    </Link>
                                    <h2
                                        style={{
                                            color: window.innerWidth < 768 ? '#ffff' : '#01295F'
                                        }}
                                    >
                                        <b>¡Factura completada!</b>
                                    </h2>
                                    
                                    <div className='col-md-12 text-center'>
                                    <p
                                        style={{
                                            color: window.innerWidth < 768 ? '#2D3277' : '#2D3277',
                                            padding: "0px 10% 0px 10%"
                                        }}
                                        
                                    >
                                     Una vez que descargues tu factura en cualquiera de los formatos, enviaremos una copia de respaldo a tu correo electrónico y quedará almacenada en tu panel de facturación
                                    </p>
                                    </div>
                                </div>
                                
                                <div className='col-md-11 container text-center' style={{paddingBottom: '0px'}}>
                                    {error && (
                                        <>
                                            <label style={{ color: 'red', fontSize: '11px' }}>{error}</label>
                                            <br />
                                        </>
                                    )}

                                    <div className='col-12 col-sm-12 col-md-12 row' >
                                        <div className='col-4 col-sm-4 col-md-4'>
                                        <button type="button" className="btn btn-primary" onClick={()=> descargarFactura(`Facturacion/DescargarPDFSinToken?id_cfdi=${cfdi}`, "Factura.pdf") }>
                                            <svg xmlns="http://www.w3.org/2000/svg" width="25" height="25" fill="currentColor" className="bi bi-filetype-pdf" viewBox="0 0 16 16">
                                              <path fillRule="evenodd" d="M14 4.5V14a2 2 0 0 1-2 2h-1v-1h1a1 1 0 0 0 1-1V4.5h-2A1.5 1.5 0 0 1 9.5 3V1H4a1 1 0 0 0-1 1v9H2V2a2 2 0 0 1 2-2h5.5L14 4.5ZM1.6 11.85H0v3.999h.791v-1.342h.803c.287 0 .531-.057.732-.173.203-.117.358-.275.463-.474a1.42 1.42 0 0 0 .161-.677c0-.25-.053-.476-.158-.677a1.176 1.176 0 0 0-.46-.477c-.2-.12-.443-.179-.732-.179Zm.545 1.333a.795.795 0 0 1-.085.38.574.574 0 0 1-.238.241.794.794 0 0 1-.375.082H.788V12.48h.66c.218 0 .389.06.512.181.123.122.185.296.185.522Zm1.217-1.333v3.999h1.46c.401 0 .734-.08.998-.237a1.45 1.45 0 0 0 .595-.689c.13-.3.196-.662.196-1.084 0-.42-.065-.778-.196-1.075a1.426 1.426 0 0 0-.589-.68c-.264-.156-.599-.234-1.005-.234H3.362Zm.791.645h.563c.248 0 .45.05.609.152a.89.89 0 0 1 .354.454c.079.201.118.452.118.753a2.3 2.3 0 0 1-.068.592 1.14 1.14 0 0 1-.196.422.8.8 0 0 1-.334.252 1.298 1.298 0 0 1-.483.082h-.563v-2.707Zm3.743 1.763v1.591h-.79V11.85h2.548v.653H7.896v1.117h1.606v.638H7.896Z"/>
                                            </svg>
                                            &nbsp; Descargar factura PDF
                                        </button>
                                        </div>
                                        <div className='col-4 col-sm-4 col-md-4'>
                                        <button type="button" className="btn btn-primary" onClick={()=> descargarFactura(`Facturacion/DescargarXMLSinToken?id_cfdi=${cfdi}`, "Factura.xml") }>
                                            <svg xmlns="http://www.w3.org/2000/svg" width="25" height="25" fill="currentColor" className="bi bi-filetype-xml" viewBox="0 0 16 16">
                                             <path fillRule="evenodd" d="M14 4.5V14a2 2 0 0 1-2 2v-1a1 1 0 0 0 1-1V4.5h-2A1.5 1.5 0 0 1 9.5 3V1H4a1 1 0 0 0-1 1v9H2V2a2 2 0 0 1 2-2h5.5L14 4.5ZM3.527 11.85h-.893l-.823 1.439h-.036L.943 11.85H.012l1.227 1.983L0 15.85h.861l.853-1.415h.035l.85 1.415h.908l-1.254-1.992 1.274-2.007Zm.954 3.999v-2.66h.038l.952 2.159h.516l.946-2.16h.038v2.661h.715V11.85h-.8l-1.14 2.596h-.025L4.58 11.85h-.806v3.999h.706Zm4.71-.674h1.696v.674H8.4V11.85h.791v3.325Z"/>
                                            </svg>
                                            &nbsp; Descargar factura XML
                                        </button>
                                        </div>
                                        <div className='col-4 col-sm-4 col-md-4'>
                                        <button type="button" className="btn btn-primary" onClick={function(event){openModal()}}>
                                            <svg xmlns="http://www.w3.org/2000/svg" width="25" height="25" fill="currentColor" className="bi bi-envelope" viewBox="0 0 16 16">
                                                <path d="M0 4a2 2 0 0 1 2-2h12a2 2 0 0 1 2 2v8a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2V4Zm2-1a1 1 0 0 0-1 1v.217l7 4.2 7-4.2V4a1 1 0 0 0-1-1H2Zm13 2.383-4.708 2.825L15 11.105V5.383Zm-.034 6.876-5.64-3.471L8 9.583l-1.326-.795-5.64 3.47A1 1 0 0 0 2 13h12a1 1 0 0 0 .966-.741ZM1 11.105l4.708-2.897L1 5.383v5.722Z"/>
                                            </svg>
                                            &nbsp;  Enviar por correo electrónico
                                        </button>
                                        </div> 
                                    </div> 
                                    {/*<div className='card-body text-start'>
                                        <div className='col-md-12'>
                                            <Link to={SLUGS.facturacion}>Volver a página de facturación</Link>
                                        </div>                                   
                                    </div>*/}
                            </div>
                        </div>
                        </div>
                    </form>
                    <div style={{background: 'rgba(245, 245, 245, 1)'}}>
                                            <div className=' col-12 col-sm-12 col-md-12 row text-center' >
                                                <div className='col-4 col-sm-4 col-md-4'>
                                                    <a href="https://atti.com.mx" target="_blank" rel="noopener noreferrer">AttiSolutions</a>
                                                </div>
                                                <div className='col-4 col-sm-4 col-md-4'>
                                                <a href={SLUGS.terminoscondiciones} target="_blank" rel="noopener noreferrer">Términos y condiciones</a>
                                                </div>
                                                <div className='col-4 col-sm-4 col-md-4'>
                                                <a href={SLUGS.avisoprivacidad} target="_blank" rel="noopener noreferrer">Aviso de privacidad</a>
                                                </div>
                                            </div>
                                        </div>
                </div>
                {/*<div className='col-md-12 row' style={{paddingTop: "5%"}}>
                            <div className='col-md-12'>
                                <label>Conecta tu facturación:</label>
                                <br></br>
                                <br></br>
                            </div>
                            <div className='col-3 col-sm-3 col-md-3'>
                                <img src={ woocomerce }  alt=''  />
                            </div>
                            <div className='col-3 col-sm-3 col-md-3'>
                                <img src={ amazon }  alt=''  />
                            </div>
                            <div className='col-3 col-sm-3 col-md-3'>
                                <img src={ mercado_libre }  alt='' />
                            </div>
                            <div className='col-3 col-sm-3 col-md-3'>
                                <img src={ shopify }  alt='' />
                            </div>
                        </div>*/}
        </div>
        
      </div>

      <Modal isOpen={isOpenModal} closeModal={closeModal}>
          <> 
            <div className="row" style={{padding: '50px'}}>
                <div className="col-md-12 tex-left">
                    <label>Correo:</label>
                </div>
                <div className="col-md-10 ">
                    <input type="text" className="form-control" {...correoEnvio} />
                </div>
                <div className="col-md-2 text-left">
                    <button type="button" className="btn btn-primary" onClick={function(event){envioCorreo(); closeModal()}}>
                        <svg xmlns="http://www.w3.org/2000/svg" width="25" height="25" fill="currentColor" className="bi bi-envelope" viewBox="0 0 16 16">
                            <path d="M0 4a2 2 0 0 1 2-2h12a2 2 0 0 1 2 2v8a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2V4Zm2-1a1 1 0 0 0-1 1v.217l7 4.2 7-4.2V4a1 1 0 0 0-1-1H2Zm13 2.383-4.708 2.825L15 11.105V5.383Zm-.034 6.876-5.64-3.471L8 9.583l-1.326-.795-5.64 3.47A1 1 0 0 0 2 13h12a1 1 0 0 0 .966-.741ZM1 11.105l4.708-2.897L1 5.383v5.722Z"/>
                        </svg>
                        &nbsp;  Enviar
                    </button>
                
                </div>
            </div>
          </>
      </Modal>

        </>
    );
};

const useFormInput = (initialValue) => {
    const [value, setValue] = useState(initialValue);

    const handleChange = (e) => {
        setValue(e.target.value);
    };
    return {
        value,
        onChange: handleChange
    };
};

Descargar.propTypes = {
    saludo: PropTypes.string
};

Descargar.defaultProps = {
    subtitulo: 'Soy subtitulo'
};

export { Descargar };
