import React from 'react';
import { Redirect, Route, Switch } from "react-router";
import { Compra } from "./Compra";
import { Finalizar } from "./Finalizar";
import { DetalleOperacion } from "./DetalleOperacion";


function Carrito({ match }) {
    const { path } = match;

    //console.log(`path- ${match.url}/add`);

    return (
        <Switch>
            <Route exact path={path} component={Compra} />
            <Route path={`${path}/Finalizar`} component={Finalizar} />
            <Route path={`${path}/DetalleOperacion`} component={DetalleOperacion} />
            
            <Redirect from="*" to={{
                                pathname: Compra
                            }}/>
        </Switch>
    ); 
}


export { Carrito };

//export default Sucurs;