import React, { useState } from 'react';
//import PropTypes from 'prop-types';
import { fetchConToken } from 'helpers/fetch';
//import { getDataSession } from 'views/Utils/Common';
import Pagination from 'components/pagination/Pagination';
//import { descargarFactura } from 'actions/archivos';
import TituloPagina from 'components/header/TituloPagina';
import { Link } from 'react-router-dom';

import  Table from 'components/table/Table'

const List = ({ match, saludo, subtitulo }) => {
    const { path } = match;

    let [data, setData] = useState([]);
    const [totalxPagina] = useState(10);
    //const [busqueda, setbusqueda] = useState('');

    async function getData(page) {
        const response = await fetchConToken(
            `DescargaXML/GetList?paginas=${page}&totalXPagina=${totalxPagina}`
        );
        setData(response);
    }

    async function descargarSolicitud(id) {

        const articulosAux = await fetchConToken(`DescargaXML/Descargar?id=${id}`)
        .then((response) => {
            getData(1);
        });
    }

    React.useEffect(() => {
        getData(1);
    }, []);

    return (
        <>
            <div className='container-fluid'>
            <TituloPagina 
                titulo='Solicitudes de descarga SAT' 
                 descripcion='Aquí se guarda el historial de peticiones al Web Service del SAT que has solicitado' />
                   <div className='row row mb-3'>
           
                                        
                                        <div className='col-md-12 text-right'>
                        <Link to={`${path}/add`} className='btn btn-outline-primary'>
                            <svg
                                xmlns='http://www.w3.org/2000/svg'
                                width='20'
                                height='20'
                                fill='currentColor'
                                className='bi bi-plus-square'
                                viewBox='0 0 16 16'
                            >
                                <path d='M14 1a1 1 0 0 1 1 1v12a1 1 0 0 1-1 1H2a1 1 0 0 1-1-1V2a1 1 0 0 1 1-1h12zM2 0a2 2 0 0 0-2 2v12a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2V2a2 2 0 0 0-2-2H2z' />
                                <path d='M8 4a.5.5 0 0 1 .5.5v3h3a.5.5 0 0 1 0 1h-3v3a.5.5 0 0 1-1 0v-3h-3a.5.5 0 0 1 0-1h3v-3A.5.5 0 0 1 8 4z' />
                            </svg>
                            &nbsp; Nueva Solicitud
                        </Link>
                    </div>
                   
                </div>
          
                <div className='col-md-12' >
                    <Table 
                        columns = {['Fecha Peticion','Fecha Inicio','Fecha Fin','RFC Emisor','RFC Receptor','RFC Solicitud','Codigo Solicitud','Solicitud Mensaje',
                    'Estado Verificacion','Numero CFDI','Codigo Descarga','Tipo Petición']}
                        rows = {[
                                ['fecha','fecha_Peticion'],
                                ['fecha','fecha_Inicio'],
                                ['fecha','fecha_Fin'],
                                ['text','rfc_Emisor'],
                                ['text','rfc_Receptor'],
                                ['text','rfc_Solicitud'],
                                ['solicitud_mensaje','solicitud_Mensaje'],
                                ['text','estado_Verificacion'],
                                ['text','mensaje_Verificacion'],
                                ['text','numero_Cfdis'],
                                ['text','codigo_Descarga'],
                                ['estado_comprobante','estado_Comprobante']
                        ]}
                        path = {path}
                        url={`/DescargaXML/List`}>
                        </Table>
                        {/*<table className='table'>
                            <thead>
                                <tr>
                                    <th>#</th>
                                    <th>Fecha Peticion</th>
                                    <th>Fecha Inicio</th>
                                    <th>Fecha Fin</th>
                                    <th>RFC Emisor</th>
                                    <th>RFC Receptor</th>
                                    <th>RFC Solicitud</th>
                                    <th>Codigo Solicitud</th>
                                    <th>Solicitud Mensaje</th>
                                    <th>Estado Verificacion</th>
                                    <th>Mensaje Verificacion</th>
                                    <th>Numero CFDI's</th>
                                    <th>Codigo Descarga</th>
                                </tr>
                            </thead> 
                            <tbody>
                                {data?.map((item) => {
                                    return (
                                        <tr key={item.id}>
                                            <td>{item.id}</td>
                                            <td>{item.fecha_Peticion?.substring(0, 10)}</td>
                                            <td>{item.fecha_Inicio?.substring(0, 10)}</td>
                                            <td>{item.fecha_Fin?.substring(0, 10)}</td>
                                            <td>{item.rfc_Emisor}</td>
                                            <td>{item.rfc_Receptor}</td>
                                            <td>{item.rfc_Solicitud}</td>
                                            <td>{item.codigo_Solicitud}</td>
                                            <td>{item.solicitud_Mensaje}</td>
                                            <td>{item.estado_Verificacion}</td>
                                            <td>{item.mensaje_Verificacion}</td>
                                            <td>{item.numero_Cfdis}</td>
                                            <td>{ (item.codigo_Descarga !== "OK") ? 
                                                 <button  className="btn btn-success" type='button' onClick={()=> descargarSolicitud(item.id) }>
                                                    Descargar
                                                 </button>
                                            : "Descargado"  }</td>
                                        </tr>
                                    );
                                })}
                            </tbody>
                            </table>*/}

                    
</div>
            </div>
        </>
    );
};

export { List };
