import React from 'react';
import { Redirect, Route, Switch } from 'react-router-dom';
import SLUGS from 'resources/slugs';
//import { Home } from 'views/home/index';
import { ForgotPassword } from 'views/login/ForgotPassword';
import { Login } from 'views/login/Login';
import { Register } from 'views/login/Register';
import { ResetPassword } from 'views/login/ResetPassword';
import { Activation } from 'views/login/Activation';
import { Facturacion } from 'views/facturacion/Index';
import { AvisoPrivacidad } from 'views/documentacionlegal/AvisoPrivacidad';
import { TerminosCondiciones } from 'views/documentacionlegal/TerminosCondiciones';


//import { Login } from 'views/usuarios/Index';
//import { Register } from 'views/usuarios/Register';
//<Route key="resetpassword" exact path={`${SLUGS.resetpassword}/:key`} component={ ResetPassword } />

function PublicRoutes() {
    return (
        <Switch>
            <Route key="login" path={SLUGS.login} component={Login} />
            <Route key="signup" path={SLUGS.signup} component={Register}/>
            <Route key="forgotPassword" path={SLUGS.forgotPassword} component={ ForgotPassword } />
            <Route key="resetpassword" exact path={SLUGS.resetpassword} component={ ResetPassword } />
            <Route key="register" path={SLUGS.register} component={Register} />
            <Route key="activation" path={SLUGS.activation} component={Activation} />
            <Route key="facturacion" path={SLUGS.facturacion} component={Facturacion} />
            <Route key="avisoprivacidad" path={SLUGS.avisoprivacidad} component={AvisoPrivacidad} />
            <Route key="terminoscondiciones" path={SLUGS.terminoscondiciones} component={TerminosCondiciones} />
            
            <Route key="home" exact path={SLUGS.Login} component={Login} />
            <Redirect to={SLUGS.login} />
            {/* para start de fyttsa */}
            {/*<Route key="home" exact path={SLUGS.home} component={Home} />
            <Redirect to={SLUGS.home} />*/}
        </Switch>
    );
}

export default PublicRoutes; 
