//overview: '/overview',
const SLUGS=  {
    // private
    dashboard: '/dashboard',

    articulos: '/articulos',
    
    // auth
    login: '/login',
    signup: '/signup',
    register: '/register',
    activation: '/activation/:token',

    forgotPassword: '/forgotpassword',
    resetpassword: '/resetpassword/:token',
    //home
    clientes: '/clientes',

    //herramientas SAT
    herramientasSAT: '/HerramientasSAT',

    
    ventas: '/ventas',
    sucursal: '/conexion',
    impuestos: '/impuestos',
    facturas: '/facturas',
    complementoPago: '/complementoPago',
    facturasRecibidas: '/FacturasRecibidas',
    facturasComplemento: '/FacturasComplemento',
    usuarios: '/usuarios',
    empresa: '/empresa',
    sucursales: '/conexion',
    sellosfiscales: '/sellosfiscales',
    //tienda: '/tienda',
    camposempresa: '/camposempresa',
    paquetes: '/paquetes',
    carrito: '/carrito',
    planesyprecios: '/PlanesYPrecios',
    facturar: '/facturar/:id',
    facturar: '/facturar',
    movimientos: '/movimientos',
    bolsatimbres: '/bolsatimbres',
    FacturacionGlobal: '/FacturacionGlobal',
    descargasat: '/descargasat',
    ayuda: '/ayuda',
    soporte: '/Soporte',
    miperfil: '/MiPerfil',

    pagomercadopago: 'PagoMercadoPago',

    mercadolibre: '/MercadoLibre',

    facturacion: '/facturacion/:empresa',
    lognotificaciones: '/lognotificaciones',

    //documentos
    documentos: '/documentos',
    cotizaciones: '/documentos/cotizaciones',
    ordenes: '/documentos/ordenes',
    //enddocumentos
    pagos: '/pagos',
    // Estadisticas
    est_ingresos_diarios: '/estadisticas/ingresos-diarios',
    est_ingresos_mensuales: '/estadisticas/ingresos-mensuales',
    est_egresos_mensuales: '/estadisticas/egresos-mensuales',
    est_ingresos_vs_egresos_mensuales: '/estadisticas/ingresos-vs-egresos-mensuales',
    est_top_clientes: '/estadisticas/top-clientes',
    est_top_proveedores: '/estadisticas/top-proveedores',
    file: '/file',

    //Reportes
    totalesFacturas: '/TotalesFacturas',
    //documentacion legal
    avisoprivacidad: '/AvisoPrivacidad',
    terminoscondiciones: '/TerminosCondiciones',
    seriesEmpresa: '/SeriesEmpresa/edit/:id',





    home: '/'
    
    
};

export default SLUGS;
