import React from 'react';
import { Redirect, Route, Switch } from "react-router";
import { AddEdit } from "./AddEdit";
import { List } from "./List";
import { Facturacion } from "./Facturacion";



function Documentos({ match }) {
    const { path } = match;

    return (
        <Switch>
    
            <Route exact path={`${path}/:documento`} component={List} />
            <Route path={`${path}/:documento/add`} component={AddEdit} />
            <Route path={`${path}/:documento/edit/:id`} component={AddEdit} />
            <Route path={`${path}/ordenes/facturacion/:id`} component={Facturacion} />

        </Switch>
    );
}

export { Documentos };

//export default Sucurs;