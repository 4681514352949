import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import SLUGS from '../../resources/slugs';
import { fetchConToken } from 'helpers/fetch';
import { alertService } from '_services';
import { getDataSession,setDataSession } from 'views/Utils/Common';

import Swal from 'sweetalert2';

const AddEditSucursal = ({ history, match }) => {

    const _AUTH_MERCADOLIBRE = process.env.REACT_APP_AUTH_MERCADOLIBRE; 
    const path = match.url;
    const { id, empresa } = match.params;
    console.log(match.params);
    const isAddMode = !id;

    let [data, setData] = useState(['']);
    const { code } = data;

    const { register, handleSubmit, reset } = useForm();

    let [error, setError] = useState(['']);
    let [erroresCliente, setErroresCliente] = useState({});

    let [folios_sucursales, setFoliosSucursales] = useState();

    let [folios_cotizacion, setFoliosCotizacion] = useState();
    let [folios_factura, setFoliosFactura] = useState();
    let [folios_venta, setFoliosVentas] = useState();
    let [folios_complementopago, setFolios_complementopago] = useState();

    let [todasSeries, setTodasSeries] = useState();
    

    let [tienda, setTienda] = useState(['']);
    let [confiTienda, setConfiTienda] = useState(['']);

    let { url_Tienda = '', api_Key = '', api_Secret = '' } = confiTienda;

    function onSubmit(data) {
        return isAddMode ? create(data) : update(id, data);
    }

    function create(data) {
        data.empresa_Id = empresa;
        console.log(data);
        setErroresCliente('');
        setError('');
        return fetchConToken('Sucursales', data, 'POST')
            .then((response) => {
                if (response.hasOwnProperty('status') && response.status === 400) {
                    var erroresC = {};
                    Object.entries(response.errors).map(([key, value]) => {
                        Swal.fire('Error', value[0], 'error');
                        return false;
                    });
                    return false;
                } else if (response.hasOwnProperty('codigo') && response.codigo !== 200) {
                    Swal.fire('Error', response.mensajes[0], 'error');
                    return false;
                }
                Swal.fire('Exito', 'Conexión agregada', 'success');
                history.push( SLUGS.sucursales);
                //alertService.success('sucursal agregado', { keepAfterRouteChange: true });
            })
            .catch(alertService.error);
    }

    async function update(id, data) {

        await fetchConToken('Sucursales', data, 'PUT')
            .then((response) => {
                if (response.hasOwnProperty('status') && response.status === 400) {
                    var erroresC = {};
                    Object.entries(response.errors).map(([key, value]) => {
                        Swal.fire('Error', value[0], 'error');
                        return false;
                    });
                    return false;
                } else if (response.hasOwnProperty('codigo') && response.codigo !== 200) {
                    Swal.fire('Error', response.mensajes[0], 'error');
                    return false;
                }
                Swal.fire('Exito', 'Conexión actualizada', 'success');
                
            })
            .catch(alertService.error);

            if (tienda === 'MERCADOLIBRE') {
                ActualizaSincroAutML();
            }
            else
            {
                await saveECommerce(tienda,data);
            }
    }

    let [sucursal, setSucursal] = useState('');
    const {
        empresa_Id = getDataSession('empresa'),
        nombre = '',
        cp = '',
        calle = '',
        no_Exterior = '',
        no_Interior = '',
        colonia = '',
        poblacion = '',
        municipio = '',
        ciudad = '',
        estado = '',
        pais = '',
        matriz = '',
        estatus = 'A',
        desglosar_Impuesto = false,
        sincronizacion_Automatica = true,
        configuracion_Sucursal = []
    } = sucursal;

    function ListFoliosSucursales() {
        return fetchConToken('FoliosSucursales/FoliosSucursales')
            .then((response) => {

                setFoliosCotizacion(response?.filter(x=> x.tipo_Documento == 'C'));
                setFoliosFactura(response?.filter(x=> x.tipo_Documento == 'F'));
                setFoliosVentas(response?.filter(x=> x.tipo_Documento == 'V'));
                setFolios_complementopago(response?.filter(x=> x.tipo_Documento == 'P'));
                setTodasSeries(response);
            })
            .catch(alertService.error);
    }

    useEffect(() => {
        
        ListFoliosSucursales();

        if (!isAddMode) {
            fetchConToken('Sucursales?id=' + id).then((sucursal) => {
                setSucursal(sucursal);
            });

            fetchConToken(`ConfiguracionesSucursales?sucursal_id=${id}&nombre=TIENDA`).then(
                (response) => {
                    setTienda(response.valor);

                    console.log('tienda:', response.valor);
                    if (response.valor === 'MERCADOLIBRE')
                    {
                        fetchConToken('ConfiguracionesSucursales/GetToken?sucursal_id=' + id).then(
                            (response) => {
                                setData(response);
                            }
                        );
                    }
                    else {
                        fetchConToken(
                            `ConfiguracionesSucursales/${response.valor}?sucursal_id=${id}`
                        ).then((response) => {
                            setConfiTienda(response);
                        });
                    }
                }
            );
        }
    }, []);

    useEffect((  ) => {
        reset(sucursal);
    }, [sucursal]);

    const handleInputChange = ({ target }) => {
        setSucursal({
            ...sucursal,
            [target.name]: target.value
        });
    };

    const handleInputChangeSerie = ({ target }) => {
        sucursal.configuracion_Sucursal.map(item=>{
            if(item.nombre == target.name)
                item.valor = target.value;
        })    
        
        setSucursal({...sucursal});
    };
    


    const handleInputChangeTienda = ({ target }) => {
        setConfiTienda({
            ...confiTienda,
            [target.name]: target.value
        });

        
    };

    const handleInputChangeCode = ({ target }) => {
        setData({
            ...data,
            [target.name]: target.value
        });
    };

    const onChangeCheckActivo = ({ target }) => {
        const xCheck = target.checked;
        setSucursal({
            ...sucursal,
            [target.name]: xCheck
        });
    };

    /*async function saveCode(){
        let respuesta = await  fetchConToken(
            `ConfiguracionesSucursales/ObtenerToken?Code=${code}&sucursal_id=${id}`
        )
            .then((response) => {
                setError('');
                if (response.hasOwnProperty('status') && response.status === 400) {
                    Object.entries(response.errors).map(([key, value]) => {
                        Swal.fire('Error', value[0], 'error');
                        return false;
                    });
                    return;
                } else if (response.hasOwnProperty('codigo') && response.codigo !== 200) {
                    Swal.fire('Error', response.mensajes[0], 'error');
                    return false;
                }
            })
            .catch(alertService.error);
    };*/

    async function saveECommerce(tiendaValor){
        let respuesta = fetchConToken(
            `ConfiguracionesSucursales/${tiendaValor}?url_tienda=${url_Tienda}&key=${api_Key}&secret=${api_Secret}&sucursal_id=${id}`,
            null,
            'POST'
        ).then((response) => {
            if (response.hasOwnProperty('status') && response.status === 400) {
                setError('');
                if (response.hasOwnProperty('status') && response.status === 400) {
                    Object.entries(response.errors).map(([key, value]) => {
                        Swal.fire('Error', value[0], 'error');
                        return false;
                    });
                    return;
                } else if (response.hasOwnProperty('codigo') && response.codigo !== 200) {
                    Swal.fire('Error', response.mensajes[0], 'error');
                    return;
                }
            }

        });
    };

    async function ActualizaSincroAutML(){
        let respuesta = fetchConToken(
            `ConfiguracionesSucursales/MercadoLibre?sucursal_id=${id}`,
            null,
            'POST'
        ).then((response) => {
            if (response.hasOwnProperty('status') && response.status === 400) {
                setError('');
                if (response.hasOwnProperty('status') && response.status === 400) {
                    Object.entries(response.errors).map(([key, value]) => {
                        Swal.fire('Error', value[0], 'error');
                        return false;
                    });
                    return;
                } else if (response.hasOwnProperty('codigo') && response.codigo !== 200) {
                    Swal.fire('Error', response.mensajes[0], 'error');
                    return;
                }
            }

        });
    };

    return (
        <>
            <div className='container-fluid'>
                <form onSubmit={handleSubmit(onSubmit)}>
                    <div className='mb-3'>
                        <div className='col-md-12'>
                            <div className='col-sm-6  col-md-6'>
                                <h3>{isAddMode ? 'Agregar Conexión' : 'Editar Conexión'}</h3>
                            </div>

                            <div className='col-md-12 text-right'>
                                <button className='btn btn-outline-success' type='submit'>
                                    <svg
                                        xmlns='http://www.w3.org/2000/svg'
                                        width='20'
                                        height='20'
                                        fill='currentColor'
                                        className='bi bi-check-lg'
                                        viewBox='0 0 16 16'
                                    >
                                        <path d='M12.736 3.97a.733.733 0 0 1 1.047 0c.286.289.29.756.01 1.05L7.88 12.01a.733.733 0 0 1-1.065.02L3.217 8.384a.757.757 0 0 1 0-1.06.733.733 0 0 1 1.047 0l3.052 3.093 5.4-6.425a.247.247 0 0 1 .02-.022Z' />
                                    </svg>
                                    &nbsp; Guardar
                                </button>
                                &nbsp;
                                <Link
                                    to={(location) => ({
                                        pathname: `${SLUGS.sucursales}`
                                    })}
                                    className='btn btn-outline-secondary'
                                >
                                    <svg
                                        xmlns='http://www.w3.org/2000/svg'
                                        width='20'
                                        height='20'
                                        fill='currentColor'
                                        className='bi bi-reply'
                                        viewBox='0 0 16 16'
                                    >
                                        <path d='M6.598 5.013a.144.144 0 0 1 .202.134V6.3a.5.5 0 0 0 .5.5c.667 0 2.013.005 3.3.822.984.624 1.99 1.76 2.595 3.876-1.02-.983-2.185-1.516-3.205-1.799a8.74 8.74 0 0 0-1.921-.306 7.404 7.404 0 0 0-.798.008h-.013l-.005.001h-.001L7.3 9.9l-.05-.498a.5.5 0 0 0-.45.498v1.153c0 .108-.11.176-.202.134L2.614 8.254a.503.503 0 0 0-.042-.028.147.147 0 0 1 0-.252.499.499 0 0 0 .042-.028l3.984-2.933zM7.8 10.386c.068 0 .143.003.223.006.434.02 1.034.086 1.7.271 1.326.368 2.896 1.202 3.94 3.08a.5.5 0 0 0 .933-.305c-.464-3.71-1.886-5.662-3.46-6.66-1.245-.79-2.527-.942-3.336-.971v-.66a1.144 1.144 0 0 0-1.767-.96l-3.994 2.94a1.147 1.147 0 0 0 0 1.946l3.994 2.94a1.144 1.144 0 0 0 1.767-.96v-.667z' />
                                    </svg>
                                    &nbsp; Regresar &nbsp;
                                </Link>
                            </div>
                        </div>
                    </div>
                    <div className='col-md-12'>
                        <p style={{ color: 'red' }}>{error}</p>
                        {Object.entries(erroresCliente).map(([key, value]) => {
                            return (
                                <>
                                    <p style={{ color: 'red' }}>{value}</p>
                                </>
                            );
                        })}
                    </div>
                    <div className='row'>
                        <div className='col-md-6' style={{ display: 'grid' }}>
                            <div className='card mb-3 '>
                                <div className='card-body text-start'>
                                    <label htmlFor='nombre' className='form-label'>
                                        Nombre
                                    </label>
                                    <input
                                        type='text'
                                        className='form-control'
                                        name='nombre'
                                        value={nombre || ''}
                                        onChange={handleInputChange}
                                    />

                                    <label htmlFor='cp' className='form-label'>
                                        Codigo Postal
                                    </label>
                                    <input
                                        type='text'
                                        className='form-control'
                                        name='cp'
                                        value={cp || ''}
                                        onChange={handleInputChange}
                                        required={true}
                                    />

                                    <label htmlFor='calle' className='form-label'>
                                        Calle
                                    </label>
                                    <input
                                        type='text'
                                        className='form-control'
                                        name='calle'
                                        value={calle || ''}
                                        onChange={handleInputChange}
                                    />

                                    <label htmlFor='no_exterior' className='form-label'>
                                        No. Exterior
                                    </label>
                                    <input
                                        type='text'
                                        className='form-control'
                                        name='no_Exterior'
                                        value={no_Exterior || ''}
                                        onChange={handleInputChange}
                                    />

                                    <label htmlFor='no_interior' className='form-label'>
                                        No. Interior
                                    </label>
                                    <input
                                        type='text'
                                        className='form-control'
                                        name='no_Interior'
                                        value={no_Interior || ''}
                                        onChange={handleInputChange}
                                    />

                                    <label htmlFor='colonia' className='form-label'>
                                        Colonia
                                    </label>
                                    <input
                                        type='text'
                                        className='form-control'
                                        name='colonia'
                                        value={colonia || ''}
                                        onChange={handleInputChange}
                                    />

                                    <br />
                                </div>
                            </div>
                        </div>

                        <div className='col-md-6' style={{ display: 'grid' }}>
                            <div className='card mb-3 '>
                                <div className='card-body text-start'>

                                    <label htmlFor='municipio' className='form-label'>
                                        Municipio
                                    </label>
                                    <input
                                        type='text'
                                        className='form-control'
                                        name='municipio'
                                        value={municipio || ''}
                                        onChange={handleInputChange}
                                    />

                                    <label htmlFor='poblacion' className='form-label'>
                                        Poblacion
                                    </label>
                                    <input
                                        type='text'
                                        className='form-control'
                                        name='poblacion'
                                        value={poblacion || ''}
                                        onChange={handleInputChange}
                                    />

                                    <label htmlFor='ciudad' className='form-label'>
                                        Ciudad
                                    </label>
                                    <input
                                        type='text'
                                        className='form-control'
                                        name='ciudad'
                                        value={ciudad || ''}
                                        onChange={handleInputChange}
                                    />

                                    <label htmlFor='estado' className='form-label'>
                                        Estado
                                    </label>
                                    <input
                                        type='text'
                                        className='form-control'
                                        name='estado'
                                        value={estado || ''}
                                        onChange={handleInputChange}
                                    />

                                    <label htmlFor='pais' className='form-label'>
                                        Pais
                                    </label>
                                    <input
                                        type='text'
                                        className='form-control'
                                        name='pais'
                                        value={pais || ''}
                                        onChange={handleInputChange}
                                    />

                                    <input
                                        type='hidden'
                                        className='form-control'
                                        name='estatus'
                                        value={estatus !== 'I' ? 'A' : 'I'}
                                    />
                                    {/*
                                    <div className='form-check form-switch'>
                                        <input
                                            type='checkbox'
                                            className='form-check-input'
                                            name='matriz'
                                            role='switch'
                                            onChange={onChangeCheckActivo}
                                            checked={matriz === true}
                                        />
                                        <label htmlFor='estatus'>matriz</label> &nbsp;&nbsp;
                    </div>}*/}

                                    <div className='form-check form-switch'>
                                        <input
                                            type='checkbox'
                                            className='form-check-input'
                                            name='desglosar_Impuesto'
                                            role='switch'
                                            onChange={onChangeCheckActivo}
                                            checked={desglosar_Impuesto === true}
                                        />
                                        <label htmlFor='estatus'>Desglosar impuestos al precio del articulo</label> &nbsp;&nbsp;
                                    </div>
                                    <div className='form-check form-switch'>
                                        <input
                                            type='checkbox'
                                            className='form-check-input'
                                            name='sincronizacion_Automatica'
                                            role='switch'
                                            onChange={onChangeCheckActivo}
                                            checked={sincronizacion_Automatica === true}
                                        />
                                        <label htmlFor='estatus'>Descargar las órdenes automáticamente</label> &nbsp;&nbsp;
                                    </div>

                                    <br />
                                </div>
                                <div></div>
                            </div>
                        </div>

                        <div className='col-md-6' style={{ display: 'grid' }}>
                            <div className='card mb-3 '>
                                <div className='card-body text-start'>
                                    <div className='card-title'>
                                        <h5>Configuracion de tu tienda</h5>
                                    </div>
                                    {tienda === 'MERCADOLIBRE' ? (
                                        <div>
                                            <a
                                                href={_AUTH_MERCADOLIBRE}
                                                onClick={()=>{setDataSession('sucursalMercadoLibre', id)}}
                                                target='_blank'
                                            >
                                                Mercado libre code{' '}
                                            </a>
                                            <br></br>
                                            {code ? (
                                                <label>Mercado Libre configurado</label>
                                            ) : (
                                                <label>Mercado Libre no configurado</label>
                                            )}
                                        </div>
                                    ) : (
                                        ''
                                    )}

                                    {tienda === 'WOOCOMMERCE' ? (
                                        <div>
                                            <label htmlFor='nombre'>URL Tienda</label>
                                            <input
                                                type='text'
                                                className='form-control'
                                                name='url_Tienda'
                                                value={url_Tienda}
                                                onChange={handleInputChangeTienda}
                                                required={true}
                                            />

                                            <label htmlFor='api_key'>API Key</label>
                                            <input
                                                type='text'
                                                className='form-control'
                                                name='api_Key'
                                                value={api_Key}
                                                onChange={handleInputChangeTienda}
                                                required={true}
                                            />

                                            <label htmlFor='secret_key'>Secret Key</label>
                                            <input
                                                type='text'
                                                className='form-control'
                                                name='api_Secret'
                                                value={api_Secret}
                                                onChange={handleInputChangeTienda}
                                                required={true}
                                            />
                                        </div>
                                    ) : (
                                        ''
                                    )}

                                {tienda === 'SHOPIFY' ? (
                                        <div>
                                            <label htmlFor='nombre'>URL Tienda</label>
                                            <input
                                                type='text'
                                                className='form-control'
                                                name='url_Tienda'
                                                value={url_Tienda}
                                                onChange={handleInputChangeTienda}
                                                required={true}
                                            />

                                            <label htmlFor='api_key'>API Key</label>
                                            <input
                                                type='text'
                                                className='form-control'
                                                name='api_Key'
                                                value={api_Key}
                                                onChange={handleInputChangeTienda}
                                                required={true}
                                            />

                                            <label htmlFor='secret_key'>Secret Key</label>
                                            <input
                                                type='text'
                                                className='form-control'
                                                name='api_Secret'
                                                value={api_Secret}
                                                onChange={handleInputChangeTienda}
                                                required={true}
                                            />
                                        </div>
                                    ) : (
                                        ''
                                    )}
                                </div>
                                <div></div>
                            </div>
                        </div>
                        <div className='col-md-6' style={{ display: 'grid' }}>
                            <div className='card mb-3 '>
                                <div className='card-body text-start'>
                                    <label htmlFor='poblacion' className='form-label'>Serie cotizacion</label>
                                    <select 
                                    name={`serie_cotizacion`}
                                    className='form-control'
                                    onChange={handleInputChangeSerie}
                                    >
                                        {console.log(folios_cotizacion)}
                                        {folios_cotizacion?.map((item, index) => {
                                            if(sucursal.configuracion_Sucursal?.filter(x=>x.nombre == 'serie_cotizacion')[0]?.valor == item.serie )
                                                 return ( <option value={item.serie} selected>{item.serie}</option>);
                                            else
                                                 return ( <option value={item.serie}>{item.serie}</option>);
                                        })}
                                    </select>

                                    <label htmlFor='poblacion' className='form-label'>Serie factura</label>
                                    <select 
                                     name={`serie_factura`}
                                    className='form-control'
                                    onChange={handleInputChangeSerie}
                                    >
                                        {folios_factura?.map((item, index) => {
                                             if(sucursal.configuracion_Sucursal?.filter(x=>x.nombre == 'serie_factura')[0]?.valor == item.serie )
                                                  return ( <option value={item.serie} selected>{item.serie}</option>);
                                             else
                                                  return ( <option value={item.serie}>{item.serie}</option>);
                                        })}
                                    </select>

                                    <label htmlFor='poblacion' className='form-label'>Serie venta</label>
                                    <select 
                                    name={`serie_venta`}
                                    className='form-control'
                                    onChange={handleInputChangeSerie}
                                   
                                    >
                                        {
                                            folios_venta?.map((item, index) => {
                                                    if(sucursal.configuracion_Sucursal?.filter(x=>x.nombre == 'serie_venta')[0]?.valor == item.serie )
                                                        return ( <option value={item.serie} selected>{item.serie}</option>);
                                                    else
                                                        return ( <option value={item.serie}>{item.serie}</option>);
                                            })
                                        }
                                    </select>

                                    <label htmlFor='poblacion' className='form-label'>Serie complemento de pago</label>
                                    <select 
                                    name={`serie_complementopago`}
                                    className='form-control'
                                    onChange={handleInputChangeSerie}
                                   
                                    >
                                        {
                                            folios_complementopago?.map((item, index) => {
                                                    if(sucursal.configuracion_Sucursal?.filter(x=>x.nombre == 'serie_complementopago')[0]?.valor == item.serie )
                                                        return ( <option value={item.serie} selected>{item.serie}</option>);
                                                    else
                                                        return ( <option value={item.serie}>{item.serie}</option>);
                                            })
                                        }
                                    </select>

                                </div>
                            </div>
                        </div>
                    </div>
                </form>
            </div>
        </>
    );
};

// AddEdit.propTypes = {
//     saludo: PropTypes.string
// }

// AddEdit.defaultProps = {
//     subtitulo: "Soy subtitulo"
// }

export { AddEditSucursal };
