
const moneyFormat = (num) => {
    num = Number(num)
    if(num !== undefined)
        return '$' + num?.toFixed(2).replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,')
    return ''
}

const numberFormat = (num) => {
    num = Number(num)
    if(num !== undefined)
        return num?.toFixed(2)
    return 0
}

export {
    moneyFormat,
    numberFormat
}