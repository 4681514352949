import React, { useState,useRef } from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { fetchConToken, fetchConTokenFile } from 'helpers/fetch';
// import { IconDownload } from 'assets/icons';
import { getDataSession, getFirstDay, getLastDay } from 'views/Utils/Common';
import { alertService } from '_services';
import TituloPagina from 'components/header/TituloPagina';
import Modal from 'components/modal/Modal';
import { useModal } from 'hooks/useModal';
import Table from 'components/table/Table';
import Swal from 'sweetalert2';
import * as FileSaver from 'file-saver';
//signalr  notificaciones
import { HubConnection,HubConnectionBuilder,HttpTransportType } from '@microsoft/signalr';
import SLUGS from 'resources/slugs';
import DateRangePicker from '@wojtekmaj/react-daterange-picker';
import '@wojtekmaj/react-daterange-picker/dist/DateRangePicker.css';
import 'react-calendar/dist/Calendar.css';
import moment from 'moment';

const $ = require('jquery')
const List = ({ match, saludo, subtitulo }) => {
    const { path } = match;
    const { documento } = match.params;
    const [isOrden, setIsOrden] = useState(match.params.documento === 'ordenes');

    let [mensajeVentaDescargados, setMensajeVentaDescargados] = useState('');
    let [descargaActiva, setDescargaActiva] = useState(false);

    var curr = new Date();
    curr.setDate(curr.getDate());
    const [data, setData] = useState([]);
    const [busqueda, setbusqueda] = useState('');
    const [totalxPagina, setTotalxPagina] = useState(10);
    const [totalVentas, setTotalVentas] = useState([]);
    const [fechaDescarga, setFechaDescarga] = useState(curr.toISOString().substring(0, 10));
    const [dateRange, setDateRange] = useState([new Date(), new Date()]);
    let [error, setError] = useState(['']);
    let [pagina, setPagina] = useState('');
    let [CodigoVenta, setCodigoVenta] = useState('');
    let [fechaInicio, setFechaInicio] = useState(moment().format('Y/MM/DD'));
    let [fechaFin, setFechaFin] = useState(moment().format('Y/MM/DD'));
    let [mes, setMes] = useState('');
    let [ejercicio, setEjercicio] = useState(moment().format('Y'));
    let [años, setAños] = useState([]);

    const [isOpenModal, openModal, closeModal] = useModal(false);
    const [modalValor, setModalValor] = useState(false);

    async function TotalVentasFactuxin() {
        const response = await fetchConToken(`ventas/total?sucursal_id=${getDataSession('sucursal')}`);
        setMensajeVentaDescargados("Ventas descargadas: "+(response.total ?? 0)+"/ "+(response.total_Ecommerce?? 0));
    }

    const baseUrl = process.env.REACT_APP_API_URL;

    const [connection, setConnection] = useState(null);
    var [mensajeNotificacion, setMensajeNotificacion] = useState('');
    var [toast, setToast] = useState(false);

React.useEffect(() => {
    const connect = new HubConnectionBuilder()
    .withUrl(baseUrl+"/notificacion",{
        skipNegotiation: true,
        transport: HttpTransportType.WebSockets
      })
    .withAutomaticReconnect()
    .build();
    setConnection(connect);
}, []);

React.useEffect(() => {
    if (connection) {
      connection
        .start()
        .then(async () => {
          await connection.invoke("Add", getDataSession('sucursal'));
          connection.on("ReceiveNotificacionDescarga", function(message){
            setMensajeVentaDescargados(message);
              setDescargaActiva(true);
              var table = $('#table-js').DataTable();
               table.ajax.reload();
          });

          connection.on("ReceiveNotificacionFinalizacion", function(message){
                setDescargaActiva(false);
                TotalVentasFactuxin();
          });

        })
        .catch((error) => console.log(error));
    }

  }, [connection]);

    React.useEffect(() => {
        TotalVentasFactuxin();

        if((match.params.documento === 'ordenes') != isOrden)
           window.location.reload()

           $('.dataTables_length').append("&nbsp; &nbsp; &nbsp; &nbsp; <label>Estatus:"+
           "<select class='select-filter-status' >"+
           "    <option value='TODOS'>Todos</option>"+
           "    <option value='FACTURADA'>Facturadas</option>"+
           "    <option value='TERMINADA'>Terminadas</option>"+
           "    <option value='PENDIENTE'>Pendientes</option>"+
           "    <option value='CANCELADA'>Canceladas</option>"+
           "</select></label>");

           $("body").on("change", ".select-filter-status", function () {
            var table = $('#table-js').DataTable();
            table.columns(1).search( this.value).draw();
            ///let fechaInicio = '2023/01/01', fechaFin = '2023/04/30'
            ///table.ajax.url(baseUrl+`/Ventas/List?sucursal_Id=${getDataSession('sucursal')}&tipo_documento=${isOrden ? 'V' : 'C'}&fecha_inicio=${fechaInicio}&fecha_fin=${fechaFin}`).load();
          });

          let year = moment().format('Y')
        const beforeYear = year - 10
        for(let x = year; x >= beforeYear; x--)
        {
            años.push(x);           
        }
    }, [match.params.documento]);

    
    async function eliminar(id) {
        const response = await fetchConToken(
            `ventas?id=${id}&sucursal_Id=${getDataSession('sucursal')}`,
            null,
            'DELETE'
        );
        Swal.fire('Exitoso', 'venta eliminada', 'success');
    }

    function actualizarTabla(){
        var table = $('#table-js').DataTable();
        table.ajax.reload();
    }

    const DescargarTienda = () => {
        Swal.fire({
            text: '¿Desea descargar las ventas de su E-Commerce con la fecha asignada?',
            showCancelButton: true,
            confirmButtonText: 'Descargar',
            cancelButtonText: 'Cancelar'
        }).then((result) => {
            closeModal();
            if (result.isConfirmed) {
                setDescargaActiva(true);
                fetchConToken(
                    `Ventas/DescargarVentas?empresa_id=${getDataSession(
                        'empresa'
                    )}&sucursal_id=${getDataSession('sucursal')}&fecha_Inicial=${fechaDescarga}`,
                    null,
                    'POST'
                ).then(function (response) {
                    if (response.hasOwnProperty('status') && response.status === 400) {
                        Object.entries(response.errors).map(([key, value]) => {
                            Swal.fire('Error', value[0], 'error');
                        });
                        actualizarTabla()
                        return;
                    } else if (response.hasOwnProperty('codigo') && response.codigo !== 200) {
                        Swal.fire('Error', response.mensajes[0], 'error'); //setError(response.mensajes[0]);
                        actualizarTabla()
                        return;
                    }
                    Swal.fire(
                        'Procesando',
                        'Se estan descargando las ventas. Esto puede tomar un momento',
                        'success'
                    );
                    actualizarTabla()
                });
            }
        });
    };

    const DescargarVenta = () => {
        closeModal();
        fetchConToken(
            `Ventas/DescargarVentaById?empresa_id=${getDataSession(
                'empresa'
            )}&sucursal_id=${getDataSession('sucursal')}&id=${CodigoVenta}`,
            null,
            'POST'
        ).then(function (response) {
            if (response.hasOwnProperty('status') && response.status === 400) {
                Object.entries(response.errors).map(([key, value]) => {
                    Swal.fire('Error', value[0], 'error');

                });
                actualizarTabla()
                return;
            } else if (response.hasOwnProperty('codigo') && response.codigo !== 200) {
                Swal.fire('Error', response.mensajes[0], 'error');
                actualizarTabla()
                return;
            }
            var table = $('#table-js').DataTable();
                        table.ajax.reload();
            Swal.fire('Exitoso', 'Venta Descargada', 'success');
        });
    };

    const handleChangeBusqueda = ({ target }) => {
        setbusqueda(target.value);
    };

    const handleChangeTotalXpagina = ({ target }) => {
        const pagination = target.value;
        setTotalxPagina(pagination);
    };

    const handleInputChange = ({ target }) => {
        setCodigoVenta(target.value);
    };
    const handleInputChangeFechaDescarga = ({ target }) => {
        setFechaDescarga(target.value);
    };
    
    async function opcionModal(opcion) {
        setModalValor(opcion);
        openModal();
    }    

    const filtrarPorFecha = () => {
        let table = $('#table-js').DataTable();
        table.ajax.url(baseUrl+`/Ventas/List?sucursal_Id=${getDataSession('sucursal')}&tipo_documento=${isOrden ? 'V' : 'C'}&mes=${mes}&ejercicio=${ejercicio}`).load();
    }

    const onChangeEjercicio = (e) =>{
        ejercicio = e.target.value
        setEjercicio(ejercicio)
        filtrarPorFecha()
    }

    const onChangeMes = (e) =>{
        mes = e.target.value
        setMes(mes)
        filtrarPorFecha()
    }

    return (
        <>
            <div className='container-fluid'>
                <TituloPagina
                    titulo= {isOrden ?  "Ordenes" : "Cotizaciones" }
                    descripcion={isOrden ? 'Aquí se muestran las ordenes realizadas.' : 'Aquí se muestran las cotizaciones realizadas.'} 
                    extra={isOrden ? mensajeVentaDescargados : ''}
                />
                <div className='col-md-12 d-flex justify-content-between'>
                    <div >
                        <Link to={`${ isOrden ? SLUGS.ordenes : SLUGS.cotizaciones }/add`} className='btn btn-outline-primary'>
                            <svg
                                    xmlns='http://www.w3.org/2000/svg'
                                    width='20'
                                    height='20'
                                    fill='currentColor'
                                    className='bi bi-plus-square'
                                    viewBox='0 0 16 16'
                                >
                                    <path d='M14 1a1 1 0 0 1 1 1v12a1 1 0 0 1-1 1H2a1 1 0 0 1-1-1V2a1 1 0 0 1 1-1h12zM2 0a2 2 0 0 0-2 2v12a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2V2a2 2 0 0 0-2-2H2z' />
                                    <path d='M8 4a.5.5 0 0 1 .5.5v3h3a.5.5 0 0 1 0 1h-3v3a.5.5 0 0 1-1 0v-3h-3a.5.5 0 0 1 0-1h3v-3A.5.5 0 0 1 8 4z' />
                            </svg>
                            &nbsp; Nuevo
                        </Link>
                        &nbsp;
                        {isOrden ? 
                            <>
                            <button
                                className='btn btn-outline-primary '
                                onClick={(e) => opcionModal('descargarVentas')}
                                style={{ maxWidth: '200px' }}
                                disabled={descargaActiva}
                            >
                                <svg
                                    xmlns='http://www.w3.org/2000/svg'
                                    width='20'
                                    height='20'
                                    fill='currentColor'
                                    className='bi bi-cloud-arrow-down-fill'
                                    viewBox='0 0 16 16'
                                >
                                    <path d='M8 2a5.53 5.53 0 0 0-3.594 1.342c-.766.66-1.321 1.52-1.464 2.383C1.266 6.095 0 7.555 0 9.318 0 11.366 1.708 13 3.781 13h8.906C14.502 13 16 11.57 16 9.773c0-1.636-1.242-2.969-2.834-3.194C12.923 3.999 10.69 2 8 2zm2.354 6.854-2 2a.5.5 0 0 1-.708 0l-2-2a.5.5 0 1 1 .708-.708L7.5 9.293V5.5a.5.5 0 0 1 1 0v3.793l1.146-1.147a.5.5 0 0 1 .708.708z' />
                                </svg>
                                &nbsp; Descargar órdenes
                            </button> 
                            &nbsp;
                            <button
                                className='btn btn-outline-primary '
                                onClick={(e) => opcionModal('descargarVenta')}
                                style={{ maxWidth: '200px' }}
                            >
                                <svg
                                    xmlns='http://www.w3.org/2000/svg'
                                    width='20'
                                    height='20'
                                    fill='currentColor'
                                    className='bi bi-cloud-arrow-down'
                                    viewBox='0 0 16 16'
                                >
                                    <path
                                        fill-rule='evenodd'
                                        d='M7.646 10.854a.5.5 0 0 0 .708 0l2-2a.5.5 0 0 0-.708-.708L8.5 9.293V5.5a.5.5 0 0 0-1 0v3.793L6.354 8.146a.5.5 0 1 0-.708.708l2 2z'
                                    />
                                    <path d='M4.406 3.342A5.53 5.53 0 0 1 8 2c2.69 0 4.923 2 5.166 4.579C14.758 6.804 16 8.137 16 9.773 16 11.569 14.502 13 12.687 13H3.781C1.708 13 0 11.366 0 9.318c0-1.763 1.266-3.223 2.942-3.593.143-.863.698-1.723 1.464-2.383zm.653.757c-.757.653-1.153 1.44-1.153 2.056v.448l-.445.049C2.064 6.805 1 7.952 1 9.318 1 10.785 2.23 12 3.781 12h8.906C13.98 12 15 10.988 15 9.773c0-1.216-1.02-2.228-2.313-2.228h-.5v-.5C12.188 4.825 10.328 3 8 3a4.53 4.53 0 0 0-2.941 1.1z' />
                                </svg>
                                &nbsp; Descargar órden
                            </button> 
                            </> : ''
                        }
                    </div>
                    <div>
                        <label className='me-1'>Ejercicio </label>
                        <select className='selectCustomTwo me-3' onChange={onChangeEjercicio}>
                            {
                                años.map((item,i)=>(
                                    <option key={i} value={item}>{item}</option>
                                ))
                            }
                        </select> 
                        <label className='me-1'>Periodo </label>
                        <select className='selectCustomTwo' onChange={onChangeMes}>
                            <option value=''>Todos</option>
                            <option value='01'>Enero</option>
                            <option value='02'>Febrero</option>
                            <option value='03'>Marzo</option>
                            <option value='04'>Abril</option>
                            <option value='05'>Mayo</option>
                            <option value='06'>Junio</option>
                            <option value='07'>Julio</option>
                            <option value='08'>Agosto</option>
                            <option value='09'>Septiembre</option>
                            <option value='10'>Octubre</option>
                            <option value='11'>Noviembre</option>
                            <option value='12'>Diciembre</option>
                        </select>
                    </div>
                </div>
                <div className='col-md-12'>
                    <Table
                        columns={[
                            isOrden ? 'Orden' : 'vacio',
                            'Folio',
                            isOrden ? 'vacio' : 'Folio Venta', 
                            'Estatus',
                            'Fecha',
                            'Cliente',
                            'RFC',
                            'SubTotal',
                            'Impuestos',
                            'Total',
                            getDataSession('sucursal') == 0 ? 'Conexión' : 'vacio',
                            'Acciones'
                        ]}
                        rows={[
                            isOrden ? ['text', 'no_Orden'] : ['vacio', 'sucursal'],
                            ['text', 'folio'],
                            isOrden ? ['vacio', 'folio_Venta'] :  ['text', 'folio_Venta'],
                            ['text', 'estatus'],
                            isOrden ? ['fecha', 'fecha_Orden'] : ['fecha', 'fecha_Registro'],
                            ['object', 'cliente', 'nombre'],
                            ['object', 'cliente', 'rfc'],
                            ['moneda', 'importe_Total'],
                            ['moneda', 'impuestos'],
                            ['moneda', 'importe_Total_Impuestos'],
                            getDataSession('sucursal') == 0 ? ['object', 'sucursal', 'nombre'] : ['vacio', 'sucursal'],
                            
                            ['opciones','id']
                        ]}
                        opciones={(isOrden ? "actualizar," : "")+"edit,cancelar,pdf,"+(isOrden ? "" : "CrearVenta,")+(isOrden ? "terminar," : "")+(isOrden ? "facturar" : "")+",reenviarLink"}
                        ruta="Ventas"
                        sucursal_id={getDataSession('sucursal')}
                        path={isOrden ? SLUGS.ordenes : SLUGS.cotizaciones }
                        url={`/Ventas/List?sucursal_Id=${getDataSession('sucursal')}&tipo_documento=${isOrden ? 'V' : 'C'}&mes=${mes}&ejercicio=${ejercicio}`}
                    ></Table>
                </div>
            </div>

         
            <Modal isOpen={isOpenModal} closeModal={closeModal}>
                {modalValor === 'descargarVenta' ? (
                    <>
                        <br></br>
                        <div className='card text-left' style={{ padding: 10 }}>
                            <div className='container'>
                                <div className=' row'>
                                    <h7>Descargar una venta de E-Commerce</h7>
                                    <input
                                        type='text'
                                        className='form-control'
                                        name='nombre'
                                        style={{ width: '250px' }}
                                        placeholder='Codigo de venta'
                                        value={CodigoVenta || ''}
                                        onChange={handleInputChange}
                                    />
                                    &nbsp;&nbsp;
                                    <button
                                        className='btn btn-outline-primary '
                                        onClick={(e) => DescargarVenta()}
                                        style={{ maxWidth: '200px' }}
                                    >
                                        <svg
                                            xmlns='http://www.w3.org/2000/svg'
                                            width='20'
                                            height='20'
                                            fill='currentColor'
                                            className='bi bi-plus-square'
                                            viewBox='0 0 16 16'
                                        >
                                            <path d='M14 1a1 1 0 0 1 1 1v12a1 1 0 0 1-1 1H2a1 1 0 0 1-1-1V2a1 1 0 0 1 1-1h12zM2 0a2 2 0 0 0-2 2v12a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2V2a2 2 0 0 0-2-2H2z' />
                                            <path d='M8 4a.5.5 0 0 1 .5.5v3h3a.5.5 0 0 1 0 1h-3v3a.5.5 0 0 1-1 0v-3h-3a.5.5 0 0 1 0-1h3v-3A.5.5 0 0 1 8 4z' />
                                        </svg>
                                        &nbsp; Descargar venta
                                    </button> 
                                </div>
                            </div>
                        </div>
                    </>
                ) : (
                    ''
                )}
                {modalValor === 'descargarVentas' ? (
                    <>
                        <br></br>
                        <div className='card text-left' style={{ padding: 10 }}>
                            <div className='container'>
                                <div className='row'>
                                    <h7>Descargar las ventas de E-Commerce</h7>
                                    <input
                                        type='date'
                                        className='form-control'
                                        name='text'
                                        style={{ width: '250px' }}
                                        value={fechaDescarga || ''}
                                        onChange={handleInputChangeFechaDescarga}
                                    />
                                    &nbsp;&nbsp;
                                    <br></br>
                                    <button
                                        className='btn btn-outline-primary '
                                        onClick={(e) => DescargarTienda()}
                                        style={{ maxWidth: '200px' }}
                                    >
                                        <svg
                                            xmlns='http://www.w3.org/2000/svg'
                                            width='20'
                                            height='20'
                                            fill='currentColor'
                                            className='bi bi-plus-square'
                                            viewBox='0 0 16 16'
                                        >
                                            <path d='M14 1a1 1 0 0 1 1 1v12a1 1 0 0 1-1 1H2a1 1 0 0 1-1-1V2a1 1 0 0 1 1-1h12zM2 0a2 2 0 0 0-2 2v12a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2V2a2 2 0 0 0-2-2H2z' />
                                            <path d='M8 4a.5.5 0 0 1 .5.5v3h3a.5.5 0 0 1 0 1h-3v3a.5.5 0 0 1-1 0v-3h-3a.5.5 0 0 1 0-1h3v-3A.5.5 0 0 1 8 4z' />
                                        </svg>
                                        &nbsp; Descargar ventas
                                    </button> 
                                </div>
                            </div>
                        </div>
                    </>
                ) : (
                    ''
                )}
            </Modal>
        </>
    );
};

List.propTypes = {
    saludo: PropTypes.string
};

List.defaultProps = {
    subtitulo: 'Soy subtitulo'
};

export { List };
