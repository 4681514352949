import React, { useState } from 'react';
// import PropTypes from 'prop-types';

import { fetchReset } from 'helpers/fetch';
import { Link } from 'react-router-dom';
import SLUGS from 'resources/slugs';

import { alertService } from '_services';

import fondo from 'images/login/recuperacion_contrasena/fondo.png';
import fondo_movil from 'images/login/recuperacion_contrasena/fondo_movil.png';
import logo from 'images/login/logo.svg';

import amazon from 'images/login/recuperacion_contrasena/amazon2.png'
import shopify from 'images/login/recuperacion_contrasena/shopify2.png'
import mercado_libre from 'images/login/recuperacion_contrasena/mercado_libre2.png'
import woocomerce from 'images/login/recuperacion_contrasena/woocomerce2.png'
 
import Swal from 'sweetalert2';

const Activation = ({ history, saludo, subtitulo,match}) => {
  var today = new Date();
  var year = today.getFullYear();

    const [loading, setLoading] = useState(false);
    const username = useFormInput('');
    const { token } = match.params;
console.log(match.params);
console.log(token);
    const isMobile = window.innerWidth <= 767;

    const [textoInfo, settextoInfo] = useState('');
    const [textoError, settextoError] = useState('');
    let [error, setError] = useState(['']);
    let [errores, setErrores] = useState({});
    let [success, setSuccess] = useState(['']);

    const handleLogin = () => { 
      setLoading(true);
      setErrores('');
      setError('');
      setSuccess('');
      fetchReset("Usuarios/ActivaUsuario",null,"POST",token)
        .then(response => {
          setLoading(false);
          if (response.hasOwnProperty('status') && response.status === 400) {
            var erroresC = {};
            Object.entries(response.errors).map(([key, value]) => {
                Swal.fire("Error",value[0], 'error');
                return false;
            });
            return false;
        } else if (response.hasOwnProperty('codigo') && response.codigo !== 200) {
            Swal.fire("Error",response.mensajes[0], 'error');
            return false;
        }
        setLoading(false);
        Swal.fire("Exitoso",'Se activo la cuenta', 'success');

      }).catch(error => {
          setLoading(false);
          if (error.response.status === 401) 
            Swal.fire("Error",error.response.data.message, 'error');
          else 
            Swal.fire("Error",'Error al recuperar su cuenta.', 'error');
      });
    }
    
    

    return (
      <>
      <div className='col-md-12' >
        <img src={isMobile ? fondo_movil : fondo}></img>
      </div>
      <div className={ isMobile ? 'col-md-12' :'col-md-12 row'} >
        {isMobile ? '' :
        <div className='col-md-8' >
            <div className='' style={{padding: "15px 15% 20px 15%"}}>
                <h2 style={{color: "#0F5EF6"}}><b>¡Factura tus compras!</b></h2>
                <br></br>
                <p style={{color: "#01295F"}}>Recuerda que puedes integrar Factuxin a la facturación de tu tienda digital, contamos con integraciones para los eCommerce mas conocidas, WooCommerce, amazon, Mercado libre, shopify y Prestashop. Agrega paquetes de folios cuando necesites y olvidate de los tardados procesos de facturación</p>
                <div className='col-md-12 row' style={{paddingTop: "5%"}}>
                            <div className='col-3 col-sm-3 col-md-3'>
                                <img src={ woocomerce }  alt='' />
                            </div>
                            <div className='col-3 col-sm-3 col-md-3'>
                                <img src={ amazon }  alt=''  />
                            </div>
                            <div className='col-3 col-sm-3 col-md-3'>
                                <img src={ mercado_libre }  alt='' />
                            </div>
                            <div className='col-3 col-sm-3 col-md-3'>
                                <img src={ shopify }  alt='' />
                            </div>
                        </div>
                        <br></br><br></br>
                        <div style={{background: 'rgba(245, 245, 245, 1)'}}>
                                            <div className=' col-12 col-sm-12 col-md-12 row text-center' >
                                                <div className='col-4 col-sm-4 col-md-4'>
                                                    <a href="https://atti.com.mx" target="_blank" rel="noopener noreferrer">AttiSolutions</a>
                                                </div>
                                                <div className='col-4 col-sm-4 col-md-4'>
                                                <a href={SLUGS.terminoscondiciones} target="_blank" rel="noopener noreferrer">Términos y condiciones</a>
                                                </div>
                                                <div className='col-4 col-sm-4 col-md-4'>
                                                <a href={SLUGS.avisoprivacidad} target="_blank" rel="noopener noreferrer">Aviso de privacidad</a>
                                                </div>
                                            </div>
                                        </div>
            </div>
        </div>
}
        <div className='col-md-4' style={{marginTop: isMobile ? '0%' : "-20%"}}>
        <div
                    className='col-12'
                    style={{
                        backgroundRepeat: 'no-repeat',
                        backgroundPosition: 'center',
                        backgroundAttachment: 'fixed',
                        backgroundImage: window.innerWidth < 768 ? `linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)),url(${fondo})` : 'none',
                        backgroundSize: 'cover',
             
                    }}
                >
                    <form>
                        <div className='card' style={{
                                       boxShadow: 'rgb(30 56 136 / 20%) 8px 8px 48px',
                                       borderRadius: '8px'
                                }}>
                            <div>
                                <div className='col-md-12 text-center'>
                                    <Link to={SLUGS.home}>
                                        <img src={ window.innerWidth < 768 ? logo : logo } className="img-fyt" alt='' />
                                    </Link>
                                    <h2
                                        style={{
                                            color:'#01295F'
                                        }}
                                    >
                                        <b>Activación de cuenta!</b>
                                    </h2>
                                    <br></br>
                                </div>
                                
                                <div className='col-md-11 container text-center' style={{paddingBottom: '15px'}}>
                                    {error && (
                                        <>
                                            <small style={{ color: 'red' }}>{error}</small>
                                            <br />
                                        </>
                                    )}
                                    <div className='card-body text-start'>

                                    <div className='col-md-12 text-center'>
                                        <p style={{ color: 'red' }}>{error}</p>
                                        <p style={{ color: 'blue' }}>{success}</p>
                                        {Object.entries(errores).map(([key, value]) => {
                                            return (
                                                <>
                                                    <p style={{ color: 'red' }}>{value}</p>
                                                </>
                                            );
                                        })}
                                    </div>  
                                    <input style={{color: "white", borderRadius: "15px", background:"#189ad6", width:"100%"}} type="button" className="btn btn-info col-md-8" value={loading ? 'Cargando...' : 'Activa tu cuenta'} onClick={handleLogin} disabled={loading} /><br />
                                    <div className='col-md-12 text-center'>
                                      <br></br>
                                      <b><Link to={SLUGS.login}  style={{ color: window.innerWidth < 768 ? "##0F5EF6" : "##0F5EF6"}}>Volver a inicio de sesión</Link></b>
                                      <br></br>
                                      <br></br>
                                      <p >¿Aún no tienes una cuenta?<Link to={SLUGS.register}>Registrate aquí</Link></p>
                                   </div>
                                    <br></br>
                                    
                                </div>
                            </div>
                        </div>
                        </div>
                    </form>
                </div>
        </div>
        {isMobile ? 
        <div className='col-md-12' >
            <div className='' style={{padding: "15px 15% 20px 15%"}}>
                <div className='text-center'>
                <h2 style={{color: "#0F5EF6"}}><b>¡Factura tus compras!</b></h2>
                </div>
                <br></br>
                <p style={{color: "#01295F"}}>Recuerda que puedes integrar Factuxin a la facturación de tu tienda digital, contamos con integraciones para los eCommerce mas conocidas, WooCommerce, amazon, Mercado libre, shopify y Prestashop. Agrega paquetes de folios cuando necesites y olvidate de los tardados procesos de facturación</p>
                <div className='col-md-12 row' style={{paddingTop: "5%"}}>
                            <div className='col-3 col-sm-3 col-md-3'>
                                <img src={ woocomerce }  alt='' />
                            </div>
                            <div className='col-3 col-sm-3 col-md-3'>
                                <img src={ amazon }  alt=''  />
                            </div>
                            <div className='col-3 col-sm-3 col-md-3'>
                                <img src={ mercado_libre }  alt='' />
                            </div>
                            <div className='col-3 col-sm-3 col-md-3'>
                                <img src={ shopify }  alt='' />
                            </div>
                        </div>
                        <br></br>
            </div>
            <div style={{background: 'rgba(245, 245, 245, 1)'}}>
                                            <div className=' col-12 col-sm-12 col-md-12 row text-center' >
                                                <div className='col-4 col-sm-4 col-md-4'>
                                                    <a href="https://atti.com.mx" target="_blank" rel="noopener noreferrer">AttiSolutions</a>
                                                </div>
                                                <div className='col-4 col-sm-4 col-md-4'>
                                                <a href={SLUGS.terminoscondiciones} target="_blank" rel="noopener noreferrer">Términos y condiciones</a>
                                                </div>
                                                <div className='col-4 col-sm-4 col-md-4'>
                                                <a href={SLUGS.avisoprivacidad} target="_blank" rel="noopener noreferrer">Aviso de privacidad</a>
                                                </div>
                                            </div>
                                        </div>
        </div> : ''
}
      </div>

     
      </>
    );
}



const useFormInput = initialValue => {
  const [value, setValue] = useState(initialValue);

  const handleChange = e => {
    setValue(e.target.value);
   
  }
  return {
    value,
    onChange: handleChange
  }
}


export { Activation };