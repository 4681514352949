import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { fetchConToken, fetchConTokenFile } from 'helpers/fetch';
import { getDataSession } from 'views/Utils/Common';
import Pagination from 'components/pagination/Pagination';
import { alertService } from '_services';
import * as FileSaver from 'file-saver';
import TituloPagina from 'components/header/TituloPagina';

import Table from 'components/table/Table';
const $ = require('jquery')
const List = ({ match, saludo, subtitulo }) => {
    const { path } = match;

    let [data, setData] = useState([]);
    const [totalxPagina, setTotalxPagina] = useState(10);
    const [busqueda, setbusqueda] = useState('');

    let [pagina, setPagina] = useState('');

    async function getData(page) {
        setPagina(page);
        const response = await fetchConToken(
            `Usuarios/GetList?empresa_id=${getDataSession(
                'empresa'
            )}&paginas=${page}&totalXpagina=${totalxPagina}&busqueda=${busqueda}`
        );
        setData(response);
    }

    async function eliminar(id) {
        const response = await fetchConToken(`usuarios?usuario_id=${id}`, null, 'DELETE');
        alertService.success('Usuario eliminado', {
            keepAfterRouteChange: true,
            autoClose: true
        });

        getData(pagina);
    }

    React.useEffect(() => {
        getData(1);

        $('.dataTables_length').append("&nbsp; &nbsp; &nbsp; &nbsp; <label>Estatus:"+
        "<select class='select-filter-status' >"+
        "  <option value='TODOS'>Todos</option>"+
        "  <option value='ACTIVOS' selected>Activos</option>"+
        "  <option value='INACTIVOS'>Inactivos</option>"+
        "</select></label>");

        $("body").on("change", ".select-filter-status", function () {
         var table = $('#table-js').DataTable();
         table.columns(1).search( this.value).draw();
       });

    }, []);

    const handleChangeBusqueda = ({ target }) => {
        setbusqueda(target.value);
    };
      
    return (
        <>
            <div className='container-fluid'>
                <TituloPagina
                    titulo='Usuarios'
                    descripcion='Aquí se muestran los usuarios registrados para sus conexiones.'
                />
                <div className='col-md-12'>
                    <div className='col-md-12'>
                        <Link to={`${path}/add`} className='btn btn-outline-primary'>
                            <svg
                                xmlns='http://www.w3.org/2000/svg'
                                width='20'
                                height='20'
                                fill='currentColor'
                                className='bi bi-plus-square'
                                viewBox='0 0 16 16'
                            >
                                <path d='M14 1a1 1 0 0 1 1 1v12a1 1 0 0 1-1 1H2a1 1 0 0 1-1-1V2a1 1 0 0 1 1-1h12zM2 0a2 2 0 0 0-2 2v12a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2V2a2 2 0 0 0-2-2H2z' />
                                <path d='M8 4a.5.5 0 0 1 .5.5v3h3a.5.5 0 0 1 0 1h-3v3a.5.5 0 0 1-1 0v-3h-3a.5.5 0 0 1 0-1h3v-3A.5.5 0 0 1 8 4z' />
                            </svg>
                            &nbsp; Nuevo
                        </Link>
                        &nbsp; &nbsp;
                    </div>
                </div>

                <div className='col-md-12'>
                    <div style={{ overflowX: 'auto' }}>
                        <Table
                            columns={[
                                'Nombre',
                                'Correo',
                                'Activo',
                                'Rol',
                                'Fecha Registro',
                                'Acciones'
                            ]}
                            rows={[
                                ['text', 'nombre'],
                                ['text', 'correo'],
                                ['bool', 'estatus'],
                                ['text', 'rol'],
                                ['date', 'fecha_Registro'],
                                ['opciones','id']
                            ]}
                            opciones={"edit"}
                            path={path}
                            url={`/Usuarios/List`}
                        ></Table>
                        {/*<table className='table'>
                            <thead>
                                <tr>
                                    <th>Nombre</th>
                                    <th>Correo</th>
                                    <th>Estatus</th>
                                    <th>Rol</th>
                                    <th>Fecha Registro</th>
                                    <th width='25'></th>
                                    <th width='25'></th>
                                </tr>
                            </thead>
                            <tbody>
                                {data.map((item) => {
                                    return (
                                        <tr key={item.id}>
                                            <td>{item.nombre}</td>
                                            <td>{item.correo}</td>
                                            <td>{item.estatus}</td>
                                            
                                            <td>{}</td>
                                            <td>{item.fecha_Registro.substring(0, 10)}</td>
                                            <td>
                                                    <Link
                                                        to={`${path}/edit/${item.id}`}
                                                        className='btn  mr-1'
                                                    >
                                                        <svg
                                                            xmlns='http://www.w3.org/2000/svg'
                                                            width='25'
                                                            height='25'
                                                            fill='currentColor'
                                                            className='bi bi-pencil-square'
                                                            viewBox='0 0 16 16'
                                                        >
                                                            <path d='M15.502 1.94a.5.5 0 0 1 0 .706L14.459 3.69l-2-2L13.502.646a.5.5 0 0 1 .707 0l1.293 1.293zm-1.75 2.456-2-2L4.939 9.21a.5.5 0 0 0-.121.196l-.805 2.414a.25.25 0 0 0 .316.316l2.414-.805a.5.5 0 0 0 .196-.12l6.813-6.814z' />
                                                            <path
                                                                fillRule='evenodd'
                                                                d='M1 13.5A1.5 1.5 0 0 0 2.5 15h11a1.5 1.5 0 0 0 1.5-1.5v-6a.5.5 0 0 0-1 0v6a.5.5 0 0 1-.5.5h-11a.5.5 0 0 1-.5-.5v-11a.5.5 0 0 1 .5-.5H9a.5.5 0 0 0 0-1H2.5A1.5 1.5 0 0 0 1 2.5v11z'
                                                            />
                                                        </svg>
                                                    </Link>
                                            </td>
                                            <td>
                                                <button type="button" className='btn  mr-1' onClick={()=>eliminar(item.id)} >
                                                    <svg xmlns="http://www.w3.org/2000/svg" width="25" height="25" fill="currentColor" className="bi bi-x-circle-fill" viewBox="0 0 16 16">
                                                      <path d="M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0zM5.354 4.646a.5.5 0 1 0-.708.708L7.293 8l-2.647 2.646a.5.5 0 0 0 .708.708L8 8.707l2.646 2.647a.5.5 0 0 0 .708-.708L8.707 8l2.647-2.646a.5.5 0 0 0-.708-.708L8 7.293 5.354 4.646z"/>
                                                    </svg>
                                                 </button>
                                            </td>
                                        </tr>
                                    );
                                })}
                            </tbody>
                        </table>*/}
                    </div>
                </div>
            </div>
        </>
    );
};

List.propTypes = {
    saludo: PropTypes.string
};

List.defaultProps = {
    subtitulo: 'Soy subtitulo'
};

export { List };
