import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { fetchConToken, fetchConTokenFile } from 'helpers/fetch';
import { getDataSession } from 'views/Utils/Common';
import Pagination from 'components/pagination/Pagination';
import TituloPagina from 'components/header/TituloPagina';
import { alertService } from '_services';
import * as FileSaver from 'file-saver';

import Table from 'components/table/Table';

const List = ({ match, saludo, subtitulo }) => {
    const { path } = match;

    let [data, setData] = useState([]);
    const [totalxPagina, setTotalxPagina] = useState(10);
    const [busqueda, setbusqueda] = useState('');

    let [error, setError] = useState(['']);
    let [pagina, setPagina] = useState('');
    let [CodigoArticulo, setCodigoArticulo] = useState('');

    async function getData(page) {
        setPagina(page);
        const response = await fetchConToken(
            `LogNotificacion/GetList?empresa_id=${getDataSession(
                'empresa'
            )}&sucursal_id=${getDataSession(
                'sucursal'
            )}&pagina=${page}&totalXpagina=${totalxPagina}&busqueda=${busqueda}`
        );
        console.log(response);
        setData(response);
    }

    React.useEffect(() => {
        getData(1);
    }, []);

    const handleChangeBusqueda = ({ target }) => {
        setbusqueda(target.value);
    };

    return (
        <>
            <div className='container-fluid'>
                <TituloPagina
                    titulo='Notificaciones'
                    descripcion='Aquí se guardan las notificaciones de los mensajes de su e-Commerce.'
                />

                <p style={{ color: 'red' }}>{error}</p>
                <div className='col-md-12'>
                    <Table
                        columns={['Metodo', 'Mensaje', 'Codigo', 'Fecha']}
                        rows={[
                            ['text', 'metodo'],
                            ['text', 'mensaje'],
                            ['text', 'codigo'],
                            ['date', 'fecha_Envio']
                        ]}
                        path={path}
                        url={`/LogNotificacion/List?sucursal_Id=${getDataSession('sucursal')}`}
                    ></Table>
                    {/*
                        <table className='table'>
                            <thead>
                                <tr>
                                    <th>#</th>
                                    <th>Metodo</th>
                                    <th>Mensaje</th>
                                    <th>Codigo</th>
                                    <th>Fecha</th>
                                </tr>
                            </thead>
                            <tbody>
                                {(data?.length > 0) ?  data?.map((item) => {
                                    return (
                                        <tr key={item.id}>
                                            <td>{item.id}</td>
                                            <td>{item.metodo}</td>
                                            <td>{item.mensaje}</td>
                                            <td>{item.codigo}</td>
                                            <td>{item.fecha_Envio}</td>
                                        </tr>
                                    );
                                }) : <tr>
                                    <td className='text-center' colSpan={11}>Sin Notificaciones</td>
                                    </tr>}
                            </tbody>
                            </table>*/}
                </div>
            </div>
        </>
    );
};

export { List };
