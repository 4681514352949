import React, { Suspense, lazy } from 'react';
import { Redirect, Route, Switch, useLocation } from 'react-router-dom';
import SLUGS from 'resources/slugs';
import LoadingComponent from 'components/loading';
import { Clientes } from 'views/clientes/Index';
import { Users } from 'views/users/Index';
import { Articulos } from 'views/articulos/Index';
import { Documentos } from 'views/documentos/Index';
import { Configempresa } from 'views/configuraciones/Index';
import { Configtienda } from 'views/tienda/Index';
import { Impuestos } from 'views/impuestos/Index';
import { Facturas } from 'views/facturas/Index';
import { Usuarios } from 'views/usuarios/Index';
import { SeriesEmpresa } from 'views/configuraciones/SeriesEmpresa';
import { CamposEmpresa } from 'views/camposempresa/Index';
import { Paquetes } from 'views/paquetes/Index';
import { Carrito } from 'views/carrito/Index';
import { PlanesYPrecios } from 'views/paquetes/PlanesYPrecios';
import { Facturar } from 'views/documentos/Facturar';
import { ComplementoPago } from 'views/documentos/ComplementoPago';
import { Movimientos } from 'views/movimientos/Index';
import { BolsaTimbres } from 'views/bolsatimbres/Index';
import {FacturacionGlobal} from 'views/facturacion/FacturacionGlobal'
import { ListRecibidos } from 'views/facturas/ListRecibidos';
import { ListComplementos } from 'views/facturas/ListComplementos';
import { DescargaSAT } from 'views/descargasat/Index';
import { SellosFis } from 'views/configuraciones/SellosFiscales';
import { Sucursales } from 'views/configuraciones/Sucursales';
import { Ayuda } from 'views/configuraciones/Ayuda';
import { Soporte } from 'views/configuraciones/Soporte';
import { MiPerfil } from 'views/usuarios/MiPerfil';
import { MercadoLibre } from 'views/configuraciones/MercadoLibre';
import { File } from 'views/archivos/File';
import { LogNotificaciones } from 'views/LogNotificaciones/Index';
import { Pagos } from 'views/pagos/Index';
import { IngresosMensuales } from 'views/estadisticas/IngresosMensuales';
import { IngresosDiarios } from 'views/estadisticas/IngresosDiarios';
import { EgresosMensuales } from 'views/estadisticas/EgresosMensuales';
import { IngresosVsEgresosMensuales } from 'views/estadisticas/IngresosVsEgresosMensuales';
import { TopClientes } from 'views/estadisticas/TopClientes';
import { TopProveedores } from 'views/estadisticas/TopProveedores';
import { PagoMercadoPago } from 'views/carrito/PagoMercadoPago';
import { TotalesFacturas } from 'views/reportes/TotalesFacturas';

import { HerramientasSAT } from 'views/herramientasSAT/Index';




let DashboardComponent = lazy(() => import('./dashboard'));

//const Programa = lazy(() => import('views/puntos'));
//<Route exact path={SLUGS.overview} component={Programa} />
//sucursaladdedit: '/branch/addedit',
////<Route exact path={SLUGS.sucursaladdedit} component={AddEdit} />

function PrivateRoutes() {
    const { pathname } = useLocation();
    return (
        <Suspense fallback={<LoadingComponent loading />}>
            <Switch>
                <Redirect from="/:url*(/+)" to={pathname.slice(0, -1)} />
                <Route path={SLUGS.dashboard} component={DashboardComponent} />

                <Route path={SLUGS.facturar} component={Facturar} />
                <Route path={SLUGS.facturas} component={Facturas} />
                <Route path={SLUGS.complementoPago} component={ComplementoPago} />
                <Route path={SLUGS.facturasRecibidas} component={ListRecibidos} />
                <Route path={SLUGS.descargasat} component={DescargaSAT} />
                <Route path={SLUGS.impuestos} component={Impuestos} />
                <Route path={SLUGS.articulos} component={Articulos} />
                <Route path={SLUGS.clientes} component={Clientes} />
                <Route path={SLUGS.pagos} component={Pagos} />
                <Route path={SLUGS.documentos} component={Documentos} />
                <Route path={SLUGS.usuarios} component={Usuarios} />
                <Route path={SLUGS.totalesFacturas} component={TotalesFacturas} />
                <Route path={SLUGS.facturasComplemento} component={ListComplementos} />
                
                <Route path={SLUGS.herramientasSAT} component={HerramientasSAT} />
                
                <Route path={SLUGS.movimientos} component={Movimientos} />
                <Route path={SLUGS.bolsatimbres} component={BolsaTimbres} />
                <Route path={SLUGS.paquetes} component={Paquetes} />
                <Route path={SLUGS.lognotificaciones} component={LogNotificaciones} />
                <Route path={SLUGS.planesyprecios} component={PlanesYPrecios} />
                <Route path={SLUGS.miperfil} component={MiPerfil} />
                <Route path={SLUGS.empresa} component={Configempresa} />
                <Route path={SLUGS.sellosfiscales} component={SellosFis} />
                <Route path={SLUGS.sucursales} component={Sucursales} />
                <Route path={SLUGS.ayuda} component={Ayuda} />
                <Route path={SLUGS.soporte} component={Soporte} />
                <Route path={SLUGS.carrito} component={Carrito} />

                <Route path={SLUGS.mercadolibre} component={MercadoLibre} />
                <Route path={SLUGS.file} component={File} />
                <Route path={SLUGS.est_ingresos_diarios} component={IngresosDiarios} />
                <Route path={SLUGS.est_ingresos_mensuales} component={IngresosMensuales} />
                <Route path={SLUGS.est_egresos_mensuales} component={EgresosMensuales} />
                <Route path={SLUGS.est_ingresos_vs_egresos_mensuales} component={IngresosVsEgresosMensuales} />
                <Route path={SLUGS.est_top_proveedores} component={TopProveedores} />
                <Route path={SLUGS.est_top_clientes} component={TopClientes} />

                <Route path={SLUGS.pagomercadopago} component={PagoMercadoPago} />

                
                <Route path={SLUGS.FacturacionGlobal} component={FacturacionGlobal} />
                <Route path={SLUGS.seriesEmpresa} component={SeriesEmpresa} />
                <Route path={SLUGS.seriesEmpresaEdit} component={SeriesEmpresa} />

                
                {/*              
                
                <Route path={SLUGS.tienda} component={Configtienda} />
                <Route path={SLUGS.camposempresa} component={CamposEmpresa} />
                <Route path={SLUGS.FacturacionGlobal} component={FacturacionGlobal} />
                
                 */}

                {/*<Route path={SLUGS.ideasThree} render={() => <div>ideasThree</div>} />
                <Route path={SLUGS.subscription} render={() => <div>subscription</div>} />*/}
                <Redirect to={SLUGS.dashboard} />
            </Switch>
        </Suspense>
    );
}

export default PrivateRoutes;

