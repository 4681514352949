import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { useForm } from 'react-hook-form';

import { fetchConToken } from 'helpers/fetch';
import { alertService } from '_services';
import { getDataSession } from 'views/Utils/Common';

const AddEdit = ({ history, match }) => {
    const path = match.url;
    const { id } = match.params;
    const isAddMode = !id;
    const { register, handleSubmit, reset } = useForm();

    let [errores, setErrores] = useState({});
    let [error, setError] = useState(['']);

    let [paquete, setPaquete] = useState({
        cantidad_Timbres: 0,
        nombre: "",
        descripcion: "",
        estatus: 'A',
        costo: 0,
        detalle_Costo: "",
        tipo_Paquete: "",
        categorias: "",
        dias_Duracion: 0
    });

    function onSubmit(data) {
        return isAddMode ? create(data) : update(id, data);
    }

    function create(data) {
        setErrores({});
        setError('');
        return fetchConToken('PaquetesTimbres', data, 'POST')
            .then((response) => {

                if (response.hasOwnProperty('status')) {
                    var erroresA = {};
                    Object.entries(response.errors).map(([key, value]) => {
                        erroresA[key] = value[0];
                        setErrores(erroresA);
                    });
                    return false;
                    } else if (response.hasOwnProperty('codigo')) {
                                        setError(response.mensajes[0]);
                        return false;
                    }

                alertService.success('Paquete agregado', { keepAfterRouteChange: true });
                history.push('.');
            })
            .catch(alertService.error);
    }

    function update(id, data) {
        setErrores({});
        setError('');
        return fetchConToken('PaquetesTimbres', data, 'PUT')
            .then((response) => {

                if (response.hasOwnProperty('status')) {
                    var erroresA = {};
                    Object.entries(response.errors).map(([key, value]) => {
                        erroresA[key] = value[0];
                        setErrores(erroresA);
                    });
                    return false;
                    } else if (response.hasOwnProperty('codigo')) {
                                        setError(response.mensajes[0]);
                        return false;
                    }

                alertService.success('Paquete modificado', { keepAfterRouteChange: true });
                history.push('..');
            })
            .catch(alertService.error);
    }


    useEffect(() => {
        if (!isAddMode) {
            fetchConToken(`PaquetesTimbres?id=${id}`).then(
                (art) => {
                    setPaquete(art);
                }
            );
        }
    }, []);

    // effect runs when user state is updated
    useEffect(() => {
        // reset form with user data
        reset(paquete);
        // eslint-disable-next-line
    }, [paquete]);

    const onChangeCheck = ({ target }) => {
        const xCheck = target.checked ? 'S' : 'N';
        setPaquete({
            ...paquete,
            [target.name]: xCheck
        });
    };

    const onChangeCheckActivo = ({ target }) => {
        
        const xCheck = target.checked ;
        console.log(xCheck);
        setPaquete({
            ...paquete,
            [target.name]: xCheck ? "A" : "I"
        });
    };

    function handleInputChange(event) {
        const target = event.target;
        const value = target.type === 'checkbox' ? target.checked : target.value;
        const name = target.name;
        console.log(value);
        setPaquete({
            ...paquete,
            [name]: value
        });
    }


    return (
        <> 
            <div className='container-fluid'>
                <form onSubmit={handleSubmit(onSubmit)} /*onReset=""*/>
                    <div className='mb-3'>
                        <div className='' style={{ textAlign: 'left' }}>
                            <div className='col-sm-6  col-md-6'>
                                <h3>{isAddMode ? 'Agregar paquete' : 'Editar paquete'}</h3>
                            </div>
                            
                                <div className='col-md-12 text-right'>
                                <button className='btn btn-outline-success' type='submit'>
                                    <svg
                                        xmlns='http://www.w3.org/2000/svg'
                                        width='20'
                                        height='20'
                                        fill='currentColor'
                                        className='bi bi-check-lg'
                                        viewBox='0 0 16 16'
                                    >
                                        <path d='M12.736 3.97a.733.733 0 0 1 1.047 0c.286.289.29.756.01 1.05L7.88 12.01a.733.733 0 0 1-1.065.02L3.217 8.384a.757.757 0 0 1 0-1.06.733.733 0 0 1 1.047 0l3.052 3.093 5.4-6.425a.247.247 0 0 1 .02-.022Z' />
                                    </svg>
                                    &nbsp; Guardar
                                </button>
                                &nbsp;
                                <Link
                                    to={isAddMode ? '.' : '..'}
                                    className='btn btn-outline-warning'
                                >
                                    <svg
                                        xmlns='http://www.w3.org/2000/svg'
                                        width='20'
                                        height='20'
                                        fill='currentColor'
                                        className='bi bi-reply'
                                        viewBox='0 0 16 16'
                                    >
                                        <path d='M6.598 5.013a.144.144 0 0 1 .202.134V6.3a.5.5 0 0 0 .5.5c.667 0 2.013.005 3.3.822.984.624 1.99 1.76 2.595 3.876-1.02-.983-2.185-1.516-3.205-1.799a8.74 8.74 0 0 0-1.921-.306 7.404 7.404 0 0 0-.798.008h-.013l-.005.001h-.001L7.3 9.9l-.05-.498a.5.5 0 0 0-.45.498v1.153c0 .108-.11.176-.202.134L2.614 8.254a.503.503 0 0 0-.042-.028.147.147 0 0 1 0-.252.499.499 0 0 0 .042-.028l3.984-2.933zM7.8 10.386c.068 0 .143.003.223.006.434.02 1.034.086 1.7.271 1.326.368 2.896 1.202 3.94 3.08a.5.5 0 0 0 .933-.305c-.464-3.71-1.886-5.662-3.46-6.66-1.245-.79-2.527-.942-3.336-.971v-.66a1.144 1.144 0 0 0-1.767-.96l-3.994 2.94a1.147 1.147 0 0 0 0 1.946l3.994 2.94a1.144 1.144 0 0 0 1.767-.96v-.667z' />
                                    </svg>
                                    &nbsp; Regresar &nbsp;
                                </Link>
                            
                            </div>
                        </div>
                    </div>

                    <div className='row'>
                    <p style={{ color: 'red' }}>{error}</p>
                        {Object.entries(errores).map(([key, value]) => {
                            return (
                                <>
                                    <p style={{ color: 'red' }}>{value}</p>
                                </>
                            );
                        })}
                        <div className='col-md-6' style={{ display: 'grid' }}>
                            <div className='card mb-3 '>
                                <div className='card-body text-start'>
                                    <label htmlFor='nombre' className='form-label'>
                                        Nombre
                                    </label>
                                    <input
                                        type='text'
                                        className='form-control'
                                        name='nombre'
                                        required={true}
                                        value={paquete.nombre}
                                        onChange={handleInputChange}
                                    />

                                    <label htmlFor='descripcion' className='form-label'>
                                        Descripcion
                                    </label>
                                    <input
                                        type='text'
                                        className='form-control'
                                        name='descripcion'
                                        required={true}
                                        value={paquete.descripcion}
                                        onChange={handleInputChange}
                                    />

                                    <label htmlFor='cantidad_Timbres' className='form-label'>
                                        Cantidad de timbres
                                    </label>
                                    <input
                                        type='text'
                                        className='form-control'
                                        name='cantidad_Timbres'
                                        required={true}
                                        value={paquete.cantidad_Timbres}
                                        onChange={handleInputChange}
                                    />

                                    <label htmlFor='detalle_Costo' className='form-label'>
                                        Detalle
                                    </label>
                                    <input
                                        type='text'
                                        className='form-control'
                                        name='detalle_Costo'
                                        required={true}
                                        value={paquete.detalle_Costo}
                                        onChange={handleInputChange}
                                    />

                                    <label htmlFor='costo' className='form-label'>
                                        Costo
                                    </label>
                                    <input
                                        type='text'
                                        className='form-control'
                                        name='costo'
                                        required={true}
                                        value={paquete.costo}
                                        onChange={handleInputChange}
                                    />
                                </div>
                            </div>
                        </div>
                        <div className='col-md-6' style={{ display: 'grid' }}>
                            <div className='card mb-3 '>
                                <div className='card-body text-start'>
                                    <label htmlFor='tipo_Paquete' className='form-label'>
                                        Tipo de paquete
                                    </label>

                                    <select
                                        className='form-control'
                                        name='tipo_Paquete'
                                        value={paquete.tipo_Paquete || ''}
                                        onChange={handleInputChange}
                                    >
                                        <option value='general'>General </option>
                                        <option value='registro'>Registro</option>
                                                                                
                                     </select>

                                    <label htmlFor='categorias' className='form-label'>
                                        Categorias
                                    </label>
                                    <select
                                        className='form-control'
                                        name='categorias'
                                        value={paquete.categorias || ''}
                                        onChange={handleInputChange}
                                    >
                                        <option value='general'>General </option>
                                        <option value='registro'>Registro</option>
                                                                                
                                     </select>


                                    <label htmlFor='dias_Duracion' className='form-label'>
                                        Dias de duracion
                                    </label>
                                    <input
                                        type='number'
                                        className='form-control' 
                                        name='dias_Duracion'
                                        required={true}
                                        value={paquete.dias_Duracion}
                                        onChange={handleInputChange}
                                    />

                                    <div className='form-check form-switch'>
                                        <input
                                            type='checkbox'
                                            className='form-check-input'
                                            name='estatus'
                                            role='switch'
                                            onChange={onChangeCheckActivo}
                                            checked={paquete.estatus == "A"}
                                        />
                                        <label htmlFor='estatus'>Estatus</label> &nbsp;&nbsp;
                                    </div>

                                </div>
                            </div>
                        </div>
                    </div>
                </form>
            </div>
        </>
    );
};


export { AddEdit };
