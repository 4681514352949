import React, { useState } from 'react';
import P1 from 'images/panel/ayuda/p1.jpg';
import P2 from 'images/panel/ayuda/p2.jpg';
import P3 from 'images/panel/ayuda/p3.jpg';
import P6 from 'images/panel/ayuda/p6.jpg';
import P7 from 'images/panel/ayuda/p7.jpg';
import P8 from 'images/panel/ayuda/p8.jpg';
import P9 from 'images/panel/ayuda/p9.jpg';
import P10 from 'images/panel/ayuda/p10.jpg';
import P11 from 'images/panel/ayuda/p11.jpg';
import B1 from 'images/panel/ayuda/b1.jpg';
import B2 from 'images/panel/ayuda/b2.jpg';
import B3 from 'images/panel/ayuda/b3.jpg';
import B7 from 'images/panel/ayuda/b7.jpg';
import B81 from 'images/panel/ayuda/b81.jpg';
import B82 from 'images/panel/ayuda/b82.jpg';
import B83 from 'images/panel/ayuda/b83.jpg';
import B10 from 'images/panel/ayuda/b10.jpg';
import B11 from 'images/panel/ayuda/b11.jpg';
import B12 from 'images/panel/ayuda/b12.jpg';
import B13 from 'images/panel/ayuda/b13.jpg';
import B15 from 'images/panel/ayuda/b15.jpg';
import B16 from 'images/panel/ayuda/b16.jpg';
import B17 from 'images/panel/ayuda/b17.jpg';
import B181 from 'images/panel/ayuda/b181.jpg';
import B182 from 'images/panel/ayuda/b182.jpg';
import B19 from 'images/panel/ayuda/b19.jpg';
import B20 from 'images/panel/ayuda/b20.jpg';
import B21 from 'images/panel/ayuda/b21.jpg';
import B22 from 'images/panel/ayuda/b22.jpg';
import B26 from 'images/panel/ayuda/b26.jpg';
import B27 from 'images/panel/ayuda/b27.jpg';
import B31 from 'images/panel/ayuda/b31.jpg';
import B32 from 'images/panel/ayuda/b32.jpg';



const Ayuda = () => {

    const Collapsible = (props) => {
        let imagenes = props.imagenes
        let link = props.link
        let list = props.list
        const [open, setOpen] = useState(false);
        const toggle = () => {
            setOpen(!open);
        };

        return (
            <div className='collapsible'>
                <p className='titulo' onClick={toggle}>{props.titulo}</p>
                { 
                open &&
                <div className='contenido'>
                    <p>{props.contenido}</p>
                    {
                        list !== undefined &&
                        <ul>
                            {
                                list.map((item,i) =>(
                                    <li key={i}>{item}</li>
                                ))
                            }
                        </ul>
                    }
                    {
                        link !== undefined &&
                        <a href={link} target='_blank'>{link}</a>
                    }
                    {
                        imagenes !== undefined
                        &&
                        <>
                        {
                           imagenes.map((item,i)=>(
                                <img key={i} className='image' src={item} alt={'imagen'+i} />
                            ))
                        }
                        </>
                    }
                </div>
                }
            </div>
        );
      };

    return (
        <div className='row' >
            <div className='col-md-12 blockAyuda'>
                <h1>Manuales</h1>
                <section className='block'>
                    <h1>Configuración del menú despegable del usuario.</h1>
                    <Collapsible titulo='Configuración de perfil' 
                            contenido='Visualice el icono de perfil, haga clic para desplegar el menú e ingrese donde dice Mi Perfil, capture la información en los campos solicitados y al finalizar, dar clic en Guardar.'
                            imagenes={[P1]}
                            link = 'https://app.factuxin.com/MiPerfil'
                        />
                    <Collapsible titulo='Configuración general'
                            contenido='Registre la información de su empresa en los campos solicitados, seleccione el logo en formato .jpg o .png que debe estar diseñado en un máximo de 500 x 500 px. (El logo se personalizará en las cotizaciones y órdenes de venta) al finalizar, presione el botón de guardar. ' 
                            imagenes={[P2]}
                            link = 'https://app.factuxin.com/empresa'
                        />
                    <Collapsible titulo='¿Cómo se configura el texto de la nota del documento?'
                            contenido='Dentro de la configuración general encontrará el apartado de texto, especifique las condiciones generales, cláusulas comerciales u otras denominaciones, donde se establezcan otros aspectos relacionados con la compraventa, tales como lugar de entrega y gastos de envío, garantía de los productos, entre otras más condiciones de su empresa que serán visibles al descargar la cotización u orden de venta realizada en el sistema para el cliente.'
                            imagenes={[P3]}
                            link = 'https://app.factuxin.com/empresa'
                        />
                    <Collapsible titulo='¿Cómo doy de alta mis certificados digitales y mi firma electrónica?'
                            contenido='Dar clic en el menú despegable del perfil, ingrese en sellos digitales/FIEL y carga tus sellos digitales (Archivos .CER, .KEY y contraseña), estos archivos son distintos a los de la fiel.
                            La Firma Electrónica avanzada (FIEL) es un archivo generado a través del SAT y entregado a sus contribuyentes para diferentes servicios tributarios.
                            Si aún no cuentas con la FIEL o está caducada te recomendamos que acudas al SAT.'
                            imagenes={[P6]}
                            link= 'https://app.factuxin.com/sellosfiscales'
                        />
                    <Collapsible titulo='¿Para qué se usa mi CSD? '
                            contenido='Se utiliza para generar y timbrar sus comprobantes fiscales CFDI.'
                            imagenes={[]}
                        />
                    <Collapsible titulo='¿Para qué se usa la FIEL? '
                            contenido='Se utiliza para descargar sus comprobantes CFDI directo desde el portal del SAT para que posteriormente puedan visualizar en nuestros reportes el cálculo de sus impuestos y movimientos.'
                            imagenes={[P6]}
                            
                        />
                    <h1>Sección de Conexiones</h1>
                    <Collapsible titulo='¿De qué manera conecto Mercado Libre a Factuxin?'
                            contenido='En el menú despegable de su usuario busque la opción de conexiones, ingrese y haga clic en el botón de agregar Nueva Conexión, en las opciones que le aparecen de e-Commerce seleccione Mercado Libre, registre el nombre de la conexión y autorice la conexión con su cuenta de Mercado Libre.'
                            imagenes={[P7]}
                            link='https://app.factuxin.com/conexiones'
                            />
                    <Collapsible titulo='¿Cómo es la conexión de Woocommerce a Factuxin?'
                            contenido='En el menú despegable de su usuario busque la opción de conexiones, ingrese y haga clic en el botón de agregar Nueva Conexión, en las opciones que le aparecen de e-Commerce seleccione WooCommerce, ingrese el nombre de la conexión, la URL de la tienda, la API Key y el Secret Key. Estas claves se generan en WooCommerce, copie y pegue en Factuxin para  autorizar su conexión.'
                            imagenes={[P8]}
                            link='https://app.factuxin.com/conexiones'
                            /> 
                    <Collapsible titulo='¿Conexión de mi tienda Shopify a Factuxin?'
                            contenido='En el menú despegable de su usuario busque la opción de conexiones, ingrese y haga clic en el botón de agregar Nueva Conexión, en las opciones que le aparecen de e-Commerce seleccione Shopify, ingrese el nombre de la conexión, la URL de la tienda, la API Key y el Secret Key. Estas claves deben ser generadas desde su página de Shopify, copie y pegue para que se autorice la conexión con el sistema Factuxin de manera exitosa. '
                            imagenes={[P9]}
                            link='https://app.factuxin.com/conexiones'
                            /> 
                    <h1>Sección de usuarios</h1>
                    <Collapsible titulo='¿Cómo agregar usuarios que administren conexiones?'
                            contenido='Ingrese en el menú desplegable de su usuario, presione la opción de usuarios, accede a nuevo e ingrese los datos solicitados del usuario y las conexiones que tendría como acceso permitido por la cuenta empresa.  '
                            imagenes={[P10]}
                            link='https://app.factuxin.com/usuarios/add'
                            />  
                    <Collapsible titulo='¿Hay algún límite para agregar usuarios a Factuxin?'
                            contenido='El sistema de facturación Factuxin le permite agregar usuarios sin ningún límite restringido.'
                            imagenes={[P11]}
                            link='https://app.factuxin.com/usuarios'
                            />           
                    <h1>Menú general de Factuxin</h1>
                    <Collapsible titulo='Dashboard'
                            contenido='Muestra el resumen realizado de las ventas por ejercicio y por mes, de igual manera la información de las facturas emitidas y recibidas, desglosando el subtotal, los impuestos aplicados en los artículos o servicios y el total señalado.'
                            imagenes={[B1]}
                            link='https://app.factuxin.com/dashboard'
                            />
                    <h1>Cotizaciones</h1>
                    <Collapsible titulo='¿Cómo se crea una cotización?'
                            contenido='En Factuxin seleccione la opción de Cotizaciones, ingrese y seleccione al cliente que desea realizar el presupuesto, en caso de no tener registrado el cliente, debe darlo de alta en su sección correspondiente. Agregue los artículos y guarde la solicitud, después tendrá la opción de descargar el PDF, cancelarlo, editarlo o convertirlo en una venta.'
                            imagenes={[B2]}
                            link='https://app.factuxin.com/documentos/cotizaciones/add'
                            />
                    <h1>Ordenes</h1>
                    <Collapsible titulo='¿De qué manera generar una orden de venta?'
                            contenido=' En la sección de órdenes de Factuxin puede generar un pedido directamente desde el sistema ingresando los datos solicitados o bien puede descargarlo desde su e-commerce vinculado.'
                            imagenes={[B3]}
                            link='https://app.factuxin.com/documentos/ordenes/add'
                            />
                    <Collapsible titulo='¿Cómo descargo mis órdenes de Mercado Libre en Factuxin?'
                            contenido='Factuxin descarga automáticamente las órdenes de venta que se llevan a cabo en Mercado Libre, llevando este proceso.'
                            list={
                            [
                            'El cliente compra en mercado libre',
                            'Factuxin descarga la orden de venta (Estatus: Terminada)',
                            'El cliente recibe en su cuenta de mercado libre la URL para facturar su pedido. ',
                            'El cliente ingresa y genera su factura.',
                            'Para verificar, ingrese en la sección de órdenes en Factuxin y revise que la venta este como Facturado.'
                            ]}
                            imagenes={[]}
                            link=''
                            />
                    <Collapsible titulo='¿Cómo descargar mis órdenes de venta de WooCommerce a Factuxin?'
                            contenido='Factuxin descarga automáticamente las órdenes de venta que se llevan a cabo en su tienda de WooCommerce, llevando este proceso.'
                            list={
                            [
                            'El cliente ingresa un pedido desde la tienda en línea.',
                            'Factuxin descarga la orden de venta automáticamente (Estatus: Pendiente).',
                            'Ingrese en su WooCommerce y una vez que el cliente ha realizado su pago, proceda en actualizar el estado como completado.',
                            'En Factuxin se actualiza la venta (Estatus: Terminada).',
                            'Factuxin envía la URL de facturación al cliente en su correo electrónico.',
                            'El cliente ingresa, rellena sus datos y genera su factura en la página de Factuxin.',
                            'Para Verificar revise en el panel de órdenes en Factuxin que el estatus de la venta se encuentre como facturada.'
                            ]}
                            imagenes={[]}
                            link=''
                            />
                    <Collapsible titulo='¿Cómo descargar las órdenes de venta de Shopify a Factuxin?'
                            contenido='Factuxin descarga automáticamente las órdenes de venta que se llevan a cabo en su tienda en línea de Shopify, llevando este proceso.'
                            list={
                            [
                            'El cliente ingresa un pedido desde la tienda en línea.',
                            'Factuxin descarga la orden de venta automáticamente (Estatus: Pendiente).',
                            'Ingrese en su Tienda de Shopify y una vez que el cliente ha realizado su pago, proceda en actualizar el estado como completado.',
                            'En Factuxin se actualiza la venta (Estatus: Terminada).',
                            'Factuxin envía la URL de facturación al cliente en su correo electrónico.',
                            'El cliente ingresa, rellena sus datos y genera su factura en la página de Factuxin.',
                            'Para Verificar, revise en el panel de órdenes en Factuxin que el estatus de la venta se encuentre como facturada.'
                            ]}
                            imagenes={[]}
                            link=''
                            />
                    <Collapsible titulo='¿Se puede descargar órdenes de un solo día en Factuxin?'
                            contenido='Ingrese en la sección de Órdenes en Factuxin, localice el botón de descargar órdenes, busque la fecha que necesita y descargue todas las órdenes de ese día. 
                            Factuxin ofrece también la opción de descargar una orden de venta específica.'
                            imagenes={[B7]}
                            link=''
                            />
                    <Collapsible titulo='¿Cómo facturar de manera directa una orden de venta?'
                            contenido='En la sección de órdenes finalice el pedido que está en estatus pendiente, despliegue el botón de opciones y proceda con terminar la venta, una vez finalizado le mostrará la opción para facturar directamente. '
                            imagenes={[B81,B82,B83]}
                            link='https://app.factuxin.com/documentos/ordenes '
                            />
                    <h1>Sección Facturas</h1>
                    <Collapsible titulo='¿Cómo hacer el registro de un cliente para crear su factura?'
                            contenido='Para registrar a un cliente en Factuxin deberá tener la constancia fiscal o los datos fiscales proporcionados por el cliente, ingrese en la sección de Clientes, acceda al icono de Nuevo, de inmediato le aparecerá los recuadros que deberá rellenar, guarde la información y proceda a generar la factura.'
                            imagenes={[B10]}
                            link='https://app.factuxin.com/clientes/add '
                            />
                    <Collapsible titulo='¿Cómo generarle una factura a un cliente desde el panel de Factuxin?'
                            contenido='Al realizar esta operación es necesario que el cliente este dado de alta en el sistema, posterior a eso ingrese en Crear Factura, le aparecerá la ventana que completará buscando el cliente y los artículos que adquirió en su compra, confirme los datos y proceda a facturar. '
                            imagenes={[B11]}
                            link='https://app.factuxin.com/facturar '
                            />
                    <Collapsible titulo='Factura Global'
                            contenido='La factura global se refiere a emitir un documento (Comprobante fiscal digital CFDI) global para todos los contribuyentes que llevan a cabo operaciones relacionadas con el público en general. El propósito de la factura global es facilitar a los contribuyentes pues se debe agrupar las ventas en donde no fue requerida la facturación. Estos son sus características:'
                            list={
                            [
                            'Debe expedirse antes de las 72 horas siguientes al cierre de las operaciones.',
                            'En los CFDIS globales los impuestos de IVA y IEPS deben desglosarse por separado.',
                            'Sólo aplica a operaciones en las que el pago se realice en ese momento, es decir, que se efectúe el Pago en Una Sola Exhibición (PUE). En este sentido, los contribuyentes no podrán emitir un CFDI Simplificado, cuando se realice el Pago en Parcialidades o Diferido (PPD).'
                            ]}
                            imagenes={[]}
                            link='https://app.factuxin.com/FacturacionGlobal '
                            />
                    <Collapsible titulo='Facturas emitidas'
                            contenido='En esta sección encontrara todas las facturas emitidas a sus clientes. Factuxin brinda la facilidad de cancelar los CFDI que fueron emitidas con errores, también descargarlo en Formato PDF y XML, del mismo modo indica el estatus de sus facturas.'
                            imagenes={[B12]}
                            link='https://app.factuxin.com/facturas'
                            />
                    <Collapsible titulo='Facturas recibidas: '
                            contenido='Aquí visualizara todas las facturas recibidas a su nombre o de la empresa según los gastos que realiza por día, mes y año.'
                            imagenes={[B13]}
                            link=''
                            />
                    <Collapsible titulo='¿Cómo puedo saber que mis facturas son válidas?'
                            contenido='Ingrese en el portal del SAT donde deberá ingresar sus datos fiscales y posteriormente ver las facturas emitidas.'
                            imagenes={[]}
                            link='https://www.sat.gob.mx/personas/factura-electronica'
                            />
                    <h1>Sección de Artículos</h1>
                    <Collapsible titulo='¿Cómo descargo todos los artículos de mi e-Commerce a Factuxin?'
                            contenido='Una vez que la conexión ha sido dado de alta en el sistema, en la sección de artículos haga Clic en el botón de Descargar Artículos y de manera automática el sistema empieza a bajar todos los productos o servicios de su e-Commerce a Factuxin.'
                            imagenes={[B15]}
                            link=' https://app.factuxin.com/articulos'
                            />
                    <Collapsible titulo='¿Se puede descargar un artículo específico?'
                            contenido='Ingrese en el botón de Descargar artículo, ingrese la clave del artículo y  presione en Descargar'
                            imagenes={[B16]}
                            link='https://app.factuxin.com/articulos '
                            />
                    <Collapsible titulo='¿Para qué es el botón de Exportar artículos?'
                            contenido='Este botón descarga en un archivo de Excel todos los artículos que tiene en su tienda e-Commerce el cual puede servir como un inventario, asimismo le puede ser de ayuda en el momento de agregar las Claves SAT a todos sus artículos en caso de que no tenga algún documento, copiando solo el SKU de sus productos. '
                            imagenes={[B17]}
                            link='https://app.factuxin.com/articulos '
                            />
                    <Collapsible titulo='¿Cómo cargar los códigos del SAT en formato de Excel?'
                            contenido='¿Cómo cargar los códigos del SAT en formato de Excel?'
                            imagenes={[B181,B182]}
                            link='https://app.factuxin.com/articulos '
                            />
                    <Collapsible titulo='¿Qué función tiene el código de actualizar códigos mediante una conexión?'
                            contenido='Esta opción agiliza el proceso para asignarle las claves y unidades de medida SAT a los artículos que ya están en una conexión, por ejemplo si en mercado libre tiene los mismos artículos que en Shopify o WooCommerce, lo que hará esta opción es transferir los códigos en los productos o servicios ahorrando el tiempo de volverlo asignar de manera manual.   '
                            imagenes={[B19]}
                            link='https://app.factuxin.com/articulos '
                            />
                    <Collapsible titulo='¿Dónde encontrar los artículos que no se descargaron en Factuxin?'
                            contenido='En notificaciones del menú de Factuxin encontrará la clave de los artículos junto con el detalle del error de descarga, cualquier duda o información requerida, comuníquese con soporte técnico. '
                            imagenes={[B20]}
                            link='https://app.factuxin.com/lognotificaciones '
                            />
                    <Collapsible titulo='¿Para qué es la opción de requiere revisión? '
                            contenido='En la sección muestra los artículos que les hace falta datos como son el SKU, clave SAT o clave de unidad. Ingrese en editar los artículos, complete los datos que faltan y haga clic en guardar. '
                            imagenes={[B21]}
                            link=''
                            />
                    <Collapsible titulo='¿Cómo agregar la Clave SAT y la Clave de Unidad de productos o servicios en Factuxin?'
                            contenido='En artículos encuentre la opción de filtros y despliegue, haga clic en cualquier botón que diga sin clave SAT, sin clave de Unidad o requiere revisión, le mostrará todos los productos o servicios que aún no tienen estos datos asignados. En el producto o servicio que va a actualizar, presione en editar y de esta manera tendrá el acceso al panel de editar producto, ingrese los datos de clave y unidad SAT, agregue también el IVA y proceda a guardar la información.'
                            imagenes={[B22]}
                            link='https://app.factuxin.com/articulos/edit'
                            />
                    <Collapsible titulo='¿Qué sucede si agrego nuevos artículos en mi tienda, pero no los descargo en Factuxin?'
                            contenido='Cuando se agrega en tu e-Commerce o Marketplace nuevos artículos, cuando el cliente realice el pedido el sistema de Factuxin descargara el artículo, y también la orden de venta, pero se mostrara como pendiente hasta que el artículo, cuente con la clave y unidad de medida SAT cambiara de estatus a terminada, por lo tanto, el cliente no podrá generar su factura. Le recomendamos seguir estos pasos:'
                            list={
                            [
                            'Ingrese en artículos de Factuxin ',
                            'En la opción de filtros despliegue la opción de, requiere revisión (le aparecerá el artículo nuevo)',
                            'Agregue la clave SAT, Unidad de Medida y el IVA.',
                            'Guarde la información agregada.',
                            'Dirígete en el panel de órdenes y despliegue las opciones y actualice la orden de venta.',
                            'Notifique al cliente que ya puede proceder a generar su factura.'
                            ]}
                            imagenes={[]}
                            link=''
                            />
                    
                    <h1>Sección de planes, precios y servicios</h1>
                    <Collapsible titulo='¿Qué requiero para contratar el servicio?'
                            contenido='Al registrarte en https://app.factuxin.com   y después de terminar tu periodo de prueba podrás contratar directamente el plan que prefieras en la sección de planes y paquetes o si requieres más información  comunícate con nosotros al 951 167 14 55 y con gusto te ayudaremos.'
                            imagenes={[]}
                            link='https://app.factuxin.com/PlanesYPrecios '
                            />
                    <Collapsible titulo='¿Cómo puedo adquirir más Folios digitales para facturar?'
                            contenido='En planes y precios en el menú de Factuxin, seleccione el paquete que se acomode a sus necesidades, agregue en el carrito y elige su forma de pago. '
                            imagenes={[B26]}
                            link='En planes y precios en el menú de Factuxin, seleccione el paquete que se acomode a sus necesidades, agregue en el carrito y elige su forma de pago. '
                            />
                    <Collapsible titulo='¿Dónde puedo ver mis paquetes de folios adquiridos? '
                            contenido='Ingrese en el menú desplegable, haga clic en Mis transacciones y obtendrá la información de  los pagos realizados de sus compras adquiridas.'
                            imagenes={[B27]}
                            link='https://app.factuxin.com/pagos'
                            />        
                    <h1>Sección de estadisticas</h1>
                    <Collapsible titulo='Ingresos diarios'
                            contenido='Aquí le mostrará todas las ventas diarias que realiza en su conexión mediante una gráfica lineal como aumenta o bajan sus ventas según el avance de los días, también la comparativa en resumen de sus ventas de la semana actual y la anterior. '
                            imagenes={[]}
                            link='https://app.factuxin.com/estadisticas/ingresos-diarios'
                            />
                    <Collapsible titulo='Ingresos Mensuales'
                            contenido='Muestra el resumen realizado en el mes actual, haciendo comparación con los otros meses anteriores, podrá visualizar el ritmo de sus ventas alcanzadas por mes.'
                            imagenes={[]}
                            link=' https://app.factuxin.com/estadisticas/ingresos-mensuales '
                            />
                    <Collapsible titulo='Egresos mensuales'
                            contenido='Indica de manera resumida todos sus gastos mediante la información obtenida de las facturas recibidas, brindando también la diferencia de los gastos que realiza según cada mes, comparando los gastos en el gráfico del mes actual y anterior.'
                            imagenes={[]}
                            link='https://app.factuxin.com/estadisticas/egresos-mensuales '
                            />
                    <Collapsible titulo='Ingresos vs egresos mensuales'
                            contenido='Se encuentra la información reflejada de sus egresos e ingresos mensuales durante el año, comparando también el mes actual y anterior en la gráfica.'
                            imagenes={[]}
                            link='https://app.factuxin.com/estadisticas/ingresos-vs-egresos-mensuales'
                            />
                    <Collapsible titulo='Top 10 clientes'
                            contenido='El sistema Factuxin evalúa y clasifica a sus clientes mostrando a los diez clientes más frecuentes, esto mediante las facturas emitidas sobre los bienes o servicios que adquieren de su e-Commerce.'
                            imagenes={[B31]}
                            link='https://app.factuxin.com/estadisticas/top-clientes'
                            />
                    <Collapsible titulo='Top 10 Proveedores'
                            contenido='Le brinda información de los proveedores con los cuales ha tenido mayor y menor adquisición de bienes o servicios, el cual es reflejado en sus facturas recibidas. Mostrando también el análisis mediante una gráfica los gastos egresados de recursos financieros.'
                            imagenes={[B32]}
                            link='https://app.factuxin.com/estadisticas/top-proveedores '
                            />
                </section>
            </div>
        </div>
    );
};

export { Ayuda };