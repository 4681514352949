import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { fetchConToken, fetchConTokenFile } from 'helpers/fetch';
import { getDataSession } from 'views/Utils/Common';
import Pagination from 'components/pagination/Pagination';
import TituloPagina from 'components/header/TituloPagina';
import * as FileSaver from 'file-saver';
import { descargarFactura } from 'actions/archivos';

import Modal from 'components/modal/Modal';
import { useModal } from 'hooks/useModal';

import Table from 'components/table/Table';
import { alertService } from '_services';
import Swal from 'sweetalert2';

import { HubConnection,HubConnectionBuilder,HttpTransportType } from '@microsoft/signalr';

const $ = require('jquery')
$.DataTable = require('datatables.net-select')

const List = ({ match, saludo, subtitulo }) => {
    const { path } = match;

    const [totalArticulos, setTotalArticulos] = useState([]);

    const [totalxPagina, setTotalxPagina] = useState(10);
    const [busqueda, setbusqueda] = useState('');

    let [descargaActiva, setDescargaActiva] = useState(false);

    let [data, setData] = useState([]);
    let [error, setError] = useState(['']);
    let [pagina, setPagina] = useState('');
    let [CodigoArticulo, setCodigoArticulo] = useState('');
    let [sucursalesList, setSucursalesList] = useState(['']);

    let [archivo, setArchivo] = useState('');
    let [actuClave, setActuClave] = useState('sku');

    let [sucursalIdOri, setSucursalIdOri] = useState(getDataSession('sucursal'));
    let [sucursalIdDes, setSucursalIdDes] = useState(getDataSession('sucursal'));

    let [mensajeArticulosDescargados, setMensajeArticulosDescargados] = useState('');

    const [isOpenModal, openModal, closeModal] = useModal(false);
    const [modalValor, setModalValor] = useState(false);

    async function getData(page) {
        setPagina(page);
        const response = await fetchConToken(
            `Articulos/GetList?empresa_id=${getDataSession('empresa')}&sucursal_id=${getDataSession(
                'sucursal'
            )}&paginas=${page}&totalXpagina=${totalxPagina}&busqueda=${busqueda}`
        );
        console.log(response);
        setData(response);
    }

    const handleInputChange = ({ target }) => {
        setCodigoArticulo(target.value);
    };

    
    const DescargarTienda = () => {
        Swal.fire({
            text: '¿Desea descargar todos los articulos de su E-Commerce?',
            showCancelButton: true,
            confirmButtonText: 'Descargar',
            cancelButtonText: 'Cancelar'
        }).then((result) => {
            if (result.isConfirmed) {
                setDescargaActiva(true);
                fetchConToken(
                    `Articulos/DescargarArticulos?empresa_id=${getDataSession(
                        'empresa'
                    )}&sucursal_id=${getDataSession('sucursal')}`,
                    null,
                    'POST'
                ).then(function (response) {
                    if (response.hasOwnProperty('status') && response.status === 400) {
                        Object.entries(response.errors).map(([key, value]) => {
                            Swal.fire('Error', value[0], 'error');
                        });
                        setError('Revisar errores');
                        return;
                    } else if (response.hasOwnProperty('codigo') && response.codigo !== 200) {
                        Swal.fire('Error', response.mensajes[0], 'error'); 
                        return;
                    }
                    Swal.fire('Descargando','Se estan descargando los articulos. Esto puede tomar un momento','info'
                    );
                });
            }
        });
    };

    async function eliminar(id) {
        const response = await fetchConToken(`Articulos?articulo_id=${id}`, null, 'DELETE');
        /*alertService.success('Articulo eliminado', {
            keepAfterRouteChange: true,
            autoClose: true
        });*/
        Swal.fire('Eliminado', 'Articulo eliminado', 'success');
        getData(pagina);
    }

    async function editar(id) {
        Window.location.href = `${path}/edit/${id}`;
    }

    async function TotalArticulosFactuxin() {
        const response = await fetchConToken(`Articulos/total?sucursal_id=${getDataSession('sucursal')}`);
        setMensajeArticulosDescargados("Articulos descargados: "+(response.total ?? 0)+"/ "+(response.total_Ecommerce?? 0));
    }

  
    const baseUrl = process.env.REACT_APP_API_URL;

    const [connection, setConnection] = useState(null);
    var [mensajeNotificacion, setMensajeNotificacion] = useState('');
    var [toast, setToast] = useState(false);

React.useEffect(() => {
    const connect = new HubConnectionBuilder()
    .withUrl(baseUrl+"/notificacion",{
        skipNegotiation: true,
        transport: HttpTransportType.WebSockets
      })
    .withAutomaticReconnect()
    .build();
    setConnection(connect);
}, []);

React.useEffect(() => {
    if (connection) {
      connection
        .start()
        .then(async () => {
          await connection.invoke("Add", getDataSession('sucursal'));
          connection.on("ReceiveNotificacionDescarga", function(message){
              setMensajeArticulosDescargados(message);
              setDescargaActiva(true);
              var table = $('#table-js').DataTable();
               table.ajax.reload();
          });

          connection.on("ReceiveNotificacionFinalizacion", function(message){
                setDescargaActiva(false);
                TotalArticulosFactuxin();
          });

        })
        .catch((error) => console.log(error));
    }

  }, [connection]);

    React.useEffect(() => {

        TotalArticulosFactuxin();

        getData(1);
        getSucursales();

        $('.dataTables_length').append("&nbsp; &nbsp;<label>Estatus:"+
        "<select class='select-filter-status' >"+
        "   <option value='Activos'>Activos</option>"+
        "    <option value='Inactivos'>Inactivos</option>"+
        "    <option value='Pendientes'>Pendientes</option>"+
        "</select></label>");

        $('.dataTables_length').append("&nbsp; &nbsp;<label>Filtro:"+
        "<select class='select-filter-filtro' >"+
        "    <option value='vacio'></option>"+
        "    <option value='SinClaveProdServ'>Sin clave SAT</option>"+
        "    <option value='SinNoIdentificacion'>Sin No. Identificacion</option>"+
        "    <option value='Sinclaveunidad'>Sin clave unidad</option>"+
        "    <option value='SinSKU'>Sin SKU</option>"+
        "    <option value='Requiererevision'>Requiere revision</option>"+
        "</select></label>");

        $("body").on("change", ".select-filter-status", function () {
            var table = $('#table-js').DataTable();
            table.columns(1).search( this.value).draw();
        });

        $("body").on("change", ".select-filter-filtro", function () {
            var table = $('#table-js').DataTable();
            table.columns(2).search( this.value).draw();
        });

    }, []);

   

    const getSucursales = async () => {
        const responseSucursal = await fetchConToken(
            `Sucursales/GetList?empresa_id=${getDataSession('empresa')}&pagina=1&totalXpagina=100`
        );
        setSucursalesList(responseSucursal);
    };

    const handleChangeBusqueda = ({ target }) => {
        setbusqueda(target.value);
    };

    const DescargarArticulo = () => {
        fetchConToken(
            `Articulos/DescargarArticulosById?empresa_id=${getDataSession(
                'empresa'
            )}&sucursal_id=${getDataSession('sucursal')}&id=${CodigoArticulo}`,
            null,
            'POST'
        ).then(function (response) {
            getData(1);
            setError('');
            if (response.hasOwnProperty('status') && response.status === 400) {
                //var erroresC = {};
                Object.entries(response.errors).map(([key, value]) => {
                    //erroresC[key] = value[0];
                    //setErroresCliente(erroresC);
                    Swal.fire('Error', value[0], 'error');
                });
                setError('Revisar errores');
                return;
            } else if (response.hasOwnProperty('codigo') && response.codigo !== 200) {
                Swal.fire('Error', response.mensajes[0], 'error'); //setError(response.mensajes[0]);
                return;
            }
            Swal.fire('Exito', 'Articulo descargado', 'success');
            /*
            alertService.success('Articulo Descargada', {
                keepAfterRouteChange: true,
                autoClose: true
            });*/
        });
    };

    const actualizarCodigosXSucursal = () => {
    
        if(sucursalIdOri === sucursalIdDes)
        {
            Swal.fire('Atención', 'Seleccione conexiones diferentes', 'info');
            return;
        }

        Swal.fire({
            text: '¿Actualizar los codigos con la conexión seleccionada?',
            showCancelButton: true,
            confirmButtonText: 'Actualizar',
            cancelButtonText: 'Cancelar'
        }).then((result) => {
            if (result.isConfirmed) {
                fetchConToken(
                    `articulos/ActualizarCodigosSucursal?empresa_id=${getDataSession(
                        'empresa'
                    )}&sucursal_ori=${sucursalIdOri}&sucursal_des=${sucursalIdDes}`,
                    true
                )
                    .then((response) => {
                        getData(1);
                        Swal.fire('Procesando', 'Actualizando codigos', 'success');
                    })
                    //.catch(alertService.error);
            }
        });
    };

    const actualizarCodigos = () => {
        const formData = new FormData();

        formData.append('empresa_id', getDataSession('empresa'));
        formData.append('sucursal_id', sucursalIdDes);
        formData.append('tipo', 'excel');

        formData.append('FileToUpload', archivo[0], archivo[0].name);

        fetchConToken('articulos/ActualizarCodigos?codigo_actualizacion='+actuClave, formData, 'POST', true);
        Swal.fire('Procesando', 'Actualizando codigos', 'success');
        closeModal();
            //.catch(alertService.error);
    };

    function handleChangeOri(e) {
        setSucursalIdOri(e.target.value);
    }

    function handleChangeActClave(e) {
        setActuClave(e.target.value);
    }
    
    function handleChangeDes(e) {
        setSucursalIdDes(e.target.value);
    }

    const handleInputChangeFileConfig = ({ target }) => {
        setArchivo(target.files);
    };

    function opcionModal(opcion) {
        setModalValor(opcion);
        openModal();
    }
    function descargarPlantilla(){
        descargarFactura(0,'articulos/PlantillaActualizacion',"plantilla_carga_codigos_articulos.xlsx");
    }  
    
    function descargarArticulos(){
        descargarFactura(0,`articulos/DescargaExcel?sucursal_id=${getDataSession('sucursal')}`,"articulos.xlsx");
    }  
    

    return (
        <>
            <div className='container-fluid'>
                <TituloPagina
                    titulo='Artículos registrados'
                    descripcion='Aquí se guardan los artículos de su tienda.'
                    extra={mensajeArticulosDescargados}
                />
                
                <div className='col-12 col-sm-12 col-md-12'>
                   
                            <Link to={`${path}/add`} className='btn btn-outline-primary m-1'>
                                <svg
                                    xmlns='http://www.w3.org/2000/svg'
                                    width='20'
                                    height='20'
                                    fill='currentColor'
                                    className='bi bi-plus-square'
                                    viewBox='0 0 16 16'
                                >
                                    <path d='M14 1a1 1 0 0 1 1 1v12a1 1 0 0 1-1 1H2a1 1 0 0 1-1-1V2a1 1 0 0 1 1-1h12zM2 0a2 2 0 0 0-2 2v12a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2V2a2 2 0 0 0-2-2H2z' />
                                    <path d='M8 4a.5.5 0 0 1 .5.5v3h3a.5.5 0 0 1 0 1h-3v3a.5.5 0 0 1-1 0v-3h-3a.5.5 0 0 1 0-1h3v-3A.5.5 0 0 1 8 4z' />
                                </svg>
                                &nbsp; Nuevo
                            </Link>
                            &nbsp;
                            <button
                                className='btn btn-outline-primary m-1'
                                onClick={(e) => DescargarTienda()}
                                style={{ width: 'auto' }}
                                disabled={descargaActiva}
                            >
                                <svg
                                    xmlns='http://www.w3.org/2000/svg'
                                    width='20'
                                    height='20'
                                    fill='currentColor'
                                    className='bi bi-cloud-arrow-down-fill'
                                    viewBox='0 0 16 16'
                                >
                                    <path d='M8 2a5.53 5.53 0 0 0-3.594 1.342c-.766.66-1.321 1.52-1.464 2.383C1.266 6.095 0 7.555 0 9.318 0 11.366 1.708 13 3.781 13h8.906C14.502 13 16 11.57 16 9.773c0-1.636-1.242-2.969-2.834-3.194C12.923 3.999 10.69 2 8 2zm2.354 6.854-2 2a.5.5 0 0 1-.708 0l-2-2a.5.5 0 1 1 .708-.708L7.5 9.293V5.5a.5.5 0 0 1 1 0v3.793l1.146-1.147a.5.5 0 0 1 .708.708z' />
                                </svg>
                                &nbsp; Descargar articulos
                            </button>
                            &nbsp;
                            <button
                                className='btn btn-outline-primary m-1'
                                onClick={(e) => opcionModal('descargarArticulo')}
                                style={{ width: 'auto' }}
                            >
                                <svg
                                    xmlns='http://www.w3.org/2000/svg'
                                    width='20'
                                    height='20'
                                    fill='currentColor'
                                    className='bi bi-cloud-arrow-down'
                                    viewBox='0 0 16 16'
                                >
                                    <path
                                        fill-rule='evenodd'
                                        d='M7.646 10.854a.5.5 0 0 0 .708 0l2-2a.5.5 0 0 0-.708-.708L8.5 9.293V5.5a.5.5 0 0 0-1 0v3.793L6.354 8.146a.5.5 0 1 0-.708.708l2 2z'
                                    />
                                    <path d='M4.406 3.342A5.53 5.53 0 0 1 8 2c2.69 0 4.923 2 5.166 4.579C14.758 6.804 16 8.137 16 9.773 16 11.569 14.502 13 12.687 13H3.781C1.708 13 0 11.366 0 9.318c0-1.763 1.266-3.223 2.942-3.593.143-.863.698-1.723 1.464-2.383zm.653.757c-.757.653-1.153 1.44-1.153 2.056v.448l-.445.049C2.064 6.805 1 7.952 1 9.318 1 10.785 2.23 12 3.781 12h8.906C13.98 12 15 10.988 15 9.773c0-1.216-1.02-2.228-2.313-2.228h-.5v-.5C12.188 4.825 10.328 3 8 3a4.53 4.53 0 0 0-2.941 1.1z' />
                                </svg>
                                &nbsp; Descargar articulo
                            </button>
                            &nbsp;
                            <button className='btn btn-outline-primary m-1' type='button' onClick={()=>descargarArticulos()}>
                            <svg
                                    xmlns='http://www.w3.org/2000/svg'
                                    width='20'
                                    height='20'
                                    fill='currentColor'
                                    className='bi bi-file-excel'
                                    viewBox='0 0 16 16'
                                >
                                    <path d='M5.18 4.616a.5.5 0 0 1 .704.064L8 7.219l2.116-2.54a.5.5 0 1 1 .768.641L8.651 8l2.233 2.68a.5.5 0 0 1-.768.64L8 8.781l-2.116 2.54a.5.5 0 0 1-.768-.641L7.349 8 5.116 5.32a.5.5 0 0 1 .064-.704z' />
                                    <path d='M4 0a2 2 0 0 0-2 2v12a2 2 0 0 0 2 2h8a2 2 0 0 0 2-2V2a2 2 0 0 0-2-2H4zm0 1h8a1 1 0 0 1 1 1v12a1 1 0 0 1-1 1H4a1 1 0 0 1-1-1V2a1 1 0 0 1 1-1z' />
                                </svg>
                                Exportar Artículos</button>
                            &nbsp;
                            <button
                                className='btn btn-outline-primary m-1'
                                onClick={(e) => opcionModal('actualizacionCodigosExcel')}
                                style={{ width: 'auto' }}
                            >
                                <svg
                                    xmlns='http://www.w3.org/2000/svg'
                                    width='20'
                                    height='20'
                                    fill='currentColor'
                                    className='bi bi-file-excel'
                                    viewBox='0 0 16 16'
                                >
                                    <path d='M5.18 4.616a.5.5 0 0 1 .704.064L8 7.219l2.116-2.54a.5.5 0 1 1 .768.641L8.651 8l2.233 2.68a.5.5 0 0 1-.768.64L8 8.781l-2.116 2.54a.5.5 0 0 1-.768-.641L7.349 8 5.116 5.32a.5.5 0 0 1 .064-.704z' />
                                    <path d='M4 0a2 2 0 0 0-2 2v12a2 2 0 0 0 2 2h8a2 2 0 0 0 2-2V2a2 2 0 0 0-2-2H4zm0 1h8a1 1 0 0 1 1 1v12a1 1 0 0 1-1 1H4a1 1 0 0 1-1-1V2a1 1 0 0 1 1-1z' />
                                </svg>
                                &nbsp; Asignar por excel
                            </button>
                            &nbsp;
                            {getDataSession('rol') === 'EMPRESA' ||
                            getDataSession('rol') === 'ADMINISTRADOR' ? (
                                <button
                                    className='btn btn-outline-primary m-1'
                                    onClick={(e) => opcionModal('actualizacionCodigosSucursal')}
                                    style={{ width: 'auto' }}
                                >
                                    <svg
                                        xmlns='http://www.w3.org/2000/svg'
                                        width='20'
                                        height='20'
                                        fill='currentColor'
                                        className='bi bi-shop'
                                        viewBox='0 0 16 16'
                                    >
                                        <path d='M2.97 1.35A1 1 0 0 1 3.73 1h8.54a1 1 0 0 1 .76.35l2.609 3.044A1.5 1.5 0 0 1 16 5.37v.255a2.375 2.375 0 0 1-4.25 1.458A2.371 2.371 0 0 1 9.875 8 2.37 2.37 0 0 1 8 7.083 2.37 2.37 0 0 1 6.125 8a2.37 2.37 0 0 1-1.875-.917A2.375 2.375 0 0 1 0 5.625V5.37a1.5 1.5 0 0 1 .361-.976l2.61-3.045zm1.78 4.275a1.375 1.375 0 0 0 2.75 0 .5.5 0 0 1 1 0 1.375 1.375 0 0 0 2.75 0 .5.5 0 0 1 1 0 1.375 1.375 0 1 0 2.75 0V5.37a.5.5 0 0 0-.12-.325L12.27 2H3.73L1.12 5.045A.5.5 0 0 0 1 5.37v.255a1.375 1.375 0 0 0 2.75 0 .5.5 0 0 1 1 0zM1.5 8.5A.5.5 0 0 1 2 9v6h1v-5a1 1 0 0 1 1-1h3a1 1 0 0 1 1 1v5h6V9a.5.5 0 0 1 1 0v6h.5a.5.5 0 0 1 0 1H.5a.5.5 0 0 1 0-1H1V9a.5.5 0 0 1 .5-.5zM4 15h3v-5H4v5zm5-5a1 1 0 0 1 1-1h2a1 1 0 0 1 1 1v3a1 1 0 0 1-1 1h-2a1 1 0 0 1-1-1v-3zm3 0h-2v3h2v-3z' />
                                    </svg>
                                    &nbsp; Asignar por conexión
                                </button>
                            ) : (
                                ''
                            )}
                        
                    
                </div>
                <br></br>
                <div className='col-md-12'>
                    <Table
                        columns={[
                            '',
                            'SKU',
                            'Nombre',
                            'URL',
                            'Clave SAT',
                            'No Identificacion',
                            'Clave Unidad',
                            'Existencias',
                            'Envio',
                            'Revisar',
                            getDataSession('sucursal') == 0 ? 'Conexión' : 'vacio',
                            'Acciones'
                        ]} 
                        rows={[
                            ['desplegable','id', 'tiene_Variante'],
                            ['text', 'sku'],
                            ['text', 'nombre'],
                            ['url', 'url'],
                            ['text', 'clave_Prod_Serv'],
                            ['text', 'no_Identificacion'],
                            ['text', 'clave_Unidad'],
                            ['text', 'existencias'],
                            ['bool', 'envio_Gratis'],
                            ['bool', 'requiere_Revision'],
                            getDataSession('sucursal') == 0
                                ? ['object', 'sucursal', 'nombre']
                                : ['vacio', 'sucursal'],
                                ['opciones','id']
                        ]}
                        opciones={"edit"}
                        path={path}
                        url={`/Articulos/List?sucursal_Id=${getDataSession('sucursal')}`}
                    ></Table>
                </div>
            </div>

            <Modal isOpen={isOpenModal} closeModal={closeModal}>
                {modalValor === 'descargarArticulo' ? (
                    <>
                        <br></br>
                        <div className='card text-left' style={{ padding: 10 }}>
                            <h6>E-Commerce</h6>
                            <div className='container'>
                                <div className=' row'>
                                    <h7>
                                        Descargar un articulo de E-Commerce, mediante la clave del
                                        articulo
                                    </h7>
                                    <input
                                        type='text'
                                        className='form-control'
                                        name='nombre'
                                        style={{ width: '320px' }}
                                        placeholder='Clave del articulo de Marketplace'
                                        value={CodigoArticulo || ''}
                                        onChange={handleInputChange}
                                    />
                                    &nbsp;&nbsp;
                                    <button
                                        className='btn btn-outline-primary'
                                        onClick={(e) => DescargarArticulo()}
                                        style={{ maxWidth: '200px' }}
                                    >
                                        <svg
                                            xmlns='http://www.w3.org/2000/svg'
                                            width='20'
                                            height='20'
                                            fill='currentColor'
                                            className='bi bi-plus-square'
                                            viewBox='0 0 16 16'
                                        >
                                            <path d='M14 1a1 1 0 0 1 1 1v12a1 1 0 0 1-1 1H2a1 1 0 0 1-1-1V2a1 1 0 0 1 1-1h12zM2 0a2 2 0 0 0-2 2v12a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2V2a2 2 0 0 0-2-2H2z' />
                                            <path d='M8 4a.5.5 0 0 1 .5.5v3h3a.5.5 0 0 1 0 1h-3v3a.5.5 0 0 1-1 0v-3h-3a.5.5 0 0 1 0-1h3v-3A.5.5 0 0 1 8 4z' />
                                        </svg>
                                        &nbsp; Descargar articulo
                                    </button>
                                </div>
                            </div>
                        </div>
                    </>
                ) : (
                    ''
                )}
                {modalValor === 'actualizacionCodigosExcel' ? (
                    <>
                        <br></br>
                        <div className='card text-left' style={{ padding: 10 }}>
                            <h6>Actualización de códigos</h6>
                            <div className='container'>
                                <div className='row col-md-12'>
                                    <div className='col-md-6'>
                                          <select 
                                            className='form-control' 
                                            onChange={handleChangeActClave}
                                            style={{ with: '350px' }}>
                                            <option value="sku">SKU</option>
                                            <option value="clave">Clave</option>
                                        </select>
                                    </div>
                                    <div className='col-md-6 text-right'>
                                    &nbsp;
                                &nbsp;
                                <button className='btn btn-outline-success' type='button' onClick={()=>descargarPlantilla()}>Descargar Plantilla</button>
                                &nbsp;
                                &nbsp;
                                <button
                                        className='btn btn-outline-primary '
                                        type='button'
                                        onClick={() => actualizarCodigos()}
                                        style={{ maxWidth: '200px' }}
                                    >
                                        Cargar codigos
                                    </button>
                                    </div>
                                </div>
                             
                                <br></br>
                                <div className='row'>
                                    
                                    <div className='col-md-6'>
                                    <h7>Configurar codigos mediante excel:</h7>
                                    <input
                                        type='file'
                                        onChange={handleInputChangeFileConfig}
                                        style={{ maxWidth: '320px' }}
                                    />
                                    </div>
                                    <div className='col-md-6'>
                                    <h7>
                                        Conexión de destino: &nbsp;
                                        &nbsp;
                                    </h7>
                                        <select
                                            className='form-control'
                                            onChange={handleChangeDes}
                                            value={sucursalIdDes}
                                            style={{ with: '350px' }}
                                        >
                                            {sucursalesList?.map((item) => {
                                                return (
                                                    <option value={item.id} key={item.id}>
                                                        {item.nombre}
                                                    </option>
                                                );
                                            })}
                                        </select>
                                    </div>

                                
                                </div>
                            </div>
                        </div>
                    </>
                ) : (
                    ''
                )}

                {modalValor === 'actualizacionCodigosSucursal' ? (
                    <>
                        <br></br>
                        <div className='card text-left' style={{ padding: 10 }}>
                            <div className='container'>
                                <div className='row'>
                                    <div className='col-md-12 row'>
                                    <h6>Actualización de códigos respecto a las conexiones</h6>
                                    </div>
                                    
                                    <div className='col-md-6'>
                                    <h7>
                                       Conexión de origen: &nbsp;
                                        &nbsp;
                                    </h7>
                                        <select
                                            className='form-control'
                                            onChange={handleChangeOri}
                                            value={sucursalIdOri}
                                            style={{ with: '350px' }}
                                        >
                                            {sucursalesList?.map((item) => {
                                                return (
                                                    <option value={item.id} key={item.id}>
                                                        {item.nombre}
                                                    </option>
                                                );
                                            })}
                                        </select>
                                    </div>
                                    <div className='col-md-6'>
                                    <h7>
                                        Conexión de destino: &nbsp;
                                        &nbsp;
                                    </h7>
                                        <select
                                            className='form-control'
                                            onChange={handleChangeDes}
                                            value={sucursalIdDes}
                                            style={{ with: '350px' }}
                                        >
                                            {sucursalesList?.map((item) => {
                                                return (
                                                    <option value={item.id} key={item.id}>
                                                        {item.nombre}
                                                    </option>
                                                );
                                            })}
                                        </select>
                                    </div>
                                    <div className='col-md-12 text-right'>
                                        <br></br>
                                    <button
                                            className='btn btn-outline-primary'
                                            type='button'
                                            onClick={() => actualizarCodigosXSucursal()}
                                        >
                                            Actualizar codigos
                                        </button>
                                    </div>
                                    </div>
                                </div>
                        </div>
                    </>
                ) : (
                    ''
                )}
            </Modal>
        </>
    );
};

export { List };
