/* eslint-disable jsx-a11y/alt-text */
import React, { useState } from 'react';
import { string } from 'prop-types';
import { useHistory } from 'react-router-dom';
//import { Row } from 'simple-flexbox';
import { createUseStyles, useTheme } from 'react-jss';
//import { SidebarContext } from 'hooks/useSidebar';
import SLUGS from 'resources/slugs';
//import { IconBell } from 'assets/icons';
import DropdownComponent from 'components/dropdown';
import { getUser, removeUserSession,getNombre,getImagenPerfil } from 'views/Utils/Common';
import { fetchConToken } from 'helpers/fetch';
import { getDataSession,setDataSession } from 'views/Utils/Common';
import { Link } from 'react-router-dom';
import { useLocation } from "react-router-dom";
import Notificacion from 'components/alert/Notificacion';

import Swal from 'sweetalert2';

// IMAGES
import campana from 'images/panel/topbar/Iconcampana.svg';
import carrito from 'images/panel/topbar/Iconcarrito.svg';
import avatar from 'images/panel/topbar/avatar.png';
import Ayuda from 'images/panel/menu/menu/ayuda.svg';

const useStyles = createUseStyles((theme) => ({
    avatar: {
        height: 35,
        width: 35,
        minWidth: 35,
        borderRadius: 50,
        marginLeft: 14,
        border: `1px solid ${theme.color.lightGrayishBlue2}`,
        '@media (max-width: 768px)': {
            marginLeft: 14
        }
    },
    container: {
        height: 40,
        padding: '25px',
        background: '#FFFF'
    },
    name: {
        ...theme.typography.itemTitle,
        textAlign: 'right',
        '@media (max-width: 768px)': {
            display: 'none'
        }
    },
    separator: {
        borderLeft: `1px solid ${theme.color.lightGrayishBlue2}`,
        marginLeft: 32,
        marginRight: 32,
        height: 32,
        width: 2,
        '@media (max-width: 768px)': {
            marginLeft: 14,
            marginRight: 0
        }
    },
    title: {
        ...theme.typography.title,
        '@media (max-width: 1080px)': {
            marginLeft: 50
        },
        '@media (max-width: 468px)': {
            fontSize: 20
        },
        color: '#0B1F44',
        fontSize: '20px'
    },
    iconStyles: {
        cursor: 'pointer',
        marginLeft: 25,
        '@media (max-width: 768px)': {
            marginLeft: 12
        },
        zIndex: 10
    }
}));

function HeaderComponent() {
    let location = useLocation();
    
    const { push } = useHistory();
    //const { currentItem } = useContext(SidebarContext);
    const theme = useTheme();
    const classes = useStyles({ theme });
    // eslint-disable-next-line
    //let [sucursal, setSucursal] = useState([]);
    const [totalxPagina] = useState(5);
    const [logoAvatar, setLogo] = useState(avatar);
    let [sucursales, setSucursales] = useState(['']);

    let [sucursalId, setSucursalId] = useState(getDataSession('sucursal'));

    var rol = getDataSession('rol');

    async function getSucursales() {
        const responseSucursal = await fetchConToken(
            `Sucursales/GetList?empresa_id=${getDataSession(
                'empresa'
            )}&pagina=1&totalXpagina=100`
        );
        setSucursales(responseSucursal);
    }
    /* 
    let title;
    switch (true) {
        case currentItem === SLUGS.dashboard:
            title = 'Dashboard';
            break;
        case [SLUGS.puntos, SLUGS.actividadps].includes(currentItem):
            title = 'Programa';
            break;
        case currentItem === SLUGS.actividadp:
            title = 'Movimientos';
            break;
        case currentItem === SLUGS.ventasp:
            title = 'Ventas';
            break;
        case currentItem === SLUGS.clientes:
            title = 'Clientes';
            break;
        case [SLUGS.puntos, SLUGS.ideasThree].includes(currentItem):
            title = 'Configuraciones';
            break;
        case currentItem === SLUGS.sucursal:
            title = 'Sucursales';
            break;
        case currentItem === SLUGS.referidosp:
            title = 'Referidos';
            break;
        case currentItem === SLUGS.users:
            title = 'Usuarios';
            break;
        case currentItem === SLUGS.notificacionp:
            title = 'Formatos de correos';
            break;
        case currentItem === SLUGS.estadisticas:
            title = 'Performance';
            break;
        case currentItem === SLUGS.promociones:
            title = 'Promociones';
            break;
        case currentItem === SLUGS.recompensas:
            title = 'Configuración de canjes';
            break;
        case currentItem === SLUGS.campanias:
            title = 'Campañas';
            break;
        case currentItem === SLUGS.subscription:
            title = 'Newsletter';
            break;
        case currentItem === SLUGS.settings:
            title = 'Configuración general';
            break;
        case currentItem === SLUGS.departamentos:
            title = 'Departamentos';
            break;
        case currentItem === SLUGS.categorias:
            title = 'Categorías';
            break;
        case currentItem === SLUGS.etiquetas:
            title = 'Etiquetas';
            break;
        case currentItem === SLUGS.articulos:
            title = 'Artículos';
            break;
        case currentItem === SLUGS.roles:
            title = 'Roles de usuario';
            break;
        case currentItem === SLUGS.slider:
            title = 'Carrousel Home';
            break;
        case currentItem === SLUGS.sliderCliente:
            title = 'Carrousel Cliente';
            break;
        case currentItem === SLUGS.facturas:
            title = 'Facturas';
            break;
        default:
            title = 'Factuxin';
    }
    */

    let [timbresEmpresa, setTimbresEmpresa] = useState([]);

    async function getTimbresEmpresa() {
        const response = await fetchConToken(
            `TimbresEmpresas`
        );
        setTimbresEmpresa(response);
    }

    async function getLogo(page) {
        const response = await fetchConToken(
            `ConfiguracionesEmpresas/GetList?paginas=${page}&totalXPagina=${totalxPagina}&busqueda=Logo`
        );
        setLogo((response != null && response.length > 0 && response[0]?.valor != null) ? response[0]?.valor : avatar)
    }

    React.useEffect(() => {
        getTimbresEmpresa();
        //if(rol === 'EMPRESA')
           getSucursales();
        getLogo(1);
    }, []);

    /*React.useEffect(()=>{
        // var token = 
      fetchConToken(`sucursales/Lista?pagina=1&totalxPagina=10&empresaId=${getDataSession('empresa')}`)
        .then(response => {
            response.forEach(r=>{
            sucursal.push({
                label: r.nombre,
                onClick: () => onSucursalesClick(r.id,r.nombre)
            })
        })
        })
        // eslint-disable-next-line
    }, []); */

    function salir() {
        Swal.fire({
            text: 'Esta apunto de salir del panel',
            icon: 'warning',
            showCancelButton: true,
            confirmButtonText: 'Salir',
            cancelButtonText: 'Cancelar'
        }).then((result) => {
            if (result.isConfirmed) {
                removeUserSession();
                push('..');
            }
        })

    }

    function onSettingsClick(slug) {
        push(slug);
    }

    function handleChange(e) {
        setSucursalId(e.target.value);
        setDataSession('sucursal',e.target.value)
        window.location.href = window.location.href;
    }

    return (
        <>
            <div className='topbar'>
                <div className='position-relative w-100 min-180'>
                    {/*rol === 'EMPRESA' ? */}
                    <select className='form-control' onChange={handleChange} value={sucursalId}>
                        <option value={0}>Todas las conexiones</option>
                    {sucursales?.map((item) => {
                        return <option value={item.id}>{item.nombre}</option>
                     })}
                    </select>
                    {/*<input
                        className='input-search'
                        placeholder='Ejecutar una busqueda'
                        type='search'
                        name='searchbar'
                        id='searchbarID'
    />*/}
                </div>
                <div className='d-flex p-1'>
                    <div className='sellos-nuevos'>
                        <span className='sellos-text' style={{ color: '#fff' }}>
                            {timbresEmpresa.hasOwnProperty('total_Usados') ? timbresEmpresa.total_Usados : '0'} Usados
                        </span>
                    </div>
                    <div className='sellos-disponibles'>
                        <div className='sellos-numero'>
                            <span className='sellos-text' style={{ color: '#1E3888' }}>
                                {timbresEmpresa.hasOwnProperty('total_Restantes') ? timbresEmpresa.total_Restantes : '0'}
                            </span>
                        </div>
                        <span className='sellos-text' style={{ color: '#FFFFFF' }}>
                            disponibles
                        </span>
                    </div>
                </div>
                <div className='d-flex justify-content-around p-2'>
                <div className={classes.iconStyles}>
                <Link to={SLUGS.ayuda}>
                        <img  src={Ayuda} style={{width: '24px'}}/>
                    </Link >
                    </div>

                <div className={classes.iconStyles}>
                    <Link to={SLUGS.carrito}>
                        <img className='icon-topbar' src={carrito} />
                    </Link >
                    <label style={{ position: 'absolute', marginTop: '-40px', marginLeft: '17px', color: 'red', width: '10px', textAlign: 'center' }}>{getDataSession('carrito')}</label>
                </div>
                </div>
                <DropdownComponent
                    label={
                        <>
                            <div
                            className='menu-user'
                                style={{
                                    display: 'grid',
                                    gridTemplateColumns: 'repeat(2,max-content)',
                                    gap: 15,
                                    marginLeft: 26
                                }}
                            >
                                <img className='avatar-user menu-user' src={getImagenPerfil() || avatar}  alt="avatar" />
                                <div className='menu-user'
                                    style={{
                                        display: 'grid',
                                        gridTemplateRows: 'repeat(2,max-content)',
                                        alignSelf: 'center'
                                    }}
                                >
                                    <span className='username menu-user'>{getNombre()}</span>
                                    <span className='user-rol menu-user'>{getDataSession('rol')}</span>
                                </div>
                            </div>
                        </>
                    }
                    
                    options={[
                        {
                            label: 'Mi Perfil',
                            style: 'z-index: 10',
                            rol: 'ADMINISTRADOR,EMPRESA,SUCURSAL',
                            onClick: (e) => onSettingsClick(SLUGS.miperfil)
                        },
                        {
                            label: 'Configuración General',
                            style: 'z-index: 10',
                            rol: 'ADMINISTRADOR,EMPRESA',
                            onClick: (e) => onSettingsClick(SLUGS.empresa)
                        },
                        {
                            label: 'Sellos digitales/Fiel',
                            style: 'z-index: 10',
                            rol: 'ADMINISTRADOR,EMPRESA',
                            onClick: (e) => onSettingsClick(SLUGS.sellosfiscales)
                        },
                        {
                            label: 'Conexiones',
                            style: 'z-index: 10',
                            rol: 'ADMINISTRADOR,EMPRESA',
                            onClick: (e) => onSettingsClick(SLUGS.sucursales)
                        },
                        {
                            label: 'Usuarios',
                            style: 'z-index: 10',
                            rol: 'ADMINISTRADOR,EMPRESA',
                            onClick: (e) =>  onSettingsClick(SLUGS.usuarios)
                        },
                        {
                            label: 'Series empresa',
                            style: 'z-index: 10',
                            rol: 'ADMINISTRADOR,EMPRESA',
                            onClick: (e) =>  onSettingsClick(SLUGS.seriesEmpresa)
                        },
                        {
                            label: 'Mis transacciones',
                            style: 'z-index: 10',
                            rol: 'ADMINISTRADOR,EMPRESA',
                            onClick: (e) =>  onSettingsClick(SLUGS.pagos)
                        },
                        {
                            label: 'Mis paquetes',
                            style: 'z-index: 10',
                            rol: 'ADMINISTRADOR,EMPRESA',
                            onClick: (e) =>  onSettingsClick(SLUGS.movimientos)
                        },
                        {
                            label: 'Comprar paquetes',
                            style: 'z-index: 10',
                            rol: 'ADMINISTRADOR,EMPRESA',
                            onClick: (e) =>  onSettingsClick(SLUGS.planesyprecios)
                        },
                        {
                            label: 'Ayuda',
                            style: 'z-index: 10',
                            rol: 'ADMINISTRADOR,EMPRESA,SUCURSAL',
                            onClick: (e) =>  onSettingsClick(SLUGS.ayuda)
                        },
                        {
                            label: 'Salir',
                            rol: 'ADMINISTRADOR,EMPRESA,SUCURSAL',
                            onClick: (e) => salir()
                        }
                    ]}
                />
            </div>
            <Notificacion path={location.pathname} />
        </>
        // <Row className={classes.container } vertical='center' horizontal='space-between'>
        //     <span className={classes.title}>{title}</span>
        //     <Row vertical='center' style={{zIndex: 10}}>
        //         <div className={classes.iconStyles}>
        //             {/*<IconSearch />*/}
        //         </div>

        //         <div className={classes.iconStyles}>
        //             <DropdownComponent
        //                 label={<IconBell />}
        //                 options={[
        //                     {
        //                         label: 'Notificacion #1',
        //                         onClick: () => console.log('Notificacion #1')
        //                     },
        //                     {
        //                         label: 'Notificacion #2',
        //                         onClick: () => console.log('Notificacion #2')
        //                     },
        //                     {
        //                         label: 'Notificacion #3',
        //                         onClick: () => console.log('Notificacion #3')
        //                     },
        //                     {
        //                         label: 'Notificacion #4',
        //                         onClick: () => console.log('Notificacion #4')
        //                     }
        //                 ]}
        //                 position={{
        //                     top: 42,
        //                     right: -14
        //                 }}
        //             />
        //         </div>
        //         <div className={classes.iconStyles}>
        //             <label>{getDataSession('sucursalNombre')}</label>
        //         </div>
        //         <div className={classes.iconStyles}>
        //             <DropdownComponent
        //                 label= {
        //                         <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-building" viewBox="0 0 16 16">
        //                             <path fillRule="evenodd" d="M14.763.075A.5.5 0 0 1 15 .5v15a.5.5 0 0 1-.5.5h-3a.5.5 0 0 1-.5-.5V14h-1v1.5a.5.5 0 0 1-.5.5h-9a.5.5 0 0 1-.5-.5V10a.5.5 0 0 1 .342-.474L6 7.64V4.5a.5.5 0 0 1 .276-.447l8-4a.5.5 0 0 1 .487.022zM6 8.694 1 10.36V15h5V8.694zM7 15h2v-1.5a.5.5 0 0 1 .5-.5h2a.5.5 0 0 1 .5.5V15h2V1.309l-7 3.5V15z"/>
        //                             <path d="M2 11h1v1H2v-1zm2 0h1v1H4v-1zm-2 2h1v1H2v-1zm2 0h1v1H4v-1zm4-4h1v1H8V9zm2 0h1v1h-1V9zm-2 2h1v1H8v-1zm2 0h1v1h-1v-1zm2-2h1v1h-1V9zm0 2h1v1h-1v-1zM8 7h1v1H8V7zm2 0h1v1h-1V7zm2 0h1v1h-1V7zM8 5h1v1H8V5zm2 0h1v1h-1V5zm2 0h1v1h-1V5zm0-2h1v1h-1V3z"/>
        //                         </svg>
        //                        }
        //                 options={sucursal}
        //                 position={{
        //                     top: 42,
        //                     right: -14
        //                 }}
        //             />
        //         </div>

        //         <div className={classes.iconStyles}>
        //             <Link to={SLUGS.carrito}>
        //                     <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-cart-fill" viewBox="0 0 16 16">
        //                     <path d="M0 1.5A.5.5 0 0 1 .5 1H2a.5.5 0 0 1 .485.379L2.89 3H14.5a.5.5 0 0 1 .491.592l-1.5 8A.5.5 0 0 1 13 12H4a.5.5 0 0 1-.491-.408L2.01 3.607 1.61 2H.5a.5.5 0 0 1-.5-.5zM5 12a2 2 0 1 0 0 4 2 2 0 0 0 0-4zm7 0a2 2 0 1 0 0 4 2 2 0 0 0 0-4zm-7 1a1 1 0 1 1 0 2 1 1 0 0 1 0-2zm7 0a1 1 0 1 1 0 2 1 1 0 0 1 0-2z"/>
        //                </svg>
        //             </Link >
        //             <label>{getDataSession('carrito')}</label>
        //         </div>

        //         <div className={classes.separator}></div>
        //         <DropdownComponent
        //             label={
        //                 <>
        //                     <span className={classes.name}>{getUser()}</span>
        //                     <img
        //                         src='https://avatars3.githubusercontent.com/u/21162888?s=460&v=4'
        //                         alt='avatar'
        //                         className={classes.avatar}
        //                     />
        //                 </>
        //             }
        //             options={[
        //                 {
        //                     label: 'Configuración',
        //                     style: 'z-index: 10',
        //                     onClick: onSettingsClick
        //                 },
        //                 {
        //                     label: 'Salir',
        //                     onClick:  () => {
        //                         removeUserSession();
        //                         push('..');
        //                     }
        //                 }
        //             ]}
        //             position={{
        //                 top: 52,
        //                 right: -6
        //             }}
        //         />
        //     </Row>
        // </Row>
    );
}

HeaderComponent.propTypes = {
    title: string
};

export default HeaderComponent;
